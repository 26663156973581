// vuex
import { MutationTree } from "vuex";

// Interface
import { ISupportCustom } from "@/interfaces/customs/ISupportCustom";

const getters: MutationTree<ISupportCustom> = {
    
    getTicketViewStatus(state) {
        return state.ticketViewStatus
    },

    getChatMessages(state) {
        return state.chatMessages
    },

    getTicketsStatus(state) {
        return state.ticketsStatus
    },

    getTicketInfo(state) {
        return state.ticketInfo
    },

    getTicketList(state) {
        return state.ticketList;
    },

    getTicketListStatus(state) {
        return state.ticketListStatus
    },

    getSupportDateRange(state) {
        return state.supportDateRange
    },

    getFirstTicketPages(state) {
        return state.firstTicketPage;
    },

    getLastTicketPages(state) {
        return state.lastTicketPage;
    }
}

export default getters;