import { GetterTree } from "vuex";
import { NotificationsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<NotificationsState, StateInterface> = {
  getNotificationsList(state) {
    return state.data.notifications.length >= 1
      ? state.data.notifications.map(
        ({
          id,
          description,
          type_notification,
          created_at: date,
          is_read: status,
          possible_id: possibleId
        }) => ({
          id,
          description,
          type: type_notification?.name,
          date,
          status,
          url: type_notification?.url_segment,
          possibleId
        })
      )
      : [];
  },
  // getResolutionReport4725View(state) {
  //   if (state.data.resolutionReport4725) {
  //     const {
  //       from_date_formatted: fromDate,
  //       to_date_formatted: toDate,
  //       created_at_formatted: date,
  //       user,
  //       care_center,
  //       reference_center,
  //       eps,
  //       status,
  //       regime,
  //       url
  //     } = state.data.resolutionReport4725;

  //     return {
  //       fromDate,
  //       toDate,
  //       date,
  //       requestedBy: user?.name,
  //       careCenter: care_center?.name,
  //       referenceCenter: reference_center?.name,
  //       eps: eps?.name,
  //       status,
  //       regime: regime?.name ?? "Todos",
  //       url
  //     };
  //   }

  //   return null;
  // },
};

export default getters;
