import { notificationsApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { NotificationsState } from "./state";

const actions: ActionTree<NotificationsState, StateInterface> = {
  async loadNotifications({ commit }, params) {
    commit("setNotifications");
    commit("setResponse");

    try {
      const { data: response } = await notificationsApi().get("/", { params });
      const { success, message, data } = response;

      if (!params.notification) commit("setNotifications", data);
      else commit("auth/setNotifications", data, { root: true });
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadTypeNotifications({ commit }) {
    commit("setTypeNotifications");
    commit("setResponse");

    try {
      const { data: response } = await notificationsApi().get(
        "/type-notifications"
      );
      const { success, message, data } = response;

      commit("setTypeNotifications", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async readNotification({ commit }, id) {
    commit("setResponse");

    try {
      const { data } = await notificationsApi().post(`/mark-as-read/${id}`);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;
