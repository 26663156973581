import { IPages } from "@/interfaces/customs/interface";
import { IDiagnostic, IRequestResponse } from "@/interfaces/global/interfaces";

export interface Datum {
  id: number;
  created_at: null | string;
  updated_at: null | string;
  code: string;
  description: string;
  status: string;
}

export interface Links {
  url: null | string;
  label: string;
  active: boolean;
}

export interface Response {
  success: boolean;
  message: string;
}

// Diagnostics V.2
export type DiagnosticsPages = Record<"diagnostics", IPages>;

export interface DiagnosticsList {
  id: IDiagnostic["id"];
  code: IDiagnostic["code"];
  description: IDiagnostic["description"];
  status: IDiagnostic["status"];
}

export interface DiagnosticsData {
  diagnostics: IDiagnostic[];
}

export interface DiagnosticsState {
  diagnosticCode: string;
  diagnosticDescription: string;

  // Diagnostics V.2
  data: DiagnosticsData;
  pages: DiagnosticsPages;
  response: IRequestResponse;
}

function state(): DiagnosticsState {
  return {
    diagnosticCode: "",
    diagnosticDescription: "",

    // Diagnostics V.2
    data: {
      diagnostics: [],
    },
    pages: {
      diagnostics: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
