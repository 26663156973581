import { GetterTree } from "vuex";
import { DiagnosticsState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<DiagnosticsState, StateInterface> = {
  getDiagnosticCode(state) {
    return state.diagnosticCode;
  },
  getDiagnosticDescription(state) {
    return state.diagnosticDescription;
  },

  // Diagnostics V.2
  getDiagnosticsList(state) {
    return state.data.diagnostics.length >= 1
      ? state.data.diagnostics.map(({ id, code, description, status }) => ({
          id,
          code,
          description,
          status,
        }))
      : [];
  },
};

export default getters;
