import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useAlertV2 } from "@/composables/useAlertV2";
import { useRedirectURL } from "@/composables/useRedirectURL";
import { certificatesApi } from "@/apis/certificatesApi";
import { IErrors } from "@/interfaces/customs/IShowErrors";
import { ICertificateStateCustom } from "@/interfaces/customs/ICertificatesCustom";
import {
  ICertificateCreate,
  ICertificateList,
  ICertificateUpdate,
  ILoadCertificateById,
} from "@/interfaces/global/ICertificates";

const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();
const { showAlert } = useAlertV2();
const { goToURLMutation } = useRedirectURL();

const actions: ActionTree<ICertificateStateCustom, StateInterface> = {
  async LoadCertificateList(
    { commit },
    { page, rows, centerRefId, careCenterId, type, search }
  ) {
    openMainLoader(true);
    commit("setCertificateListStatus", true);
    
    try {
      const certificatesURL = `?page=${page}&rows=${rows}&reference_center_id=${centerRefId}&care_center_id=${careCenterId}&type=${type}&search=${search}`;
      const certificateList = await certificatesApi().get(certificatesURL);

      const certificateListRef: ICertificateList[] =
        certificateList.data.data.data;
        
      const certificateListInfo = certificateListRef.map((certificate) => ({
        certificateId: certificate.id,
        centerRefId: certificate.reference_center.id,
        centerRefName: certificate.reference_center.name,
        date: certificate.created_at_formatted,
        patientName: `${certificate.patient.name} ${certificate.patient.last_name}`,
        patientId: certificate.patient.id,
        documentTypeId: certificate.patient.document_type_id,
        documentTypeNumber: certificate.patient.document,
        diagnostigId: certificate.diagnostic.id,
        diagnostigCode: certificate.diagnostic.code,
        diagnostig: certificate.diagnostic.description,
        type: certificate.type,
        email: certificate.patient.email,
        pdfURL: certificate.pdf_url,
        downloadTitle: `${certificate.type}#${certificate.id} | ${certificate.patient.name} ${certificate.patient.last_name}`,
        printStatus: !certificate.pdf_url,
      }));

      const certificateMessage = certificateList.data.message;

      const currentPage = certificateList.data.data.current_page;
      const lastPage = certificateList.data.data.last_page;

      commit("setCertificateList", certificateListInfo);
      commit("setFirstCertificatePages", currentPage);
      commit("setLastCertificatePages", lastPage);
      commit("setCertificateListStatus", false);
      commit("setCertificateStatus", "certificate/in-session");

      openMainLoader(false);
      showAlert(certificateMessage, "success");
      openMainLoader(false);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setCertificateList", []);
      commit("setCertificateListStatus", false);
      openMainLoader(false);

      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async CreateCertificate({ commit }, info: ICertificateCreate) {
    openMainLoader(true);
    commit("setCertificateListStatus", true);

    try {
      const certificatesURL = ``;
      const createCertificate = await certificatesApi().post(
        certificatesURL,
        info
      );

      const createCertificateMessage = createCertificate.data.message;

      commit("setCertificateStatus", "certificate/loading");

      goToURLMutation("CertificatesListView");
      openMainLoader(false);
      showAlert(createCertificateMessage, "success");
      openMainLoader(false);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setCertificateList", []);
      commit("setCertificateListStatus", false);
      openMainLoader(false);

      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async LoadCertificateById({ commit }, id: number) {
    openMainLoader(true);
    commit("setCertificateListStatus", true);

    try {
      const certificatesURL = `/${id}`;
      const loadByIdCertificate = await certificatesApi().get(certificatesURL);

      const loadByIdCertificateRef: ILoadCertificateById =
        loadByIdCertificate.data.data;
        
      const patientRef = loadByIdCertificateRef.patient;
      const centerRef = loadByIdCertificateRef.reference_center;
      const diagnostigRef = loadByIdCertificateRef.diagnostic;

      const certificateUserTable = [
        {
          name: `${patientRef.name} ${patientRef.last_name}`,
          document: patientRef.document_type.name,
          documentNumber: patientRef.document,
          age: patientRef.age,
          gender: patientRef.gender,
          eps: patientRef.eps.name,
        },
      ];

      const userInfo = {
        certificateId: loadByIdCertificateRef.id,
        description: loadByIdCertificateRef.description,
        patientId: patientRef.id,
        gender:loadByIdCertificateRef.patient.gender,

        dates: {
          dateIni: loadByIdCertificateRef.start_date_formatted,
          dateEnd: loadByIdCertificateRef.end_date_formatted,
        },

        centerRef: {
          label: centerRef.name,
          value: centerRef.id,
        },
        diagnostig: {
          label: diagnostigRef.description,
          id: diagnostigRef.id,
          code: diagnostigRef.code,
          status: diagnostigRef.code,
          fullReference: diagnostigRef.description,
        },

        complementaryInfo: {
          delivery: loadByIdCertificateRef.delivery_date_formatted,
          gestationalAge: loadByIdCertificateRef.gestational_age,
          multiplPregnancy: loadByIdCertificateRef.multiple_pregnancy
            ? { label: "Si", value: 1 }
            : { label: "No", value: 0 },
          numberOfBirthsAlive: loadByIdCertificateRef.number_of_births_alive,
          aliveBirthCertificate: loadByIdCertificateRef.alive_birth_certificate,
        },

        type: {
          label: loadByIdCertificateRef.type,
          value: loadByIdCertificateRef.type,
        },

        downloads: {
          title: `${loadByIdCertificateRef.type} #${loadByIdCertificateRef.id} | ${loadByIdCertificateRef.patient.name} ${loadByIdCertificateRef.patient.last_name}`,
          pdfURL: loadByIdCertificateRef.pdf_url,
          status: !loadByIdCertificateRef.pdf_url,
          type: "application/pdf",
        },
      };

      const createCertificateMessage = loadByIdCertificate.data.message;

      commit("setUserCertificateInfo", userInfo);
      commit("setUserCertificateTable", certificateUserTable);

      openMainLoader(false);
      showAlert(createCertificateMessage, "success");
      openMainLoader(false);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setCertificateList", []);
      commit("setCertificateListStatus", false);
      openMainLoader(false);

      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async UpdateCertificateById(
    { commit },
    { certificateId, info }: { certificateId: string; info: ICertificateUpdate }
  ) {
    openMainLoader(true);
    commit("setCertificateListStatus", true);

    try {
      const certificatesURL = `/${certificateId}`;
      const createCertificate = await certificatesApi().post(certificatesURL, {
        ...info,
        _method: "PATCH",
      });

      const createCertificateMessage = createCertificate.data.message;

      commit("setCertificateStatus", "certificate/loading");

      goToURLMutation("CertificatesListView");
      openMainLoader(false);
      showAlert(createCertificateMessage, "success");
      openMainLoader(false);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setCertificateList", []);
      commit("setCertificateListStatus", false);
      openMainLoader(false);

      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async SendCertificateEmail({ commit }, certificateId: number) {
    openMainLoader(true);

    try {
      const sendCertificatesURL = `/${certificateId}/email`;
      const sendCertificate = await certificatesApi().post(sendCertificatesURL);
      const sendCertificateMessage = sendCertificate.data.message;
      commit("setCertificateStatus", "certificate/in-session");

      openMainLoader(false);
      showAlert(sendCertificateMessage, "success");
      openMainLoader(false);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      openMainLoader(false);
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },
};

export default actions;