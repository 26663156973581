import { useStore } from "vuex";
import { StateInterface } from '@/store/index';

export const useDigitalSignature = () => {

    const store = useStore<StateInterface>();

    return {
        // Actions
        isGetListDigitalSignature: (params:any) => store.dispatch('digitalSignature/getListDigitalSignature', params),
        isStatusListDigitalSignature: (data:any) => store.dispatch('digitalSignature/statusDigitalSignature', data),
        isDeleteDigitalSignature: (data:any) => store.dispatch('digitalSignature/deleteDigitalSignature', data),
        isGetDigitalSignature: (data:any) => store.dispatch('digitalSignature/getDigitalSignature', data),
        isEditDigitalSignature: (data:any) => store.dispatch('digitalSignature/createdDigitalSignature', data),
        isCreateDigitalSignature: (data:any) => store.dispatch('digitalSignature/createdDigitalSignature', data),
    }
}