import { ICertificateStateCustom } from "@/interfaces/customs/ICertificatesCustom";

function state(): ICertificateStateCustom {
    return {
      certificateList: [],
      firstCertificatePages: 0,
      lastCertificatePages: 0,
      certificateListStatus: false,
      certificateStatus: 'certificate/loading'
     
  }
}
  
  export default state;