import { ref } from 'vue';
const mainLoaderStatus = ref<boolean>(false)
const titleLoader = ref<string>('Cargando')
const loaderMessage = ref<string>('Por favor, espere un momento')

export const useMainLoader = () => {
    function openMainLoader(value: boolean) {
        if(value){
            mainLoaderStatus.value = value
        } else {
            setTimeout(() => {
                mainLoaderStatus.value = value
            }, 200)
        }
       
    }

    function setOpenFakeLoader(time = 600) {
        mainLoaderStatus.value = true
        setTimeout(() => {
            mainLoaderStatus.value = false
        }, time)
    }

    return {
        openMainLoader,
        titleLoader,
        mainLoaderStatus,
        loaderMessage,
        setOpenFakeLoader
    }
}