import { GetterTree } from "vuex";
import { IndicatorsUI } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<IndicatorsUI, StateInterface> = {
  getRowIndicators(state) {
    return state.rowIndicators;
  },

  getIndicatorsCurrentPage(state) {
    return state.indicatorsCurrentPage;
  },

  getIndicatorsLastPage(state) {
    return state.indicatorsLastPage;
  },

  getIndicatorListLoader(state) {
    return state.indicatorListLoader;
  },

  // view indicators
  getViewIndicatorsURL(state) {
    return state.viewIndicatorsURL;
  },

  getViewCardIndicator(state) {
    return state.viewCardIndicator;
  },

  getViewIndicatorListLoader(state) {
    return state.viewIndicatorListLoader;
  },
  getRowViewIndicators(state) {
    return state.rowViewIndicators;
  },
  getViewIndicatorsCurrentPage(state) {
    return state.viewIndicatorsCurrentPage;
  },
  getViewIndicatorsLastPage(state) {
    return state.viewIndicatorsTotalPage;
  },

  //create
  getIndicatorsYears(state) {
    return state.indicatorsYears;
  },

  // redirect
  getIndicatorsRedirectTo(state) {
    return state.indicatorsRedirectTo;
  },
};
export default getters;
