import { ActionTree } from 'vuex';
import { StateInterface } from '../index';
import { DigitalSignatureApi } from '@/apis/digitalSignatureApi';

const actions: ActionTree<any, StateInterface> = {

    async getListDigitalSignature( { }, data ) {

        const { token, params } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            params
        };
        try{
            const { data } = await DigitalSignatureApi.get('', config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async createdDigitalSignature( { }, data ) {

        const { token, formData } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
            }
        };

        try{
            const { data } = await DigitalSignatureApi.post('', formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async statusDigitalSignature( { }, data ) {

        const { id, token } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
            }
        };

        try{
            const { data } = await DigitalSignatureApi.post(`/changeStatus/${id}`, {}, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async deleteDigitalSignature( { }, data ) {

        const { id, token } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await DigitalSignatureApi.delete(`/${id}`, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async getDigitalSignature( { }, data:any ) {
        const { token, id } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await DigitalSignatureApi.get(`/get/${id}`, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    }

}

function errorExeption(error: any) {
    
    //No hay conexion
    if(error.message == 'Network Error'){
        const success = false;
        const { message } = error;
        return { success, message }; 
    }            
    const { data } = error.response;
    const { success, message } = data; 
    return { success, message };
}

export default actions;