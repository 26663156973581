export default function errorException(error: any) {
  // Error for connection
  if (error.message == "Network Error") {
    const success = false;
    const { message } = error;

    return { success, message };
  }

  const { data } = error.response;
  const { success, message } = data;

  return { success, message };
}