import {
  IData,
  IProcedure,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { Datum, ProceduresState } from "./state";

const mutation: MutationTree<ProceduresState> = {
  getProcedureById(state: ProceduresState, procedure: Datum): void {
    if (procedure) {
      const { code, description }: Datum = procedure;

      state.procedureCode = code;
      state.procedureDescription = description;
    }
  },
  clearProceduresData(state: ProceduresState): void {
    state.procedureCode = "";
    state.procedureDescription = "";
  },

  // Procedures V.2
  setProcedures(state: ProceduresState, data?: IData): void {
    if (data) {
      state.data.procedures = data.data as IProcedure[];

      state.pages.procedures.currentPage.value = data.current_page;
      state.pages.procedures.lastPage.value = data.last_page;
    } else {
      state.data.procedures = [];
    }
  },
  setResponse(state: ProceduresState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
