// outside of a Vue file
import Notify from 'quasar/src/plugins/Notify.js';

// Trigger Notification Function
export default function triggerNotification (
  message: string,
  timeout = 1000,
  type = "negative"
) {
  Notify.create({
    type,
    message,
    timeout,
  })
}
