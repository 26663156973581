import { ActionTree } from "vuex";
import { SchedulesState } from "./state";
import { StateInterface } from "../index";
import { schedulesApi } from "@/apis";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { IErrors } from "@/interfaces/customs/IShowErrors";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import {
  IScheduleListCard,
  IScheduleListV3,
} from "@/interfaces/global/Schedule";
import { schedulesApiV3 } from "@/apis/schedulesApi";

const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();

const actions: ActionTree<SchedulesState, StateInterface> = {
  async ScheduleListUpdate({ commit }, urlFilter: string) {
    // loader
    openMainLoader(true);
    //in table loader
    commit("setScheduleListBySpecialtyLoading", true);

    try {
      const doctorData = await schedulesApi().get(`/v3${urlFilter}`);
      const dataListRef = doctorData.data.data;

      const LastPage = dataListRef.schedules.last_page;
      const currentPage = dataListRef.schedules.current_page;

      const doctorsWithSchedule = dataListRef.schedules.data;
      const scheduleDashboard: IScheduleListCard = dataListRef.dashboard;

      const alertMessage = doctorData.data.message;
      const listSheduleRow = doctorsWithSchedule.map(
        (schedule: IScheduleListV3) => ({
          id: schedule.scheduleable.id,
          specialty: schedule.scheduleable.specialty.description,
          specialtyId: schedule.scheduleable.care_center.id,
          careCenter: schedule.scheduleable.care_center.name,
          careCenterId: schedule.scheduleable.care_center.id,
          doctor: schedule.scheduled_to.name,
          doctorId: schedule.scheduled_to.id,
          patient: schedule.scheduleable.patient.name,
          patientId: schedule.scheduleable.patient.id,
          document: schedule.scheduleable.patient.document,
          date: schedule.scheduleable.current_schedule.scheduled_at_formatted,
          sessionName: schedule.session_type.name,
          sessionId: schedule.session_type.id,
        })
      );

      const listSheduleCard = [
        {
          title: "Habilitadas",
          icon: "mdi-calendar",
          quote: scheduleDashboard.enabled,
        },
        {
          title: "Asignadas",
          icon: "mdi-calendar-clock",
          quote: scheduleDashboard.scheduled,
        },
        {
          title: "No asistidas",
          icon: "mdi-calendar-question",
          quote: scheduleDashboard.absenced,
        },
        {
          title: "Canceladas",
          icon: "mdi-calendar-remove",
          quote: scheduleDashboard.cancelled,
        },
        {
          title: "Reasignadas",
          icon: "mdi-calendar-start",
          quote: scheduleDashboard.rescheduled,
        },
      ];

      commit("setScheduleListCard", listSheduleCard);
      commit("setScheduleListBySpecialty", listSheduleRow);
      commit("setSchedulesCurrentPage", currentPage);
      commit("setSchedulesLastPage", LastPage);
      //loader
      openMainLoader(false);
      //table loader
      commit("setScheduleListBySpecialtyLoading", false);

      //alert
      commit("general/setAlertType", "success", { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit(
        "general/setAlertMessage",
        `¡${
          doctorsWithSchedule.length > 0 ? alertMessage : "Sin agendamientos"
        }!`,
        { root: true }
      );
      commit("general/setShowAlert", true, { root: true });
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      openMainLoader(false);
      //table loader
      commit("setScheduleListBySpecialtyLoading", false);
      //alert
      commit("general/setAlertType", "error", { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setShowAlert", true, { root: true });
    }
  },

  // available list update
  async ScheduleCancelAndAppointment(
    { commit, dispatch },
    {
      typeFilter,
      info,
      messageType,
      refreshURL
    }: {
      typeFilter: string;
      info: {
        session_id: number;
        reason: string;
        type: number;
      };
      messageType: string;
      refreshURL:string
    }
  ) {
  
    commit("general/setPopupLoadingMessage", messageType, { root: true });
    commit("general/setPopupLoading", true, { root: true });

    dispatch("ScheduleListUpdate", refreshURL);
    //in table loader
    commit("setScheduleListBySpecialtyLoading", true);

    try {
      const doctorData = await schedulesApiV3().post(`/v3/${typeFilter}`, info);

      const alertMessage = doctorData.data.message;

      //loader
      commit("general/setPopupOpen", false, { root: true });
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });

      //alert
      commit("general/setAlertType", "success", { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", `¡${alertMessage}!`, { root: true });
      commit("general/setShowAlert", true, { root: true });

      dispatch("ScheduleListUpdate", refreshURL);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      commit("general/setPopupOpen", false, { root: true });
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });

      //table loader
      commit("setScheduleListBySpecialtyLoading", false);
      //alert
      commit("general/setAlertType", "error", { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setShowAlert", true, { root: true });
    }
  },
};

export default actions;
