import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useNursingNotes = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    nursingNotesList: computed(() => store.state.nursingNotes.nursingNotesList),
    nursingNote: computed(() => store.state.nursingNotes.nursingNote),
    nursingNotesCurrentPage: computed(
      () => store.state.nursingNotes.nursingNotesCurrentPage
    ),
    nursingNotesLastPage: computed(
      () => store.state.nursingNotes.nursingNotesLastPage
    ),

    // Actions
    _getListNursingNotes: (
      token: string,
      referenceCenterId: number,
      careCenterId: number,
      page: number
    ) =>
      store.dispatch("nursingNotes/getNursingNotes", {
        token,
        referenceCenterId,
        careCenterId,
        page,
      }),
    _createNursingNotes: (token: string, bodyRequest: any, documents: any) =>
      store.dispatch("nursingNotes/createNursingNotes", {
        token,
        bodyRequest,
        documents,
      }),
    _getNursingNoteById: (token: string, id: unknown) =>
      store.dispatch("nursingNotes/getNursingNoteByID", {
        token,
        id,
      }),
  };
};
