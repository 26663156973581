import { IPages } from "@/interfaces/customs/interface";
import { IMedicine, IRequestResponse } from "@/interfaces/global/interfaces";
import { StateInterface } from "@/store/index";
import {
  MedicinesData,
  MedicinesList,
  MedicinesPages,
} from "@/store/medicines/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useMedicinesStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    // Medicines V.3
    medicines: computed<IMedicine[]>(
      () => store.state.medicines.data.medicines
    ),
    medicinesPages: computed<IPages>(
      () => store.state.medicines.pages.medicines
    ),
    data: computed<MedicinesData>(() => store.state.medicines.data),
    pages: computed<MedicinesPages>(() => store.state.medicines.pages),
    response: computed<IRequestResponse>(() => store.state.medicines.response),
    medicinesListSelector: computed(
      () => store.state.medicines.medicinesListSelector
    ),

    // Getters
    medicineCode: computed<string>(
      () => store.getters["medicines/getMedicineCode"]
    ),
    medicineDescription: computed<string>(
      () => store.getters["medicines/getMedicineDescription"]
    ),
    pbsValue: computed(() => store.getters["medicines/getPbsValue"]),

    // Medicines V.3
    getMedicinesList: computed<MedicinesList[]>(
      () => store.getters["medicines/getMedicinesList"]
    ),

    // Actions
    changeMedicineStatusById: (token: string, id: number) =>
      store.dispatch("medicines/changeMedicineStatusById", { token, id }),
    createMedicine: (token: string, bodyRequest: object) =>
      store.dispatch("medicines/createMedicine", { token, bodyRequest }),
    getMedicineById: (token: string, id: string | string[]) =>
      store.dispatch("medicines/getMedicineById", { token, id }),
    updateMedicine: (token: string, bodyRequest: object) =>
      store.dispatch("medicines/updateMedicine", {
        token,
        bodyRequest,
      }),

    // Medicines V.2
    isGetMedicinesV2: (data: any) =>
      store.dispatch("medicines/getMedicinesV2", data),

    medicinesListWithoutPaginate: (params: object | null) =>
      store.dispatch("medicines/medicinesListWithoutPaginate", params),

    // Medicines V.3
    loadMedicines: (params: {
      status: string;
      page: number;
      full_reference?: string;
      care_center_id?: number;
    }) => store.dispatch("medicines/loadMedicines", params),

    // Mutations
    // Medicines V.3
    setMedicines: () => store.commit("medicines/setMedicines"),
  };
};
