import {
  IData,
  IRequestResponse,
  INotification,
  ITypeNotification,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { NotificationsState } from "./state";

const mutation: MutationTree<NotificationsState> = {
  setNotifications(state: NotificationsState, data?: IData): void {
    if (data) {
      state.data.notifications = data.data as INotification[];

      state.pages.notifications.currentPage.value = data.current_page;
      state.pages.notifications.lastPage.value = data.last_page;
    } else {
      state.data.notifications = [];
    }
  },
  setTypeNotifications(state: NotificationsState, data?: ITypeNotification[]) {
    if (data) {
      state.data.typeNotifications = data;
    } else {
      state.data.typeNotifications = [];
    }
  },
  setResponse(state: NotificationsState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
};

export default mutation;
