export interface DisabilityUI {
  disabilitiesTableInfo: {
    age: string;
    cause: string;
    careCenter: string;
    careCenterId: number;
    dateFrom: string;
    dateEnd: string;
    days: string;
    description: string;
    diagnosticId: string;
    diagnosticCode: string;
    diagnosticName: string;
    disabilityId: number;
    documentId: string;
    documentType: string;
    documentTypeId: string;
    email: string;
    epsId: number;
    epsName: string;
    epsCode: string;
    gender: string;
    grade: string;
    patient: string;
    patientId: number;
    type: string;
  }[];

  desabilitiesLastPage: number;

  desabilitiesCurrentPage: number;

  currentPatientSearch:
    | {
        name: string;
        document: string;
        documentId: number;
        age: string;
        gender: string;
        eps: string;
        epsId: number;
        userId: number;
      }
    | object;

  diagnosticOptions: {
    label: string;
    id: number;
    code: string;
    status: string;
    fullReference: string;
  }[];

  disabilityInfoById:
    | {
        age: string;
        careCenter: number;
        cause: string;
        careCenterId: string;
        dateEnd: string;
        dateFrom: string;
        days: string;
        diagnostic: string;
        disabilityId: number;
        document: string;
        documentId: number;
        documentType: string;
        description: string;
        email: string;
        epsName: string;
        epsId: number;
        epsCode: string;
        grade: string;
        patient: string;
        patientId: number;
        pdfURL: string;
        type: string;
      }
    | object;
}

function state(): DisabilityUI {
  return {
    disabilitiesTableInfo: [],

    desabilitiesLastPage: 0,
    desabilitiesCurrentPage: 0,

    currentPatientSearch: {},
    diagnosticOptions: [],

    disabilityInfoById: {},
  };
}

export default state;
