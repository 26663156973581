import { IPages } from "@/interfaces/customs/interface";
import { IProcedure, IRequestResponse } from "@/interfaces/global/interfaces";

export interface Datum {
  id: number;
  created_at: null | string;
  updated_at: null | string;
  code: string;
  description: string;
  status: string;
}

export interface Links {
  url: null | string;
  label: string;
  active: boolean;
}

export interface Response {
  success: boolean;
  message: string;
}

// Procedures V.2
export type ProceduresPages = Record<"procedures", IPages>;

export interface ProceduresList {
  id: IProcedure["id"];
  code: IProcedure["code"];
  description: IProcedure["description"];
  status: IProcedure["status"];
}

export interface ProceduresData {
  procedures: IProcedure[];
}

export interface ProceduresState {
  procedureCode: string;
  procedureDescription: string;

  // Procedures V.2
  data: ProceduresData;
  pages: ProceduresPages;
  response: IRequestResponse;
}

function state(): ProceduresState {
  return {
    procedureCode: "",
    procedureDescription: "",

    // Procedures V.2
    data: {
      procedures: [],
    },
    pages: {
      procedures: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
  };
}

export default state;
