import { GetterTree } from "vuex";
import { FoliosState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<FoliosState, StateInterface> = {
  // Folios V.2
  getProcedures(state) {
    return state.data.procedures.length >= 1
      ? state.data.procedures.map(
          ({
            presentation_type: presentationType,
            usage_frequency: usageFrequency,
            ammount: amount,
            treatment_duration,
            treatment_duration_period,
            observation,
            warning_signs: warningSigns,
            procedure,
          }) => ({
            procedure: procedure?.description,
            presentationType,
            usageFrequency,
            amount,
            treatmentDuration: `${treatment_duration ?? ""} ${
              treatment_duration_period ?? ""
            }`,
            observation,
            warningSigns,
          })
        )
      : [];
  },
  getManagementPlans(state) {
    return state.data.managementPlans.length >= 1
      ? state.data.managementPlans.map(
          ({
            medicine,
            presentation_type: presentationType,
            dosage_form,
            route_administration: administrationRoute,
            administration_frequency: administrationFrequency,
            treatment_duration: treatmentDuration,
            amount,
            amount_unit,
            posology,
            warning_signs: warningSigns,
          }) => ({
            medicine: medicine?.description,
            presentationType,
            dosage: dosage_form ?? "",
            administrationRoute,
            administrationFrequency,
            treatmentDuration,
            amount: `${amount ?? ""} ${amount_unit ?? ""}`,
            posology,
            warningSigns,
          })
        )
      : [];
  },

  // Update endpoint
  getFolio(state) {
    if (state.data.folio) {
      const {
        current_schedule,
        reference_center,
        care_center,
        doctor,
        specialty,
        patient,
        name_companion_patient,
        phone_companion_patient,
        relationship_companion_patient,
        current_illness,
        reason_for_consultation,
        diagnostics,
      } = state.data.folio;

      return {
        date: current_schedule?.session_date ?? "Sin datos disponibles",
        referenceCenter: reference_center?.name ?? "Sin datos disponibles",
        careCenter: care_center?.name ?? "Sin datos disponibles",
        doctor: doctor?.name ?? "Sin datos disponibles",
        specialty: specialty?.description ?? "Sin datos disponibles",
        eps: patient?.eps?.name ?? "Sin datos disponibles",
        companionName: name_companion_patient ?? "Sin datos disponibles",
        companionPhone: phone_companion_patient ?? "Sin datos disponibles",
        companionRelationship:
          relationship_companion_patient ?? "Sin datos disponibles",
        currentIllness: current_illness ?? "Sin datos disponibles",
        reasonForConsultation: reason_for_consultation ?? "Sin datos disponibles",
        analysis:
          diagnostics?.length >= 1 ? diagnostics[0].description : "Sin datos disponibles",
        status: current_schedule?.status ?? "Sin datos disponibles",
      };
    }

    return null;
  },
  getVitalSigns(state) {
    if (state.data.vitalSigns) {
      const {
        average_blood_pressure: bloodPressure,
        heart_rate: heartRate,
        breathing_frequency: breathingFrequency,
        oxygen_saturation: oxygenSaturation,
        temperature,
        weight,
        height,
        imc,
        weight_level: imcDescription,
      } = state.data.vitalSigns;

      return {
        bloodPressure,
        heartRate,
        breathingFrequency,
        oxygenSaturation,
        temperature,
        weight,
        height,
        imc,
        imcDescription,
      };
    }

    return null;
  },
  getDiagnostics(state) {
    return state.data.diagnostics.length >= 1
      ? state.data.diagnostics.map(({ diagnostic }, index) => ({
          code: diagnostic?.code ?? "",
          description: diagnostic?.description ?? "",
          type: index == 0 ? "Principal" : "Secundario",
        }))
      : [];
  },
  getBackgrounds(state) {
    return state.data.backgrounds.length >= 1
      ? state.data.backgrounds.map(({ background, description }) => ({
          type: background?.name ?? "",
          description,
        }))
      : [];
  },
  getDocuments(state) {
    return state.data.documents.length >= 1
      ? state.data.documents.map(
          ({
            reference_center,
            date,
            description,
            url,
            mime_type: type,
            name,
          }) => ({
            center: reference_center?.name,
            date,
            description,
            url,
            type,
            name,
          })
        )
      : [];
  },
};

export default getters;
