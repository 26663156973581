import { ActionTree } from "vuex";
import { ProceduresState, Response } from "./state";
import { StateInterface } from "../index";
import { proceduresApi, proceduresApiV2, executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const URL_PATH = "/api/settings/procedures/v2";

const actions: ActionTree<ProceduresState, StateInterface> = {
  async changeProcedureStatusById(
    { commit },
    { token, id }
  ): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await proceduresApi().post(
        `/changeStatus/${id}`,
        {},
        config
      );
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createProcedure({ commit }, { token, bodyRequest }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await proceduresApi().post("/", bodyRequest, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getProcedureById({ commit }, { token, id }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await proceduresApi().get(`/${id}`, config);
      const { success, message, data: procedureData } = data;

      commit("getProcedureById", procedureData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async updateProcedure({ commit }, { token, bodyRequest }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await proceduresApi().post("/", bodyRequest, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  // Procedures V.2
  async loadProcedures({ commit }, params) {
    commit("setProcedures");
    commit("setResponse");

    try {
      const { data: response } = await proceduresApi().get("/", { params });
      const { success, message, data } = response;

      commit("setProcedures", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  // Procedures V.2
  async loadProceduresWithoutPaginate(
    { commit },
    { filters, page, urlRequest }
  ) {
    try {
      const { data: response } = await proceduresApiV2(
        filters,
        page,
        urlRequest
      ).get(``);
      const { success, message, data } = response;
      return { success, message, data };
    } catch (error) {
      return errorException(error);
    }
  },
  // Procedures V2 Selectors
  async loadProceduresWithoutPaginateV2({ commit }, params) {
    try {
      const { data: response } = await executeApi().get(URL_PATH, { params });
      const { success, message, data } = response;
      return { success, message, data };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
