import Echo, { Channel } from "@ably/laravel-echo";
import * as Ably from "ably";
import { IAblyService } from "@/interfaces/global/interfaces";
import triggerNotification from "@/functions/TriggerNotification";
import Pusher from "pusher-js";
export class AblyService implements IAblyService {
  private static instance: AblyService;
  private echo: Echo;
  private ablyRealtime: Ably.Realtime;
  private channels: Map<string, Ably.Types.RealtimeChannelCallbacks>;
  private tokenAbly: string;
  private tokenEcho: string;

  private constructor() {
    window.Pusher = Pusher;
    window.Ably = Ably;

    // Inicializamos Echo con la configuración de Ably
    this.echo = new Echo({
      broadcaster: "ably",
      authEndpoint: process.env.VUE_APP_ABLY_URL,
      bearerToken: sessionStorage.getItem("token"),
    });

    // // Inicializamos Ably Realtime
    this.ablyRealtime = new Ably.Realtime(process.env.VUE_APP_ABLY_KEY_DEV as string);
    this.channels = new Map<string, Ably.Types.RealtimeChannelCallbacks>();
    this.tokenAbly = "";
    this.tokenEcho = "";

    // Configuramos el evento de conexión
    this.echo.connector.ably.connection.on(
      (stateChange: Ably.Types.ConnectionStateChange) => {
        if (stateChange.current === "connected") {
          this.tokenEcho =
            this.echo.connector.ably.auth.client.auth.tokenDetails.token;
        }
      }
    );

    this.ablyRealtime.connection.on(
      "connected",
      (stateChange: Ably.Types.ConnectionStateChange) => {
        if (stateChange.current === "connected") {
          // @ts-ignore
          this.tokenAbly = this.ablyRealtime.auth.key;
        }
      }
    );
  }

  public static getInstance(): AblyService | null {
    const isAuthenticated = sessionStorage.getItem("isAuthenticated");

    if (!isAuthenticated || isAuthenticated !== "1") {
      return null;
    }

    if (!AblyService.instance) {
      AblyService.instance = new AblyService();
    }
    return AblyService.instance;
  }

  public getTokenRequestAbly(token: "ably" | "echo" = "ably"): string {
    return token == "echo" ? this.tokenEcho : this.tokenAbly;
  }

  public setAPiKeyAbly(API_KEY: string): void {
    this.ablyRealtime = new Ably.Realtime(API_KEY);
    this.tokenAbly = API_KEY;
  }

  private getChannel(channelName: string): Ably.Types.RealtimeChannelCallbacks {
    if (!this.channels.has(channelName)) {
      const channel = this.ablyRealtime.channels.get(channelName);
      this.channels.set(channelName, channel);
    }
    return this.channels.get(channelName)!;
  }

  public subscribeToPublicChannel(
    channelName: string,
    callback: (message: Ably.Types.Message) => void,
    eventName: string
  ): void {
    const channel = this.echo.channel(channelName);
    channel.stopListening(eventName, callback);
    channel.listen(eventName, callback);
  }

  public subscribeToPrivateChannel(
    channelName: string,
    callback: (message: Ably.Types.Message) => void,
    eventName: string
  ): void {
    const channel = this.echo.private(channelName);
    channel.stopListening(eventName, callback);
    channel.listen(eventName, callback);
  }

  public unsubscribeFromChannel(channelName: string): void {
    this.echo.leave(channelName);
  }

  public unsubscribeFromAllChannels(): void {
    this.echo.disconnect();
  }

  public publishToChannelFromAbly(
    channelName: string,
    eventName: string,
    message: Ably.Types.Message | string
  ): void {
    const channel = this.getChannel(channelName);
    channel.publish(eventName, message, (err) => {
      if (err) {
        triggerNotification(
          `Error publishing message to ${channelName}: ${err?.cause}`,
          1000,
          "negative"
        );
      } else {
        triggerNotification(
          `Message successfully published to ${channelName}`,
          1000,
          "positive"
        );
      }
    });
  }

  public suscribeToChannelFromAbly(
    channelName: string,
    callback: (message: Ably.Types.Message) => void,
    eventName: string
  ): Ably.Types.RealtimeChannelCallbacks {
    const channel = this.getChannel(channelName);
    channel.subscribe(eventName, (message) => {
      callback(message);
    });
    return channel;
  }
}
