import { IPages } from "@/interfaces/customs/interface";
import { IDiagnostic, IRequestResponse } from "@/interfaces/global/interfaces";
import {
  DiagnosticsData,
  DiagnosticsList,
  DiagnosticsPages,
} from "@/store/diagnostics/state";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { useStore } from "vuex";

export const useDiagnosticsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    // Diagnostics V.2
    diagnostics: computed<IDiagnostic[]>(
      () => store.state.diagnostics.data.diagnostics
    ),
    diagnosticsPages: computed<IPages>(
      () => store.state.diagnostics.pages.diagnostics
    ),
    data: computed<DiagnosticsData>(() => store.state.diagnostics.data),
    pages: computed<DiagnosticsPages>(() => store.state.diagnostics.pages),
    response: computed<IRequestResponse>(
      () => store.state.diagnostics.response
    ),

    // Getters
    diagnosticCode: computed<string>(
      () => store.getters["diagnostics/getDiagnosticCode"]
    ),
    diagnosticDescription: computed<string>(
      () => store.getters["diagnostics/getDiagnosticDescription"]
    ),

    // Diagnostics V.2
    getDiagnosticsList: computed<DiagnosticsList[]>(
      () => store.getters["diagnostics/getDiagnosticsList"]
    ),

    // Actions
    changeDiagnosticStatusById: (token: string, id: number) =>
      store.dispatch("diagnostics/changeDiagnosticStatusById", { token, id }),
    createDiagnostic: (token: string, bodyRequest: object) =>
      store.dispatch("diagnostics/createDiagnostic", { token, bodyRequest }),
    getDiagnosticById: (token: string, id: string | string[]) =>
      store.dispatch("diagnostics/getDiagnosticById", { token, id }),
    updateDiagnostic: (token: string, bodyRequest: object) =>
      store.dispatch("diagnostics/updateDiagnostic", {
        token,
        bodyRequest,
      }),

    // Diagnostics V.2
    loadDiagnostics: (params: {
      status: string;
      page: number;
      full_reference?: string;
    }) => store.dispatch("diagnostics/loadDiagnostics", params),

    // Mutations
    // Diagnostics V.2
    setDiagnostics: () => store.commit("diagnostics/setDiagnostics"),
  };
};
