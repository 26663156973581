import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
    {
        path: "soporte/tickets",
        name: 'SupportView',
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
            import(
                /* webpackChunkName: "support/tickets" */
                "@/views/support/support-view/SupportView.vue"
            )
    },

    {
        path: "soporte/tickets/crear-ticket",
        name: 'SupportCreateView',
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
            import(
                /* webpackChunkName: "support/create-tickets" */
                "@/views/support/support-create/SupportCreateView.vue"
            )
    },

    {
        path: "soporte/tickets/ver-ticket/:id",
        name: 'SupportResponseView',
        beforeEnter: [isAuthenticatedGuard],
        component: () =>
            import(
                /* webpackChunkName: "support/response-tickets" */
                "@/views/support/support-response-view/SupportResponseView.vue"
            )
    },
];