import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/voluntades-anticipadas",
    name: "AdvanceDirectivesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Advance Directives List" */ "@/views/advance-directives/AdvanceDirectivesList.vue"
      ),
  },
  {
    path: "/voluntades-anticipadas/crear-voluntades-anticipadas",
    name: "AdvanceDirectivesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Advance Directives Create" */ "@/views/advance-directives/sub-pages/advance-directives-create/AdvanceDirectivesCreate.vue"
      ),
  },
  {
    path: "/voluntades-anticipadas/edit-voluntades-anticipadas/:id(\\d+)",
    name: "AdvanceDirectivesEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Advance Directives Edit" */ "@/views/advance-directives/sub-pages/advance-directives-edit/AdvanceDirectivesEdit.vue"
      ),
  },
];
