import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/especialidades",
    name: "SpecialtesList",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Specialtes List" */ "@/views/specialtes-list/SpecialtesList.vue"
      ),
  },
  {
    path: "/especialidades/editar-especialidades/:id(\\d+)",
    name: "SpecialtesEdit",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medicines Edit" */ "@/views/specialtes-edit/SpecialtesEditView.vue"
      ),
  },
  {
    path: "/especialidades/crear-especialidades",
    name: "SpecialtesCreate",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Medicines Create" */ "@/views/specialtes-create/SpecialtesCreateView.vue"
      ),
  },
];
