import {
  ICareCenter,
  ICarePrograms,
  ICity,
  IEPS,
  IPatientResources,
  IReferenceCenter,
  IRegime,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { SelectorsResponse, SelectorsState, patientValue } from "./state";

const mutation: MutationTree<SelectorsState> = {
  setCenters(
    state: SelectorsState,
    { type, data }: { type: number; data?: IReferenceCenter[] | ICareCenter[] }
  ) {
    if (data) {
      if (type == 1) {
        state.centers.referenceCenters = data as IReferenceCenter[];
        state.request.centers.referenceCenters = true;
      } else {
        state.centers.careCenters = data as ICareCenter[];
        state.request.centers.careCenters = true;
      }
    } else {
      if (type == 1) {
        state.centers.referenceCenters = [];
        state.request.centers.referenceCenters = false;
      } else {
        state.centers.careCenters = [];
        state.request.centers.careCenters = false;
      }
    }
  },
  setEPS(state: SelectorsState, data?: IEPS[]) {
    if (data) {
      state.patient.eps = data;
      state.request.patient.eps = true;
    } else {
      state.patient.eps = [];
      state.request.patient.eps = false;
    }
  },
  setRegimes(state: SelectorsState, data?: IRegime[]) {
    if (data) {
      state.patient.regimes = data;
      state.request.patient.regimes = true;
    } else {
      state.patient.regimes = [];
      state.request.patient.regimes = false;
    }
  },
  setCities(state: SelectorsState, data?: ICity[]) {
    if (data) {
      state.patient.cities = data;
      state.request.patient.cities = true;
    } else {
      state.patient.cities = [];
      state.request.patient.cities = false;
    }
  },
  setCarePrograms(state: SelectorsState, data?: ICarePrograms[]) {
    if (data) {
      state.patient.carePrograms = data;
      state.request.patient.carePrograms = true;
    } else {
      state.patient.carePrograms = [];
      state.request.patient.carePrograms = false;
    }
  },
  setPatientResources(state: SelectorsState, data?: IPatientResources) {
    if (data) {
      state.patient.eps = data.eps;
      state.patient.memberships = data.memberships;
      state.patient.documentTypes = data.document_types;
      state.patient.regimes = data.regimes;
      state.patient.arls = data.arls;
      state.patient.prepaids = data.prepaids;
      state.patient.sexes = data.sexes;
      state.patient.departments = data.departments;
      state.patient.bloodTypes = data.blood_types;
      state.patient.nationalities = data.nationalities;
      state.patient.expedition_location = data.cities;

      state.request.patient.eps = true;
      state.request.patient.memberships = true;
      state.request.patient.documentTypes = true;
      state.request.patient.regimes = true;
      state.request.patient.arls = true;
      state.request.patient.prepaids = true;
      state.request.patient.sexes = true;
      state.request.patient.departments = true;
      state.request.patient.bloodTypes = true;
      state.request.patient.nationalities = true;
      state.request.patient.expedition_location = true;
    } else {
      state.patient.eps = [];
      state.patient.memberships = [];
      state.patient.documentTypes = [];
      state.patient.regimes = [];
      state.patient.arls = [];
      state.patient.prepaids = [];
      state.patient.sexes = [];
      state.patient.departments = [];
      state.patient.bloodTypes = [];
      state.patient.nationalities = [];
      state.patient.expedition_location = [];

      state.request.patient.eps = false;
      state.request.patient.memberships = false;
      state.request.patient.documentTypes = false;
      state.request.patient.regimes = false;
      state.request.patient.arls = false;
      state.request.patient.prepaids = false;
      state.request.patient.sexes = false;
      state.request.patient.departments = false;
      state.request.patient.bloodTypes = false;
      state.request.patient.nationalities = false;
      state.request.patient.expedition_location = false;
    }
  },
  setResponse(state: SelectorsState, response?: SelectorsResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "", of: null };
    }
  },
  setCentersValue(
    state: SelectorsState,
    { type, value }: { type: number; value: number | null }
  ) {
    if (type == 1) {
      state.values.centers.referenceCenter = value;
    } else {
      state.values.centers.careCenter = value;
    }
  },
  setEPSValue(state: SelectorsState, value: number | null) {
    state.values.patient.eps = value;
  },
  setRegimeValue(state: SelectorsState, value: number | null) {
    state.values.patient.regime = value;
  },
  setPatientResourcesValues(
    state: SelectorsState,
    {
      resource,
      value,
    }: {
      resource: patientValue;
      value: string | number | number[] | null;
    }
  ) {
    if (
      resource != "department" &&
      resource != "city" &&
      resource != "carePrograms" &&
      resource != "bloodType" &&
      resource != "nationalities" &&
      resource != "expedition_location"
    ) {
      if (!Array.isArray(value) && typeof value != "string") {
        state.values.patient[resource] = value;
      }
    } else {
      if (resource != "carePrograms") {
        if (!Array.isArray(value) && typeof value != "number") {
          state.values.patient[resource] = value;
        }
      } else {
        if (Array.isArray(value) || value == null) {
          state.values.patient.carePrograms = value == null ? [] : value;
        }
      }
    }
  },
};

export default mutation;
