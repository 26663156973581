import { ActionTree } from "vuex";
import { MedicinesState, Response } from "./state";
import { StateInterface } from "../index";
import { medicinesApi } from "@/apis";
import { MedicineFormUI } from "../../views/medicines/interfaces/medicalsUI";
import errorException from "@/functions/ErrorException";
import { medicinesApiV2 } from "@/apis/medicinesApi";

const actions: ActionTree<MedicinesState, StateInterface> = {
  async changeMedicineStatusById(_, { token, id }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await medicinesApi().post(
        `/changeStatus/${id}`,
        {},
        config
      );
      const { success, message } = data;
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createMedicine(
    _,
    { token, bodyRequest }: { token: string; bodyRequest: MedicineFormUI }
  ): Promise<Response> {
    const request = {
      ...bodyRequest,
      pbs: bodyRequest.pbs?.pbsValue,
    };

    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await medicinesApi().post("/", request, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getMedicineById({ commit }, { token, id }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await medicinesApi().get(`/${id}`, config);
      const { success, message, data: medicineData } = data;

      commit("getMedicineById", medicineData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async updateMedicine(
    _,
    { token, bodyRequest }: { token: string; bodyRequest: MedicineFormUI }
  ): Promise<Response> {
    const request = {
      ...bodyRequest,
      pbs: bodyRequest.pbs?.pbsValue,
    };

    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await medicinesApi().post("/", request, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },

  // Medicines V2
  async getMedicinesV2({ commit }, data) {
    const { token, params } = data;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    };

    try {
      const { data } = await medicinesApiV2().get("", config);
      commit("setMedicines", data);
      return data;
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },

  // Medicines V.3
  async loadMedicines({ commit }, params) {
    commit("setMedicines");
    commit("setResponse");

    try {
      const { data: response } = await medicinesApi().get("/", { params });
      const { success, message, data } = response;

      commit("setMedicines", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },

  async medicinesListWithoutPaginate({ commit }, params) {
    try {
      const { data: response } = await medicinesApi().get("/withoutPaginate");
      const { success, message, data } = response;
      commit("setListMedicinesSelector", data);
      return { success, message, data };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
