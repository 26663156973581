// vuex
import { MutationTree } from "vuex";

// Interface
import { ISupportCustom } from "@/interfaces/customs/ISupportCustom";

const mutation: MutationTree<ISupportCustom> = {
  setTicketViewStatus(state, status){
    state.ticketViewStatus = status
  },

  setUpdateTicketMessage(state, addMessage){
   const updateMessages = [...state.chatMessages, addMessage]
   state.chatMessages = updateMessages

  },

  setChatMessages(state, chatMessages) {
    state.chatMessages = chatMessages
  },

  setTicketsStatus(state, status) {
    state.ticketsStatus = status
  },

  setTicketInfo(state, ticketInfo) {
    state.ticketInfo = ticketInfo
  },

  setTicketList(state, ticketList) {
    state.ticketList = ticketList;
  },

  setSupportDateRange(state, dateRange) {
    state.supportDateRange = dateRange
  },

  setTicketListStatus(state, status) {
    state.ticketListStatus = status
  },

  setFirstTicketPages(state, firstPage) {
    state.firstTicketPage = firstPage
  },

  setLastTicketPages(state, lastPage) {
    state.lastTicketPage = lastPage
  },





}

export default mutation;