import { MutationTree } from "vuex";
import { IDataTable } from "@/interfaces/customs/interface";
import { MedicationAdministratorState } from "@/interfaces/global/interfaces";

const mutation: MutationTree<MedicationAdministratorState> = {
  setMedicationAdministratorList(
    state: MedicationAdministratorState,
    list: IDataTable
  ): void {
    state.medicationAdministratorList = list?.data;

    state.medicalAdministratorCurrentPage = list?.current_page || 1;
    state.medicalAdministratorLastPage = list?.last_page || 1;
  },
  setDataForm(
    state: MedicationAdministratorState,
    data: { from_date: string; to_date: string; admission: string; closure_reason: string | null } | null
  ): void {
    state.data_form = data;
  },
  setMedicalAdministrator(
    state: MedicationAdministratorState,
    data: MedicationAdministratorState["medicalAdministrator"] | null
  ): void {
    state.medicalAdministrator = data;
  },
  setDataApi(state: MedicationAdministratorState, data: object[]): void {
    state.data_api = data;
  },
  setDataTable(state: MedicationAdministratorState, data: object[]): void {
    state.data_table = data;
  },
};

export default mutation;
