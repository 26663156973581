import { GetterTree } from "vuex";
import { MedicinesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<MedicinesState, StateInterface> = {
  getMedicineCode(state) {
    return state.medicineCode;
  },
  getMedicineDescription(state) {
    return state.medicineDescription;
  },

  getPbsValue(state) {
    return state.pbs;
  },

  // Medicines V.3
  getMedicinesList(state) {
    return state.data.medicines.length >= 1
      ? state.data.medicines.map(({ id, code, description, pbs, status }) => ({
          id,
          code,
          description,
          pbs: pbs ? "Si" : "No",
          status,
        }))
      : [];
  },
};

export default getters;
