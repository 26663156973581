import JSZip from "jszip";
import { saveAs } from "file-saver";

import { IErrors } from "@/interfaces/customs/IShowErrors";
import { useAlertV2 } from "@/composables/useAlertV2";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";

const { showAlert } = useAlertV2()
const { showCatchError } = showErrorInTS();

export const generateFile = (name: string, type: string) =>
  new File([], name, {
    type: type,
  });

const secureRandom = (length: number, quantity: number) => {
  const randomValuesArray = new Array(quantity);

  for (let i = 0; i < quantity; i++) {
    const values = new Uint32Array(length);
    crypto.getRandomValues(values);
    randomValuesArray[i] = values[0] % 10; // Para obtener un número entre 0 y 9
  }

  return randomValuesArray;
};

export const generateRandomNumber = (amount: number) =>
  secureRandom(1, amount).join("").toString();

export const checkDigit = (
  event: KeyboardEvent,
  allowAdditionalChars?: string,
  maxLength?: number
) => {
  const allowedCharsRegex = allowAdditionalChars
    ? new RegExp(`^[0-9${allowAdditionalChars}]$`)
    : /^[0-9]$/;

  if (
    maxLength &&
    event.target instanceof HTMLInputElement &&
    event.target.value.length >= maxLength
  ) {
    event.preventDefault();
  }

  if (event.key.length === 1 && !allowedCharsRegex.test(event.key)) {
    event.preventDefault();
  }
};

export const clearCacheImg = (url: string) =>
  `${url}?${generateRandomNumber(6)}${new Date().getTime()}`;

const dictionary: {
  [key: string | number]: string;
} = {
  hc: "Historia clínica",
  medicines: "Medicamentos",
  procedures: "Procedimientos",
  informed_consent_pdf: "Consentimiento informado",
  informed_consent: "Consentimiento informado",
  technical_annex_pdf: "Anexo técnico",
  technical_annex: "Anexo técnico",
};

export const translate = (key: string | number) =>
  key in dictionary ? dictionary[key] : ``;

// Descargar el archivo y agregarlo al ZIP
const addFileToZip = async (url: string, filename: string, folder: JSZip) => {
  const response = await fetch(url);
  if (!response.ok) {
    showAlert(
      `Error al descargar ${filename}: ${response.statusText}`,
      "error"
    );
  }
  const blob = await response.blob();
  folder.file(`${filename.trim()}.pdf`, blob);
};

export const downloadZipFiles = async (
  urls: { name: string; value: string }[]
) => {
  const FOLDER_NAME = `documents${new Date().getTime()}`;
  const FILE_NAME = `documentos${new Date().getTime()}`;
  const ZIP = new JSZip();
  const FOLDER = ZIP.folder(FOLDER_NAME) as JSZip;

  // Descargar y añadir cada archivo al ZIP
  try {
    for (const url of urls) {
      await addFileToZip(url.value, url.name, FOLDER);
    }

    // Generar el archivo ZIP y descargarlo
    ZIP.generateAsync({ type: "blob" })
      .then((content: Blob) => {
        saveAs(content, FILE_NAME);
        showAlert(
          `Se ha generado el archivo con éxito`,
          "success"
        );
      })
      .catch((e : IErrors) => {
        const error = e;
        const message = error.message;
        const response = error.response;

        const errorParse = JSON.parse(JSON.stringify({ message, response }));
        showAlert(String(showCatchError(errorParse)), "error");
      });
  } catch (error) {
    showAlert(`${error}`, "error");
  }
};
