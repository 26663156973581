import { IMeetResponseFull } from "@/interfaces/customs/MeetResponseCustom";

export interface Tab {
  color: string;
  disable: boolean;
  icon: string;
  label: string;
  name: string;
  text_color: string;
}
export interface Response {
  message: string;
  success: boolean;
}

export interface ResponseData {
  message: string;
  success: boolean;
  data: any;
}

export interface Data {
  current_page: number;
  data: Datum[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url: null | string;
  path: string;
  per_page: number;
  prev_page_url: null | string;
  to: number;
  total: number;
}

export interface dataTableComponent {
  data: any[];
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export interface Datum {
  application_date: Date;
  backgrounds: TypeTeleexpertise[];
  created_at: Date;
  current_illness: string;
  diagnostics: Diagnostic[];
  id: number;
  is_notified: number;
  patient: Patient;
  patient_id: number;
  patient_signature_url: null | string;
  priority: string;
  query_type: string;
  reason_for_consultation: string;
  specialty_id: number;
  status: string;
  templates: Template[];
  type_teleexpertise: TypeTeleexpertise;
  type_teleexpertise_id: number;
  updated_at: Date;
  user_id: null | number;
  vital_signs: VitalSigns;
  //
  scheduled_at: string;
  url_meeting: string;
  final_hour: string;
  url_pdf: string;
  technical_annex_pdf: string;
  date_status: string;
  is_qualification: boolean;
  created_by: number;
  pdfs: object;
}

export interface TypeTeleexpertise {
  created_at: null | string;
  description?: string;
  id: number;
  name: string;
  pivot?: Pivot;
  updated_at: null | string;
}

export interface Pivot {
  description: string;
  diagnostic_id: number;
  teleexpertise_id: number;
}

export interface Diagnostic {
  code: string;
  created_at: null | string;
  description: string;
  id: number;
  pivot: Pivot;
  status: string;
  updated_at: null | string;
}

export interface Patient {
  address: string;
  allergies: null | string;
  background: null | string;
  birthday: string;
  care_center_id: number;
  center_attention: CenterAttention;
  care_center?: { id: number; name: string };
  reference_center?: { id: number; name: string };
  city_code: string;
  companion_name: string;
  companion_phone: string;
  created_at: null | string;
  department_code: string;
  document: string;
  document_type_id: number;
  email: string;
  eps_id: number;
  gender: string;
  id: number;
  last_name: string;
  marital_status: string;
  membership_id: number;
  name: string;
  occupation: string;
  pacientescol: null | string;
  phone: string;
  reason: null | string;
  regime_id: number;
  rh_group: string;
  scholarship: string;
  status: string;
  updated_at: null | string;
  url_informed_consent: string;
}

export interface CenterAttention {
  address: string;
  centers_types_id: number;
  contact_name: string;
  contact_phone: string;
  created_at: null | string;
  email: string;
  id: number;
  logo: null | string;
  name: string;
  nit: string;
  phone: string;
  status: string;
  updated_at: null | string;
}

export interface Template {
  created_at: Date;
  description: string;
  id: number;
  teleexpertise_id: number;
  title: string;
  updated_at: Date;
}

export interface VitalSigns {
  blood_pressure: string;
  bmi_for_age_BMIE: string;
  bmi_for_gestational_age_BMIGA: string;
  breathing_frequency: string;
  head_circumference_for_age_PCE: string;
  heart_rate: string;
  height: string;
  oxygen_saturation: string;
  temperature: string;
  weight: string;
  weight_for_PT_table: string;
  weight_for_age_PE: string;
  weight_for_age_TE: string;
}

export interface Link {
  active: boolean;
  label: string;
  url: null | string;
}
export interface Telexpertises {
  id: number;
  user_id: null | string;
  patient_id: number;
  type_teleexpertise_id: number;
  is_notified: number;
  status: string;
  application_date: string;
  date: null | string;
  priority: string;
  reason_for_consultation: string;
  current_illness: string;
  vital_signs: Vital_Signs;
  patient_signature_url: string;
  query_type: string;
  specialty_id: number;
  created_by: number;
  center_reference_id: number;
  url_meeting: string;
  final_hour: null;
  created_at: string;
  updated_at: string;
  consecutive: null;
  key: null;
  url_pdf: null | string;
  attention_date: null;
  is_companion_patient: boolean;
  phone_companion_patient: null;
  name_companion_patient: null;
  relationship_companion_patient: null;
  center: Center;
  patient: Patients;
  type_teleexpertise: TypeTeleexpertise;
}

export interface Patients {
  id: number;
  name: string;
  last_name: string;
  care_center_id: number;
  birthday: string;
  document_type_id: number;
  document: string;
  eps_id: number;
  arl_id: null;
  prepaid_id: null;
  sex_id: null;
  email: string;
  gender: string;
  marital_status: string;
  phone: string;
  department_code: string;
  city_code: string;
  address: string;
  regime_id: number;
  membership_id: number;
  scholarship: string;
  status: string;
  vital_state: string;
  companion_phone: string;
  companion_name: string;
  occupation: string;
  rh_group: string;
  pacientescol: null;
  background: null;
  allergies: null;
  reason: string;
  url_informed_consent: string;
  type_of_population: string;
  relationship_companion: null;
  is_companion: boolean;
  created_at: null;
  updated_at: string;
}

export interface Center {
  id: number;
  created_at: null;
  updated_at: string;
  name: string;
  nit: string;
  address: string;
  phone: string;
  email: string;
  logo: string;
  contact_name: string;
  contact_phone: string;
  status: string;
  centers_types_id: number;
  laravel_through_key: number;
}

export interface Vital_Signs {
  imc: number;
  height: string;
  weight: string;
  heart_rate: string;
  temperature: string;
  weight_level: string;
  blood_pressure: string;
  bmi_for_age_BMIE: string;
  oxygen_saturation: string;
  weight_for_age_PE: string;
  weight_for_age_TE: string;
  breathing_frequency: string;
  weight_for_PT_table: string;
  bmi_for_gestational_age_BMIGA: string;
  head_circumference_for_age_PCE: string;
}

export interface DataList {
  id: number;
  careCenter: string;
  patient: string;
  document: string;
  priority: string;
  date: string;
  type: string;
  status: string;

  //
  dateInfoYear: string;
  meetURL: string;
  finalHour: string;
  initialHour: string;
  telexperticeType: string;
  telexperticeStatus: string;
  baseURL: string;
  titleResponse: string;
  baseMeetURL: string;
  dateTelexpertice: string;
  pdfURL: string;
  technical_annex_pdf: string;
  dateStatus: string;
  is_qualification: boolean;
  created_by: number;
  informedConsentUrl: string | null;
  pdfs: object | null;
}

export interface TelexpertisesList {
  id: Telexpertises["id"];
  careCenter: Telexpertises["center"]["name"];
  patient: Telexpertises["patient"]["name"];
  document: Telexpertises["patient"]["document"];
  priority: Telexpertises["priority"];
  date: Telexpertises["application_date"];
  type: Telexpertises["type_teleexpertise"]["name"];
  status: Telexpertises["status"];
}

import { IAllResource, IPatient } from "@/interfaces/global/interfaces";
import { IDataTable } from "@/interfaces/customs/interface";

export interface TeleexpertisesState {
  teleexperticeInfo: IMeetResponseFull | object;
  tab: Tab;
  teleexpertisesList: DataList[] | [];
  teleexpertisesCurrentPage: number;
  teleexpertisesLastPage: number;
  telexperticeById: any;

  // TELEXPERTISE
  resourcesTelexpertises: IAllResource;
  // PatientDetail
  dataPatientDetail: IPatient | null;
  // ReasonConsultation
  dataReasonConsultation: any;
  // NursingRecord
  dataNursingRecord: any;
  // Scales
  dataScales: Array<any>;
  dataInterpretations: Array<any>;
  dataTableScales: Array<object>;
  optionScales: Array<object>;
  // Vaccination
  dataVaccination: Array<any>;
  dataTableVaccination: dataTableComponent;
  vaccinationCurrentPage: number;
  vaccinationLastPage: number;
  // Backgrounds
  dataTableBackground: dataTableComponent;
  backgroundCurrentPage: number;
  backgroundLastPage: number;
  dataBackgrounds: any;
  backgroundsHistory: Array<any>;
  backgroundsHistoryCurrentPage: number;
  backgroundsHistoryLastPage: number;
  // Clinical Laboratory
  dataTableClinicalLaboratory: dataTableComponent;
  clinicalLaboratoryCurrentPage: number;
  clinicalLaboratoryLastPage: number;
  dataClinicalLaboratory: any;
  clinicalLaboratoryHistory: Array<any>;
  clinicalLaboratoryHistoryCurrentPage: number;
  clinicalLaboratoryHistoryLastPage: number;
  // Medical Exam
  dataTableMedicalExams: dataTableComponent;
  medicalExamsCurrentPage: number;
  medicalExamsLastPage: number;
  dataMedicalExams: any;
  medicalExamsDocuments: any;
  clinicalLaboratoriesDocuments: any;
  medicalExamsHistory: Array<any>;
  medicalExamsHistoryCurrentPage: number;
  medicalExamsHistoryLastPage: number;
  // Vital Signs
  dataVitalSigns: any;
  // Vital Signs Nursing Note
  dataVitalSignsNursingNotes: any;
  // Physical Exams
  dataPhysicalExams: any;
  // Diagnostic
  dataTableDiagnostic: IDataTable;
  diagnosticCurrentPage: number;
  diagnosticLastPage: number;
  dataDiagnostic: any;
  diagnosticHistory: IDataTable;
  diagnosticHistoryCurrentPage: number;
  diagnosticHistoryLastPage: number;
  // Vaccine
  vaccineHistory: IDataTable;
  vaccineHistoryCurrentPage: number;
  vaccineHistoryLastPage: number;
  // Medicine
  medicineHistory: IDataTable;
  medicineHistoryCurrentPage: number;
  medicineHistoryLastPage: number;
  // Procedures
  procedureHistory: IDataTable;
  procedureHistoryCurrentPage: number;
  procedureHistoryLastPage: number;
  // Document
  dataDocuments: any;
  dataTableDocuments: dataTableComponent;
  documentsCurrentPage: number;
  documentsLastPage: number;
  // AttachedDocuments
  dataAttachedDocuments: any;
  dataTableAttachedDocuments: dataTableComponent;
  attachedDocumentsCurrentPage: number;
  attachedDocumentsLastPage: number;
  // Response
  dataResponseTelexpertice: any;
  dataResponseTelexperticeTable: any;
  dataResponseCurrentPage: any;
  dataResponseLastPage: any;
  // Medicines
  dataTabMedicines: any;
  medicinesCurrentPage: number;
  medicinesLastPage: number;
  dataHistoryMedicinesPatient: Array<any>;
  dataTableHistoryMedicinesPatient: dataTableComponent;
  dataTableHistoryMedicinesPatientCurrentPage: number;
  dataTableHistoryMedicinesPatientLastPage: number;
  // Procedures
  dataTabProcedures: any;
  dataHistoryProceduresPatient: Array<any>;
  dataTableHistoryProceduresPatient: dataTableComponent;
  dataTableHistoryProceduresPatientCurrentPage: number;
  dataTableHistoryProceduresPatientLastPage: number;
  proceduresCurrentPage: number;
  proceduresLastPage: number;
  // Request
  dataRequest: any;
  // DocumentsResponse
  dataDocumentsResponse: any;
  dataTableDocumentsResponse: dataTableComponent;
  documentsResponseCurrentPage: number;
  documentsResponseLastPage: number;
  // Technical Annex
  dataTechnicalAnnex: any;

  // Graphics
  jsonDataGraphic: any;

  /**Peso para la edad - Nuevo */
  weightForAgeGirl: any;
  weightForAgeBoy: any;
  /**Peso para la edad - Nuevo */

  /**Talla para la edad - Nuevo */
  sizeForAgeGirl: any;
  sizeForAgeBoy: any;
  /**Talla para la edad - Nuevo */

  /** IMC para la edad - Nuevo */
  imcForAgeGirl: any;
  imcForAgeBoy: any;
  /** IMC para la edad - Nuevo */

  /** Perímetro Cefálico para la edad - Nuevo */
  headCircunferenceForAgeGirl: any;
  headCircunferenceForAgeBoy: any;
  /** Perímetro Cefálico para la edad - Nuevo */

  // Specialty
  specialtySelected: any;

  // Query Type
  queryTypeSelected: any;

  // Document Patient
  documentPatient: any;

  // Telexpertises List without pagination
  telexpertisesListWP: TelexpertisesList[] | [];

  diagnosticsTypes: string[];
  typification: string[];
}

function state(): TeleexpertisesState {
  return {
    teleexperticeInfo: {},
    diagnosticsTypes: [
      "Impresión diagnóstica",
      "Confirmado nuevo",
      "Confirmado repetido",
    ],
    typification: [
      "Principal",
      "Secundario",
      "Complicación de procedimiento",
      "Salida de urgencias",
      "De la muerte",
      "Ingreso a hospitalización",
      "Complicación de la hospitalización",
      "Recién nacido",
    ],
    tab: {
      color: "",
      disable: false,
      icon: "",
      label: "",
      name: "",
      text_color: "",
    },
    teleexpertisesList: [],
    teleexpertisesCurrentPage: 1,
    teleexpertisesLastPage: 1,
    telexperticeById: null,
    resourcesTelexpertises: {
      backgrounds: [],
      care_priorities: [],
      classification: [],
      diagnostics: [],
      medicines: [],
      origin_attentions: [],
      procedures: [],
      requested_services: [],
      scales: [],
      scopes: [],
      type_teleexpertises: [],
      units: [],
      vaccines: [],
    },
    // PatientDetails --------------------------------------
    dataPatientDetail: null,
    // ReasonConsultation ----------------------------------
    dataReasonConsultation: null,
    // NursingRecord
    dataNursingRecord: null,
    // Scales ----------------------------------------------
    dataScales: [],
    dataInterpretations: [],
    dataTableScales: [],
    optionScales: [],
    // Vaccination -----------------------------------------
    dataVaccination: [],
    dataTableVaccination: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    vaccinationCurrentPage: 1,
    vaccinationLastPage: 1,
    // Backgrounds -----------------------------------------
    dataTableBackground: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    backgroundCurrentPage: 1,
    backgroundLastPage: 1,
    dataBackgrounds: null,
    backgroundsHistoryCurrentPage: 1,
    backgroundsHistoryLastPage: 1,
    backgroundsHistory: [],
    // Clinical Laboratory ----------------------------------
    dataTableClinicalLaboratory: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    clinicalLaboratoryCurrentPage: 1,
    clinicalLaboratoryLastPage: 1,
    dataClinicalLaboratory: null,
    clinicalLaboratoryHistoryCurrentPage: 1,
    clinicalLaboratoryHistoryLastPage: 1,
    clinicalLaboratoryHistory: [],
    // Medical Examns ----------------------------------
    dataTableMedicalExams: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    medicalExamsCurrentPage: 1,
    medicalExamsLastPage: 1,
    dataMedicalExams: null,
    medicalExamsDocuments: null,
    clinicalLaboratoriesDocuments: null,
    medicalExamsHistory: [],
    medicalExamsHistoryCurrentPage: 1,
    medicalExamsHistoryLastPage: 1,
    // Vital Signs --------------------------------------
    dataVitalSigns: null,
    // Vital Signs Nursing Notes --------------------------------------
    dataVitalSignsNursingNotes: null,
    // Vital Signs --------------------------------------
    dataPhysicalExams: null,
    // Medical Examns ----------------------------------
    dataTableDiagnostic: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    diagnosticCurrentPage: 1,
    diagnosticLastPage: 1,
    dataDiagnostic: null,
    diagnosticHistory: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    diagnosticHistoryCurrentPage: 1,
    diagnosticHistoryLastPage: 1,
    // Vaccine History
    vaccineHistory: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    vaccineHistoryCurrentPage: 1,
    vaccineHistoryLastPage: 1,
    // Medicine History
    medicineHistory: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    medicineHistoryCurrentPage: 1,
    medicineHistoryLastPage: 1,
    // Procedure History
    procedureHistory: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    procedureHistoryCurrentPage: 1,
    procedureHistoryLastPage: 1,
    // Documents
    dataDocuments: null,
    dataTableDocuments: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    documentsCurrentPage: 1,
    documentsLastPage: 1,
    // AttachedDocuments
    dataAttachedDocuments: null,
    dataTableAttachedDocuments: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    attachedDocumentsCurrentPage: 1,
    attachedDocumentsLastPage: 1,
    // ResponseTelexpertice
    dataResponseTelexpertice: null,
    dataResponseTelexperticeTable: null,
    dataResponseCurrentPage: 1,
    dataResponseLastPage: 1,
    // Medicines
    dataTabMedicines: null,
    dataHistoryMedicinesPatient: [],
    dataTableHistoryMedicinesPatient: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    dataTableHistoryMedicinesPatientCurrentPage: 1,
    dataTableHistoryMedicinesPatientLastPage: 1,
    medicinesCurrentPage: 1,
    medicinesLastPage: 1,
    // Procedures
    dataTabProcedures: null,
    dataHistoryProceduresPatient: [],
    dataTableHistoryProceduresPatient: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    dataTableHistoryProceduresPatientCurrentPage: 1,
    dataTableHistoryProceduresPatientLastPage: 1,
    proceduresCurrentPage: 1,
    proceduresLastPage: 1,
    // Requests
    dataRequest: null,
    // Documents Response
    dataDocumentsResponse: null,
    dataTableDocumentsResponse: {
      data: [],
      current_page: 1,
      last_page: 1,
      per_page: 20,
      total: 0,
    },
    documentsResponseCurrentPage: 1,
    documentsResponseLastPage: 1,
    // TechnicalAnnex
    dataTechnicalAnnex: null,
    // Graphics

    /**Peso para la edad edad - Nuevo*/
    weightForAgeGirl: [
      // 2
      {
        color: "#8260BB",
        name: "2",
        data: [
          {
            x: 0,
            y: 4.2,
          },
          {
            x: 1,
            y: 5.5,
          },
          {
            x: 2,
            y: 6.6,
          },
          {
            x: 3,
            y: 7.5,
          },
          {
            x: 4,
            y: 8.2,
          },
          {
            x: 5,
            y: 8.8,
          },
          {
            x: 6,
            y: 9.3,
          },
          {
            x: 7,
            y: 9.8,
          },
          {
            x: 8,
            y: 10.2,
          },
          {
            x: 9,
            y: 10.5,
          },
          {
            x: 10,
            y: 10.9,
          },
          {
            x: 11,
            y: 11.2,
          },
          {
            x: 12,
            y: 11.5,
          },
          {
            x: 13,
            y: 11.8,
          },
          {
            x: 14,
            y: 12.1,
          },
          {
            x: 15,
            y: 12.4,
          },
          {
            x: 16,
            y: 12.6,
          },
          {
            x: 17,
            y: 12.9,
          },
          {
            x: 18,
            y: 13.2,
          },
          {
            x: 19,
            y: 13.5,
          },
          {
            x: 20,
            y: 13.7,
          },
          {
            x: 21,
            y: 14,
          },
          {
            x: 22,
            y: 14.3,
          },
          {
            x: 23,
            y: 14.6,
          },
          {
            x: 24,
            y: 14.8,
          },
          {
            x: 25,
            y: 15.1,
          },
          {
            x: 26,
            y: 15.4,
          },
          {
            x: 27,
            y: 15.7,
          },
          {
            x: 28,
            y: 16,
          },
          {
            x: 29,
            y: 16.2,
          },
          {
            x: 30,
            y: 16.5,
          },
          {
            x: 31,
            y: 16.8,
          },
          {
            x: 32,
            y: 17.1,
          },
          {
            x: 33,
            y: 17.3,
          },
          {
            x: 34,
            y: 17.6,
          },
          {
            x: 35,
            y: 17.9,
          },
          {
            x: 36,
            y: 18.1,
          },
          {
            x: 37,
            y: 18.4,
          },
          {
            x: 38,
            y: 18.7,
          },
          {
            x: 39,
            y: 19,
          },
          {
            x: 40,
            y: 19.2,
          },
          {
            x: 41,
            y: 19.5,
          },
          {
            x: 42,
            y: 19.8,
          },
          {
            x: 43,
            y: 20.1,
          },
          {
            x: 44,
            y: 20.4,
          },
          {
            x: 45,
            y: 20.7,
          },
          {
            x: 46,
            y: 20.9,
          },
          {
            x: 47,
            y: 21.2,
          },
          {
            x: 48,
            y: 21.5,
          },
          {
            x: 49,
            y: 21.8,
          },
          {
            x: 50,
            y: 22.1,
          },
          {
            x: 51,
            y: 22.4,
          },
          {
            x: 52,
            y: 22.6,
          },
          {
            x: 53,
            y: 22.9,
          },
          {
            x: 54,
            y: 23.2,
          },
          {
            x: 55,
            y: 23.5,
          },
          {
            x: 56,
            y: 23.8,
          },
          {
            x: 57,
            y: 24.1,
          },
          {
            x: 58,
            y: 24.4,
          },
          {
            x: 59,
            y: 24.6,
          },
          {
            x: 60,
            y: 24.9,
          },
          {
            x: 61,
            y: 24.82,
          },
          {
            x: 62,
            y: 25.09,
          },
          {
            x: 63,
            y: 25.36,
          },
          {
            x: 64,
            y: 25.64,
          },
          {
            x: 65,
            y: 25.91,
          },
          {
            x: 66,
            y: 26.18,
          },
          {
            x: 67,
            y: 26.46,
          },
          {
            x: 68,
            y: 26.73,
          },
          {
            x: 69,
            y: 27.01,
          },
          {
            x: 70,
            y: 27.29,
          },
          {
            x: 71,
            y: 27.57,
          },
          {
            x: 72,
            y: 27.84,
          },
          {
            x: 73,
            y: 28.13,
          },
          {
            x: 74,
            y: 28.41,
          },
          {
            x: 75,
            y: 28.7,
          },
          {
            x: 76,
            y: 28.98,
          },
          {
            x: 77,
            y: 29.28,
          },
          {
            x: 78,
            y: 29.57,
          },
          {
            x: 79,
            y: 29.87,
          },
          {
            x: 80,
            y: 30.18,
          },
          {
            x: 81,
            y: 30.48,
          },
          {
            x: 82,
            y: 30.8,
          },
          {
            x: 83,
            y: 31.12,
          },
          {
            x: 84,
            y: 31.44,
          },
          {
            x: 85,
            y: 31.77,
          },
          {
            x: 86,
            y: 32.11,
          },
          {
            x: 87,
            y: 32.45,
          },
          {
            x: 88,
            y: 32.8,
          },
          {
            x: 89,
            y: 33.15,
          },
          {
            x: 90,
            y: 33.51,
          },
          {
            x: 91,
            y: 33.87,
          },
          {
            x: 92,
            y: 34.24,
          },
          {
            x: 93,
            y: 34.61,
          },
          {
            x: 94,
            y: 35,
          },
          {
            x: 95,
            y: 35.38,
          },
          {
            x: 96,
            y: 35.78,
          },
          {
            x: 97,
            y: 36.18,
          },
          {
            x: 98,
            y: 36.58,
          },
          {
            x: 99,
            y: 36.99,
          },
          {
            x: 100,
            y: 37.41,
          },
          {
            x: 101,
            y: 37.84,
          },
          {
            x: 102,
            y: 38.27,
          },
          {
            x: 103,
            y: 38.7,
          },
          {
            x: 104,
            y: 39.14,
          },
          {
            x: 105,
            y: 39.59,
          },
          {
            x: 106,
            y: 40.04,
          },
          {
            x: 107,
            y: 40.5,
          },
          {
            x: 108,
            y: 40.96,
          },
          {
            x: 109,
            y: 41.42,
          },
          {
            x: 110,
            y: 41.9,
          },
          {
            x: 111,
            y: 42.37,
          },
          {
            x: 112,
            y: 42.85,
          },
          {
            x: 113,
            y: 43.34,
          },
          {
            x: 114,
            y: 43.83,
          },
          {
            x: 115,
            y: 44.32,
          },
          {
            x: 116,
            y: 44.82,
          },
          {
            x: 117,
            y: 45.33,
          },
          {
            x: 118,
            y: 45.84,
          },
          {
            x: 119,
            y: 46.36,
          },
          {
            x: 120,
            y: 46.89,
          },
        ],
      },
      // 1
      {
        color: "#ED9300",
        name: "1",
        data: [
          {
            x: 0,
            y: 3.7,
          },
          {
            x: 1,
            y: 4.8,
          },
          {
            x: 2,
            y: 5.8,
          },
          {
            x: 3,
            y: 6.6,
          },
          {
            x: 4,
            y: 7.3,
          },
          {
            x: 5,
            y: 7.8,
          },
          {
            x: 6,
            y: 8.2,
          },
          {
            x: 7,
            y: 8.6,
          },
          {
            x: 8,
            y: 9,
          },
          {
            x: 9,
            y: 9.3,
          },
          {
            x: 10,
            y: 9.6,
          },
          {
            x: 11,
            y: 9.9,
          },
          {
            x: 12,
            y: 10.1,
          },
          {
            x: 13,
            y: 10.4,
          },
          {
            x: 14,
            y: 10.6,
          },
          {
            x: 15,
            y: 10.9,
          },
          {
            x: 16,
            y: 11.1,
          },
          {
            x: 17,
            y: 11.4,
          },
          {
            x: 18,
            y: 11.6,
          },
          {
            x: 19,
            y: 11.8,
          },
          {
            x: 20,
            y: 12.1,
          },
          {
            x: 21,
            y: 12.3,
          },
          {
            x: 22,
            y: 12.5,
          },
          {
            x: 23,
            y: 12.8,
          },
          {
            x: 24,
            y: 13,
          },
          {
            x: 25,
            y: 13.3,
          },
          {
            x: 26,
            y: 13.5,
          },
          {
            x: 27,
            y: 13.7,
          },
          {
            x: 28,
            y: 14,
          },
          {
            x: 29,
            y: 14.2,
          },
          {
            x: 30,
            y: 14.4,
          },
          {
            x: 31,
            y: 14.7,
          },
          {
            x: 32,
            y: 14.9,
          },
          {
            x: 33,
            y: 15.1,
          },
          {
            x: 34,
            y: 15.4,
          },
          {
            x: 35,
            y: 15.6,
          },
          {
            x: 36,
            y: 15.8,
          },
          {
            x: 37,
            y: 16,
          },
          {
            x: 38,
            y: 16.3,
          },
          {
            x: 39,
            y: 16.5,
          },
          {
            x: 40,
            y: 16.7,
          },
          {
            x: 41,
            y: 16.9,
          },
          {
            x: 42,
            y: 17.2,
          },
          {
            x: 43,
            y: 17.4,
          },
          {
            x: 44,
            y: 17.6,
          },
          {
            x: 45,
            y: 17.8,
          },
          {
            x: 46,
            y: 18.1,
          },
          {
            x: 47,
            y: 18.3,
          },
          {
            x: 48,
            y: 18.5,
          },
          {
            x: 49,
            y: 18.8,
          },
          {
            x: 50,
            y: 19,
          },
          {
            x: 51,
            y: 19.2,
          },
          {
            x: 52,
            y: 19.4,
          },
          {
            x: 53,
            y: 19.7,
          },
          {
            x: 54,
            y: 19.9,
          },
          {
            x: 55,
            y: 20.1,
          },
          {
            x: 56,
            y: 20.3,
          },
          {
            x: 57,
            y: 20.6,
          },
          {
            x: 58,
            y: 20.8,
          },
          {
            x: 59,
            y: 21,
          },
          {
            x: 60,
            y: 21.2,
          },
          {
            x: 61,
            y: 21.17,
          },
          {
            x: 62,
            y: 21.39,
          },
          {
            x: 63,
            y: 21.6,
          },
          {
            x: 64,
            y: 21.82,
          },
          {
            x: 65,
            y: 22.03,
          },
          {
            x: 66,
            y: 22.25,
          },
          {
            x: 67,
            y: 22.46,
          },
          {
            x: 68,
            y: 22.68,
          },
          {
            x: 69,
            y: 22.89,
          },
          {
            x: 70,
            y: 23.11,
          },
          {
            x: 71,
            y: 23.32,
          },
          {
            x: 72,
            y: 23.54,
          },
          {
            x: 73,
            y: 23.76,
          },
          {
            x: 74,
            y: 23.98,
          },
          {
            x: 75,
            y: 24.2,
          },
          {
            x: 76,
            y: 24.42,
          },
          {
            x: 77,
            y: 24.65,
          },
          {
            x: 78,
            y: 24.88,
          },
          {
            x: 79,
            y: 25.11,
          },
          {
            x: 80,
            y: 25.34,
          },
          {
            x: 81,
            y: 25.58,
          },
          {
            x: 82,
            y: 25.82,
          },
          {
            x: 83,
            y: 26.07,
          },
          {
            x: 84,
            y: 26.32,
          },
          {
            x: 85,
            y: 26.57,
          },
          {
            x: 86,
            y: 26.83,
          },
          {
            x: 87,
            y: 27.09,
          },
          {
            x: 88,
            y: 27.36,
          },
          {
            x: 89,
            y: 27.63,
          },
          {
            x: 90,
            y: 27.91,
          },
          {
            x: 91,
            y: 28.19,
          },
          {
            x: 92,
            y: 28.47,
          },
          {
            x: 93,
            y: 28.76,
          },
          {
            x: 94,
            y: 29.05,
          },
          {
            x: 95,
            y: 29.35,
          },
          {
            x: 96,
            y: 29.66,
          },
          {
            x: 97,
            y: 29.96,
          },
          {
            x: 98,
            y: 30.27,
          },
          {
            x: 99,
            y: 30.59,
          },
          {
            x: 100,
            y: 30.91,
          },
          {
            x: 101,
            y: 31.24,
          },
          {
            x: 102,
            y: 31.57,
          },
          {
            x: 103,
            y: 31.91,
          },
          {
            x: 104,
            y: 32.25,
          },
          {
            x: 105,
            y: 32.59,
          },
          {
            x: 106,
            y: 32.94,
          },
          {
            x: 107,
            y: 33.29,
          },
          {
            x: 108,
            y: 33.65,
          },
          {
            x: 109,
            y: 34,
          },
          {
            x: 110,
            y: 34.37,
          },
          {
            x: 111,
            y: 34.73,
          },
          {
            x: 112,
            y: 35.1,
          },
          {
            x: 113,
            y: 35.48,
          },
          {
            x: 114,
            y: 35.86,
          },
          {
            x: 115,
            y: 36.24,
          },
          {
            x: 116,
            y: 36.63,
          },
          {
            x: 117,
            y: 37.02,
          },
          {
            x: 118,
            y: 37.41,
          },
          {
            x: 119,
            y: 37.81,
          },
          {
            x: 120,
            y: 38.22,
          },
        ],
      },
      // 0
      {
        color: "#77EA04",
        name: "0",
        data: [
          {
            x: 0,
            y: 3.2,
          },
          {
            x: 1,
            y: 4.2,
          },
          {
            x: 2,
            y: 5.1,
          },
          {
            x: 3,
            y: 5.8,
          },
          {
            x: 4,
            y: 6.4,
          },
          {
            x: 5,
            y: 6.9,
          },
          {
            x: 6,
            y: 7.3,
          },
          {
            x: 7,
            y: 7.6,
          },
          {
            x: 8,
            y: 7.9,
          },
          {
            x: 9,
            y: 8.2,
          },
          {
            x: 10,
            y: 8.5,
          },
          {
            x: 11,
            y: 8.7,
          },
          {
            x: 12,
            y: 8.9,
          },
          {
            x: 13,
            y: 9.2,
          },
          {
            x: 14,
            y: 9.4,
          },
          {
            x: 15,
            y: 9.6,
          },
          {
            x: 16,
            y: 9.8,
          },
          {
            x: 17,
            y: 10,
          },
          {
            x: 18,
            y: 10.2,
          },
          {
            x: 19,
            y: 10.4,
          },
          {
            x: 20,
            y: 10.6,
          },
          {
            x: 21,
            y: 10.9,
          },
          {
            x: 22,
            y: 11.1,
          },
          {
            x: 23,
            y: 11.3,
          },
          {
            x: 24,
            y: 11.5,
          },
          {
            x: 25,
            y: 11.7,
          },
          {
            x: 26,
            y: 11.9,
          },
          {
            x: 27,
            y: 12.1,
          },
          {
            x: 28,
            y: 12.3,
          },
          {
            x: 29,
            y: 12.5,
          },
          {
            x: 30,
            y: 12.7,
          },
          {
            x: 31,
            y: 12.9,
          },
          {
            x: 32,
            y: 13.1,
          },
          {
            x: 33,
            y: 13.3,
          },
          {
            x: 34,
            y: 13.5,
          },
          {
            x: 35,
            y: 13.7,
          },
          {
            x: 36,
            y: 13.9,
          },
          {
            x: 37,
            y: 14,
          },
          {
            x: 38,
            y: 14.2,
          },
          {
            x: 39,
            y: 14.4,
          },
          {
            x: 40,
            y: 14.6,
          },
          {
            x: 41,
            y: 14.8,
          },
          {
            x: 42,
            y: 15,
          },
          {
            x: 43,
            y: 15.2,
          },
          {
            x: 44,
            y: 15.3,
          },
          {
            x: 45,
            y: 15.5,
          },
          {
            x: 46,
            y: 15.7,
          },
          {
            x: 47,
            y: 15.9,
          },
          {
            x: 48,
            y: 16.1,
          },
          {
            x: 49,
            y: 16.3,
          },
          {
            x: 50,
            y: 16.4,
          },
          {
            x: 51,
            y: 16.6,
          },
          {
            x: 52,
            y: 16.8,
          },
          {
            x: 53,
            y: 17,
          },
          {
            x: 54,
            y: 17.2,
          },
          {
            x: 55,
            y: 17.3,
          },
          {
            x: 56,
            y: 17.5,
          },
          {
            x: 57,
            y: 17.7,
          },
          {
            x: 58,
            y: 17.9,
          },
          {
            x: 59,
            y: 18,
          },
          {
            x: 60,
            y: 18.2,
          },
          {
            x: 61,
            y: 18.26,
          },
          {
            x: 62,
            y: 18.43,
          },
          {
            x: 63,
            y: 18.61,
          },
          {
            x: 64,
            y: 18.78,
          },
          {
            x: 65,
            y: 18.95,
          },
          {
            x: 66,
            y: 19.13,
          },
          {
            x: 67,
            y: 19.3,
          },
          {
            x: 68,
            y: 19.47,
          },
          {
            x: 69,
            y: 19.65,
          },
          {
            x: 70,
            y: 19.82,
          },
          {
            x: 71,
            y: 19.99,
          },
          {
            x: 72,
            y: 20.16,
          },
          {
            x: 73,
            y: 20.34,
          },
          {
            x: 74,
            y: 20.51,
          },
          {
            x: 75,
            y: 20.69,
          },
          {
            x: 76,
            y: 20.87,
          },
          {
            x: 77,
            y: 21.05,
          },
          {
            x: 78,
            y: 21.23,
          },
          {
            x: 79,
            y: 21.41,
          },
          {
            x: 80,
            y: 21.6,
          },
          {
            x: 81,
            y: 21.79,
          },
          {
            x: 82,
            y: 21.98,
          },
          {
            x: 83,
            y: 22.18,
          },
          {
            x: 84,
            y: 22.37,
          },
          {
            x: 85,
            y: 22.58,
          },
          {
            x: 86,
            y: 22.78,
          },
          {
            x: 87,
            y: 22.99,
          },
          {
            x: 88,
            y: 23.2,
          },
          {
            x: 89,
            y: 23.42,
          },
          {
            x: 90,
            y: 23.64,
          },
          {
            x: 91,
            y: 23.86,
          },
          {
            x: 92,
            y: 24.09,
          },
          {
            x: 93,
            y: 24.32,
          },
          {
            x: 94,
            y: 24.55,
          },
          {
            x: 95,
            y: 24.79,
          },
          {
            x: 96,
            y: 25.03,
          },
          {
            x: 97,
            y: 25.27,
          },
          {
            x: 98,
            y: 25.52,
          },
          {
            x: 99,
            y: 25.77,
          },
          {
            x: 100,
            y: 26.03,
          },
          {
            x: 101,
            y: 26.29,
          },
          {
            x: 102,
            y: 26.55,
          },
          {
            x: 103,
            y: 26.82,
          },
          {
            x: 104,
            y: 27.09,
          },
          {
            x: 105,
            y: 27.36,
          },
          {
            x: 106,
            y: 27.64,
          },
          {
            x: 107,
            y: 27.92,
          },
          {
            x: 108,
            y: 28.2,
          },
          {
            x: 109,
            y: 28.49,
          },
          {
            x: 110,
            y: 28.78,
          },
          {
            x: 111,
            y: 29.07,
          },
          {
            x: 112,
            y: 29.37,
          },
          {
            x: 113,
            y: 29.66,
          },
          {
            x: 114,
            y: 29.97,
          },
          {
            x: 115,
            y: 30.27,
          },
          {
            x: 116,
            y: 30.58,
          },
          {
            x: 117,
            y: 30.89,
          },
          {
            x: 118,
            y: 31.21,
          },
          {
            x: 119,
            y: 31.53,
          },
          {
            x: 120,
            y: 31.86,
          },
        ],
      },
      // -1
      {
        color: "#000000",
        name: "-1",
        data: [
          {
            x: 0,
            y: 2.8,
          },
          {
            x: 1,
            y: 3.6,
          },
          {
            x: 2,
            y: 4.5,
          },
          {
            x: 3,
            y: 5.2,
          },
          {
            x: 4,
            y: 5.7,
          },
          {
            x: 5,
            y: 6.1,
          },
          {
            x: 6,
            y: 6.5,
          },
          {
            x: 7,
            y: 6.8,
          },
          {
            x: 8,
            y: 7,
          },
          {
            x: 9,
            y: 7.3,
          },
          {
            x: 10,
            y: 7.5,
          },
          {
            x: 11,
            y: 7.7,
          },
          {
            x: 12,
            y: 7.9,
          },
          {
            x: 13,
            y: 8.1,
          },
          {
            x: 14,
            y: 8.3,
          },
          {
            x: 15,
            y: 8.5,
          },
          {
            x: 16,
            y: 8.7,
          },
          {
            x: 17,
            y: 8.9,
          },
          {
            x: 18,
            y: 9.1,
          },
          {
            x: 19,
            y: 9.2,
          },
          {
            x: 20,
            y: 9.4,
          },
          {
            x: 21,
            y: 9.6,
          },
          {
            x: 22,
            y: 9.8,
          },
          {
            x: 23,
            y: 10,
          },
          {
            x: 24,
            y: 10.2,
          },
          {
            x: 25,
            y: 10.3,
          },
          {
            x: 26,
            y: 10.5,
          },
          {
            x: 27,
            y: 10.7,
          },
          {
            x: 28,
            y: 10.9,
          },
          {
            x: 29,
            y: 11.1,
          },
          {
            x: 30,
            y: 11.2,
          },
          {
            x: 31,
            y: 11.4,
          },
          {
            x: 32,
            y: 11.6,
          },
          {
            x: 33,
            y: 11.7,
          },
          {
            x: 34,
            y: 11.9,
          },
          {
            x: 35,
            y: 12,
          },
          {
            x: 36,
            y: 12.2,
          },
          {
            x: 37,
            y: 12.4,
          },
          {
            x: 38,
            y: 12.5,
          },
          {
            x: 39,
            y: 12.7,
          },
          {
            x: 40,
            y: 12.8,
          },
          {
            x: 41,
            y: 13,
          },
          {
            x: 42,
            y: 13.1,
          },
          {
            x: 43,
            y: 13.3,
          },
          {
            x: 44,
            y: 13.4,
          },
          {
            x: 45,
            y: 13.6,
          },
          {
            x: 46,
            y: 13.7,
          },
          {
            x: 47,
            y: 13.9,
          },
          {
            x: 48,
            y: 14,
          },
          {
            x: 49,
            y: 14.2,
          },
          {
            x: 50,
            y: 14.3,
          },
          {
            x: 51,
            y: 14.5,
          },
          {
            x: 52,
            y: 14.6,
          },
          {
            x: 53,
            y: 14.8,
          },
          {
            x: 54,
            y: 14.9,
          },
          {
            x: 55,
            y: 15.1,
          },
          {
            x: 56,
            y: 15.2,
          },
          {
            x: 57,
            y: 15.3,
          },
          {
            x: 58,
            y: 15.5,
          },
          {
            x: 59,
            y: 15.6,
          },
          {
            x: 60,
            y: 15.8,
          },
          {
            x: 61,
            y: 15.9,
          },
          {
            x: 62,
            y: 16.04,
          },
          {
            x: 63,
            y: 16.19,
          },
          {
            x: 64,
            y: 16.33,
          },
          {
            x: 65,
            y: 16.47,
          },
          {
            x: 66,
            y: 16.62,
          },
          {
            x: 67,
            y: 16.76,
          },
          {
            x: 68,
            y: 16.9,
          },
          {
            x: 69,
            y: 17.04,
          },
          {
            x: 70,
            y: 17.18,
          },
          {
            x: 71,
            y: 17.32,
          },
          {
            x: 72,
            y: 17.46,
          },
          {
            x: 73,
            y: 17.61,
          },
          {
            x: 74,
            y: 17.75,
          },
          {
            x: 75,
            y: 17.89,
          },
          {
            x: 76,
            y: 18.04,
          },
          {
            x: 77,
            y: 18.18,
          },
          {
            x: 78,
            y: 18.33,
          },
          {
            x: 79,
            y: 18.48,
          },
          {
            x: 80,
            y: 18.64,
          },
          {
            x: 81,
            y: 18.79,
          },
          {
            x: 82,
            y: 18.95,
          },
          {
            x: 83,
            y: 19.11,
          },
          {
            x: 84,
            y: 19.27,
          },
          {
            x: 85,
            y: 19.43,
          },
          {
            x: 86,
            y: 19.6,
          },
          {
            x: 87,
            y: 19.77,
          },
          {
            x: 88,
            y: 19.95,
          },
          {
            x: 89,
            y: 20.12,
          },
          {
            x: 90,
            y: 20.3,
          },
          {
            x: 91,
            y: 20.48,
          },
          {
            x: 92,
            y: 20.67,
          },
          {
            x: 93,
            y: 20.85,
          },
          {
            x: 94,
            y: 21.04,
          },
          {
            x: 95,
            y: 21.24,
          },
          {
            x: 96,
            y: 21.43,
          },
          {
            x: 97,
            y: 21.64,
          },
          {
            x: 98,
            y: 21.84,
          },
          {
            x: 99,
            y: 22.05,
          },
          {
            x: 100,
            y: 22.25,
          },
          {
            x: 101,
            y: 22.47,
          },
          {
            x: 102,
            y: 22.68,
          },
          {
            x: 103,
            y: 22.9,
          },
          {
            x: 104,
            y: 23.12,
          },
          {
            x: 105,
            y: 23.35,
          },
          {
            x: 106,
            y: 23.57,
          },
          {
            x: 107,
            y: 23.8,
          },
          {
            x: 108,
            y: 24.04,
          },
          {
            x: 109,
            y: 24.27,
          },
          {
            x: 110,
            y: 24.51,
          },
          {
            x: 111,
            y: 24.75,
          },
          {
            x: 112,
            y: 24.99,
          },
          {
            x: 113,
            y: 25.23,
          },
          {
            x: 114,
            y: 25.48,
          },
          {
            x: 115,
            y: 25.73,
          },
          {
            x: 116,
            y: 25.98,
          },
          {
            x: 117,
            y: 26.24,
          },
          {
            x: 118,
            y: 26.5,
          },
          {
            x: 119,
            y: 26.76,
          },
          {
            x: 120,
            y: 27.03,
          },
        ],
      },
      // -2
      {
        color: "#F30037",
        name: "-2",
        data: [
          {
            x: 0,
            y: 2.4,
          },
          {
            x: 1,
            y: 3.2,
          },
          {
            x: 2,
            y: 3.9,
          },
          {
            x: 3,
            y: 4.5,
          },
          {
            x: 4,
            y: 5,
          },
          {
            x: 5,
            y: 5.4,
          },
          {
            x: 6,
            y: 5.7,
          },
          {
            x: 7,
            y: 6,
          },
          {
            x: 8,
            y: 6.3,
          },
          {
            x: 9,
            y: 6.5,
          },
          {
            x: 10,
            y: 6.7,
          },
          {
            x: 11,
            y: 6.9,
          },
          {
            x: 12,
            y: 7,
          },
          {
            x: 13,
            y: 7.2,
          },
          {
            x: 14,
            y: 7.4,
          },
          {
            x: 15,
            y: 7.6,
          },
          {
            x: 16,
            y: 7.7,
          },
          {
            x: 17,
            y: 7.9,
          },
          {
            x: 18,
            y: 8.1,
          },
          {
            x: 19,
            y: 8.2,
          },
          {
            x: 20,
            y: 8.4,
          },
          {
            x: 21,
            y: 8.6,
          },
          {
            x: 22,
            y: 8.7,
          },
          {
            x: 23,
            y: 8.9,
          },
          {
            x: 24,
            y: 9,
          },
          {
            x: 25,
            y: 9.2,
          },
          {
            x: 26,
            y: 9.4,
          },
          {
            x: 27,
            y: 9.5,
          },
          {
            x: 28,
            y: 9.7,
          },
          {
            x: 29,
            y: 9.8,
          },
          {
            x: 30,
            y: 10,
          },
          {
            x: 31,
            y: 10.1,
          },
          {
            x: 32,
            y: 10.3,
          },
          {
            x: 33,
            y: 10.4,
          },
          {
            x: 34,
            y: 10.5,
          },
          {
            x: 35,
            y: 10.7,
          },
          {
            x: 36,
            y: 10.8,
          },
          {
            x: 37,
            y: 10.9,
          },
          {
            x: 38,
            y: 11.1,
          },
          {
            x: 39,
            y: 11.2,
          },
          {
            x: 40,
            y: 11.3,
          },
          {
            x: 41,
            y: 11.5,
          },
          {
            x: 42,
            y: 11.6,
          },
          {
            x: 43,
            y: 11.7,
          },
          {
            x: 44,
            y: 11.8,
          },
          {
            x: 45,
            y: 12,
          },
          {
            x: 46,
            y: 12.1,
          },
          {
            x: 47,
            y: 12.2,
          },
          {
            x: 48,
            y: 12.3,
          },
          {
            x: 49,
            y: 12.4,
          },
          {
            x: 50,
            y: 12.6,
          },
          {
            x: 51,
            y: 12.7,
          },
          {
            x: 52,
            y: 12.8,
          },
          {
            x: 53,
            y: 12.9,
          },
          {
            x: 54,
            y: 13,
          },
          {
            x: 55,
            y: 13.2,
          },
          {
            x: 56,
            y: 13.3,
          },
          {
            x: 57,
            y: 13.4,
          },
          {
            x: 58,
            y: 13.5,
          },
          {
            x: 59,
            y: 13.6,
          },
          {
            x: 60,
            y: 13.7,
          },
          {
            x: 61,
            y: 13.96,
          },
          {
            x: 62,
            y: 14.08,
          },
          {
            x: 63,
            y: 14.2,
          },
          {
            x: 64,
            y: 14.32,
          },
          {
            x: 65,
            y: 14.44,
          },
          {
            x: 66,
            y: 14.56,
          },
          {
            x: 67,
            y: 14.68,
          },
          {
            x: 68,
            y: 14.8,
          },
          {
            x: 69,
            y: 14.92,
          },
          {
            x: 70,
            y: 15.04,
          },
          {
            x: 71,
            y: 15.16,
          },
          {
            x: 72,
            y: 15.27,
          },
          {
            x: 73,
            y: 15.39,
          },
          {
            x: 74,
            y: 15.51,
          },
          {
            x: 75,
            y: 15.63,
          },
          {
            x: 76,
            y: 15.75,
          },
          {
            x: 77,
            y: 15.88,
          },
          {
            x: 78,
            y: 16,
          },
          {
            x: 79,
            y: 16.12,
          },
          {
            x: 80,
            y: 16.25,
          },
          {
            x: 81,
            y: 16.38,
          },
          {
            x: 82,
            y: 16.51,
          },
          {
            x: 83,
            y: 16.64,
          },
          {
            x: 84,
            y: 16.78,
          },
          {
            x: 85,
            y: 16.92,
          },
          {
            x: 86,
            y: 17.06,
          },
          {
            x: 87,
            y: 17.2,
          },
          {
            x: 88,
            y: 17.34,
          },
          {
            x: 89,
            y: 17.49,
          },
          {
            x: 90,
            y: 17.64,
          },
          {
            x: 91,
            y: 17.79,
          },
          {
            x: 92,
            y: 17.95,
          },
          {
            x: 93,
            y: 18.1,
          },
          {
            x: 94,
            y: 18.26,
          },
          {
            x: 95,
            y: 18.42,
          },
          {
            x: 96,
            y: 18.59,
          },
          {
            x: 97,
            y: 18.76,
          },
          {
            x: 98,
            y: 18.93,
          },
          {
            x: 99,
            y: 19.1,
          },
          {
            x: 100,
            y: 19.27,
          },
          {
            x: 101,
            y: 19.45,
          },
          {
            x: 102,
            y: 19.63,
          },
          {
            x: 103,
            y: 19.82,
          },
          {
            x: 104,
            y: 20,
          },
          {
            x: 105,
            y: 20.19,
          },
          {
            x: 106,
            y: 20.38,
          },
          {
            x: 107,
            y: 20.57,
          },
          {
            x: 108,
            y: 20.76,
          },
          {
            x: 109,
            y: 20.96,
          },
          {
            x: 110,
            y: 21.16,
          },
          {
            x: 111,
            y: 21.36,
          },
          {
            x: 112,
            y: 21.56,
          },
          {
            x: 113,
            y: 21.77,
          },
          {
            x: 114,
            y: 21.97,
          },
          {
            x: 115,
            y: 22.18,
          },
          {
            x: 116,
            y: 22.39,
          },
          {
            x: 117,
            y: 22.61,
          },
          {
            x: 118,
            y: 22.83,
          },
          {
            x: 119,
            y: 23.05,
          },
          {
            x: 120,
            y: 23.27,
          },
        ],
      },
    ],
    weightForAgeBoy: [
      // 2
      {
        color: "#8260BB",
        name: "2",
        data: [
          {
            x: 0,
            y: 4.4,
          },
          {
            x: 1,
            y: 5.8,
          },
          {
            x: 2,
            y: 7.1,
          },
          {
            x: 3,
            y: 8,
          },
          {
            x: 4,
            y: 8.7,
          },
          {
            x: 5,
            y: 9.3,
          },
          {
            x: 6,
            y: 9.8,
          },
          {
            x: 7,
            y: 10.3,
          },
          {
            x: 8,
            y: 10.7,
          },
          {
            x: 9,
            y: 11,
          },
          {
            x: 10,
            y: 11.4,
          },
          {
            x: 11,
            y: 11.7,
          },
          {
            x: 12,
            y: 12,
          },
          {
            x: 13,
            y: 12.3,
          },
          {
            x: 14,
            y: 12.6,
          },
          {
            x: 15,
            y: 12.8,
          },
          {
            x: 16,
            y: 13.1,
          },
          {
            x: 17,
            y: 13.4,
          },
          {
            x: 18,
            y: 13.7,
          },
          {
            x: 19,
            y: 13.9,
          },
          {
            x: 20,
            y: 14.2,
          },
          {
            x: 21,
            y: 14.5,
          },
          {
            x: 22,
            y: 14.7,
          },
          {
            x: 23,
            y: 15,
          },
          {
            x: 24,
            y: 15.3,
          },
          {
            x: 25,
            y: 15.5,
          },
          {
            x: 26,
            y: 15.8,
          },
          {
            x: 27,
            y: 16.1,
          },
          {
            x: 28,
            y: 16.3,
          },
          {
            x: 29,
            y: 16.6,
          },
          {
            x: 30,
            y: 16.9,
          },
          {
            x: 31,
            y: 17.1,
          },
          {
            x: 32,
            y: 17.4,
          },
          {
            x: 33,
            y: 17.6,
          },
          {
            x: 34,
            y: 17.8,
          },
          {
            x: 35,
            y: 18.1,
          },
          {
            x: 36,
            y: 18.3,
          },
          {
            x: 37,
            y: 18.6,
          },
          {
            x: 38,
            y: 18.8,
          },
          {
            x: 39,
            y: 19,
          },
          {
            x: 40,
            y: 19.3,
          },
          {
            x: 41,
            y: 19.5,
          },
          {
            x: 42,
            y: 19.7,
          },
          {
            x: 43,
            y: 20,
          },
          {
            x: 44,
            y: 20.2,
          },
          {
            x: 45,
            y: 20.5,
          },
          {
            x: 46,
            y: 20.7,
          },
          {
            x: 47,
            y: 20.9,
          },
          {
            x: 48,
            y: 21.2,
          },
          {
            x: 49,
            y: 21.4,
          },
          {
            x: 50,
            y: 21.7,
          },
          {
            x: 51,
            y: 21.9,
          },
          {
            x: 52,
            y: 22.2,
          },
          {
            x: 53,
            y: 22.4,
          },
          {
            x: 54,
            y: 22.7,
          },
          {
            x: 55,
            y: 22.9,
          },
          {
            x: 56,
            y: 23.2,
          },
          {
            x: 57,
            y: 23.4,
          },
          {
            x: 58,
            y: 23.7,
          },
          {
            x: 59,
            y: 23.9,
          },
          {
            x: 60,
            y: 24.2,
          },
          {
            x: 61,
            y: 24.16,
          },
          {
            x: 62,
            y: 24.42,
          },
          {
            x: 63,
            y: 24.68,
          },
          {
            x: 64,
            y: 24.95,
          },
          {
            x: 65,
            y: 25.21,
          },
          {
            x: 66,
            y: 25.48,
          },
          {
            x: 67,
            y: 25.75,
          },
          {
            x: 68,
            y: 26.02,
          },
          {
            x: 69,
            y: 26.29,
          },
          {
            x: 70,
            y: 26.57,
          },
          {
            x: 71,
            y: 26.85,
          },
          {
            x: 72,
            y: 27.13,
          },
          {
            x: 73,
            y: 27.41,
          },
          {
            x: 74,
            y: 27.7,
          },
          {
            x: 75,
            y: 27.98,
          },
          {
            x: 76,
            y: 28.27,
          },
          {
            x: 77,
            y: 28.57,
          },
          {
            x: 78,
            y: 28.86,
          },
          {
            x: 79,
            y: 29.16,
          },
          {
            x: 80,
            y: 29.46,
          },
          {
            x: 81,
            y: 29.77,
          },
          {
            x: 82,
            y: 30.07,
          },
          {
            x: 83,
            y: 30.38,
          },
          {
            x: 84,
            y: 30.7,
          },
          {
            x: 85,
            y: 31.01,
          },
          {
            x: 86,
            y: 31.33,
          },
          {
            x: 87,
            y: 31.65,
          },
          {
            x: 88,
            y: 31.98,
          },
          {
            x: 89,
            y: 32.31,
          },
          {
            x: 90,
            y: 32.64,
          },
          {
            x: 91,
            y: 32.98,
          },
          {
            x: 92,
            y: 33.32,
          },
          {
            x: 93,
            y: 33.66,
          },
          {
            x: 94,
            y: 34.02,
          },
          {
            x: 95,
            y: 34.37,
          },
          {
            x: 96,
            y: 34.73,
          },
          {
            x: 97,
            y: 35.09,
          },
          {
            x: 98,
            y: 35.46,
          },
          {
            x: 99,
            y: 35.83,
          },
          {
            x: 100,
            y: 36.21,
          },
          {
            x: 101,
            y: 36.59,
          },
          {
            x: 102,
            y: 36.98,
          },
          {
            x: 103,
            y: 37.38,
          },
          {
            x: 104,
            y: 37.77,
          },
          {
            x: 105,
            y: 38.18,
          },
          {
            x: 106,
            y: 38.59,
          },
          {
            x: 107,
            y: 39.01,
          },
          {
            x: 108,
            y: 39.43,
          },
          {
            x: 109,
            y: 39.86,
          },
          {
            x: 110,
            y: 40.3,
          },
          {
            x: 111,
            y: 40.74,
          },
          {
            x: 112,
            y: 41.2,
          },
          {
            x: 113,
            y: 41.65,
          },
          {
            x: 114,
            y: 42.12,
          },
          {
            x: 115,
            y: 42.59,
          },
          {
            x: 116,
            y: 43.06,
          },
          {
            x: 117,
            y: 43.55,
          },
          {
            x: 118,
            y: 44.04,
          },
          {
            x: 119,
            y: 44.53,
          },
          {
            x: 120,
            y: 45.04,
          },
        ],
      },
      // 1
      {
        color: "#ED9300",
        name: "1",
        data: [
          {
            x: 0,
            y: 3.9,
          },
          {
            x: 1,
            y: 5.1,
          },
          {
            x: 2,
            y: 6.3,
          },
          {
            x: 3,
            y: 7.2,
          },
          {
            x: 4,
            y: 7.8,
          },
          {
            x: 5,
            y: 8.4,
          },
          {
            x: 6,
            y: 8.8,
          },
          {
            x: 7,
            y: 9.2,
          },
          {
            x: 8,
            y: 9.6,
          },
          {
            x: 9,
            y: 9.9,
          },
          {
            x: 10,
            y: 10.2,
          },
          {
            x: 11,
            y: 10.5,
          },
          {
            x: 12,
            y: 10.8,
          },
          {
            x: 13,
            y: 11,
          },
          {
            x: 14,
            y: 11.3,
          },
          {
            x: 15,
            y: 11.5,
          },
          {
            x: 16,
            y: 11.7,
          },
          {
            x: 17,
            y: 12,
          },
          {
            x: 18,
            y: 12.2,
          },
          {
            x: 19,
            y: 12.5,
          },
          {
            x: 20,
            y: 12.7,
          },
          {
            x: 21,
            y: 12.9,
          },
          {
            x: 22,
            y: 13.2,
          },
          {
            x: 23,
            y: 13.4,
          },
          {
            x: 24,
            y: 13.6,
          },
          {
            x: 25,
            y: 13.9,
          },
          {
            x: 26,
            y: 14.1,
          },
          {
            x: 27,
            y: 14.3,
          },
          {
            x: 28,
            y: 14.5,
          },
          {
            x: 29,
            y: 14.8,
          },
          {
            x: 30,
            y: 15,
          },
          {
            x: 31,
            y: 15.2,
          },
          {
            x: 32,
            y: 15.4,
          },
          {
            x: 33,
            y: 15.6,
          },
          {
            x: 34,
            y: 15.8,
          },
          {
            x: 35,
            y: 16,
          },
          {
            x: 36,
            y: 16.2,
          },
          {
            x: 37,
            y: 16.4,
          },
          {
            x: 38,
            y: 16.6,
          },
          {
            x: 39,
            y: 16.8,
          },
          {
            x: 40,
            y: 17,
          },
          {
            x: 41,
            y: 17.2,
          },
          {
            x: 42,
            y: 17.4,
          },
          {
            x: 43,
            y: 17.6,
          },
          {
            x: 44,
            y: 17.8,
          },
          {
            x: 45,
            y: 18,
          },
          {
            x: 46,
            y: 18.2,
          },
          {
            x: 47,
            y: 18.4,
          },
          {
            x: 48,
            y: 18.6,
          },
          {
            x: 49,
            y: 18.8,
          },
          {
            x: 50,
            y: 19,
          },
          {
            x: 51,
            y: 19.2,
          },
          {
            x: 52,
            y: 19.4,
          },
          {
            x: 53,
            y: 19.6,
          },
          {
            x: 54,
            y: 19.8,
          },
          {
            x: 55,
            y: 20,
          },
          {
            x: 56,
            y: 20.2,
          },
          {
            x: 57,
            y: 20.4,
          },
          {
            x: 58,
            y: 20.6,
          },
          {
            x: 59,
            y: 20.8,
          },
          {
            x: 60,
            y: 21,
          },
          {
            x: 61,
            y: 21.11,
          },
          {
            x: 62,
            y: 21.32,
          },
          {
            x: 63,
            y: 21.53,
          },
          {
            x: 64,
            y: 21.74,
          },
          {
            x: 65,
            y: 21.96,
          },
          {
            x: 66,
            y: 22.18,
          },
          {
            x: 67,
            y: 22.39,
          },
          {
            x: 68,
            y: 22.61,
          },
          {
            x: 69,
            y: 22.84,
          },
          {
            x: 70,
            y: 23.06,
          },
          {
            x: 71,
            y: 23.29,
          },
          {
            x: 72,
            y: 23.52,
          },
          {
            x: 73,
            y: 23.75,
          },
          {
            x: 74,
            y: 23.98,
          },
          {
            x: 75,
            y: 24.21,
          },
          {
            x: 76,
            y: 24.45,
          },
          {
            x: 77,
            y: 24.68,
          },
          {
            x: 78,
            y: 24.92,
          },
          {
            x: 79,
            y: 25.16,
          },
          {
            x: 80,
            y: 25.4,
          },
          {
            x: 81,
            y: 25.64,
          },
          {
            x: 82,
            y: 25.89,
          },
          {
            x: 83,
            y: 26.13,
          },
          {
            x: 84,
            y: 26.38,
          },
          {
            x: 85,
            y: 26.63,
          },
          {
            x: 86,
            y: 26.89,
          },
          {
            x: 87,
            y: 27.14,
          },
          {
            x: 88,
            y: 27.39,
          },
          {
            x: 89,
            y: 27.65,
          },
          {
            x: 90,
            y: 27.91,
          },
          {
            x: 91,
            y: 28.17,
          },
          {
            x: 92,
            y: 28.44,
          },
          {
            x: 93,
            y: 28.7,
          },
          {
            x: 94,
            y: 28.97,
          },
          {
            x: 95,
            y: 29.24,
          },
          {
            x: 96,
            y: 29.51,
          },
          {
            x: 97,
            y: 29.79,
          },
          {
            x: 98,
            y: 30.06,
          },
          {
            x: 99,
            y: 30.34,
          },
          {
            x: 100,
            y: 30.63,
          },
          {
            x: 101,
            y: 30.91,
          },
          {
            x: 102,
            y: 31.2,
          },
          {
            x: 103,
            y: 31.49,
          },
          {
            x: 104,
            y: 31.78,
          },
          {
            x: 105,
            y: 32.08,
          },
          {
            x: 106,
            y: 32.38,
          },
          {
            x: 107,
            y: 32.69,
          },
          {
            x: 108,
            y: 32.99,
          },
          {
            x: 109,
            y: 33.3,
          },
          {
            x: 110,
            y: 33.62,
          },
          {
            x: 111,
            y: 33.94,
          },
          {
            x: 112,
            y: 34.27,
          },
          {
            x: 113,
            y: 34.59,
          },
          {
            x: 114,
            y: 34.93,
          },
          {
            x: 115,
            y: 35.27,
          },
          {
            x: 116,
            y: 35.61,
          },
          {
            x: 117,
            y: 35.96,
          },
          {
            x: 118,
            y: 36.31,
          },
          {
            x: 119,
            y: 36.67,
          },
          {
            x: 120,
            y: 37.03,
          },
        ],
      },
      // 0
      {
        color: "#77EA04",
        name: "0",
        data: [
          {
            x: 0,
            y: 3.3,
          },
          {
            x: 1,
            y: 4.5,
          },
          {
            x: 2,
            y: 5.6,
          },
          {
            x: 3,
            y: 6.4,
          },
          {
            x: 4,
            y: 7,
          },
          {
            x: 5,
            y: 7.5,
          },
          {
            x: 6,
            y: 7.9,
          },
          {
            x: 7,
            y: 8.3,
          },
          {
            x: 8,
            y: 8.6,
          },
          {
            x: 9,
            y: 8.9,
          },
          {
            x: 10,
            y: 9.2,
          },
          {
            x: 11,
            y: 9.4,
          },
          {
            x: 12,
            y: 9.6,
          },
          {
            x: 13,
            y: 9.9,
          },
          {
            x: 14,
            y: 10.1,
          },
          {
            x: 15,
            y: 10.3,
          },
          {
            x: 16,
            y: 10.5,
          },
          {
            x: 17,
            y: 10.7,
          },
          {
            x: 18,
            y: 10.9,
          },
          {
            x: 19,
            y: 11.1,
          },
          {
            x: 20,
            y: 11.3,
          },
          {
            x: 21,
            y: 11.5,
          },
          {
            x: 22,
            y: 11.8,
          },
          {
            x: 23,
            y: 12,
          },
          {
            x: 24,
            y: 12.2,
          },
          {
            x: 25,
            y: 12.4,
          },
          {
            x: 26,
            y: 12.5,
          },
          {
            x: 27,
            y: 12.7,
          },
          {
            x: 28,
            y: 12.9,
          },
          {
            x: 29,
            y: 13.1,
          },
          {
            x: 30,
            y: 13.3,
          },
          {
            x: 31,
            y: 13.5,
          },
          {
            x: 32,
            y: 13.7,
          },
          {
            x: 33,
            y: 13.8,
          },
          {
            x: 34,
            y: 14,
          },
          {
            x: 35,
            y: 14.2,
          },
          {
            x: 36,
            y: 14.3,
          },
          {
            x: 37,
            y: 14.5,
          },
          {
            x: 38,
            y: 14.7,
          },
          {
            x: 39,
            y: 14.8,
          },
          {
            x: 40,
            y: 15,
          },
          {
            x: 41,
            y: 15.2,
          },
          {
            x: 42,
            y: 15.3,
          },
          {
            x: 43,
            y: 15.5,
          },
          {
            x: 44,
            y: 15.7,
          },
          {
            x: 45,
            y: 15.8,
          },
          {
            x: 46,
            y: 16,
          },
          {
            x: 47,
            y: 16.2,
          },
          {
            x: 48,
            y: 16.3,
          },
          {
            x: 49,
            y: 16.5,
          },
          {
            x: 50,
            y: 16.7,
          },
          {
            x: 51,
            y: 16.8,
          },
          {
            x: 52,
            y: 17,
          },
          {
            x: 53,
            y: 17.2,
          },
          {
            x: 54,
            y: 17.3,
          },
          {
            x: 55,
            y: 17.5,
          },
          {
            x: 56,
            y: 17.7,
          },
          {
            x: 57,
            y: 17.8,
          },
          {
            x: 58,
            y: 18,
          },
          {
            x: 59,
            y: 18.2,
          },
          {
            x: 60,
            y: 18.3,
          },
          {
            x: 61,
            y: 18.51,
          },
          {
            x: 62,
            y: 18.68,
          },
          {
            x: 63,
            y: 18.86,
          },
          {
            x: 64,
            y: 19.03,
          },
          {
            x: 65,
            y: 19.21,
          },
          {
            x: 66,
            y: 19.39,
          },
          {
            x: 67,
            y: 19.58,
          },
          {
            x: 68,
            y: 19.76,
          },
          {
            x: 69,
            y: 19.95,
          },
          {
            x: 70,
            y: 20.13,
          },
          {
            x: 71,
            y: 20.32,
          },
          {
            x: 72,
            y: 20.51,
          },
          {
            x: 73,
            y: 20.7,
          },
          {
            x: 74,
            y: 20.9,
          },
          {
            x: 75,
            y: 21.09,
          },
          {
            x: 76,
            y: 21.29,
          },
          {
            x: 77,
            y: 21.48,
          },
          {
            x: 78,
            y: 21.68,
          },
          {
            x: 79,
            y: 21.88,
          },
          {
            x: 80,
            y: 22.08,
          },
          {
            x: 81,
            y: 22.28,
          },
          {
            x: 82,
            y: 22.48,
          },
          {
            x: 83,
            y: 22.69,
          },
          {
            x: 84,
            y: 22.89,
          },
          {
            x: 85,
            y: 23.1,
          },
          {
            x: 86,
            y: 23.3,
          },
          {
            x: 87,
            y: 23.51,
          },
          {
            x: 88,
            y: 23.72,
          },
          {
            x: 89,
            y: 23.93,
          },
          {
            x: 90,
            y: 24.14,
          },
          {
            x: 91,
            y: 24.35,
          },
          {
            x: 92,
            y: 24.56,
          },
          {
            x: 93,
            y: 24.77,
          },
          {
            x: 94,
            y: 24.99,
          },
          {
            x: 95,
            y: 25.2,
          },
          {
            x: 96,
            y: 25.42,
          },
          {
            x: 97,
            y: 25.63,
          },
          {
            x: 98,
            y: 25.85,
          },
          {
            x: 99,
            y: 26.07,
          },
          {
            x: 100,
            y: 26.29,
          },
          {
            x: 101,
            y: 26.51,
          },
          {
            x: 102,
            y: 26.74,
          },
          {
            x: 103,
            y: 26.96,
          },
          {
            x: 104,
            y: 27.19,
          },
          {
            x: 105,
            y: 27.41,
          },
          {
            x: 106,
            y: 27.64,
          },
          {
            x: 107,
            y: 27.88,
          },
          {
            x: 108,
            y: 28.11,
          },
          {
            x: 109,
            y: 28.35,
          },
          {
            x: 110,
            y: 28.59,
          },
          {
            x: 111,
            y: 28.83,
          },
          {
            x: 112,
            y: 29.07,
          },
          {
            x: 113,
            y: 29.32,
          },
          {
            x: 114,
            y: 29.57,
          },
          {
            x: 115,
            y: 29.83,
          },
          {
            x: 116,
            y: 30.09,
          },
          {
            x: 117,
            y: 30.35,
          },
          {
            x: 118,
            y: 30.62,
          },
          {
            x: 119,
            y: 30.89,
          },
          {
            x: 120,
            y: 31.16,
          },
        ],
      },
      // -1
      {
        color: "#000000",
        name: "-1",
        data: [
          {
            x: 0,
            y: 2.9,
          },
          {
            x: 1,
            y: 3.9,
          },
          {
            x: 2,
            y: 4.9,
          },
          {
            x: 3,
            y: 5.7,
          },
          {
            x: 4,
            y: 6.2,
          },
          {
            x: 5,
            y: 6.7,
          },
          {
            x: 6,
            y: 7.1,
          },
          {
            x: 7,
            y: 7.4,
          },
          {
            x: 8,
            y: 7.7,
          },
          {
            x: 9,
            y: 8,
          },
          {
            x: 10,
            y: 8.2,
          },
          {
            x: 11,
            y: 8.4,
          },
          {
            x: 12,
            y: 8.6,
          },
          {
            x: 13,
            y: 8.8,
          },
          {
            x: 14,
            y: 9,
          },
          {
            x: 15,
            y: 9.2,
          },
          {
            x: 16,
            y: 9.4,
          },
          {
            x: 17,
            y: 9.6,
          },
          {
            x: 18,
            y: 9.8,
          },
          {
            x: 19,
            y: 10,
          },
          {
            x: 20,
            y: 10.1,
          },
          {
            x: 21,
            y: 10.3,
          },
          {
            x: 22,
            y: 10.5,
          },
          {
            x: 23,
            y: 10.7,
          },
          {
            x: 24,
            y: 10.8,
          },
          {
            x: 25,
            y: 11,
          },
          {
            x: 26,
            y: 11.2,
          },
          {
            x: 27,
            y: 11.3,
          },
          {
            x: 28,
            y: 11.5,
          },
          {
            x: 29,
            y: 11.7,
          },
          {
            x: 30,
            y: 11.8,
          },
          {
            x: 31,
            y: 12,
          },
          {
            x: 32,
            y: 12.1,
          },
          {
            x: 33,
            y: 12.3,
          },
          {
            x: 34,
            y: 12.4,
          },
          {
            x: 35,
            y: 12.6,
          },
          {
            x: 36,
            y: 12.7,
          },
          {
            x: 37,
            y: 12.9,
          },
          {
            x: 38,
            y: 13,
          },
          {
            x: 39,
            y: 13.1,
          },
          {
            x: 40,
            y: 13.3,
          },
          {
            x: 41,
            y: 13.4,
          },
          {
            x: 42,
            y: 13.6,
          },
          {
            x: 43,
            y: 13.7,
          },
          {
            x: 44,
            y: 13.8,
          },
          {
            x: 45,
            y: 14,
          },
          {
            x: 46,
            y: 14.1,
          },
          {
            x: 47,
            y: 14.3,
          },
          {
            x: 48,
            y: 14.4,
          },
          {
            x: 49,
            y: 14.5,
          },
          {
            x: 50,
            y: 14.7,
          },
          {
            x: 51,
            y: 14.8,
          },
          {
            x: 52,
            y: 15,
          },
          {
            x: 53,
            y: 15.1,
          },
          {
            x: 54,
            y: 15.2,
          },
          {
            x: 55,
            y: 15.4,
          },
          {
            x: 56,
            y: 15.5,
          },
          {
            x: 57,
            y: 15.6,
          },
          {
            x: 58,
            y: 15.8,
          },
          {
            x: 59,
            y: 15.9,
          },
          {
            x: 60,
            y: 16,
          },
          {
            x: 61,
            y: 16.28,
          },
          {
            x: 62,
            y: 16.43,
          },
          {
            x: 63,
            y: 16.58,
          },
          {
            x: 64,
            y: 16.73,
          },
          {
            x: 65,
            y: 16.88,
          },
          {
            x: 66,
            y: 17.04,
          },
          {
            x: 67,
            y: 17.19,
          },
          {
            x: 68,
            y: 17.35,
          },
          {
            x: 69,
            y: 17.51,
          },
          {
            x: 70,
            y: 17.67,
          },
          {
            x: 71,
            y: 17.83,
          },
          {
            x: 72,
            y: 18,
          },
          {
            x: 73,
            y: 18.16,
          },
          {
            x: 74,
            y: 18.33,
          },
          {
            x: 75,
            y: 18.49,
          },
          {
            x: 76,
            y: 18.66,
          },
          {
            x: 77,
            y: 18.83,
          },
          {
            x: 78,
            y: 19,
          },
          {
            x: 79,
            y: 19.17,
          },
          {
            x: 80,
            y: 19.34,
          },
          {
            x: 81,
            y: 19.51,
          },
          {
            x: 82,
            y: 19.68,
          },
          {
            x: 83,
            y: 19.86,
          },
          {
            x: 84,
            y: 20.03,
          },
          {
            x: 85,
            y: 20.2,
          },
          {
            x: 86,
            y: 20.38,
          },
          {
            x: 87,
            y: 20.55,
          },
          {
            x: 88,
            y: 20.73,
          },
          {
            x: 89,
            y: 20.9,
          },
          {
            x: 90,
            y: 21.08,
          },
          {
            x: 91,
            y: 21.25,
          },
          {
            x: 92,
            y: 21.43,
          },
          {
            x: 93,
            y: 21.61,
          },
          {
            x: 94,
            y: 21.78,
          },
          {
            x: 95,
            y: 21.96,
          },
          {
            x: 96,
            y: 22.14,
          },
          {
            x: 97,
            y: 22.32,
          },
          {
            x: 98,
            y: 22.49,
          },
          {
            x: 99,
            y: 22.67,
          },
          {
            x: 100,
            y: 22.85,
          },
          {
            x: 101,
            y: 23.03,
          },
          {
            x: 102,
            y: 23.21,
          },
          {
            x: 103,
            y: 23.39,
          },
          {
            x: 104,
            y: 23.57,
          },
          {
            x: 105,
            y: 23.75,
          },
          {
            x: 106,
            y: 23.94,
          },
          {
            x: 107,
            y: 24.12,
          },
          {
            x: 108,
            y: 24.3,
          },
          {
            x: 109,
            y: 24.49,
          },
          {
            x: 110,
            y: 24.68,
          },
          {
            x: 111,
            y: 24.87,
          },
          {
            x: 112,
            y: 25.06,
          },
          {
            x: 113,
            y: 25.26,
          },
          {
            x: 114,
            y: 25.46,
          },
          {
            x: 115,
            y: 25.66,
          },
          {
            x: 116,
            y: 25.86,
          },
          {
            x: 117,
            y: 26.06,
          },
          {
            x: 118,
            y: 26.27,
          },
          {
            x: 119,
            y: 26.48,
          },
          {
            x: 120,
            y: 26.69,
          },
        ],
      },
      // -2
      {
        color: "#F30037",
        name: "-2",
        data: [
          {
            x: 0,
            y: 2.5,
          },
          {
            x: 1,
            y: 3.4,
          },
          {
            x: 2,
            y: 4.3,
          },
          {
            x: 3,
            y: 5,
          },
          {
            x: 4,
            y: 5.6,
          },
          {
            x: 5,
            y: 6,
          },
          {
            x: 6,
            y: 6.4,
          },
          {
            x: 7,
            y: 6.7,
          },
          {
            x: 8,
            y: 6.9,
          },
          {
            x: 9,
            y: 7.1,
          },
          {
            x: 10,
            y: 7.4,
          },
          {
            x: 11,
            y: 7.6,
          },
          {
            x: 12,
            y: 7.7,
          },
          {
            x: 13,
            y: 7.9,
          },
          {
            x: 14,
            y: 8.1,
          },
          {
            x: 15,
            y: 8.3,
          },
          {
            x: 16,
            y: 8.4,
          },
          {
            x: 17,
            y: 8.6,
          },
          {
            x: 18,
            y: 8.8,
          },
          {
            x: 19,
            y: 8.9,
          },
          {
            x: 20,
            y: 9.1,
          },
          {
            x: 21,
            y: 9.2,
          },
          {
            x: 22,
            y: 9.4,
          },
          {
            x: 23,
            y: 9.5,
          },
          {
            x: 24,
            y: 9.7,
          },
          {
            x: 25,
            y: 9.8,
          },
          {
            x: 26,
            y: 10,
          },
          {
            x: 27,
            y: 10.1,
          },
          {
            x: 28,
            y: 10.2,
          },
          {
            x: 29,
            y: 10.4,
          },
          {
            x: 30,
            y: 10.5,
          },
          {
            x: 31,
            y: 10.7,
          },
          {
            x: 32,
            y: 10.8,
          },
          {
            x: 33,
            y: 10.9,
          },
          {
            x: 34,
            y: 11,
          },
          {
            x: 35,
            y: 11.2,
          },
          {
            x: 36,
            y: 11.3,
          },
          {
            x: 37,
            y: 11.4,
          },
          {
            x: 38,
            y: 11.5,
          },
          {
            x: 39,
            y: 11.6,
          },
          {
            x: 40,
            y: 11.8,
          },
          {
            x: 41,
            y: 11.9,
          },
          {
            x: 42,
            y: 12,
          },
          {
            x: 43,
            y: 12.1,
          },
          {
            x: 44,
            y: 12.2,
          },
          {
            x: 45,
            y: 12.4,
          },
          {
            x: 46,
            y: 12.5,
          },
          {
            x: 47,
            y: 12.6,
          },
          {
            x: 48,
            y: 12.7,
          },
          {
            x: 49,
            y: 12.8,
          },
          {
            x: 50,
            y: 12.9,
          },
          {
            x: 51,
            y: 13.1,
          },
          {
            x: 52,
            y: 13.2,
          },
          {
            x: 53,
            y: 13.3,
          },
          {
            x: 54,
            y: 13.4,
          },
          {
            x: 55,
            y: 13.5,
          },
          {
            x: 56,
            y: 13.6,
          },
          {
            x: 57,
            y: 13.7,
          },
          {
            x: 58,
            y: 13.8,
          },
          {
            x: 59,
            y: 14,
          },
          {
            x: 60,
            y: 14.1,
          },
          {
            x: 61,
            y: 14.37,
          },
          {
            x: 62,
            y: 14.5,
          },
          {
            x: 63,
            y: 14.63,
          },
          {
            x: 64,
            y: 14.76,
          },
          {
            x: 65,
            y: 14.89,
          },
          {
            x: 66,
            y: 15.03,
          },
          {
            x: 67,
            y: 15.16,
          },
          {
            x: 68,
            y: 15.3,
          },
          {
            x: 69,
            y: 15.44,
          },
          {
            x: 70,
            y: 15.58,
          },
          {
            x: 71,
            y: 15.73,
          },
          {
            x: 72,
            y: 15.87,
          },
          {
            x: 73,
            y: 16.02,
          },
          {
            x: 74,
            y: 16.16,
          },
          {
            x: 75,
            y: 16.31,
          },
          {
            x: 76,
            y: 16.45,
          },
          {
            x: 77,
            y: 16.6,
          },
          {
            x: 78,
            y: 16.75,
          },
          {
            x: 79,
            y: 16.9,
          },
          {
            x: 80,
            y: 17.05,
          },
          {
            x: 81,
            y: 17.2,
          },
          {
            x: 82,
            y: 17.35,
          },
          {
            x: 83,
            y: 17.5,
          },
          {
            x: 84,
            y: 17.66,
          },
          {
            x: 85,
            y: 17.81,
          },
          {
            x: 86,
            y: 17.96,
          },
          {
            x: 87,
            y: 18.11,
          },
          {
            x: 88,
            y: 18.26,
          },
          {
            x: 89,
            y: 18.41,
          },
          {
            x: 90,
            y: 18.57,
          },
          {
            x: 91,
            y: 18.72,
          },
          {
            x: 92,
            y: 18.87,
          },
          {
            x: 93,
            y: 19.02,
          },
          {
            x: 94,
            y: 19.17,
          },
          {
            x: 95,
            y: 19.32,
          },
          {
            x: 96,
            y: 19.47,
          },
          {
            x: 97,
            y: 19.62,
          },
          {
            x: 98,
            y: 19.77,
          },
          {
            x: 99,
            y: 19.92,
          },
          {
            x: 100,
            y: 20.07,
          },
          {
            x: 101,
            y: 20.22,
          },
          {
            x: 102,
            y: 20.37,
          },
          {
            x: 103,
            y: 20.52,
          },
          {
            x: 104,
            y: 20.67,
          },
          {
            x: 105,
            y: 20.82,
          },
          {
            x: 106,
            y: 20.97,
          },
          {
            x: 107,
            y: 21.12,
          },
          {
            x: 108,
            y: 21.27,
          },
          {
            x: 109,
            y: 21.43,
          },
          {
            x: 110,
            y: 21.58,
          },
          {
            x: 111,
            y: 21.74,
          },
          {
            x: 112,
            y: 21.89,
          },
          {
            x: 113,
            y: 22.05,
          },
          {
            x: 114,
            y: 22.21,
          },
          {
            x: 115,
            y: 22.37,
          },
          {
            x: 116,
            y: 22.54,
          },
          {
            x: 117,
            y: 22.7,
          },
          {
            x: 118,
            y: 22.87,
          },
          {
            x: 119,
            y: 23.04,
          },
          {
            x: 120,
            y: 23.21,
          },
        ],
      },
    ],
    /**Peso para la edad edad - Nuevo*/

    /**Talla para la edad edad - Nuevo*/
    sizeForAgeGirl: [
      // 2
      {
        color: "#000000",
        name: "2",
        data: [
          {
            x: 0,
            y: 52.9,
          },
          {
            x: 1,
            y: 57.6,
          },
          {
            x: 2,
            y: 61.1,
          },
          {
            x: 3,
            y: 64,
          },
          {
            x: 4,
            y: 66.4,
          },
          {
            x: 5,
            y: 68.5,
          },
          {
            x: 6,
            y: 70.3,
          },
          {
            x: 7,
            y: 71.9,
          },
          {
            x: 8,
            y: 73.5,
          },
          {
            x: 9,
            y: 75,
          },
          {
            x: 10,
            y: 76.4,
          },
          {
            x: 11,
            y: 77.8,
          },
          {
            x: 12,
            y: 79.2,
          },
          {
            x: 13,
            y: 80.5,
          },
          {
            x: 14,
            y: 81.7,
          },
          {
            x: 15,
            y: 83,
          },
          {
            x: 16,
            y: 84.2,
          },
          {
            x: 17,
            y: 85.4,
          },
          {
            x: 18,
            y: 86.5,
          },
          {
            x: 19,
            y: 87.6,
          },
          {
            x: 20,
            y: 88.7,
          },
          {
            x: 21,
            y: 89.8,
          },
          {
            x: 22,
            y: 90.8,
          },
          {
            x: 23,
            y: 91.9,
          },
          {
            x: 24,
            y: 92.2,
          },
          {
            x: 25,
            y: 93.1,
          },
          {
            x: 26,
            y: 94.1,
          },
          {
            x: 27,
            y: 95,
          },
          {
            x: 28,
            y: 96,
          },
          {
            x: 29,
            y: 96.9,
          },
          {
            x: 30,
            y: 97.7,
          },
          {
            x: 31,
            y: 98.6,
          },
          {
            x: 32,
            y: 99.4,
          },
          {
            x: 33,
            y: 100.3,
          },
          {
            x: 34,
            y: 101.1,
          },
          {
            x: 35,
            y: 101.9,
          },
          {
            x: 36,
            y: 102.7,
          },
          {
            x: 37,
            y: 103.4,
          },
          {
            x: 38,
            y: 104.2,
          },
          {
            x: 39,
            y: 105,
          },
          {
            x: 40,
            y: 105.7,
          },
          {
            x: 41,
            y: 106.4,
          },
          {
            x: 42,
            y: 107.2,
          },
          {
            x: 43,
            y: 107.9,
          },
          {
            x: 44,
            y: 108.6,
          },
          {
            x: 45,
            y: 109.3,
          },
          {
            x: 46,
            y: 110,
          },
          {
            x: 47,
            y: 110.7,
          },
          {
            x: 48,
            y: 111.3,
          },
          {
            x: 49,
            y: 112,
          },
          {
            x: 50,
            y: 112.7,
          },
          {
            x: 51,
            y: 113.3,
          },
          {
            x: 52,
            y: 114,
          },
          {
            x: 53,
            y: 114.6,
          },
          {
            x: 54,
            y: 115.2,
          },
          {
            x: 55,
            y: 115.9,
          },
          {
            x: 56,
            y: 116.5,
          },
          {
            x: 57,
            y: 117.1,
          },
          {
            x: 58,
            y: 117.7,
          },
          {
            x: 59,
            y: 118.3,
          },
          {
            x: 60,
            y: 118.9,
          },
          {
            x: 61,
            y: 119.15,
          },
          {
            x: 62,
            y: 119.74,
          },
          {
            x: 63,
            y: 120.32,
          },
          {
            x: 64,
            y: 120.9,
          },
          {
            x: 65,
            y: 121.47,
          },
          {
            x: 66,
            y: 122.04,
          },
          {
            x: 67,
            y: 122.61,
          },
          {
            x: 68,
            y: 123.17,
          },
          {
            x: 69,
            y: 123.72,
          },
          {
            x: 70,
            y: 124.27,
          },
          {
            x: 71,
            y: 124.82,
          },
          {
            x: 72,
            y: 125.36,
          },
          {
            x: 73,
            y: 125.9,
          },
          {
            x: 74,
            y: 126.44,
          },
          {
            x: 75,
            y: 126.97,
          },
          {
            x: 76,
            y: 127.5,
          },
          {
            x: 77,
            y: 128.04,
          },
          {
            x: 78,
            y: 128.57,
          },
          {
            x: 79,
            y: 129.1,
          },
          {
            x: 80,
            y: 129.63,
          },
          {
            x: 81,
            y: 130.16,
          },
          {
            x: 82,
            y: 130.69,
          },
          {
            x: 83,
            y: 131.22,
          },
          {
            x: 84,
            y: 131.74,
          },
          {
            x: 85,
            y: 132.28,
          },
          {
            x: 86,
            y: 132.81,
          },
          {
            x: 87,
            y: 133.34,
          },
          {
            x: 88,
            y: 133.87,
          },
          {
            x: 89,
            y: 134.4,
          },
          {
            x: 90,
            y: 134.93,
          },
          {
            x: 91,
            y: 135.47,
          },
          {
            x: 92,
            y: 136,
          },
          {
            x: 93,
            y: 136.54,
          },
          {
            x: 94,
            y: 137.07,
          },
          {
            x: 95,
            y: 137.61,
          },
          {
            x: 96,
            y: 138.15,
          },
          {
            x: 97,
            y: 138.69,
          },
          {
            x: 98,
            y: 139.23,
          },
          {
            x: 99,
            y: 139.78,
          },
          {
            x: 100,
            y: 140.32,
          },
          {
            x: 101,
            y: 140.86,
          },
          {
            x: 102,
            y: 141.41,
          },
          {
            x: 103,
            y: 141.96,
          },
          {
            x: 104,
            y: 142.51,
          },
          {
            x: 105,
            y: 143.06,
          },
          {
            x: 106,
            y: 143.61,
          },
          {
            x: 107,
            y: 144.16,
          },
          {
            x: 108,
            y: 144.72,
          },
          {
            x: 109,
            y: 145.27,
          },
          {
            x: 110,
            y: 145.82,
          },
          {
            x: 111,
            y: 146.38,
          },
          {
            x: 112,
            y: 146.94,
          },
          {
            x: 113,
            y: 147.5,
          },
          {
            x: 114,
            y: 148.06,
          },
          {
            x: 115,
            y: 148.62,
          },
          {
            x: 116,
            y: 149.18,
          },
          {
            x: 117,
            y: 149.74,
          },
          {
            x: 118,
            y: 150.3,
          },
          {
            x: 119,
            y: 150.87,
          },
          {
            x: 120,
            y: 151.43,
          },
          {
            x: 121,
            y: 151.99,
          },
          {
            x: 122,
            y: 152.56,
          },
          {
            x: 123,
            y: 153.13,
          },
          {
            x: 124,
            y: 153.7,
          },
          {
            x: 125,
            y: 154.27,
          },
          {
            x: 126,
            y: 154.84,
          },
          {
            x: 127,
            y: 155.41,
          },
          {
            x: 128,
            y: 155.99,
          },
          {
            x: 129,
            y: 156.56,
          },
          {
            x: 130,
            y: 157.14,
          },
          {
            x: 131,
            y: 157.71,
          },
          {
            x: 132,
            y: 158.29,
          },
          {
            x: 133,
            y: 158.86,
          },
          {
            x: 134,
            y: 159.43,
          },
          {
            x: 135,
            y: 160,
          },
          {
            x: 136,
            y: 160.56,
          },
          {
            x: 137,
            y: 161.13,
          },
          {
            x: 138,
            y: 161.69,
          },
          {
            x: 139,
            y: 162.24,
          },
          {
            x: 140,
            y: 162.79,
          },
          {
            x: 141,
            y: 163.33,
          },
          {
            x: 142,
            y: 163.87,
          },
          {
            x: 143,
            y: 164.39,
          },
          {
            x: 144,
            y: 164.91,
          },
          {
            x: 145,
            y: 165.42,
          },
          {
            x: 146,
            y: 165.92,
          },
          {
            x: 147,
            y: 166.41,
          },
          {
            x: 148,
            y: 166.89,
          },
          {
            x: 149,
            y: 167.36,
          },
          {
            x: 150,
            y: 167.81,
          },
          {
            x: 151,
            y: 168.25,
          },
          {
            x: 152,
            y: 168.68,
          },
          {
            x: 153,
            y: 169.09,
          },
          {
            x: 154,
            y: 169.5,
          },
          {
            x: 155,
            y: 169.88,
          },
          {
            x: 156,
            y: 170.26,
          },
          {
            x: 157,
            y: 170.62,
          },
          {
            x: 158,
            y: 170.96,
          },
          {
            x: 159,
            y: 171.29,
          },
          {
            x: 160,
            y: 171.61,
          },
          {
            x: 161,
            y: 171.91,
          },
          {
            x: 162,
            y: 172.21,
          },
          {
            x: 163,
            y: 172.48,
          },
          {
            x: 164,
            y: 172.74,
          },
          {
            x: 165,
            y: 173,
          },
          {
            x: 166,
            y: 173.24,
          },
          {
            x: 167,
            y: 173.46,
          },
          {
            x: 168,
            y: 173.68,
          },
          {
            x: 169,
            y: 173.88,
          },
          {
            x: 170,
            y: 174.07,
          },
          {
            x: 171,
            y: 174.25,
          },
          {
            x: 172,
            y: 174.42,
          },
          {
            x: 173,
            y: 174.58,
          },
          {
            x: 174,
            y: 174.73,
          },
          {
            x: 175,
            y: 174.87,
          },
          {
            x: 176,
            y: 175,
          },
          {
            x: 177,
            y: 175.12,
          },
          {
            x: 178,
            y: 175.23,
          },
          {
            x: 179,
            y: 175.33,
          },
          {
            x: 180,
            y: 175.43,
          },
          {
            x: 181,
            y: 175.52,
          },
          {
            x: 182,
            y: 175.6,
          },
          {
            x: 183,
            y: 175.67,
          },
          {
            x: 184,
            y: 175.74,
          },
          {
            x: 185,
            y: 175.8,
          },
          {
            x: 186,
            y: 175.86,
          },
          {
            x: 187,
            y: 175.91,
          },
          {
            x: 188,
            y: 175.95,
          },
          {
            x: 189,
            y: 176,
          },
          {
            x: 190,
            y: 176.03,
          },
          {
            x: 191,
            y: 176.06,
          },
          {
            x: 192,
            y: 176.09,
          },
          {
            x: 193,
            y: 176.11,
          },
          {
            x: 194,
            y: 176.13,
          },
          {
            x: 195,
            y: 176.15,
          },
          {
            x: 196,
            y: 176.17,
          },
          {
            x: 197,
            y: 176.18,
          },
          {
            x: 198,
            y: 176.19,
          },
          {
            x: 199,
            y: 176.21,
          },
          {
            x: 200,
            y: 176.21,
          },
          {
            x: 201,
            y: 176.22,
          },
          {
            x: 202,
            y: 176.22,
          },
          {
            x: 203,
            y: 176.23,
          },
          {
            x: 204,
            y: 176.24,
          },
          {
            x: 205,
            y: 176.24,
          },
          {
            x: 206,
            y: 176.25,
          },
          {
            x: 207,
            y: 176.25,
          },
          {
            x: 208,
            y: 176.25,
          },
          {
            x: 209,
            y: 176.26,
          },
          {
            x: 210,
            y: 176.26,
          },
          {
            x: 211,
            y: 176.26,
          },
          {
            x: 212,
            y: 176.27,
          },
          {
            x: 213,
            y: 176.27,
          },
          {
            x: 214,
            y: 176.28,
          },
          {
            x: 215,
            y: 176.28,
          },
          {
            x: 216,
            y: 176.28,
          },
          {
            x: 217,
            y: 176.28,
          },
          {
            x: 218,
            y: 176.28,
          },
          {
            x: 219,
            y: 176.28,
          },
          {
            x: 220,
            y: 176.28,
          },
          {
            x: 221,
            y: 176.28,
          },
          {
            x: 222,
            y: 176.28,
          },
          {
            x: 223,
            y: 176.27,
          },
          {
            x: 224,
            y: 176.27,
          },
          {
            x: 225,
            y: 176.26,
          },
          {
            x: 226,
            y: 176.25,
          },
          {
            x: 227,
            y: 176.25,
          },
          {
            x: 228,
            y: 176.24,
          },
        ],
      },
      // 1
      {
        color: "#000000",
        name: "1",
        data: [
          {
            x: 0,
            y: 51,
          },
          {
            x: 1,
            y: 55.6,
          },
          {
            x: 2,
            y: 59.1,
          },
          {
            x: 3,
            y: 61.9,
          },
          {
            x: 4,
            y: 64.3,
          },
          {
            x: 5,
            y: 66.2,
          },
          {
            x: 6,
            y: 68,
          },
          {
            x: 7,
            y: 69.6,
          },
          {
            x: 8,
            y: 71.1,
          },
          {
            x: 9,
            y: 72.6,
          },
          {
            x: 10,
            y: 73.9,
          },
          {
            x: 11,
            y: 75.3,
          },
          {
            x: 12,
            y: 76.6,
          },
          {
            x: 13,
            y: 77.8,
          },
          {
            x: 14,
            y: 79.1,
          },
          {
            x: 15,
            y: 80.2,
          },
          {
            x: 16,
            y: 81.4,
          },
          {
            x: 17,
            y: 82.5,
          },
          {
            x: 18,
            y: 83.6,
          },
          {
            x: 19,
            y: 84.7,
          },
          {
            x: 20,
            y: 85.7,
          },
          {
            x: 21,
            y: 86.7,
          },
          {
            x: 22,
            y: 87.7,
          },
          {
            x: 23,
            y: 88.7,
          },
          {
            x: 24,
            y: 88.9,
          },
          {
            x: 25,
            y: 89.9,
          },
          {
            x: 26,
            y: 90.8,
          },
          {
            x: 27,
            y: 91.7,
          },
          {
            x: 28,
            y: 92.5,
          },
          {
            x: 29,
            y: 93.4,
          },
          {
            x: 30,
            y: 94.2,
          },
          {
            x: 31,
            y: 95,
          },
          {
            x: 32,
            y: 95.8,
          },
          {
            x: 33,
            y: 96.6,
          },
          {
            x: 34,
            y: 97.4,
          },
          {
            x: 35,
            y: 98.1,
          },
          {
            x: 36,
            y: 98.9,
          },
          {
            x: 37,
            y: 99.6,
          },
          {
            x: 38,
            y: 100.3,
          },
          {
            x: 39,
            y: 101,
          },
          {
            x: 40,
            y: 101.7,
          },
          {
            x: 41,
            y: 102.4,
          },
          {
            x: 42,
            y: 103.1,
          },
          {
            x: 43,
            y: 103.8,
          },
          {
            x: 44,
            y: 104.5,
          },
          {
            x: 45,
            y: 105.1,
          },
          {
            x: 46,
            y: 105.8,
          },
          {
            x: 47,
            y: 106.4,
          },
          {
            x: 48,
            y: 107,
          },
          {
            x: 49,
            y: 107.7,
          },
          {
            x: 50,
            y: 108.3,
          },
          {
            x: 51,
            y: 108.9,
          },
          {
            x: 52,
            y: 109.5,
          },
          {
            x: 53,
            y: 110.1,
          },
          {
            x: 54,
            y: 110.7,
          },
          {
            x: 55,
            y: 111.3,
          },
          {
            x: 56,
            y: 111.9,
          },
          {
            x: 57,
            y: 112.5,
          },
          {
            x: 58,
            y: 113,
          },
          {
            x: 59,
            y: 113.6,
          },
          {
            x: 60,
            y: 114.2,
          },
          {
            x: 61,
            y: 114.38,
          },
          {
            x: 62,
            y: 114.93,
          },
          {
            x: 63,
            y: 115.48,
          },
          {
            x: 64,
            y: 116.03,
          },
          {
            x: 65,
            y: 116.57,
          },
          {
            x: 66,
            y: 117.11,
          },
          {
            x: 67,
            y: 117.64,
          },
          {
            x: 68,
            y: 118.17,
          },
          {
            x: 69,
            y: 118.69,
          },
          {
            x: 70,
            y: 119.22,
          },
          {
            x: 71,
            y: 119.73,
          },
          {
            x: 72,
            y: 120.24,
          },
          {
            x: 73,
            y: 120.75,
          },
          {
            x: 74,
            y: 121.26,
          },
          {
            x: 75,
            y: 121.77,
          },
          {
            x: 76,
            y: 122.27,
          },
          {
            x: 77,
            y: 122.77,
          },
          {
            x: 78,
            y: 123.27,
          },
          {
            x: 79,
            y: 123.77,
          },
          {
            x: 80,
            y: 124.28,
          },
          {
            x: 81,
            y: 124.78,
          },
          {
            x: 82,
            y: 125.28,
          },
          {
            x: 83,
            y: 125.78,
          },
          {
            x: 84,
            y: 126.28,
          },
          {
            x: 85,
            y: 126.78,
          },
          {
            x: 86,
            y: 127.28,
          },
          {
            x: 87,
            y: 127.79,
          },
          {
            x: 88,
            y: 128.29,
          },
          {
            x: 89,
            y: 128.79,
          },
          {
            x: 90,
            y: 129.3,
          },
          {
            x: 91,
            y: 129.81,
          },
          {
            x: 92,
            y: 130.31,
          },
          {
            x: 93,
            y: 130.82,
          },
          {
            x: 94,
            y: 131.33,
          },
          {
            x: 95,
            y: 131.84,
          },
          {
            x: 96,
            y: 132.35,
          },
          {
            x: 97,
            y: 132.87,
          },
          {
            x: 98,
            y: 133.38,
          },
          {
            x: 99,
            y: 133.9,
          },
          {
            x: 100,
            y: 134.41,
          },
          {
            x: 101,
            y: 134.93,
          },
          {
            x: 102,
            y: 135.45,
          },
          {
            x: 103,
            y: 135.97,
          },
          {
            x: 104,
            y: 136.5,
          },
          {
            x: 105,
            y: 137.02,
          },
          {
            x: 106,
            y: 137.55,
          },
          {
            x: 107,
            y: 138.08,
          },
          {
            x: 108,
            y: 138.6,
          },
          {
            x: 109,
            y: 139.13,
          },
          {
            x: 110,
            y: 139.66,
          },
          {
            x: 111,
            y: 140.2,
          },
          {
            x: 112,
            y: 140.73,
          },
          {
            x: 113,
            y: 141.26,
          },
          {
            x: 114,
            y: 141.8,
          },
          {
            x: 115,
            y: 142.34,
          },
          {
            x: 116,
            y: 142.87,
          },
          {
            x: 117,
            y: 143.41,
          },
          {
            x: 118,
            y: 143.95,
          },
          {
            x: 119,
            y: 144.49,
          },
          {
            x: 120,
            y: 145.03,
          },
          {
            x: 121,
            y: 145.57,
          },
          {
            x: 122,
            y: 146.12,
          },
          {
            x: 123,
            y: 146.67,
          },
          {
            x: 124,
            y: 147.22,
          },
          {
            x: 125,
            y: 147.76,
          },
          {
            x: 126,
            y: 148.32,
          },
          {
            x: 127,
            y: 148.87,
          },
          {
            x: 128,
            y: 149.42,
          },
          {
            x: 129,
            y: 149.97,
          },
          {
            x: 130,
            y: 150.53,
          },
          {
            x: 131,
            y: 151.09,
          },
          {
            x: 132,
            y: 151.64,
          },
          {
            x: 133,
            y: 152.19,
          },
          {
            x: 134,
            y: 152.75,
          },
          {
            x: 135,
            y: 153.3,
          },
          {
            x: 136,
            y: 153.84,
          },
          {
            x: 137,
            y: 154.39,
          },
          {
            x: 138,
            y: 154.93,
          },
          {
            x: 139,
            y: 155.47,
          },
          {
            x: 140,
            y: 156,
          },
          {
            x: 141,
            y: 156.53,
          },
          {
            x: 142,
            y: 157.05,
          },
          {
            x: 143,
            y: 157.57,
          },
          {
            x: 144,
            y: 158.07,
          },
          {
            x: 145,
            y: 158.57,
          },
          {
            x: 146,
            y: 159.06,
          },
          {
            x: 147,
            y: 159.54,
          },
          {
            x: 148,
            y: 160.01,
          },
          {
            x: 149,
            y: 160.46,
          },
          {
            x: 150,
            y: 160.91,
          },
          {
            x: 151,
            y: 161.34,
          },
          {
            x: 152,
            y: 161.76,
          },
          {
            x: 153,
            y: 162.17,
          },
          {
            x: 154,
            y: 162.56,
          },
          {
            x: 155,
            y: 162.95,
          },
          {
            x: 156,
            y: 163.32,
          },
          {
            x: 157,
            y: 163.67,
          },
          {
            x: 158,
            y: 164.01,
          },
          {
            x: 159,
            y: 164.34,
          },
          {
            x: 160,
            y: 164.66,
          },
          {
            x: 161,
            y: 164.96,
          },
          {
            x: 162,
            y: 165.25,
          },
          {
            x: 163,
            y: 165.53,
          },
          {
            x: 164,
            y: 165.79,
          },
          {
            x: 165,
            y: 166.05,
          },
          {
            x: 166,
            y: 166.29,
          },
          {
            x: 167,
            y: 166.51,
          },
          {
            x: 168,
            y: 166.73,
          },
          {
            x: 169,
            y: 166.94,
          },
          {
            x: 170,
            y: 167.13,
          },
          {
            x: 171,
            y: 167.32,
          },
          {
            x: 172,
            y: 167.49,
          },
          {
            x: 173,
            y: 167.66,
          },
          {
            x: 174,
            y: 167.81,
          },
          {
            x: 175,
            y: 167.96,
          },
          {
            x: 176,
            y: 168.09,
          },
          {
            x: 177,
            y: 168.22,
          },
          {
            x: 178,
            y: 168.34,
          },
          {
            x: 179,
            y: 168.45,
          },
          {
            x: 180,
            y: 168.55,
          },
          {
            x: 181,
            y: 168.64,
          },
          {
            x: 182,
            y: 168.73,
          },
          {
            x: 183,
            y: 168.81,
          },
          {
            x: 184,
            y: 168.89,
          },
          {
            x: 185,
            y: 168.96,
          },
          {
            x: 186,
            y: 169.02,
          },
          {
            x: 187,
            y: 169.08,
          },
          {
            x: 188,
            y: 169.13,
          },
          {
            x: 189,
            y: 169.18,
          },
          {
            x: 190,
            y: 169.23,
          },
          {
            x: 191,
            y: 169.27,
          },
          {
            x: 192,
            y: 169.3,
          },
          {
            x: 193,
            y: 169.34,
          },
          {
            x: 194,
            y: 169.36,
          },
          {
            x: 195,
            y: 169.39,
          },
          {
            x: 196,
            y: 169.41,
          },
          {
            x: 197,
            y: 169.44,
          },
          {
            x: 198,
            y: 169.46,
          },
          {
            x: 199,
            y: 169.47,
          },
          {
            x: 200,
            y: 169.49,
          },
          {
            x: 201,
            y: 169.51,
          },
          {
            x: 202,
            y: 169.52,
          },
          {
            x: 203,
            y: 169.53,
          },
          {
            x: 204,
            y: 169.55,
          },
          {
            x: 205,
            y: 169.56,
          },
          {
            x: 206,
            y: 169.57,
          },
          {
            x: 207,
            y: 169.58,
          },
          {
            x: 208,
            y: 169.59,
          },
          {
            x: 209,
            y: 169.6,
          },
          {
            x: 210,
            y: 169.61,
          },
          {
            x: 211,
            y: 169.62,
          },
          {
            x: 212,
            y: 169.63,
          },
          {
            x: 213,
            y: 169.64,
          },
          {
            x: 214,
            y: 169.65,
          },
          {
            x: 215,
            y: 169.66,
          },
          {
            x: 216,
            y: 169.67,
          },
          {
            x: 217,
            y: 169.68,
          },
          {
            x: 218,
            y: 169.68,
          },
          {
            x: 219,
            y: 169.69,
          },
          {
            x: 220,
            y: 169.69,
          },
          {
            x: 221,
            y: 169.7,
          },
          {
            x: 222,
            y: 169.7,
          },
          {
            x: 223,
            y: 169.7,
          },
          {
            x: 224,
            y: 169.71,
          },
          {
            x: 225,
            y: 169.7,
          },
          {
            x: 226,
            y: 169.7,
          },
          {
            x: 227,
            y: 169.7,
          },
          {
            x: 228,
            y: 169.7,
          },
        ],
      },
      // 0
      {
        color: "#77EA04",
        name: "0",
        data: [
          {
            x: 0,
            y: 49.1,
          },
          {
            x: 1,
            y: 53.7,
          },
          {
            x: 2,
            y: 57.1,
          },
          {
            x: 3,
            y: 59.8,
          },
          {
            x: 4,
            y: 62.1,
          },
          {
            x: 5,
            y: 64,
          },
          {
            x: 6,
            y: 65.7,
          },
          {
            x: 7,
            y: 67.3,
          },
          {
            x: 8,
            y: 68.7,
          },
          {
            x: 9,
            y: 70.1,
          },
          {
            x: 10,
            y: 71.5,
          },
          {
            x: 11,
            y: 72.8,
          },
          {
            x: 12,
            y: 74,
          },
          {
            x: 13,
            y: 75.2,
          },
          {
            x: 14,
            y: 76.4,
          },
          {
            x: 15,
            y: 77.5,
          },
          {
            x: 16,
            y: 78.6,
          },
          {
            x: 17,
            y: 79.7,
          },
          {
            x: 18,
            y: 80.7,
          },
          {
            x: 19,
            y: 81.7,
          },
          {
            x: 20,
            y: 82.7,
          },
          {
            x: 21,
            y: 83.7,
          },
          {
            x: 22,
            y: 84.6,
          },
          {
            x: 23,
            y: 85.5,
          },
          {
            x: 24,
            y: 85.7,
          },
          {
            x: 25,
            y: 86.6,
          },
          {
            x: 26,
            y: 87.4,
          },
          {
            x: 27,
            y: 88.3,
          },
          {
            x: 28,
            y: 89.1,
          },
          {
            x: 29,
            y: 89.9,
          },
          {
            x: 30,
            y: 90.7,
          },
          {
            x: 31,
            y: 91.4,
          },
          {
            x: 32,
            y: 92.2,
          },
          {
            x: 33,
            y: 92.9,
          },
          {
            x: 34,
            y: 93.6,
          },
          {
            x: 35,
            y: 94.4,
          },
          {
            x: 36,
            y: 95.1,
          },
          {
            x: 37,
            y: 95.7,
          },
          {
            x: 38,
            y: 96.4,
          },
          {
            x: 39,
            y: 97.1,
          },
          {
            x: 40,
            y: 97.7,
          },
          {
            x: 41,
            y: 98.4,
          },
          {
            x: 42,
            y: 99,
          },
          {
            x: 43,
            y: 99.7,
          },
          {
            x: 44,
            y: 100.3,
          },
          {
            x: 45,
            y: 100.9,
          },
          {
            x: 46,
            y: 101.5,
          },
          {
            x: 47,
            y: 102.1,
          },
          {
            x: 48,
            y: 102.7,
          },
          {
            x: 49,
            y: 103.3,
          },
          {
            x: 50,
            y: 103.9,
          },
          {
            x: 51,
            y: 104.5,
          },
          {
            x: 52,
            y: 105,
          },
          {
            x: 53,
            y: 105.6,
          },
          {
            x: 54,
            y: 106.2,
          },
          {
            x: 55,
            y: 106.7,
          },
          {
            x: 56,
            y: 107.3,
          },
          {
            x: 57,
            y: 107.8,
          },
          {
            x: 58,
            y: 108.4,
          },
          {
            x: 59,
            y: 108.9,
          },
          {
            x: 60,
            y: 109.4,
          },
          {
            x: 61,
            y: 109.6,
          },
          {
            x: 62,
            y: 110.13,
          },
          {
            x: 63,
            y: 110.64,
          },
          {
            x: 64,
            y: 111.16,
          },
          {
            x: 65,
            y: 111.67,
          },
          {
            x: 66,
            y: 112.17,
          },
          {
            x: 67,
            y: 112.68,
          },
          {
            x: 68,
            y: 113.17,
          },
          {
            x: 69,
            y: 113.67,
          },
          {
            x: 70,
            y: 114.16,
          },
          {
            x: 71,
            y: 114.64,
          },
          {
            x: 72,
            y: 115.12,
          },
          {
            x: 73,
            y: 115.6,
          },
          {
            x: 74,
            y: 116.08,
          },
          {
            x: 75,
            y: 116.56,
          },
          {
            x: 76,
            y: 117.03,
          },
          {
            x: 77,
            y: 117.5,
          },
          {
            x: 78,
            y: 117.98,
          },
          {
            x: 79,
            y: 118.45,
          },
          {
            x: 80,
            y: 118.92,
          },
          {
            x: 81,
            y: 119.39,
          },
          {
            x: 82,
            y: 119.86,
          },
          {
            x: 83,
            y: 120.34,
          },
          {
            x: 84,
            y: 120.81,
          },
          {
            x: 85,
            y: 121.28,
          },
          {
            x: 86,
            y: 121.76,
          },
          {
            x: 87,
            y: 122.23,
          },
          {
            x: 88,
            y: 122.71,
          },
          {
            x: 89,
            y: 123.19,
          },
          {
            x: 90,
            y: 123.67,
          },
          {
            x: 91,
            y: 124.14,
          },
          {
            x: 92,
            y: 124.62,
          },
          {
            x: 93,
            y: 125.1,
          },
          {
            x: 94,
            y: 125.59,
          },
          {
            x: 95,
            y: 126.07,
          },
          {
            x: 96,
            y: 126.56,
          },
          {
            x: 97,
            y: 127.04,
          },
          {
            x: 98,
            y: 127.53,
          },
          {
            x: 99,
            y: 128.02,
          },
          {
            x: 100,
            y: 128.51,
          },
          {
            x: 101,
            y: 129,
          },
          {
            x: 102,
            y: 129.5,
          },
          {
            x: 103,
            y: 129.99,
          },
          {
            x: 104,
            y: 130.49,
          },
          {
            x: 105,
            y: 130.99,
          },
          {
            x: 106,
            y: 131.49,
          },
          {
            x: 107,
            y: 131.99,
          },
          {
            x: 108,
            y: 132.49,
          },
          {
            x: 109,
            y: 133,
          },
          {
            x: 110,
            y: 133.5,
          },
          {
            x: 111,
            y: 134.01,
          },
          {
            x: 112,
            y: 134.52,
          },
          {
            x: 113,
            y: 135.03,
          },
          {
            x: 114,
            y: 135.54,
          },
          {
            x: 115,
            y: 136.05,
          },
          {
            x: 116,
            y: 136.57,
          },
          {
            x: 117,
            y: 137.08,
          },
          {
            x: 118,
            y: 137.6,
          },
          {
            x: 119,
            y: 138.12,
          },
          {
            x: 120,
            y: 138.64,
          },
          {
            x: 121,
            y: 139.16,
          },
          {
            x: 122,
            y: 139.68,
          },
          {
            x: 123,
            y: 140.21,
          },
          {
            x: 124,
            y: 140.73,
          },
          {
            x: 125,
            y: 141.26,
          },
          {
            x: 126,
            y: 141.79,
          },
          {
            x: 127,
            y: 142.32,
          },
          {
            x: 128,
            y: 142.85,
          },
          {
            x: 129,
            y: 143.39,
          },
          {
            x: 130,
            y: 143.92,
          },
          {
            x: 131,
            y: 144.46,
          },
          {
            x: 132,
            y: 144.99,
          },
          {
            x: 133,
            y: 145.53,
          },
          {
            x: 134,
            y: 146.06,
          },
          {
            x: 135,
            y: 146.59,
          },
          {
            x: 136,
            y: 147.13,
          },
          {
            x: 137,
            y: 147.66,
          },
          {
            x: 138,
            y: 148.18,
          },
          {
            x: 139,
            y: 148.7,
          },
          {
            x: 140,
            y: 149.22,
          },
          {
            x: 141,
            y: 149.73,
          },
          {
            x: 142,
            y: 150.24,
          },
          {
            x: 143,
            y: 150.74,
          },
          {
            x: 144,
            y: 151.23,
          },
          {
            x: 145,
            y: 151.72,
          },
          {
            x: 146,
            y: 152.19,
          },
          {
            x: 147,
            y: 152.66,
          },
          {
            x: 148,
            y: 153.12,
          },
          {
            x: 149,
            y: 153.57,
          },
          {
            x: 150,
            y: 154,
          },
          {
            x: 151,
            y: 154.43,
          },
          {
            x: 152,
            y: 154.84,
          },
          {
            x: 153,
            y: 155.24,
          },
          {
            x: 154,
            y: 155.63,
          },
          {
            x: 155,
            y: 156.01,
          },
          {
            x: 156,
            y: 156.38,
          },
          {
            x: 157,
            y: 156.73,
          },
          {
            x: 158,
            y: 157.07,
          },
          {
            x: 159,
            y: 157.39,
          },
          {
            x: 160,
            y: 157.71,
          },
          {
            x: 161,
            y: 158.01,
          },
          {
            x: 162,
            y: 158.3,
          },
          {
            x: 163,
            y: 158.58,
          },
          {
            x: 164,
            y: 158.84,
          },
          {
            x: 165,
            y: 159.1,
          },
          {
            x: 166,
            y: 159.34,
          },
          {
            x: 167,
            y: 159.57,
          },
          {
            x: 168,
            y: 159.79,
          },
          {
            x: 169,
            y: 160,
          },
          {
            x: 170,
            y: 160.2,
          },
          {
            x: 171,
            y: 160.39,
          },
          {
            x: 172,
            y: 160.56,
          },
          {
            x: 173,
            y: 160.73,
          },
          {
            x: 174,
            y: 160.89,
          },
          {
            x: 175,
            y: 161.04,
          },
          {
            x: 176,
            y: 161.18,
          },
          {
            x: 177,
            y: 161.32,
          },
          {
            x: 178,
            y: 161.44,
          },
          {
            x: 179,
            y: 161.56,
          },
          {
            x: 180,
            y: 161.67,
          },
          {
            x: 181,
            y: 161.77,
          },
          {
            x: 182,
            y: 161.87,
          },
          {
            x: 183,
            y: 161.96,
          },
          {
            x: 184,
            y: 162.04,
          },
          {
            x: 185,
            y: 162.12,
          },
          {
            x: 186,
            y: 162.19,
          },
          {
            x: 187,
            y: 162.25,
          },
          {
            x: 188,
            y: 162.31,
          },
          {
            x: 189,
            y: 162.37,
          },
          {
            x: 190,
            y: 162.42,
          },
          {
            x: 191,
            y: 162.47,
          },
          {
            x: 192,
            y: 162.52,
          },
          {
            x: 193,
            y: 162.56,
          },
          {
            x: 194,
            y: 162.59,
          },
          {
            x: 195,
            y: 162.63,
          },
          {
            x: 196,
            y: 162.66,
          },
          {
            x: 197,
            y: 162.69,
          },
          {
            x: 198,
            y: 162.72,
          },
          {
            x: 199,
            y: 162.74,
          },
          {
            x: 200,
            y: 162.77,
          },
          {
            x: 201,
            y: 162.79,
          },
          {
            x: 202,
            y: 162.81,
          },
          {
            x: 203,
            y: 162.83,
          },
          {
            x: 204,
            y: 162.85,
          },
          {
            x: 205,
            y: 162.87,
          },
          {
            x: 206,
            y: 162.89,
          },
          {
            x: 207,
            y: 162.91,
          },
          {
            x: 208,
            y: 162.93,
          },
          {
            x: 209,
            y: 162.95,
          },
          {
            x: 210,
            y: 162.97,
          },
          {
            x: 211,
            y: 162.98,
          },
          {
            x: 212,
            y: 163,
          },
          {
            x: 213,
            y: 163.01,
          },
          {
            x: 214,
            y: 163.03,
          },
          {
            x: 215,
            y: 163.04,
          },
          {
            x: 216,
            y: 163.06,
          },
          {
            x: 217,
            y: 163.07,
          },
          {
            x: 218,
            y: 163.09,
          },
          {
            x: 219,
            y: 163.1,
          },
          {
            x: 220,
            y: 163.11,
          },
          {
            x: 221,
            y: 163.12,
          },
          {
            x: 222,
            y: 163.13,
          },
          {
            x: 223,
            y: 163.14,
          },
          {
            x: 224,
            y: 163.14,
          },
          {
            x: 225,
            y: 163.15,
          },
          {
            x: 226,
            y: 163.15,
          },
          {
            x: 227,
            y: 163.15,
          },
          {
            x: 228,
            y: 163.16,
          },
        ],
      },
      // -1
      {
        color: "#ED9300",
        name: "-1",
        data: [
          {
            x: 0,
            y: 47.3,
          },
          {
            x: 1,
            y: 51.7,
          },
          {
            x: 2,
            y: 55,
          },
          {
            x: 3,
            y: 57.7,
          },
          {
            x: 4,
            y: 59.9,
          },
          {
            x: 5,
            y: 61.8,
          },
          {
            x: 6,
            y: 63.5,
          },
          {
            x: 7,
            y: 65,
          },
          {
            x: 8,
            y: 66.4,
          },
          {
            x: 9,
            y: 67.7,
          },
          {
            x: 10,
            y: 69,
          },
          {
            x: 11,
            y: 70.3,
          },
          {
            x: 12,
            y: 71.4,
          },
          {
            x: 13,
            y: 72.6,
          },
          {
            x: 14,
            y: 73.7,
          },
          {
            x: 15,
            y: 74.8,
          },
          {
            x: 16,
            y: 75.8,
          },
          {
            x: 17,
            y: 76.8,
          },
          {
            x: 18,
            y: 77.8,
          },
          {
            x: 19,
            y: 78.8,
          },
          {
            x: 20,
            y: 79.7,
          },
          {
            x: 21,
            y: 80.6,
          },
          {
            x: 22,
            y: 81.5,
          },
          {
            x: 23,
            y: 82.3,
          },
          {
            x: 24,
            y: 82.5,
          },
          {
            x: 25,
            y: 83.3,
          },
          {
            x: 26,
            y: 84.1,
          },
          {
            x: 27,
            y: 84.9,
          },
          {
            x: 28,
            y: 85.7,
          },
          {
            x: 29,
            y: 86.4,
          },
          {
            x: 30,
            y: 87.1,
          },
          {
            x: 31,
            y: 87.9,
          },
          {
            x: 32,
            y: 88.6,
          },
          {
            x: 33,
            y: 89.3,
          },
          {
            x: 34,
            y: 89.9,
          },
          {
            x: 35,
            y: 90.6,
          },
          {
            x: 36,
            y: 91.2,
          },
          {
            x: 37,
            y: 91.9,
          },
          {
            x: 38,
            y: 92.5,
          },
          {
            x: 39,
            y: 93.1,
          },
          {
            x: 40,
            y: 93.8,
          },
          {
            x: 41,
            y: 94.4,
          },
          {
            x: 42,
            y: 95,
          },
          {
            x: 43,
            y: 95.6,
          },
          {
            x: 44,
            y: 96.2,
          },
          {
            x: 45,
            y: 96.7,
          },
          {
            x: 46,
            y: 97.3,
          },
          {
            x: 47,
            y: 97.9,
          },
          {
            x: 48,
            y: 98.4,
          },
          {
            x: 49,
            y: 99,
          },
          {
            x: 50,
            y: 99.5,
          },
          {
            x: 51,
            y: 100.1,
          },
          {
            x: 52,
            y: 100.6,
          },
          {
            x: 53,
            y: 101.1,
          },
          {
            x: 54,
            y: 101.6,
          },
          {
            x: 55,
            y: 102.2,
          },
          {
            x: 56,
            y: 102.7,
          },
          {
            x: 57,
            y: 103.2,
          },
          {
            x: 58,
            y: 103.7,
          },
          {
            x: 59,
            y: 104.2,
          },
          {
            x: 60,
            y: 104.7,
          },
          {
            x: 61,
            y: 104.83,
          },
          {
            x: 62,
            y: 105.32,
          },
          {
            x: 63,
            y: 105.81,
          },
          {
            x: 64,
            y: 106.29,
          },
          {
            x: 65,
            y: 106.77,
          },
          {
            x: 66,
            y: 107.24,
          },
          {
            x: 67,
            y: 107.71,
          },
          {
            x: 68,
            y: 108.18,
          },
          {
            x: 69,
            y: 108.64,
          },
          {
            x: 70,
            y: 109.1,
          },
          {
            x: 71,
            y: 109.55,
          },
          {
            x: 72,
            y: 110,
          },
          {
            x: 73,
            y: 110.45,
          },
          {
            x: 74,
            y: 110.9,
          },
          {
            x: 75,
            y: 111.35,
          },
          {
            x: 76,
            y: 111.79,
          },
          {
            x: 77,
            y: 112.24,
          },
          {
            x: 78,
            y: 112.68,
          },
          {
            x: 79,
            y: 113.13,
          },
          {
            x: 80,
            y: 113.57,
          },
          {
            x: 81,
            y: 114.01,
          },
          {
            x: 82,
            y: 114.45,
          },
          {
            x: 83,
            y: 114.9,
          },
          {
            x: 84,
            y: 115.34,
          },
          {
            x: 85,
            y: 115.79,
          },
          {
            x: 86,
            y: 116.24,
          },
          {
            x: 87,
            y: 116.68,
          },
          {
            x: 88,
            y: 117.13,
          },
          {
            x: 89,
            y: 117.58,
          },
          {
            x: 90,
            y: 118.03,
          },
          {
            x: 91,
            y: 118.48,
          },
          {
            x: 92,
            y: 118.93,
          },
          {
            x: 93,
            y: 119.39,
          },
          {
            x: 94,
            y: 119.84,
          },
          {
            x: 95,
            y: 120.3,
          },
          {
            x: 96,
            y: 120.76,
          },
          {
            x: 97,
            y: 121.22,
          },
          {
            x: 98,
            y: 121.68,
          },
          {
            x: 99,
            y: 122.14,
          },
          {
            x: 100,
            y: 122.61,
          },
          {
            x: 101,
            y: 123.07,
          },
          {
            x: 102,
            y: 123.54,
          },
          {
            x: 103,
            y: 124.01,
          },
          {
            x: 104,
            y: 124.48,
          },
          {
            x: 105,
            y: 124.95,
          },
          {
            x: 106,
            y: 125.43,
          },
          {
            x: 107,
            y: 125.91,
          },
          {
            x: 108,
            y: 126.38,
          },
          {
            x: 109,
            y: 126.86,
          },
          {
            x: 110,
            y: 127.34,
          },
          {
            x: 111,
            y: 127.83,
          },
          {
            x: 112,
            y: 128.31,
          },
          {
            x: 113,
            y: 128.8,
          },
          {
            x: 114,
            y: 129.28,
          },
          {
            x: 115,
            y: 129.77,
          },
          {
            x: 116,
            y: 130.26,
          },
          {
            x: 117,
            y: 130.75,
          },
          {
            x: 118,
            y: 131.25,
          },
          {
            x: 119,
            y: 131.74,
          },
          {
            x: 120,
            y: 132.24,
          },
          {
            x: 121,
            y: 132.74,
          },
          {
            x: 122,
            y: 133.24,
          },
          {
            x: 123,
            y: 133.74,
          },
          {
            x: 124,
            y: 134.25,
          },
          {
            x: 125,
            y: 134.75,
          },
          {
            x: 126,
            y: 135.26,
          },
          {
            x: 127,
            y: 135.77,
          },
          {
            x: 128,
            y: 136.29,
          },
          {
            x: 129,
            y: 136.8,
          },
          {
            x: 130,
            y: 137.31,
          },
          {
            x: 131,
            y: 137.83,
          },
          {
            x: 132,
            y: 138.35,
          },
          {
            x: 133,
            y: 138.86,
          },
          {
            x: 134,
            y: 139.38,
          },
          {
            x: 135,
            y: 139.89,
          },
          {
            x: 136,
            y: 140.41,
          },
          {
            x: 137,
            y: 140.92,
          },
          {
            x: 138,
            y: 141.43,
          },
          {
            x: 139,
            y: 141.93,
          },
          {
            x: 140,
            y: 142.44,
          },
          {
            x: 141,
            y: 142.93,
          },
          {
            x: 142,
            y: 143.43,
          },
          {
            x: 143,
            y: 143.91,
          },
          {
            x: 144,
            y: 144.39,
          },
          {
            x: 145,
            y: 144.87,
          },
          {
            x: 146,
            y: 145.33,
          },
          {
            x: 147,
            y: 145.79,
          },
          {
            x: 148,
            y: 146.24,
          },
          {
            x: 149,
            y: 146.67,
          },
          {
            x: 150,
            y: 147.1,
          },
          {
            x: 151,
            y: 147.52,
          },
          {
            x: 152,
            y: 147.92,
          },
          {
            x: 153,
            y: 148.32,
          },
          {
            x: 154,
            y: 148.7,
          },
          {
            x: 155,
            y: 149.07,
          },
          {
            x: 156,
            y: 149.43,
          },
          {
            x: 157,
            y: 149.78,
          },
          {
            x: 158,
            y: 150.12,
          },
          {
            x: 159,
            y: 150.44,
          },
          {
            x: 160,
            y: 150.76,
          },
          {
            x: 161,
            y: 151.06,
          },
          {
            x: 162,
            y: 151.35,
          },
          {
            x: 163,
            y: 151.63,
          },
          {
            x: 164,
            y: 151.89,
          },
          {
            x: 165,
            y: 152.15,
          },
          {
            x: 166,
            y: 152.39,
          },
          {
            x: 167,
            y: 152.62,
          },
          {
            x: 168,
            y: 152.85,
          },
          {
            x: 169,
            y: 153.06,
          },
          {
            x: 170,
            y: 153.26,
          },
          {
            x: 171,
            y: 153.45,
          },
          {
            x: 172,
            y: 153.64,
          },
          {
            x: 173,
            y: 153.81,
          },
          {
            x: 174,
            y: 153.98,
          },
          {
            x: 175,
            y: 154.13,
          },
          {
            x: 176,
            y: 154.28,
          },
          {
            x: 177,
            y: 154.42,
          },
          {
            x: 178,
            y: 154.55,
          },
          {
            x: 179,
            y: 154.67,
          },
          {
            x: 180,
            y: 154.79,
          },
          {
            x: 181,
            y: 154.9,
          },
          {
            x: 182,
            y: 155,
          },
          {
            x: 183,
            y: 155.1,
          },
          {
            x: 184,
            y: 155.19,
          },
          {
            x: 185,
            y: 155.27,
          },
          {
            x: 186,
            y: 155.35,
          },
          {
            x: 187,
            y: 155.43,
          },
          {
            x: 188,
            y: 155.5,
          },
          {
            x: 189,
            y: 155.56,
          },
          {
            x: 190,
            y: 155.62,
          },
          {
            x: 191,
            y: 155.68,
          },
          {
            x: 192,
            y: 155.73,
          },
          {
            x: 193,
            y: 155.78,
          },
          {
            x: 194,
            y: 155.82,
          },
          {
            x: 195,
            y: 155.87,
          },
          {
            x: 196,
            y: 155.91,
          },
          {
            x: 197,
            y: 155.94,
          },
          {
            x: 198,
            y: 155.98,
          },
          {
            x: 199,
            y: 156.01,
          },
          {
            x: 200,
            y: 156.04,
          },
          {
            x: 201,
            y: 156.07,
          },
          {
            x: 202,
            y: 156.11,
          },
          {
            x: 203,
            y: 156.13,
          },
          {
            x: 204,
            y: 156.16,
          },
          {
            x: 205,
            y: 156.19,
          },
          {
            x: 206,
            y: 156.22,
          },
          {
            x: 207,
            y: 156.24,
          },
          {
            x: 208,
            y: 156.27,
          },
          {
            x: 209,
            y: 156.29,
          },
          {
            x: 210,
            y: 156.32,
          },
          {
            x: 211,
            y: 156.34,
          },
          {
            x: 212,
            y: 156.36,
          },
          {
            x: 213,
            y: 156.39,
          },
          {
            x: 214,
            y: 156.41,
          },
          {
            x: 215,
            y: 156.43,
          },
          {
            x: 216,
            y: 156.45,
          },
          {
            x: 217,
            y: 156.47,
          },
          {
            x: 218,
            y: 156.49,
          },
          {
            x: 219,
            y: 156.51,
          },
          {
            x: 220,
            y: 156.52,
          },
          {
            x: 221,
            y: 156.54,
          },
          {
            x: 222,
            y: 156.55,
          },
          {
            x: 223,
            y: 156.57,
          },
          {
            x: 224,
            y: 156.58,
          },
          {
            x: 225,
            y: 156.59,
          },
          {
            x: 226,
            y: 156.6,
          },
          {
            x: 227,
            y: 156.61,
          },
          {
            x: 228,
            y: 156.61,
          },
        ],
      },
      // -2
      {
        color: "#F30037",
        name: "-2",
        data: [
          {
            x: 0,
            y: 45.4,
          },
          {
            x: 1,
            y: 49.8,
          },
          {
            x: 2,
            y: 53,
          },
          {
            x: 3,
            y: 55.6,
          },
          {
            x: 4,
            y: 57.8,
          },
          {
            x: 5,
            y: 59.6,
          },
          {
            x: 6,
            y: 61.2,
          },
          {
            x: 7,
            y: 62.7,
          },
          {
            x: 8,
            y: 64,
          },
          {
            x: 9,
            y: 65.3,
          },
          {
            x: 10,
            y: 66.5,
          },
          {
            x: 11,
            y: 67.7,
          },
          {
            x: 12,
            y: 68.9,
          },
          {
            x: 13,
            y: 70,
          },
          {
            x: 14,
            y: 71,
          },
          {
            x: 15,
            y: 72,
          },
          {
            x: 16,
            y: 73,
          },
          {
            x: 17,
            y: 74,
          },
          {
            x: 18,
            y: 74.9,
          },
          {
            x: 19,
            y: 75.8,
          },
          {
            x: 20,
            y: 76.7,
          },
          {
            x: 21,
            y: 77.5,
          },
          {
            x: 22,
            y: 78.4,
          },
          {
            x: 23,
            y: 79.2,
          },
          {
            x: 24,
            y: 79.3,
          },
          {
            x: 25,
            y: 80,
          },
          {
            x: 26,
            y: 80.8,
          },
          {
            x: 27,
            y: 81.5,
          },
          {
            x: 28,
            y: 82.2,
          },
          {
            x: 29,
            y: 82.9,
          },
          {
            x: 30,
            y: 83.6,
          },
          {
            x: 31,
            y: 84.3,
          },
          {
            x: 32,
            y: 84.9,
          },
          {
            x: 33,
            y: 85.6,
          },
          {
            x: 34,
            y: 86.2,
          },
          {
            x: 35,
            y: 86.8,
          },
          {
            x: 36,
            y: 87.4,
          },
          {
            x: 37,
            y: 88,
          },
          {
            x: 38,
            y: 88.6,
          },
          {
            x: 39,
            y: 89.2,
          },
          {
            x: 40,
            y: 89.8,
          },
          {
            x: 41,
            y: 90.4,
          },
          {
            x: 42,
            y: 90.9,
          },
          {
            x: 43,
            y: 91.5,
          },
          {
            x: 44,
            y: 92,
          },
          {
            x: 45,
            y: 92.5,
          },
          {
            x: 46,
            y: 93.1,
          },
          {
            x: 47,
            y: 93.6,
          },
          {
            x: 48,
            y: 94.1,
          },
          {
            x: 49,
            y: 94.6,
          },
          {
            x: 50,
            y: 95.1,
          },
          {
            x: 51,
            y: 95.6,
          },
          {
            x: 52,
            y: 96.1,
          },
          {
            x: 53,
            y: 96.6,
          },
          {
            x: 54,
            y: 97.1,
          },
          {
            x: 55,
            y: 97.6,
          },
          {
            x: 56,
            y: 98.1,
          },
          {
            x: 57,
            y: 98.5,
          },
          {
            x: 58,
            y: 99,
          },
          {
            x: 59,
            y: 99.5,
          },
          {
            x: 60,
            y: 99.9,
          },
          {
            x: 61,
            y: 100.06,
          },
          {
            x: 62,
            y: 100.51,
          },
          {
            x: 63,
            y: 100.97,
          },
          {
            x: 64,
            y: 101.42,
          },
          {
            x: 65,
            y: 101.86,
          },
          {
            x: 66,
            y: 102.31,
          },
          {
            x: 67,
            y: 102.75,
          },
          {
            x: 68,
            y: 103.18,
          },
          {
            x: 69,
            y: 103.61,
          },
          {
            x: 70,
            y: 104.04,
          },
          {
            x: 71,
            y: 104.46,
          },
          {
            x: 72,
            y: 104.89,
          },
          {
            x: 73,
            y: 105.31,
          },
          {
            x: 74,
            y: 105.72,
          },
          {
            x: 75,
            y: 106.14,
          },
          {
            x: 76,
            y: 106.56,
          },
          {
            x: 77,
            y: 106.97,
          },
          {
            x: 78,
            y: 107.39,
          },
          {
            x: 79,
            y: 107.8,
          },
          {
            x: 80,
            y: 108.21,
          },
          {
            x: 81,
            y: 108.63,
          },
          {
            x: 82,
            y: 109.04,
          },
          {
            x: 83,
            y: 109.46,
          },
          {
            x: 84,
            y: 109.88,
          },
          {
            x: 85,
            y: 110.29,
          },
          {
            x: 86,
            y: 110.71,
          },
          {
            x: 87,
            y: 111.13,
          },
          {
            x: 88,
            y: 111.55,
          },
          {
            x: 89,
            y: 111.97,
          },
          {
            x: 90,
            y: 112.4,
          },
          {
            x: 91,
            y: 112.82,
          },
          {
            x: 92,
            y: 113.25,
          },
          {
            x: 93,
            y: 113.67,
          },
          {
            x: 94,
            y: 114.1,
          },
          {
            x: 95,
            y: 114.53,
          },
          {
            x: 96,
            y: 114.96,
          },
          {
            x: 97,
            y: 115.39,
          },
          {
            x: 98,
            y: 115.83,
          },
          {
            x: 99,
            y: 116.27,
          },
          {
            x: 100,
            y: 116.7,
          },
          {
            x: 101,
            y: 117.14,
          },
          {
            x: 102,
            y: 117.58,
          },
          {
            x: 103,
            y: 118.03,
          },
          {
            x: 104,
            y: 118.47,
          },
          {
            x: 105,
            y: 118.92,
          },
          {
            x: 106,
            y: 119.37,
          },
          {
            x: 107,
            y: 119.82,
          },
          {
            x: 108,
            y: 120.27,
          },
          {
            x: 109,
            y: 120.73,
          },
          {
            x: 110,
            y: 121.19,
          },
          {
            x: 111,
            y: 121.64,
          },
          {
            x: 112,
            y: 122.1,
          },
          {
            x: 113,
            y: 122.56,
          },
          {
            x: 114,
            y: 123.03,
          },
          {
            x: 115,
            y: 123.49,
          },
          {
            x: 116,
            y: 123.96,
          },
          {
            x: 117,
            y: 124.43,
          },
          {
            x: 118,
            y: 124.9,
          },
          {
            x: 119,
            y: 125.37,
          },
          {
            x: 120,
            y: 125.84,
          },
          {
            x: 121,
            y: 126.32,
          },
          {
            x: 122,
            y: 126.8,
          },
          {
            x: 123,
            y: 127.28,
          },
          {
            x: 124,
            y: 127.76,
          },
          {
            x: 125,
            y: 128.25,
          },
          {
            x: 126,
            y: 128.74,
          },
          {
            x: 127,
            y: 129.23,
          },
          {
            x: 128,
            y: 129.72,
          },
          {
            x: 129,
            y: 130.21,
          },
          {
            x: 130,
            y: 130.71,
          },
          {
            x: 131,
            y: 131.2,
          },
          {
            x: 132,
            y: 131.7,
          },
          {
            x: 133,
            y: 132.2,
          },
          {
            x: 134,
            y: 132.69,
          },
          {
            x: 135,
            y: 133.19,
          },
          {
            x: 136,
            y: 133.69,
          },
          {
            x: 137,
            y: 134.18,
          },
          {
            x: 138,
            y: 134.68,
          },
          {
            x: 139,
            y: 135.16,
          },
          {
            x: 140,
            y: 135.65,
          },
          {
            x: 141,
            y: 136.13,
          },
          {
            x: 142,
            y: 136.61,
          },
          {
            x: 143,
            y: 137.09,
          },
          {
            x: 144,
            y: 137.55,
          },
          {
            x: 145,
            y: 138.01,
          },
          {
            x: 146,
            y: 138.47,
          },
          {
            x: 147,
            y: 138.91,
          },
          {
            x: 148,
            y: 139.35,
          },
          {
            x: 149,
            y: 139.78,
          },
          {
            x: 150,
            y: 140.2,
          },
          {
            x: 151,
            y: 140.6,
          },
          {
            x: 152,
            y: 141.01,
          },
          {
            x: 153,
            y: 141.39,
          },
          {
            x: 154,
            y: 141.77,
          },
          {
            x: 155,
            y: 142.14,
          },
          {
            x: 156,
            y: 142.49,
          },
          {
            x: 157,
            y: 142.84,
          },
          {
            x: 158,
            y: 143.17,
          },
          {
            x: 159,
            y: 143.5,
          },
          {
            x: 160,
            y: 143.81,
          },
          {
            x: 161,
            y: 144.1,
          },
          {
            x: 162,
            y: 144.4,
          },
          {
            x: 163,
            y: 144.67,
          },
          {
            x: 164,
            y: 144.94,
          },
          {
            x: 165,
            y: 145.19,
          },
          {
            x: 166,
            y: 145.44,
          },
          {
            x: 167,
            y: 145.68,
          },
          {
            x: 168,
            y: 145.9,
          },
          {
            x: 169,
            y: 146.12,
          },
          {
            x: 170,
            y: 146.32,
          },
          {
            x: 171,
            y: 146.52,
          },
          {
            x: 172,
            y: 146.71,
          },
          {
            x: 173,
            y: 146.89,
          },
          {
            x: 174,
            y: 147.06,
          },
          {
            x: 175,
            y: 147.22,
          },
          {
            x: 176,
            y: 147.37,
          },
          {
            x: 177,
            y: 147.52,
          },
          {
            x: 178,
            y: 147.66,
          },
          {
            x: 179,
            y: 147.78,
          },
          {
            x: 180,
            y: 147.91,
          },
          {
            x: 181,
            y: 148.03,
          },
          {
            x: 182,
            y: 148.14,
          },
          {
            x: 183,
            y: 148.24,
          },
          {
            x: 184,
            y: 148.34,
          },
          {
            x: 185,
            y: 148.43,
          },
          {
            x: 186,
            y: 148.52,
          },
          {
            x: 187,
            y: 148.6,
          },
          {
            x: 188,
            y: 148.68,
          },
          {
            x: 189,
            y: 148.75,
          },
          {
            x: 190,
            y: 148.82,
          },
          {
            x: 191,
            y: 148.88,
          },
          {
            x: 192,
            y: 148.94,
          },
          {
            x: 193,
            y: 149,
          },
          {
            x: 194,
            y: 149.05,
          },
          {
            x: 195,
            y: 149.1,
          },
          {
            x: 196,
            y: 149.15,
          },
          {
            x: 197,
            y: 149.2,
          },
          {
            x: 198,
            y: 149.24,
          },
          {
            x: 199,
            y: 149.28,
          },
          {
            x: 200,
            y: 149.32,
          },
          {
            x: 201,
            y: 149.36,
          },
          {
            x: 202,
            y: 149.4,
          },
          {
            x: 203,
            y: 149.44,
          },
          {
            x: 204,
            y: 149.47,
          },
          {
            x: 205,
            y: 149.51,
          },
          {
            x: 206,
            y: 149.54,
          },
          {
            x: 207,
            y: 149.57,
          },
          {
            x: 208,
            y: 149.61,
          },
          {
            x: 209,
            y: 149.64,
          },
          {
            x: 210,
            y: 149.67,
          },
          {
            x: 211,
            y: 149.7,
          },
          {
            x: 212,
            y: 149.73,
          },
          {
            x: 213,
            y: 149.76,
          },
          {
            x: 214,
            y: 149.78,
          },
          {
            x: 215,
            y: 149.81,
          },
          {
            x: 216,
            y: 149.84,
          },
          {
            x: 217,
            y: 149.87,
          },
          {
            x: 218,
            y: 149.89,
          },
          {
            x: 219,
            y: 149.92,
          },
          {
            x: 220,
            y: 149.94,
          },
          {
            x: 221,
            y: 149.96,
          },
          {
            x: 222,
            y: 149.98,
          },
          {
            x: 223,
            y: 150,
          },
          {
            x: 224,
            y: 150.01,
          },
          {
            x: 225,
            y: 150.03,
          },
          {
            x: 226,
            y: 150.05,
          },
          {
            x: 227,
            y: 150.06,
          },
          {
            x: 228,
            y: 150.07,
          },
        ],
      },
    ],
    sizeForAgeBoy: [
      // 2
      {
        color: "#000000",
        name: "2",
        data: [
          {
            x: 0,
            y: 53.7,
          },
          {
            x: 1,
            y: 58.6,
          },
          {
            x: 2,
            y: 62.4,
          },
          {
            x: 3,
            y: 65.5,
          },
          {
            x: 4,
            y: 68,
          },
          {
            x: 5,
            y: 70.1,
          },
          {
            x: 6,
            y: 71.9,
          },
          {
            x: 7,
            y: 73.5,
          },
          {
            x: 8,
            y: 75,
          },
          {
            x: 9,
            y: 76.5,
          },
          {
            x: 10,
            y: 77.9,
          },
          {
            x: 11,
            y: 79.2,
          },
          {
            x: 12,
            y: 80.5,
          },
          {
            x: 13,
            y: 81.8,
          },
          {
            x: 14,
            y: 83,
          },
          {
            x: 15,
            y: 84.2,
          },
          {
            x: 16,
            y: 85.4,
          },
          {
            x: 17,
            y: 86.5,
          },
          {
            x: 18,
            y: 87.7,
          },
          {
            x: 19,
            y: 88.8,
          },
          {
            x: 20,
            y: 89.8,
          },
          {
            x: 21,
            y: 90.9,
          },
          {
            x: 22,
            y: 91.9,
          },
          {
            x: 23,
            y: 92.9,
          },
          {
            x: 24,
            y: 93.2,
          },
          {
            x: 25,
            y: 94.2,
          },
          {
            x: 26,
            y: 95.2,
          },
          {
            x: 27,
            y: 96.1,
          },
          {
            x: 28,
            y: 97,
          },
          {
            x: 29,
            y: 97.9,
          },
          {
            x: 30,
            y: 98.7,
          },
          {
            x: 31,
            y: 99.6,
          },
          {
            x: 32,
            y: 100.4,
          },
          {
            x: 33,
            y: 101.2,
          },
          {
            x: 34,
            y: 102,
          },
          {
            x: 35,
            y: 102.7,
          },
          {
            x: 36,
            y: 103.5,
          },
          {
            x: 37,
            y: 104.2,
          },
          {
            x: 38,
            y: 105,
          },
          {
            x: 39,
            y: 105.7,
          },
          {
            x: 40,
            y: 106.4,
          },
          {
            x: 41,
            y: 107.1,
          },
          {
            x: 42,
            y: 107.8,
          },
          {
            x: 43,
            y: 108.5,
          },
          {
            x: 44,
            y: 109.1,
          },
          {
            x: 45,
            y: 109.8,
          },
          {
            x: 46,
            y: 110.4,
          },
          {
            x: 47,
            y: 111.1,
          },
          {
            x: 48,
            y: 111.7,
          },
          {
            x: 49,
            y: 112.4,
          },
          {
            x: 50,
            y: 113,
          },
          {
            x: 51,
            y: 113.6,
          },
          {
            x: 52,
            y: 114.2,
          },
          {
            x: 53,
            y: 114.9,
          },
          {
            x: 54,
            y: 115.5,
          },
          {
            x: 55,
            y: 116.1,
          },
          {
            x: 56,
            y: 116.7,
          },
          {
            x: 57,
            y: 117.4,
          },
          {
            x: 58,
            y: 118,
          },
          {
            x: 59,
            y: 118.6,
          },
          {
            x: 60,
            y: 119.2,
          },
          {
            x: 61,
            y: 119.45,
          },
          {
            x: 62,
            y: 120.05,
          },
          {
            x: 63,
            y: 120.64,
          },
          {
            x: 64,
            y: 121.23,
          },
          {
            x: 65,
            y: 121.82,
          },
          {
            x: 66,
            y: 122.4,
          },
          {
            x: 67,
            y: 122.98,
          },
          {
            x: 68,
            y: 123.55,
          },
          {
            x: 69,
            y: 124.12,
          },
          {
            x: 70,
            y: 124.69,
          },
          {
            x: 71,
            y: 125.25,
          },
          {
            x: 72,
            y: 125.8,
          },
          {
            x: 73,
            y: 126.36,
          },
          {
            x: 74,
            y: 126.91,
          },
          {
            x: 75,
            y: 127.45,
          },
          {
            x: 76,
            y: 128,
          },
          {
            x: 77,
            y: 128.54,
          },
          {
            x: 78,
            y: 129.08,
          },
          {
            x: 79,
            y: 129.62,
          },
          {
            x: 80,
            y: 130.16,
          },
          {
            x: 81,
            y: 130.7,
          },
          {
            x: 82,
            y: 131.24,
          },
          {
            x: 83,
            y: 131.77,
          },
          {
            x: 84,
            y: 132.31,
          },
          {
            x: 85,
            y: 132.84,
          },
          {
            x: 86,
            y: 133.37,
          },
          {
            x: 87,
            y: 133.9,
          },
          {
            x: 88,
            y: 134.42,
          },
          {
            x: 89,
            y: 134.95,
          },
          {
            x: 90,
            y: 135.47,
          },
          {
            x: 91,
            y: 135.99,
          },
          {
            x: 92,
            y: 136.51,
          },
          {
            x: 93,
            y: 137.03,
          },
          {
            x: 94,
            y: 137.54,
          },
          {
            x: 95,
            y: 138.05,
          },
          {
            x: 96,
            y: 138.56,
          },
          {
            x: 97,
            y: 139.07,
          },
          {
            x: 98,
            y: 139.57,
          },
          {
            x: 99,
            y: 140.08,
          },
          {
            x: 100,
            y: 140.58,
          },
          {
            x: 101,
            y: 141.09,
          },
          {
            x: 102,
            y: 141.59,
          },
          {
            x: 103,
            y: 142.09,
          },
          {
            x: 104,
            y: 142.59,
          },
          {
            x: 105,
            y: 143.09,
          },
          {
            x: 106,
            y: 143.59,
          },
          {
            x: 107,
            y: 144.09,
          },
          {
            x: 108,
            y: 144.59,
          },
          {
            x: 109,
            y: 145.09,
          },
          {
            x: 110,
            y: 145.59,
          },
          {
            x: 111,
            y: 146.08,
          },
          {
            x: 112,
            y: 146.58,
          },
          {
            x: 113,
            y: 147.07,
          },
          {
            x: 114,
            y: 147.57,
          },
          {
            x: 115,
            y: 148.06,
          },
          {
            x: 116,
            y: 148.56,
          },
          {
            x: 117,
            y: 149.05,
          },
          {
            x: 118,
            y: 149.54,
          },
          {
            x: 119,
            y: 150.04,
          },
          {
            x: 120,
            y: 150.53,
          },
          {
            x: 121,
            y: 151.02,
          },
          {
            x: 122,
            y: 151.51,
          },
          {
            x: 123,
            y: 152.01,
          },
          {
            x: 124,
            y: 152.5,
          },
          {
            x: 125,
            y: 153,
          },
          {
            x: 126,
            y: 153.5,
          },
          {
            x: 127,
            y: 154,
          },
          {
            x: 128,
            y: 154.51,
          },
          {
            x: 129,
            y: 155.02,
          },
          {
            x: 130,
            y: 155.53,
          },
          {
            x: 131,
            y: 156.05,
          },
          {
            x: 132,
            y: 156.57,
          },
          {
            x: 133,
            y: 157.1,
          },
          {
            x: 134,
            y: 157.63,
          },
          {
            x: 135,
            y: 158.17,
          },
          {
            x: 136,
            y: 158.71,
          },
          {
            x: 137,
            y: 159.25,
          },
          {
            x: 138,
            y: 159.81,
          },
          {
            x: 139,
            y: 160.36,
          },
          {
            x: 140,
            y: 160.93,
          },
          {
            x: 141,
            y: 161.5,
          },
          {
            x: 142,
            y: 162.08,
          },
          {
            x: 143,
            y: 162.66,
          },
          {
            x: 144,
            y: 163.25,
          },
          {
            x: 145,
            y: 163.85,
          },
          {
            x: 146,
            y: 164.46,
          },
          {
            x: 147,
            y: 165.07,
          },
          {
            x: 148,
            y: 165.7,
          },
          {
            x: 149,
            y: 166.33,
          },
          {
            x: 150,
            y: 166.96,
          },
          {
            x: 151,
            y: 167.61,
          },
          {
            x: 152,
            y: 168.26,
          },
          {
            x: 153,
            y: 168.91,
          },
          {
            x: 154,
            y: 169.57,
          },
          {
            x: 155,
            y: 170.24,
          },
          {
            x: 156,
            y: 170.9,
          },
          {
            x: 157,
            y: 171.56,
          },
          {
            x: 158,
            y: 172.22,
          },
          {
            x: 159,
            y: 172.89,
          },
          {
            x: 160,
            y: 173.55,
          },
          {
            x: 161,
            y: 174.2,
          },
          {
            x: 162,
            y: 174.85,
          },
          {
            x: 163,
            y: 175.49,
          },
          {
            x: 164,
            y: 176.12,
          },
          {
            x: 165,
            y: 176.75,
          },
          {
            x: 166,
            y: 177.36,
          },
          {
            x: 167,
            y: 177.97,
          },
          {
            x: 168,
            y: 178.57,
          },
          {
            x: 169,
            y: 179.15,
          },
          {
            x: 170,
            y: 179.72,
          },
          {
            x: 171,
            y: 180.27,
          },
          {
            x: 172,
            y: 180.81,
          },
          {
            x: 173,
            y: 181.33,
          },
          {
            x: 174,
            y: 181.84,
          },
          {
            x: 175,
            y: 182.33,
          },
          {
            x: 176,
            y: 182.81,
          },
          {
            x: 177,
            y: 183.27,
          },
          {
            x: 178,
            y: 183.72,
          },
          {
            x: 179,
            y: 184.15,
          },
          {
            x: 180,
            y: 184.57,
          },
          {
            x: 181,
            y: 184.97,
          },
          {
            x: 182,
            y: 185.35,
          },
          {
            x: 183,
            y: 185.72,
          },
          {
            x: 184,
            y: 186.08,
          },
          {
            x: 185,
            y: 186.42,
          },
          {
            x: 186,
            y: 186.75,
          },
          {
            x: 187,
            y: 187.06,
          },
          {
            x: 188,
            y: 187.37,
          },
          {
            x: 189,
            y: 187.65,
          },
          {
            x: 190,
            y: 187.93,
          },
          {
            x: 191,
            y: 188.19,
          },
          {
            x: 192,
            y: 188.44,
          },
          {
            x: 193,
            y: 188.68,
          },
          {
            x: 194,
            y: 188.9,
          },
          {
            x: 195,
            y: 189.11,
          },
          {
            x: 196,
            y: 189.3,
          },
          {
            x: 197,
            y: 189.49,
          },
          {
            x: 198,
            y: 189.66,
          },
          {
            x: 199,
            y: 189.82,
          },
          {
            x: 200,
            y: 189.97,
          },
          {
            x: 201,
            y: 190.1,
          },
          {
            x: 202,
            y: 190.23,
          },
          {
            x: 203,
            y: 190.34,
          },
          {
            x: 204,
            y: 190.45,
          },
          {
            x: 205,
            y: 190.54,
          },
          {
            x: 206,
            y: 190.63,
          },
          {
            x: 207,
            y: 190.71,
          },
          {
            x: 208,
            y: 190.77,
          },
          {
            x: 209,
            y: 190.83,
          },
          {
            x: 210,
            y: 190.89,
          },
          {
            x: 211,
            y: 190.93,
          },
          {
            x: 212,
            y: 190.97,
          },
          {
            x: 213,
            y: 191.01,
          },
          {
            x: 214,
            y: 191.04,
          },
          {
            x: 215,
            y: 191.06,
          },
          {
            x: 216,
            y: 191.09,
          },
          {
            x: 217,
            y: 191.1,
          },
          {
            x: 218,
            y: 191.12,
          },
          {
            x: 219,
            y: 191.13,
          },
          {
            x: 220,
            y: 191.14,
          },
          {
            x: 221,
            y: 191.15,
          },
          {
            x: 222,
            y: 191.15,
          },
          {
            x: 223,
            y: 191.15,
          },
          {
            x: 224,
            y: 191.15,
          },
          {
            x: 225,
            y: 191.15,
          },
          {
            x: 226,
            y: 191.15,
          },
          {
            x: 227,
            y: 191.14,
          },
          {
            x: 228,
            y: 191.14,
          },
        ],
      },
      // 1
      {
        color: "#000000",
        name: "1",
        data: [
          {
            x: 0,
            y: 51.8,
          },
          {
            x: 1,
            y: 56.7,
          },
          {
            x: 2,
            y: 60.4,
          },
          {
            x: 3,
            y: 63.5,
          },
          {
            x: 4,
            y: 66,
          },
          {
            x: 5,
            y: 68,
          },
          {
            x: 6,
            y: 69.8,
          },
          {
            x: 7,
            y: 71.3,
          },
          {
            x: 8,
            y: 72.8,
          },
          {
            x: 9,
            y: 74.2,
          },
          {
            x: 10,
            y: 75.6,
          },
          {
            x: 11,
            y: 76.9,
          },
          {
            x: 12,
            y: 78.1,
          },
          {
            x: 13,
            y: 79.3,
          },
          {
            x: 14,
            y: 80.5,
          },
          {
            x: 15,
            y: 81.7,
          },
          {
            x: 16,
            y: 82.8,
          },
          {
            x: 17,
            y: 83.9,
          },
          {
            x: 18,
            y: 85,
          },
          {
            x: 19,
            y: 86,
          },
          {
            x: 20,
            y: 87,
          },
          {
            x: 21,
            y: 88,
          },
          {
            x: 22,
            y: 89,
          },
          {
            x: 23,
            y: 89.9,
          },
          {
            x: 24,
            y: 90.2,
          },
          {
            x: 25,
            y: 91.1,
          },
          {
            x: 26,
            y: 92,
          },
          {
            x: 27,
            y: 92.9,
          },
          {
            x: 28,
            y: 93.7,
          },
          {
            x: 29,
            y: 94.5,
          },
          {
            x: 30,
            y: 95.3,
          },
          {
            x: 31,
            y: 96.1,
          },
          {
            x: 32,
            y: 96.9,
          },
          {
            x: 33,
            y: 97.6,
          },
          {
            x: 34,
            y: 98.4,
          },
          {
            x: 35,
            y: 99.1,
          },
          {
            x: 36,
            y: 99.8,
          },
          {
            x: 37,
            y: 100.5,
          },
          {
            x: 38,
            y: 101.2,
          },
          {
            x: 39,
            y: 101.8,
          },
          {
            x: 40,
            y: 102.5,
          },
          {
            x: 41,
            y: 103.2,
          },
          {
            x: 42,
            y: 103.8,
          },
          {
            x: 43,
            y: 104.5,
          },
          {
            x: 44,
            y: 105.1,
          },
          {
            x: 45,
            y: 105.7,
          },
          {
            x: 46,
            y: 106.3,
          },
          {
            x: 47,
            y: 106.9,
          },
          {
            x: 48,
            y: 107.5,
          },
          {
            x: 49,
            y: 108.1,
          },
          {
            x: 50,
            y: 108.7,
          },
          {
            x: 51,
            y: 109.3,
          },
          {
            x: 52,
            y: 109.9,
          },
          {
            x: 53,
            y: 110.5,
          },
          {
            x: 54,
            y: 111.1,
          },
          {
            x: 55,
            y: 111.7,
          },
          {
            x: 56,
            y: 112.3,
          },
          {
            x: 57,
            y: 112.8,
          },
          {
            x: 58,
            y: 113.4,
          },
          {
            x: 59,
            y: 114,
          },
          {
            x: 60,
            y: 114.6,
          },
          {
            x: 61,
            y: 114.86,
          },
          {
            x: 62,
            y: 115.42,
          },
          {
            x: 63,
            y: 115.99,
          },
          {
            x: 64,
            y: 116.55,
          },
          {
            x: 65,
            y: 117.1,
          },
          {
            x: 66,
            y: 117.66,
          },
          {
            x: 67,
            y: 118.2,
          },
          {
            x: 68,
            y: 118.75,
          },
          {
            x: 69,
            y: 119.29,
          },
          {
            x: 70,
            y: 119.82,
          },
          {
            x: 71,
            y: 120.35,
          },
          {
            x: 72,
            y: 120.88,
          },
          {
            x: 73,
            y: 121.4,
          },
          {
            x: 74,
            y: 121.92,
          },
          {
            x: 75,
            y: 122.44,
          },
          {
            x: 76,
            y: 122.95,
          },
          {
            x: 77,
            y: 123.46,
          },
          {
            x: 78,
            y: 123.97,
          },
          {
            x: 79,
            y: 124.49,
          },
          {
            x: 80,
            y: 125,
          },
          {
            x: 81,
            y: 125.5,
          },
          {
            x: 82,
            y: 126.01,
          },
          {
            x: 83,
            y: 126.52,
          },
          {
            x: 84,
            y: 127.02,
          },
          {
            x: 85,
            y: 127.52,
          },
          {
            x: 86,
            y: 128.02,
          },
          {
            x: 87,
            y: 128.52,
          },
          {
            x: 88,
            y: 129.02,
          },
          {
            x: 89,
            y: 129.51,
          },
          {
            x: 90,
            y: 130,
          },
          {
            x: 91,
            y: 130.49,
          },
          {
            x: 92,
            y: 130.98,
          },
          {
            x: 93,
            y: 131.47,
          },
          {
            x: 94,
            y: 131.95,
          },
          {
            x: 95,
            y: 132.43,
          },
          {
            x: 96,
            y: 132.91,
          },
          {
            x: 97,
            y: 133.39,
          },
          {
            x: 98,
            y: 133.87,
          },
          {
            x: 99,
            y: 134.34,
          },
          {
            x: 100,
            y: 134.81,
          },
          {
            x: 101,
            y: 135.29,
          },
          {
            x: 102,
            y: 135.76,
          },
          {
            x: 103,
            y: 136.23,
          },
          {
            x: 104,
            y: 136.7,
          },
          {
            x: 105,
            y: 137.17,
          },
          {
            x: 106,
            y: 137.64,
          },
          {
            x: 107,
            y: 138.11,
          },
          {
            x: 108,
            y: 138.58,
          },
          {
            x: 109,
            y: 139.04,
          },
          {
            x: 110,
            y: 139.51,
          },
          {
            x: 111,
            y: 139.98,
          },
          {
            x: 112,
            y: 140.45,
          },
          {
            x: 113,
            y: 140.91,
          },
          {
            x: 114,
            y: 141.38,
          },
          {
            x: 115,
            y: 141.84,
          },
          {
            x: 116,
            y: 142.3,
          },
          {
            x: 117,
            y: 142.77,
          },
          {
            x: 118,
            y: 143.23,
          },
          {
            x: 119,
            y: 143.69,
          },
          {
            x: 120,
            y: 144.15,
          },
          {
            x: 121,
            y: 144.62,
          },
          {
            x: 122,
            y: 145.08,
          },
          {
            x: 123,
            y: 145.54,
          },
          {
            x: 124,
            y: 146.01,
          },
          {
            x: 125,
            y: 146.48,
          },
          {
            x: 126,
            y: 146.95,
          },
          {
            x: 127,
            y: 147.42,
          },
          {
            x: 128,
            y: 147.9,
          },
          {
            x: 129,
            y: 148.38,
          },
          {
            x: 130,
            y: 148.86,
          },
          {
            x: 131,
            y: 149.35,
          },
          {
            x: 132,
            y: 149.84,
          },
          {
            x: 133,
            y: 150.34,
          },
          {
            x: 134,
            y: 150.84,
          },
          {
            x: 135,
            y: 151.35,
          },
          {
            x: 136,
            y: 151.86,
          },
          {
            x: 137,
            y: 152.38,
          },
          {
            x: 138,
            y: 152.9,
          },
          {
            x: 139,
            y: 153.43,
          },
          {
            x: 140,
            y: 153.96,
          },
          {
            x: 141,
            y: 154.5,
          },
          {
            x: 142,
            y: 155.05,
          },
          {
            x: 143,
            y: 155.6,
          },
          {
            x: 144,
            y: 156.17,
          },
          {
            x: 145,
            y: 156.74,
          },
          {
            x: 146,
            y: 157.31,
          },
          {
            x: 147,
            y: 157.9,
          },
          {
            x: 148,
            y: 158.49,
          },
          {
            x: 149,
            y: 159.09,
          },
          {
            x: 150,
            y: 159.7,
          },
          {
            x: 151,
            y: 160.32,
          },
          {
            x: 152,
            y: 160.94,
          },
          {
            x: 153,
            y: 161.57,
          },
          {
            x: 154,
            y: 162.2,
          },
          {
            x: 155,
            y: 162.84,
          },
          {
            x: 156,
            y: 163.47,
          },
          {
            x: 157,
            y: 164.11,
          },
          {
            x: 158,
            y: 164.75,
          },
          {
            x: 159,
            y: 165.38,
          },
          {
            x: 160,
            y: 166.02,
          },
          {
            x: 161,
            y: 166.65,
          },
          {
            x: 162,
            y: 167.27,
          },
          {
            x: 163,
            y: 167.89,
          },
          {
            x: 164,
            y: 168.5,
          },
          {
            x: 165,
            y: 169.11,
          },
          {
            x: 166,
            y: 169.71,
          },
          {
            x: 167,
            y: 170.3,
          },
          {
            x: 168,
            y: 170.87,
          },
          {
            x: 169,
            y: 171.44,
          },
          {
            x: 170,
            y: 171.99,
          },
          {
            x: 171,
            y: 172.53,
          },
          {
            x: 172,
            y: 173.06,
          },
          {
            x: 173,
            y: 173.57,
          },
          {
            x: 174,
            y: 174.07,
          },
          {
            x: 175,
            y: 174.56,
          },
          {
            x: 176,
            y: 175.03,
          },
          {
            x: 177,
            y: 175.48,
          },
          {
            x: 178,
            y: 175.92,
          },
          {
            x: 179,
            y: 176.35,
          },
          {
            x: 180,
            y: 176.76,
          },
          {
            x: 181,
            y: 177.16,
          },
          {
            x: 182,
            y: 177.54,
          },
          {
            x: 183,
            y: 177.92,
          },
          {
            x: 184,
            y: 178.27,
          },
          {
            x: 185,
            y: 178.62,
          },
          {
            x: 186,
            y: 178.95,
          },
          {
            x: 187,
            y: 179.27,
          },
          {
            x: 188,
            y: 179.57,
          },
          {
            x: 189,
            y: 179.87,
          },
          {
            x: 190,
            y: 180.14,
          },
          {
            x: 191,
            y: 180.41,
          },
          {
            x: 192,
            y: 180.67,
          },
          {
            x: 193,
            y: 180.91,
          },
          {
            x: 194,
            y: 181.14,
          },
          {
            x: 195,
            y: 181.36,
          },
          {
            x: 196,
            y: 181.56,
          },
          {
            x: 197,
            y: 181.76,
          },
          {
            x: 198,
            y: 181.94,
          },
          {
            x: 199,
            y: 182.11,
          },
          {
            x: 200,
            y: 182.27,
          },
          {
            x: 201,
            y: 182.42,
          },
          {
            x: 202,
            y: 182.56,
          },
          {
            x: 203,
            y: 182.69,
          },
          {
            x: 204,
            y: 182.81,
          },
          {
            x: 205,
            y: 182.91,
          },
          {
            x: 206,
            y: 183.01,
          },
          {
            x: 207,
            y: 183.1,
          },
          {
            x: 208,
            y: 183.19,
          },
          {
            x: 209,
            y: 183.26,
          },
          {
            x: 210,
            y: 183.33,
          },
          {
            x: 211,
            y: 183.39,
          },
          {
            x: 212,
            y: 183.44,
          },
          {
            x: 213,
            y: 183.49,
          },
          {
            x: 214,
            y: 183.54,
          },
          {
            x: 215,
            y: 183.58,
          },
          {
            x: 216,
            y: 183.62,
          },
          {
            x: 217,
            y: 183.65,
          },
          {
            x: 218,
            y: 183.68,
          },
          {
            x: 219,
            y: 183.7,
          },
          {
            x: 220,
            y: 183.73,
          },
          {
            x: 221,
            y: 183.75,
          },
          {
            x: 222,
            y: 183.77,
          },
          {
            x: 223,
            y: 183.78,
          },
          {
            x: 224,
            y: 183.8,
          },
          {
            x: 225,
            y: 183.81,
          },
          {
            x: 226,
            y: 183.82,
          },
          {
            x: 227,
            y: 183.83,
          },
          {
            x: 228,
            y: 183.84,
          },
        ],
      },
      // 0
      {
        color: "#77EA04",
        name: "0",
        data: [
          {
            x: 0,
            y: 49.9,
          },
          {
            x: 1,
            y: 54.7,
          },
          {
            x: 2,
            y: 58.4,
          },
          {
            x: 3,
            y: 61.4,
          },
          {
            x: 4,
            y: 63.9,
          },
          {
            x: 5,
            y: 65.9,
          },
          {
            x: 6,
            y: 67.6,
          },
          {
            x: 7,
            y: 69.2,
          },
          {
            x: 8,
            y: 70.6,
          },
          {
            x: 9,
            y: 72,
          },
          {
            x: 10,
            y: 73.3,
          },
          {
            x: 11,
            y: 74.5,
          },
          {
            x: 12,
            y: 75.7,
          },
          {
            x: 13,
            y: 76.9,
          },
          {
            x: 14,
            y: 78,
          },
          {
            x: 15,
            y: 79.1,
          },
          {
            x: 16,
            y: 80.2,
          },
          {
            x: 17,
            y: 81.2,
          },
          {
            x: 18,
            y: 82.3,
          },
          {
            x: 19,
            y: 83.2,
          },
          {
            x: 20,
            y: 84.2,
          },
          {
            x: 21,
            y: 85.1,
          },
          {
            x: 22,
            y: 86,
          },
          {
            x: 23,
            y: 86.9,
          },
          {
            x: 24,
            y: 87.1,
          },
          {
            x: 25,
            y: 88,
          },
          {
            x: 26,
            y: 88.8,
          },
          {
            x: 27,
            y: 89.6,
          },
          {
            x: 28,
            y: 90.4,
          },
          {
            x: 29,
            y: 91.2,
          },
          {
            x: 30,
            y: 91.9,
          },
          {
            x: 31,
            y: 92.7,
          },
          {
            x: 32,
            y: 93.4,
          },
          {
            x: 33,
            y: 94.1,
          },
          {
            x: 34,
            y: 94.8,
          },
          {
            x: 35,
            y: 95.4,
          },
          {
            x: 36,
            y: 96.1,
          },
          {
            x: 37,
            y: 96.7,
          },
          {
            x: 38,
            y: 97.4,
          },
          {
            x: 39,
            y: 98,
          },
          {
            x: 40,
            y: 98.6,
          },
          {
            x: 41,
            y: 99.2,
          },
          {
            x: 42,
            y: 99.9,
          },
          {
            x: 43,
            y: 100.4,
          },
          {
            x: 44,
            y: 101,
          },
          {
            x: 45,
            y: 101.6,
          },
          {
            x: 46,
            y: 102.2,
          },
          {
            x: 47,
            y: 102.8,
          },
          {
            x: 48,
            y: 103.3,
          },
          {
            x: 49,
            y: 103.9,
          },
          {
            x: 50,
            y: 104.4,
          },
          {
            x: 51,
            y: 105,
          },
          {
            x: 52,
            y: 105.6,
          },
          {
            x: 53,
            y: 106.1,
          },
          {
            x: 54,
            y: 106.7,
          },
          {
            x: 55,
            y: 107.2,
          },
          {
            x: 56,
            y: 107.8,
          },
          {
            x: 57,
            y: 108.3,
          },
          {
            x: 58,
            y: 108.9,
          },
          {
            x: 59,
            y: 109.4,
          },
          {
            x: 60,
            y: 110,
          },
          {
            x: 61,
            y: 110.27,
          },
          {
            x: 62,
            y: 110.8,
          },
          {
            x: 63,
            y: 111.33,
          },
          {
            x: 64,
            y: 111.86,
          },
          {
            x: 65,
            y: 112.39,
          },
          {
            x: 66,
            y: 112.91,
          },
          {
            x: 67,
            y: 113.43,
          },
          {
            x: 68,
            y: 113.94,
          },
          {
            x: 69,
            y: 114.45,
          },
          {
            x: 70,
            y: 114.95,
          },
          {
            x: 71,
            y: 115.45,
          },
          {
            x: 72,
            y: 115.95,
          },
          {
            x: 73,
            y: 116.44,
          },
          {
            x: 74,
            y: 116.93,
          },
          {
            x: 75,
            y: 117.42,
          },
          {
            x: 76,
            y: 117.91,
          },
          {
            x: 77,
            y: 118.39,
          },
          {
            x: 78,
            y: 118.87,
          },
          {
            x: 79,
            y: 119.35,
          },
          {
            x: 80,
            y: 119.83,
          },
          {
            x: 81,
            y: 120.31,
          },
          {
            x: 82,
            y: 120.78,
          },
          {
            x: 83,
            y: 121.26,
          },
          {
            x: 84,
            y: 121.73,
          },
          {
            x: 85,
            y: 122.2,
          },
          {
            x: 86,
            y: 122.67,
          },
          {
            x: 87,
            y: 123.14,
          },
          {
            x: 88,
            y: 123.61,
          },
          {
            x: 89,
            y: 124.07,
          },
          {
            x: 90,
            y: 124.54,
          },
          {
            x: 91,
            y: 125,
          },
          {
            x: 92,
            y: 125.45,
          },
          {
            x: 93,
            y: 125.91,
          },
          {
            x: 94,
            y: 126.36,
          },
          {
            x: 95,
            y: 126.82,
          },
          {
            x: 96,
            y: 127.27,
          },
          {
            x: 97,
            y: 127.71,
          },
          {
            x: 98,
            y: 128.16,
          },
          {
            x: 99,
            y: 128.6,
          },
          {
            x: 100,
            y: 129.05,
          },
          {
            x: 101,
            y: 129.49,
          },
          {
            x: 102,
            y: 129.93,
          },
          {
            x: 103,
            y: 130.37,
          },
          {
            x: 104,
            y: 130.81,
          },
          {
            x: 105,
            y: 131.25,
          },
          {
            x: 106,
            y: 131.69,
          },
          {
            x: 107,
            y: 132.13,
          },
          {
            x: 108,
            y: 132.56,
          },
          {
            x: 109,
            y: 133,
          },
          {
            x: 110,
            y: 133.44,
          },
          {
            x: 111,
            y: 133.88,
          },
          {
            x: 112,
            y: 134.31,
          },
          {
            x: 113,
            y: 134.75,
          },
          {
            x: 114,
            y: 135.18,
          },
          {
            x: 115,
            y: 135.62,
          },
          {
            x: 116,
            y: 136.05,
          },
          {
            x: 117,
            y: 136.48,
          },
          {
            x: 118,
            y: 136.91,
          },
          {
            x: 119,
            y: 137.35,
          },
          {
            x: 120,
            y: 137.78,
          },
          {
            x: 121,
            y: 138.21,
          },
          {
            x: 122,
            y: 138.65,
          },
          {
            x: 123,
            y: 139.08,
          },
          {
            x: 124,
            y: 139.52,
          },
          {
            x: 125,
            y: 139.95,
          },
          {
            x: 126,
            y: 140.4,
          },
          {
            x: 127,
            y: 140.84,
          },
          {
            x: 128,
            y: 141.29,
          },
          {
            x: 129,
            y: 141.74,
          },
          {
            x: 130,
            y: 142.19,
          },
          {
            x: 131,
            y: 142.65,
          },
          {
            x: 132,
            y: 143.11,
          },
          {
            x: 133,
            y: 143.58,
          },
          {
            x: 134,
            y: 144.05,
          },
          {
            x: 135,
            y: 144.53,
          },
          {
            x: 136,
            y: 145.01,
          },
          {
            x: 137,
            y: 145.5,
          },
          {
            x: 138,
            y: 145.99,
          },
          {
            x: 139,
            y: 146.49,
          },
          {
            x: 140,
            y: 146.99,
          },
          {
            x: 141,
            y: 147.5,
          },
          {
            x: 142,
            y: 148.02,
          },
          {
            x: 143,
            y: 148.55,
          },
          {
            x: 144,
            y: 149.08,
          },
          {
            x: 145,
            y: 149.62,
          },
          {
            x: 146,
            y: 150.17,
          },
          {
            x: 147,
            y: 150.73,
          },
          {
            x: 148,
            y: 151.29,
          },
          {
            x: 149,
            y: 151.86,
          },
          {
            x: 150,
            y: 152.44,
          },
          {
            x: 151,
            y: 153.03,
          },
          {
            x: 152,
            y: 153.62,
          },
          {
            x: 153,
            y: 154.22,
          },
          {
            x: 154,
            y: 154.83,
          },
          {
            x: 155,
            y: 155.43,
          },
          {
            x: 156,
            y: 156.04,
          },
          {
            x: 157,
            y: 156.65,
          },
          {
            x: 158,
            y: 157.27,
          },
          {
            x: 159,
            y: 157.88,
          },
          {
            x: 160,
            y: 158.49,
          },
          {
            x: 161,
            y: 159.09,
          },
          {
            x: 162,
            y: 159.7,
          },
          {
            x: 163,
            y: 160.29,
          },
          {
            x: 164,
            y: 160.89,
          },
          {
            x: 165,
            y: 161.47,
          },
          {
            x: 166,
            y: 162.05,
          },
          {
            x: 167,
            y: 162.62,
          },
          {
            x: 168,
            y: 163.18,
          },
          {
            x: 169,
            y: 163.73,
          },
          {
            x: 170,
            y: 164.27,
          },
          {
            x: 171,
            y: 164.8,
          },
          {
            x: 172,
            y: 165.31,
          },
          {
            x: 173,
            y: 165.82,
          },
          {
            x: 174,
            y: 166.31,
          },
          {
            x: 175,
            y: 166.78,
          },
          {
            x: 176,
            y: 167.24,
          },
          {
            x: 177,
            y: 167.69,
          },
          {
            x: 178,
            y: 168.13,
          },
          {
            x: 179,
            y: 168.55,
          },
          {
            x: 180,
            y: 168.96,
          },
          {
            x: 181,
            y: 169.35,
          },
          {
            x: 182,
            y: 169.74,
          },
          {
            x: 183,
            y: 170.11,
          },
          {
            x: 184,
            y: 170.47,
          },
          {
            x: 185,
            y: 170.81,
          },
          {
            x: 186,
            y: 171.15,
          },
          {
            x: 187,
            y: 171.47,
          },
          {
            x: 188,
            y: 171.78,
          },
          {
            x: 189,
            y: 172.07,
          },
          {
            x: 190,
            y: 172.36,
          },
          {
            x: 191,
            y: 172.63,
          },
          {
            x: 192,
            y: 172.9,
          },
          {
            x: 193,
            y: 173.15,
          },
          {
            x: 194,
            y: 173.39,
          },
          {
            x: 195,
            y: 173.61,
          },
          {
            x: 196,
            y: 173.83,
          },
          {
            x: 197,
            y: 174.03,
          },
          {
            x: 198,
            y: 174.22,
          },
          {
            x: 199,
            y: 174.41,
          },
          {
            x: 200,
            y: 174.58,
          },
          {
            x: 201,
            y: 174.74,
          },
          {
            x: 202,
            y: 174.89,
          },
          {
            x: 203,
            y: 175.03,
          },
          {
            x: 204,
            y: 175.16,
          },
          {
            x: 205,
            y: 175.28,
          },
          {
            x: 206,
            y: 175.4,
          },
          {
            x: 207,
            y: 175.5,
          },
          {
            x: 208,
            y: 175.6,
          },
          {
            x: 209,
            y: 175.69,
          },
          {
            x: 210,
            y: 175.77,
          },
          {
            x: 211,
            y: 175.84,
          },
          {
            x: 212,
            y: 175.91,
          },
          {
            x: 213,
            y: 175.98,
          },
          {
            x: 214,
            y: 176.04,
          },
          {
            x: 215,
            y: 176.09,
          },
          {
            x: 216,
            y: 176.15,
          },
          {
            x: 217,
            y: 176.19,
          },
          {
            x: 218,
            y: 176.24,
          },
          {
            x: 219,
            y: 176.28,
          },
          {
            x: 220,
            y: 176.32,
          },
          {
            x: 221,
            y: 176.35,
          },
          {
            x: 222,
            y: 176.38,
          },
          {
            x: 223,
            y: 176.42,
          },
          {
            x: 224,
            y: 176.44,
          },
          {
            x: 225,
            y: 176.47,
          },
          {
            x: 226,
            y: 176.5,
          },
          {
            x: 227,
            y: 176.52,
          },
          {
            x: 228,
            y: 176.54,
          },
        ],
      },
      // -1
      {
        color: "#ED9300",
        name: "-1",
        data: [
          {
            x: 0,
            y: 48,
          },
          {
            x: 1,
            y: 52.8,
          },
          {
            x: 2,
            y: 56.4,
          },
          {
            x: 3,
            y: 59.4,
          },
          {
            x: 4,
            y: 61.8,
          },
          {
            x: 5,
            y: 63.8,
          },
          {
            x: 6,
            y: 65.5,
          },
          {
            x: 7,
            y: 67,
          },
          {
            x: 8,
            y: 68.4,
          },
          {
            x: 9,
            y: 69.7,
          },
          {
            x: 10,
            y: 71,
          },
          {
            x: 11,
            y: 72.2,
          },
          {
            x: 12,
            y: 73.4,
          },
          {
            x: 13,
            y: 74.5,
          },
          {
            x: 14,
            y: 75.6,
          },
          {
            x: 15,
            y: 76.6,
          },
          {
            x: 16,
            y: 77.6,
          },
          {
            x: 17,
            y: 78.6,
          },
          {
            x: 18,
            y: 79.6,
          },
          {
            x: 19,
            y: 80.5,
          },
          {
            x: 20,
            y: 81.4,
          },
          {
            x: 21,
            y: 82.3,
          },
          {
            x: 22,
            y: 83.1,
          },
          {
            x: 23,
            y: 83.9,
          },
          {
            x: 24,
            y: 84.1,
          },
          {
            x: 25,
            y: 84.9,
          },
          {
            x: 26,
            y: 85.6,
          },
          {
            x: 27,
            y: 86.4,
          },
          {
            x: 28,
            y: 87.1,
          },
          {
            x: 29,
            y: 87.8,
          },
          {
            x: 30,
            y: 88.5,
          },
          {
            x: 31,
            y: 89.2,
          },
          {
            x: 32,
            y: 89.9,
          },
          {
            x: 33,
            y: 90.5,
          },
          {
            x: 34,
            y: 91.1,
          },
          {
            x: 35,
            y: 91.8,
          },
          {
            x: 36,
            y: 92.4,
          },
          {
            x: 37,
            y: 93,
          },
          {
            x: 38,
            y: 93.6,
          },
          {
            x: 39,
            y: 94.2,
          },
          {
            x: 40,
            y: 94.7,
          },
          {
            x: 41,
            y: 95.3,
          },
          {
            x: 42,
            y: 95.9,
          },
          {
            x: 43,
            y: 96.4,
          },
          {
            x: 44,
            y: 97,
          },
          {
            x: 45,
            y: 97.5,
          },
          {
            x: 46,
            y: 98.1,
          },
          {
            x: 47,
            y: 98.6,
          },
          {
            x: 48,
            y: 99.1,
          },
          {
            x: 49,
            y: 99.7,
          },
          {
            x: 50,
            y: 100.2,
          },
          {
            x: 51,
            y: 100.7,
          },
          {
            x: 52,
            y: 101.2,
          },
          {
            x: 53,
            y: 101.7,
          },
          {
            x: 54,
            y: 102.3,
          },
          {
            x: 55,
            y: 102.8,
          },
          {
            x: 56,
            y: 103.3,
          },
          {
            x: 57,
            y: 103.8,
          },
          {
            x: 58,
            y: 104.3,
          },
          {
            x: 59,
            y: 104.8,
          },
          {
            x: 60,
            y: 105.3,
          },
          {
            x: 61,
            y: 105.67,
          },
          {
            x: 62,
            y: 106.18,
          },
          {
            x: 63,
            y: 106.68,
          },
          {
            x: 64,
            y: 107.18,
          },
          {
            x: 65,
            y: 107.67,
          },
          {
            x: 66,
            y: 108.17,
          },
          {
            x: 67,
            y: 108.65,
          },
          {
            x: 68,
            y: 109.14,
          },
          {
            x: 69,
            y: 109.61,
          },
          {
            x: 70,
            y: 110.09,
          },
          {
            x: 71,
            y: 110.56,
          },
          {
            x: 72,
            y: 111.02,
          },
          {
            x: 73,
            y: 111.49,
          },
          {
            x: 74,
            y: 111.95,
          },
          {
            x: 75,
            y: 112.4,
          },
          {
            x: 76,
            y: 112.86,
          },
          {
            x: 77,
            y: 113.31,
          },
          {
            x: 78,
            y: 113.77,
          },
          {
            x: 79,
            y: 114.22,
          },
          {
            x: 80,
            y: 114.66,
          },
          {
            x: 81,
            y: 115.11,
          },
          {
            x: 82,
            y: 115.56,
          },
          {
            x: 83,
            y: 116,
          },
          {
            x: 84,
            y: 116.45,
          },
          {
            x: 85,
            y: 116.89,
          },
          {
            x: 86,
            y: 117.33,
          },
          {
            x: 87,
            y: 117.77,
          },
          {
            x: 88,
            y: 118.2,
          },
          {
            x: 89,
            y: 118.64,
          },
          {
            x: 90,
            y: 119.07,
          },
          {
            x: 91,
            y: 119.5,
          },
          {
            x: 92,
            y: 119.93,
          },
          {
            x: 93,
            y: 120.35,
          },
          {
            x: 94,
            y: 120.78,
          },
          {
            x: 95,
            y: 121.2,
          },
          {
            x: 96,
            y: 121.62,
          },
          {
            x: 97,
            y: 122.03,
          },
          {
            x: 98,
            y: 122.45,
          },
          {
            x: 99,
            y: 122.86,
          },
          {
            x: 100,
            y: 123.28,
          },
          {
            x: 101,
            y: 123.69,
          },
          {
            x: 102,
            y: 124.1,
          },
          {
            x: 103,
            y: 124.51,
          },
          {
            x: 104,
            y: 124.92,
          },
          {
            x: 105,
            y: 125.33,
          },
          {
            x: 106,
            y: 125.74,
          },
          {
            x: 107,
            y: 126.15,
          },
          {
            x: 108,
            y: 126.55,
          },
          {
            x: 109,
            y: 126.96,
          },
          {
            x: 110,
            y: 127.37,
          },
          {
            x: 111,
            y: 127.77,
          },
          {
            x: 112,
            y: 128.18,
          },
          {
            x: 113,
            y: 128.59,
          },
          {
            x: 114,
            y: 128.99,
          },
          {
            x: 115,
            y: 129.39,
          },
          {
            x: 116,
            y: 129.8,
          },
          {
            x: 117,
            y: 130.2,
          },
          {
            x: 118,
            y: 130.6,
          },
          {
            x: 119,
            y: 131,
          },
          {
            x: 120,
            y: 131.41,
          },
          {
            x: 121,
            y: 131.81,
          },
          {
            x: 122,
            y: 132.21,
          },
          {
            x: 123,
            y: 132.62,
          },
          {
            x: 124,
            y: 133.02,
          },
          {
            x: 125,
            y: 133.43,
          },
          {
            x: 126,
            y: 133.84,
          },
          {
            x: 127,
            y: 134.26,
          },
          {
            x: 128,
            y: 134.67,
          },
          {
            x: 129,
            y: 135.09,
          },
          {
            x: 130,
            y: 135.52,
          },
          {
            x: 131,
            y: 135.95,
          },
          {
            x: 132,
            y: 136.38,
          },
          {
            x: 133,
            y: 136.82,
          },
          {
            x: 134,
            y: 137.26,
          },
          {
            x: 135,
            y: 137.71,
          },
          {
            x: 136,
            y: 138.16,
          },
          {
            x: 137,
            y: 138.62,
          },
          {
            x: 138,
            y: 139.08,
          },
          {
            x: 139,
            y: 139.55,
          },
          {
            x: 140,
            y: 140.03,
          },
          {
            x: 141,
            y: 140.51,
          },
          {
            x: 142,
            y: 141,
          },
          {
            x: 143,
            y: 141.49,
          },
          {
            x: 144,
            y: 142,
          },
          {
            x: 145,
            y: 142.51,
          },
          {
            x: 146,
            y: 143.02,
          },
          {
            x: 147,
            y: 143.55,
          },
          {
            x: 148,
            y: 144.09,
          },
          {
            x: 149,
            y: 144.63,
          },
          {
            x: 150,
            y: 145.18,
          },
          {
            x: 151,
            y: 145.74,
          },
          {
            x: 152,
            y: 146.31,
          },
          {
            x: 153,
            y: 146.88,
          },
          {
            x: 154,
            y: 147.45,
          },
          {
            x: 155,
            y: 148.03,
          },
          {
            x: 156,
            y: 148.62,
          },
          {
            x: 157,
            y: 149.2,
          },
          {
            x: 158,
            y: 149.79,
          },
          {
            x: 159,
            y: 150.37,
          },
          {
            x: 160,
            y: 150.96,
          },
          {
            x: 161,
            y: 151.54,
          },
          {
            x: 162,
            y: 152.12,
          },
          {
            x: 163,
            y: 152.7,
          },
          {
            x: 164,
            y: 153.27,
          },
          {
            x: 165,
            y: 153.83,
          },
          {
            x: 166,
            y: 154.39,
          },
          {
            x: 167,
            y: 154.94,
          },
          {
            x: 168,
            y: 155.49,
          },
          {
            x: 169,
            y: 156.03,
          },
          {
            x: 170,
            y: 156.55,
          },
          {
            x: 171,
            y: 157.06,
          },
          {
            x: 172,
            y: 157.57,
          },
          {
            x: 173,
            y: 158.06,
          },
          {
            x: 174,
            y: 158.54,
          },
          {
            x: 175,
            y: 159,
          },
          {
            x: 176,
            y: 159.46,
          },
          {
            x: 177,
            y: 159.9,
          },
          {
            x: 178,
            y: 160.33,
          },
          {
            x: 179,
            y: 160.75,
          },
          {
            x: 180,
            y: 161.15,
          },
          {
            x: 181,
            y: 161.55,
          },
          {
            x: 182,
            y: 161.93,
          },
          {
            x: 183,
            y: 162.3,
          },
          {
            x: 184,
            y: 162.66,
          },
          {
            x: 185,
            y: 163.01,
          },
          {
            x: 186,
            y: 163.34,
          },
          {
            x: 187,
            y: 163.67,
          },
          {
            x: 188,
            y: 163.98,
          },
          {
            x: 189,
            y: 164.28,
          },
          {
            x: 190,
            y: 164.58,
          },
          {
            x: 191,
            y: 164.86,
          },
          {
            x: 192,
            y: 165.13,
          },
          {
            x: 193,
            y: 165.38,
          },
          {
            x: 194,
            y: 165.63,
          },
          {
            x: 195,
            y: 165.87,
          },
          {
            x: 196,
            y: 166.09,
          },
          {
            x: 197,
            y: 166.31,
          },
          {
            x: 198,
            y: 166.51,
          },
          {
            x: 199,
            y: 166.7,
          },
          {
            x: 200,
            y: 166.88,
          },
          {
            x: 201,
            y: 167.06,
          },
          {
            x: 202,
            y: 167.22,
          },
          {
            x: 203,
            y: 167.37,
          },
          {
            x: 204,
            y: 167.52,
          },
          {
            x: 205,
            y: 167.65,
          },
          {
            x: 206,
            y: 167.78,
          },
          {
            x: 207,
            y: 167.9,
          },
          {
            x: 208,
            y: 168.01,
          },
          {
            x: 209,
            y: 168.11,
          },
          {
            x: 210,
            y: 168.21,
          },
          {
            x: 211,
            y: 168.3,
          },
          {
            x: 212,
            y: 168.38,
          },
          {
            x: 213,
            y: 168.46,
          },
          {
            x: 214,
            y: 168.54,
          },
          {
            x: 215,
            y: 168.61,
          },
          {
            x: 216,
            y: 168.68,
          },
          {
            x: 217,
            y: 168.74,
          },
          {
            x: 218,
            y: 168.8,
          },
          {
            x: 219,
            y: 168.85,
          },
          {
            x: 220,
            y: 168.9,
          },
          {
            x: 221,
            y: 168.95,
          },
          {
            x: 222,
            y: 169,
          },
          {
            x: 223,
            y: 169.05,
          },
          {
            x: 224,
            y: 169.09,
          },
          {
            x: 225,
            y: 169.13,
          },
          {
            x: 226,
            y: 169.17,
          },
          {
            x: 227,
            y: 169.21,
          },
          {
            x: 228,
            y: 169.25,
          },
        ],
      },
      // -2
      {
        color: "#F30037",
        name: "-2",
        data: [
          {
            x: 0,
            y: 46.1,
          },
          {
            x: 1,
            y: 50.8,
          },
          {
            x: 2,
            y: 54.4,
          },
          {
            x: 3,
            y: 57.3,
          },
          {
            x: 4,
            y: 59.7,
          },
          {
            x: 5,
            y: 61.7,
          },
          {
            x: 6,
            y: 63.3,
          },
          {
            x: 7,
            y: 64.8,
          },
          {
            x: 8,
            y: 66.2,
          },
          {
            x: 9,
            y: 67.5,
          },
          {
            x: 10,
            y: 68.7,
          },
          {
            x: 11,
            y: 69.9,
          },
          {
            x: 12,
            y: 71,
          },
          {
            x: 13,
            y: 72.1,
          },
          {
            x: 14,
            y: 73.1,
          },
          {
            x: 15,
            y: 74.1,
          },
          {
            x: 16,
            y: 75,
          },
          {
            x: 17,
            y: 76,
          },
          {
            x: 18,
            y: 76.9,
          },
          {
            x: 19,
            y: 77.7,
          },
          {
            x: 20,
            y: 78.6,
          },
          {
            x: 21,
            y: 79.4,
          },
          {
            x: 22,
            y: 80.2,
          },
          {
            x: 23,
            y: 81,
          },
          {
            x: 24,
            y: 81,
          },
          {
            x: 25,
            y: 81.7,
          },
          {
            x: 26,
            y: 82.5,
          },
          {
            x: 27,
            y: 83.1,
          },
          {
            x: 28,
            y: 83.8,
          },
          {
            x: 29,
            y: 84.5,
          },
          {
            x: 30,
            y: 85.1,
          },
          {
            x: 31,
            y: 85.7,
          },
          {
            x: 32,
            y: 86.4,
          },
          {
            x: 33,
            y: 86.9,
          },
          {
            x: 34,
            y: 87.5,
          },
          {
            x: 35,
            y: 88.1,
          },
          {
            x: 36,
            y: 88.7,
          },
          {
            x: 37,
            y: 89.2,
          },
          {
            x: 38,
            y: 89.8,
          },
          {
            x: 39,
            y: 90.3,
          },
          {
            x: 40,
            y: 90.9,
          },
          {
            x: 41,
            y: 91.4,
          },
          {
            x: 42,
            y: 91.9,
          },
          {
            x: 43,
            y: 92.4,
          },
          {
            x: 44,
            y: 93,
          },
          {
            x: 45,
            y: 93.5,
          },
          {
            x: 46,
            y: 94,
          },
          {
            x: 47,
            y: 94.4,
          },
          {
            x: 48,
            y: 94.9,
          },
          {
            x: 49,
            y: 95.4,
          },
          {
            x: 50,
            y: 95.9,
          },
          {
            x: 51,
            y: 96.4,
          },
          {
            x: 52,
            y: 96.9,
          },
          {
            x: 53,
            y: 97.4,
          },
          {
            x: 54,
            y: 97.8,
          },
          {
            x: 55,
            y: 98.3,
          },
          {
            x: 56,
            y: 98.8,
          },
          {
            x: 57,
            y: 99.3,
          },
          {
            x: 58,
            y: 99.7,
          },
          {
            x: 59,
            y: 100.2,
          },
          {
            x: 60,
            y: 100.7,
          },
          {
            x: 61,
            y: 101.08,
          },
          {
            x: 62,
            y: 101.56,
          },
          {
            x: 63,
            y: 102.03,
          },
          {
            x: 64,
            y: 102.5,
          },
          {
            x: 65,
            y: 102.96,
          },
          {
            x: 66,
            y: 103.42,
          },
          {
            x: 67,
            y: 103.88,
          },
          {
            x: 68,
            y: 104.33,
          },
          {
            x: 69,
            y: 104.78,
          },
          {
            x: 70,
            y: 105.22,
          },
          {
            x: 71,
            y: 105.66,
          },
          {
            x: 72,
            y: 106.1,
          },
          {
            x: 73,
            y: 106.53,
          },
          {
            x: 74,
            y: 106.96,
          },
          {
            x: 75,
            y: 107.39,
          },
          {
            x: 76,
            y: 107.81,
          },
          {
            x: 77,
            y: 108.24,
          },
          {
            x: 78,
            y: 108.66,
          },
          {
            x: 79,
            y: 109.08,
          },
          {
            x: 80,
            y: 109.5,
          },
          {
            x: 81,
            y: 109.92,
          },
          {
            x: 82,
            y: 110.33,
          },
          {
            x: 83,
            y: 110.75,
          },
          {
            x: 84,
            y: 111.16,
          },
          {
            x: 85,
            y: 111.57,
          },
          {
            x: 86,
            y: 111.98,
          },
          {
            x: 87,
            y: 112.39,
          },
          {
            x: 88,
            y: 112.8,
          },
          {
            x: 89,
            y: 113.2,
          },
          {
            x: 90,
            y: 113.6,
          },
          {
            x: 91,
            y: 114,
          },
          {
            x: 92,
            y: 114.4,
          },
          {
            x: 93,
            y: 114.8,
          },
          {
            x: 94,
            y: 115.19,
          },
          {
            x: 95,
            y: 115.58,
          },
          {
            x: 96,
            y: 115.97,
          },
          {
            x: 97,
            y: 116.36,
          },
          {
            x: 98,
            y: 116.74,
          },
          {
            x: 99,
            y: 117.13,
          },
          {
            x: 100,
            y: 117.51,
          },
          {
            x: 101,
            y: 117.89,
          },
          {
            x: 102,
            y: 118.27,
          },
          {
            x: 103,
            y: 118.65,
          },
          {
            x: 104,
            y: 119.03,
          },
          {
            x: 105,
            y: 119.41,
          },
          {
            x: 106,
            y: 119.79,
          },
          {
            x: 107,
            y: 120.16,
          },
          {
            x: 108,
            y: 120.54,
          },
          {
            x: 109,
            y: 120.92,
          },
          {
            x: 110,
            y: 121.3,
          },
          {
            x: 111,
            y: 121.67,
          },
          {
            x: 112,
            y: 122.05,
          },
          {
            x: 113,
            y: 122.42,
          },
          {
            x: 114,
            y: 122.8,
          },
          {
            x: 115,
            y: 123.17,
          },
          {
            x: 116,
            y: 123.54,
          },
          {
            x: 117,
            y: 123.92,
          },
          {
            x: 118,
            y: 124.29,
          },
          {
            x: 119,
            y: 124.66,
          },
          {
            x: 120,
            y: 125.03,
          },
          {
            x: 121,
            y: 125.41,
          },
          {
            x: 122,
            y: 125.78,
          },
          {
            x: 123,
            y: 126.15,
          },
          {
            x: 124,
            y: 126.53,
          },
          {
            x: 125,
            y: 126.91,
          },
          {
            x: 126,
            y: 127.29,
          },
          {
            x: 127,
            y: 127.67,
          },
          {
            x: 128,
            y: 128.06,
          },
          {
            x: 129,
            y: 128.45,
          },
          {
            x: 130,
            y: 128.85,
          },
          {
            x: 131,
            y: 129.25,
          },
          {
            x: 132,
            y: 129.65,
          },
          {
            x: 133,
            y: 130.06,
          },
          {
            x: 134,
            y: 130.47,
          },
          {
            x: 135,
            y: 130.89,
          },
          {
            x: 136,
            y: 131.31,
          },
          {
            x: 137,
            y: 131.74,
          },
          {
            x: 138,
            y: 132.17,
          },
          {
            x: 139,
            y: 132.61,
          },
          {
            x: 140,
            y: 133.06,
          },
          {
            x: 141,
            y: 133.51,
          },
          {
            x: 142,
            y: 133.97,
          },
          {
            x: 143,
            y: 134.44,
          },
          {
            x: 144,
            y: 134.91,
          },
          {
            x: 145,
            y: 135.39,
          },
          {
            x: 146,
            y: 135.88,
          },
          {
            x: 147,
            y: 136.38,
          },
          {
            x: 148,
            y: 136.88,
          },
          {
            x: 149,
            y: 137.4,
          },
          {
            x: 150,
            y: 137.92,
          },
          {
            x: 151,
            y: 138.45,
          },
          {
            x: 152,
            y: 138.99,
          },
          {
            x: 153,
            y: 139.53,
          },
          {
            x: 154,
            y: 140.08,
          },
          {
            x: 155,
            y: 140.63,
          },
          {
            x: 156,
            y: 141.19,
          },
          {
            x: 157,
            y: 141.75,
          },
          {
            x: 158,
            y: 142.31,
          },
          {
            x: 159,
            y: 142.87,
          },
          {
            x: 160,
            y: 143.43,
          },
          {
            x: 161,
            y: 143.99,
          },
          {
            x: 162,
            y: 144.54,
          },
          {
            x: 163,
            y: 145.1,
          },
          {
            x: 164,
            y: 145.65,
          },
          {
            x: 165,
            y: 146.2,
          },
          {
            x: 166,
            y: 146.74,
          },
          {
            x: 167,
            y: 147.27,
          },
          {
            x: 168,
            y: 147.8,
          },
          {
            x: 169,
            y: 148.32,
          },
          {
            x: 170,
            y: 148.83,
          },
          {
            x: 171,
            y: 149.33,
          },
          {
            x: 172,
            y: 149.82,
          },
          {
            x: 173,
            y: 150.3,
          },
          {
            x: 174,
            y: 150.77,
          },
          {
            x: 175,
            y: 151.23,
          },
          {
            x: 176,
            y: 151.67,
          },
          {
            x: 177,
            y: 152.11,
          },
          {
            x: 178,
            y: 152.53,
          },
          {
            x: 179,
            y: 152.95,
          },
          {
            x: 180,
            y: 153.35,
          },
          {
            x: 181,
            y: 153.74,
          },
          {
            x: 182,
            y: 154.13,
          },
          {
            x: 183,
            y: 154.5,
          },
          {
            x: 184,
            y: 154.86,
          },
          {
            x: 185,
            y: 155.21,
          },
          {
            x: 186,
            y: 155.54,
          },
          {
            x: 187,
            y: 155.87,
          },
          {
            x: 188,
            y: 156.19,
          },
          {
            x: 189,
            y: 156.5,
          },
          {
            x: 190,
            y: 156.79,
          },
          {
            x: 191,
            y: 157.08,
          },
          {
            x: 192,
            y: 157.35,
          },
          {
            x: 193,
            y: 157.62,
          },
          {
            x: 194,
            y: 157.88,
          },
          {
            x: 195,
            y: 158.12,
          },
          {
            x: 196,
            y: 158.35,
          },
          {
            x: 197,
            y: 158.58,
          },
          {
            x: 198,
            y: 158.79,
          },
          {
            x: 199,
            y: 159,
          },
          {
            x: 200,
            y: 159.19,
          },
          {
            x: 201,
            y: 159.38,
          },
          {
            x: 202,
            y: 159.55,
          },
          {
            x: 203,
            y: 159.72,
          },
          {
            x: 204,
            y: 159.87,
          },
          {
            x: 205,
            y: 160.02,
          },
          {
            x: 206,
            y: 160.16,
          },
          {
            x: 207,
            y: 160.29,
          },
          {
            x: 208,
            y: 160.42,
          },
          {
            x: 209,
            y: 160.54,
          },
          {
            x: 210,
            y: 160.65,
          },
          {
            x: 211,
            y: 160.75,
          },
          {
            x: 212,
            y: 160.85,
          },
          {
            x: 213,
            y: 160.95,
          },
          {
            x: 214,
            y: 161.04,
          },
          {
            x: 215,
            y: 161.12,
          },
          {
            x: 216,
            y: 161.2,
          },
          {
            x: 217,
            y: 161.28,
          },
          {
            x: 218,
            y: 161.35,
          },
          {
            x: 219,
            y: 161.43,
          },
          {
            x: 220,
            y: 161.49,
          },
          {
            x: 221,
            y: 161.56,
          },
          {
            x: 222,
            y: 161.62,
          },
          {
            x: 223,
            y: 161.68,
          },
          {
            x: 224,
            y: 161.74,
          },
          {
            x: 225,
            y: 161.79,
          },
          {
            x: 226,
            y: 161.85,
          },
          {
            x: 227,
            y: 161.9,
          },
          {
            x: 228,
            y: 161.95,
          },
        ],
      },
    ],
    /**Talla para la edad edad - Nuevo*/

    /** IMC para la edad - Nuevo */
    imcForAgeGirl: [
      // 2
      {
        color: "#8260BB",
        name: "2",
        data: [
          {
            x: 0,
            y: 16.1,
          },
          {
            x: 1,
            y: 17.5,
          },
          {
            x: 2,
            y: 19,
          },
          {
            x: 3,
            y: 19.7,
          },
          {
            x: 4,
            y: 20,
          },
          {
            x: 5,
            y: 20.2,
          },
          {
            x: 6,
            y: 20.3,
          },
          {
            x: 7,
            y: 20.3,
          },
          {
            x: 8,
            y: 20.2,
          },
          {
            x: 9,
            y: 20.1,
          },
          {
            x: 10,
            y: 19.9,
          },
          {
            x: 11,
            y: 19.8,
          },
          {
            x: 12,
            y: 19.6,
          },
          {
            x: 13,
            y: 19.5,
          },
          {
            x: 14,
            y: 19.3,
          },
          {
            x: 15,
            y: 19.2,
          },
          {
            x: 16,
            y: 19.1,
          },
          {
            x: 17,
            y: 18.9,
          },
          {
            x: 18,
            y: 18.8,
          },
          {
            x: 19,
            y: 18.8,
          },
          {
            x: 20,
            y: 18.7,
          },
          {
            x: 21,
            y: 18.6,
          },
          {
            x: 22,
            y: 18.5,
          },
          {
            x: 23,
            y: 18.5,
          },
          {
            x: 24,
            y: 18.7,
          },
          {
            x: 25,
            y: 18.7,
          },
          {
            x: 26,
            y: 18.7,
          },
          {
            x: 27,
            y: 18.6,
          },
          {
            x: 28,
            y: 18.6,
          },
          {
            x: 29,
            y: 18.6,
          },
          {
            x: 30,
            y: 18.5,
          },
          {
            x: 31,
            y: 18.5,
          },
          {
            x: 32,
            y: 18.5,
          },
          {
            x: 33,
            y: 18.5,
          },
          {
            x: 34,
            y: 18.5,
          },
          {
            x: 35,
            y: 18.4,
          },
          {
            x: 36,
            y: 18.4,
          },
          {
            x: 37,
            y: 18.4,
          },
          {
            x: 38,
            y: 18.4,
          },
          {
            x: 39,
            y: 18.4,
          },
          {
            x: 40,
            y: 18.4,
          },
          {
            x: 41,
            y: 18.4,
          },
          {
            x: 42,
            y: 18.4,
          },
          {
            x: 43,
            y: 18.4,
          },
          {
            x: 44,
            y: 18.5,
          },
          {
            x: 45,
            y: 18.5,
          },
          {
            x: 46,
            y: 18.5,
          },
          {
            x: 47,
            y: 18.5,
          },
          {
            x: 48,
            y: 18.5,
          },
          {
            x: 49,
            y: 18.5,
          },
          {
            x: 50,
            y: 18.6,
          },
          {
            x: 51,
            y: 18.6,
          },
          {
            x: 52,
            y: 18.6,
          },
          {
            x: 53,
            y: 18.6,
          },
          {
            x: 54,
            y: 18.7,
          },
          {
            x: 55,
            y: 18.7,
          },
          {
            x: 56,
            y: 18.7,
          },
          {
            x: 57,
            y: 18.7,
          },
          {
            x: 58,
            y: 18.8,
          },
          {
            x: 59,
            y: 18.8,
          },
          {
            x: 60,
            y: 18.8,
          },
          {
            x: 61,
            y: 18.86,
          },
          {
            x: 62,
            y: 18.89,
          },
          {
            x: 63,
            y: 18.91,
          },
          {
            x: 64,
            y: 18.95,
          },
          {
            x: 65,
            y: 18.98,
          },
          {
            x: 66,
            y: 19.01,
          },
          {
            x: 67,
            y: 19.04,
          },
          {
            x: 68,
            y: 19.08,
          },
          {
            x: 69,
            y: 19.11,
          },
          {
            x: 70,
            y: 19.15,
          },
          {
            x: 71,
            y: 19.18,
          },
          {
            x: 72,
            y: 19.22,
          },
          {
            x: 73,
            y: 19.26,
          },
          {
            x: 74,
            y: 19.3,
          },
          {
            x: 75,
            y: 19.35,
          },
          {
            x: 76,
            y: 19.39,
          },
          {
            x: 77,
            y: 19.44,
          },
          {
            x: 78,
            y: 19.48,
          },
          {
            x: 79,
            y: 19.53,
          },
          {
            x: 80,
            y: 19.58,
          },
          {
            x: 81,
            y: 19.63,
          },
          {
            x: 82,
            y: 19.68,
          },
          {
            x: 83,
            y: 19.73,
          },
          {
            x: 84,
            y: 19.79,
          },
          {
            x: 85,
            y: 19.84,
          },
          {
            x: 86,
            y: 19.9,
          },
          {
            x: 87,
            y: 19.96,
          },
          {
            x: 88,
            y: 20.02,
          },
          {
            x: 89,
            y: 20.09,
          },
          {
            x: 90,
            y: 20.15,
          },
          {
            x: 91,
            y: 20.21,
          },
          {
            x: 92,
            y: 20.28,
          },
          {
            x: 93,
            y: 20.35,
          },
          {
            x: 94,
            y: 20.42,
          },
          {
            x: 95,
            y: 20.49,
          },
          {
            x: 96,
            y: 20.56,
          },
          {
            x: 97,
            y: 20.63,
          },
          {
            x: 98,
            y: 20.71,
          },
          {
            x: 99,
            y: 20.78,
          },
          {
            x: 100,
            y: 20.86,
          },
          {
            x: 101,
            y: 20.94,
          },
          {
            x: 102,
            y: 21.02,
          },
          {
            x: 103,
            y: 21.1,
          },
          {
            x: 104,
            y: 21.18,
          },
          {
            x: 105,
            y: 21.26,
          },
          {
            x: 106,
            y: 21.35,
          },
          {
            x: 107,
            y: 21.43,
          },
          {
            x: 108,
            y: 21.51,
          },
          {
            x: 109,
            y: 21.6,
          },
          {
            x: 110,
            y: 21.68,
          },
          {
            x: 111,
            y: 21.77,
          },
          {
            x: 112,
            y: 21.86,
          },
          {
            x: 113,
            y: 21.94,
          },
          {
            x: 114,
            y: 22.03,
          },
          {
            x: 115,
            y: 22.12,
          },
          {
            x: 116,
            y: 22.21,
          },
          {
            x: 117,
            y: 22.3,
          },
          {
            x: 118,
            y: 22.39,
          },
          {
            x: 119,
            y: 22.48,
          },
          {
            x: 120,
            y: 22.57,
          },
          {
            x: 121,
            y: 22.66,
          },
          {
            x: 122,
            y: 22.75,
          },
          {
            x: 123,
            y: 22.85,
          },
          {
            x: 124,
            y: 22.94,
          },
          {
            x: 125,
            y: 23.04,
          },
          {
            x: 126,
            y: 23.13,
          },
          {
            x: 127,
            y: 23.23,
          },
          {
            x: 128,
            y: 23.33,
          },
          {
            x: 129,
            y: 23.43,
          },
          {
            x: 130,
            y: 23.52,
          },
          {
            x: 131,
            y: 23.62,
          },
          {
            x: 132,
            y: 23.73,
          },
          {
            x: 133,
            y: 23.82,
          },
          {
            x: 134,
            y: 23.93,
          },
          {
            x: 135,
            y: 24.03,
          },
          {
            x: 136,
            y: 24.13,
          },
          {
            x: 137,
            y: 24.23,
          },
          {
            x: 138,
            y: 24.34,
          },
          {
            x: 139,
            y: 24.44,
          },
          {
            x: 140,
            y: 24.55,
          },
          {
            x: 141,
            y: 24.65,
          },
          {
            x: 142,
            y: 24.76,
          },
          {
            x: 143,
            y: 24.86,
          },
          {
            x: 144,
            y: 24.97,
          },
          {
            x: 145,
            y: 25.07,
          },
          {
            x: 146,
            y: 25.18,
          },
          {
            x: 147,
            y: 25.28,
          },
          {
            x: 148,
            y: 25.39,
          },
          {
            x: 149,
            y: 25.49,
          },
          {
            x: 150,
            y: 25.6,
          },
          {
            x: 151,
            y: 25.7,
          },
          {
            x: 152,
            y: 25.8,
          },
          {
            x: 153,
            y: 25.91,
          },
          {
            x: 154,
            y: 26.01,
          },
          {
            x: 155,
            y: 26.11,
          },
          {
            x: 156,
            y: 26.21,
          },
          {
            x: 157,
            y: 26.31,
          },
          {
            x: 158,
            y: 26.41,
          },
          {
            x: 159,
            y: 26.5,
          },
          {
            x: 160,
            y: 26.6,
          },
          {
            x: 161,
            y: 26.69,
          },
          {
            x: 162,
            y: 26.79,
          },
          {
            x: 163,
            y: 26.88,
          },
          {
            x: 164,
            y: 26.97,
          },
          {
            x: 165,
            y: 27.06,
          },
          {
            x: 166,
            y: 27.15,
          },
          {
            x: 167,
            y: 27.23,
          },
          {
            x: 168,
            y: 27.32,
          },
          {
            x: 169,
            y: 27.41,
          },
          {
            x: 170,
            y: 27.49,
          },
          {
            x: 171,
            y: 27.57,
          },
          {
            x: 172,
            y: 27.65,
          },
          {
            x: 173,
            y: 27.73,
          },
          {
            x: 174,
            y: 27.8,
          },
          {
            x: 175,
            y: 27.88,
          },
          {
            x: 176,
            y: 27.95,
          },
          {
            x: 177,
            y: 28.02,
          },
          {
            x: 178,
            y: 28.09,
          },
          {
            x: 179,
            y: 28.16,
          },
          {
            x: 180,
            y: 28.22,
          },
          {
            x: 181,
            y: 28.29,
          },
          {
            x: 182,
            y: 28.35,
          },
          {
            x: 183,
            y: 28.41,
          },
          {
            x: 184,
            y: 28.47,
          },
          {
            x: 185,
            y: 28.52,
          },
          {
            x: 186,
            y: 28.58,
          },
          {
            x: 187,
            y: 28.63,
          },
          {
            x: 188,
            y: 28.68,
          },
          {
            x: 189,
            y: 28.73,
          },
          {
            x: 190,
            y: 28.78,
          },
          {
            x: 191,
            y: 28.83,
          },
          {
            x: 192,
            y: 28.87,
          },
          {
            x: 193,
            y: 28.91,
          },
          {
            x: 194,
            y: 28.96,
          },
          {
            x: 195,
            y: 29,
          },
          {
            x: 196,
            y: 29.03,
          },
          {
            x: 197,
            y: 29.07,
          },
          {
            x: 198,
            y: 29.11,
          },
          {
            x: 199,
            y: 29.14,
          },
          {
            x: 200,
            y: 29.17,
          },
          {
            x: 201,
            y: 29.2,
          },
          {
            x: 202,
            y: 29.23,
          },
          {
            x: 203,
            y: 29.26,
          },
          {
            x: 204,
            y: 29.28,
          },
          {
            x: 205,
            y: 29.31,
          },
          {
            x: 206,
            y: 29.33,
          },
          {
            x: 207,
            y: 29.36,
          },
          {
            x: 208,
            y: 29.38,
          },
          {
            x: 209,
            y: 29.4,
          },
          {
            x: 210,
            y: 29.42,
          },
          {
            x: 211,
            y: 29.44,
          },
          {
            x: 212,
            y: 29.45,
          },
          {
            x: 213,
            y: 29.47,
          },
          {
            x: 214,
            y: 29.49,
          },
          {
            x: 215,
            y: 29.5,
          },
          {
            x: 216,
            y: 29.52,
          },
          {
            x: 217,
            y: 29.54,
          },
          {
            x: 218,
            y: 29.55,
          },
          {
            x: 219,
            y: 29.56,
          },
          {
            x: 220,
            y: 29.58,
          },
          {
            x: 221,
            y: 29.59,
          },
          {
            x: 222,
            y: 29.6,
          },
          {
            x: 223,
            y: 29.61,
          },
          {
            x: 224,
            y: 29.63,
          },
          {
            x: 225,
            y: 29.64,
          },
          {
            x: 226,
            y: 29.65,
          },
          {
            x: 227,
            y: 29.66,
          },
          {
            x: 228,
            y: 29.67,
          },
        ],
      },
      // 1
      {
        color: "#ED9300",
        name: "1",
        data: [
          {
            x: 0,
            y: 14.6,
          },
          {
            x: 1,
            y: 16,
          },
          {
            x: 2,
            y: 17.3,
          },
          {
            x: 3,
            y: 17.9,
          },
          {
            x: 4,
            y: 18.3,
          },
          {
            x: 5,
            y: 18.4,
          },
          {
            x: 6,
            y: 18.5,
          },
          {
            x: 7,
            y: 18.5,
          },
          {
            x: 8,
            y: 18.4,
          },
          {
            x: 9,
            y: 18.3,
          },
          {
            x: 10,
            y: 18.2,
          },
          {
            x: 11,
            y: 18,
          },
          {
            x: 12,
            y: 17.9,
          },
          {
            x: 13,
            y: 17.7,
          },
          {
            x: 14,
            y: 17.6,
          },
          {
            x: 15,
            y: 17.5,
          },
          {
            x: 16,
            y: 17.4,
          },
          {
            x: 17,
            y: 17.3,
          },
          {
            x: 18,
            y: 17.2,
          },
          {
            x: 19,
            y: 17.1,
          },
          {
            x: 20,
            y: 17,
          },
          {
            x: 21,
            y: 17,
          },
          {
            x: 22,
            y: 16.9,
          },
          {
            x: 23,
            y: 16.9,
          },
          {
            x: 24,
            y: 17.1,
          },
          {
            x: 25,
            y: 17.1,
          },
          {
            x: 26,
            y: 17,
          },
          {
            x: 27,
            y: 17,
          },
          {
            x: 28,
            y: 17,
          },
          {
            x: 29,
            y: 17,
          },
          {
            x: 30,
            y: 16.9,
          },
          {
            x: 31,
            y: 16.9,
          },
          {
            x: 32,
            y: 16.9,
          },
          {
            x: 33,
            y: 16.9,
          },
          {
            x: 34,
            y: 16.8,
          },
          {
            x: 35,
            y: 16.8,
          },
          {
            x: 36,
            y: 16.8,
          },
          {
            x: 37,
            y: 16.8,
          },
          {
            x: 38,
            y: 16.8,
          },
          {
            x: 39,
            y: 16.8,
          },
          {
            x: 40,
            y: 16.8,
          },
          {
            x: 41,
            y: 16.8,
          },
          {
            x: 42,
            y: 16.8,
          },
          {
            x: 43,
            y: 16.8,
          },
          {
            x: 44,
            y: 16.8,
          },
          {
            x: 45,
            y: 16.8,
          },
          {
            x: 46,
            y: 16.8,
          },
          {
            x: 47,
            y: 16.8,
          },
          {
            x: 48,
            y: 16.8,
          },
          {
            x: 49,
            y: 16.8,
          },
          {
            x: 50,
            y: 16.8,
          },
          {
            x: 51,
            y: 16.8,
          },
          {
            x: 52,
            y: 16.8,
          },
          {
            x: 53,
            y: 16.8,
          },
          {
            x: 54,
            y: 16.8,
          },
          {
            x: 55,
            y: 16.8,
          },
          {
            x: 56,
            y: 16.8,
          },
          {
            x: 57,
            y: 16.9,
          },
          {
            x: 58,
            y: 16.9,
          },
          {
            x: 59,
            y: 16.9,
          },
          {
            x: 60,
            y: 16.9,
          },
          {
            x: 61,
            y: 16.87,
          },
          {
            x: 62,
            y: 16.88,
          },
          {
            x: 63,
            y: 16.89,
          },
          {
            x: 64,
            y: 16.9,
          },
          {
            x: 65,
            y: 16.91,
          },
          {
            x: 66,
            y: 16.92,
          },
          {
            x: 67,
            y: 16.94,
          },
          {
            x: 68,
            y: 16.95,
          },
          {
            x: 69,
            y: 16.96,
          },
          {
            x: 70,
            y: 16.98,
          },
          {
            x: 71,
            y: 17,
          },
          {
            x: 72,
            y: 17.01,
          },
          {
            x: 73,
            y: 17.03,
          },
          {
            x: 74,
            y: 17.05,
          },
          {
            x: 75,
            y: 17.07,
          },
          {
            x: 76,
            y: 17.09,
          },
          {
            x: 77,
            y: 17.11,
          },
          {
            x: 78,
            y: 17.13,
          },
          {
            x: 79,
            y: 17.15,
          },
          {
            x: 80,
            y: 17.18,
          },
          {
            x: 81,
            y: 17.2,
          },
          {
            x: 82,
            y: 17.23,
          },
          {
            x: 83,
            y: 17.26,
          },
          {
            x: 84,
            y: 17.29,
          },
          {
            x: 85,
            y: 17.32,
          },
          {
            x: 86,
            y: 17.35,
          },
          {
            x: 87,
            y: 17.38,
          },
          {
            x: 88,
            y: 17.42,
          },
          {
            x: 89,
            y: 17.45,
          },
          {
            x: 90,
            y: 17.49,
          },
          {
            x: 91,
            y: 17.53,
          },
          {
            x: 92,
            y: 17.56,
          },
          {
            x: 93,
            y: 17.6,
          },
          {
            x: 94,
            y: 17.64,
          },
          {
            x: 95,
            y: 17.69,
          },
          {
            x: 96,
            y: 17.73,
          },
          {
            x: 97,
            y: 17.77,
          },
          {
            x: 98,
            y: 17.82,
          },
          {
            x: 99,
            y: 17.87,
          },
          {
            x: 100,
            y: 17.91,
          },
          {
            x: 101,
            y: 17.96,
          },
          {
            x: 102,
            y: 18.01,
          },
          {
            x: 103,
            y: 18.06,
          },
          {
            x: 104,
            y: 18.11,
          },
          {
            x: 105,
            y: 18.17,
          },
          {
            x: 106,
            y: 18.22,
          },
          {
            x: 107,
            y: 18.27,
          },
          {
            x: 108,
            y: 18.33,
          },
          {
            x: 109,
            y: 18.38,
          },
          {
            x: 110,
            y: 18.44,
          },
          {
            x: 111,
            y: 18.49,
          },
          {
            x: 112,
            y: 18.55,
          },
          {
            x: 113,
            y: 18.61,
          },
          {
            x: 114,
            y: 18.67,
          },
          {
            x: 115,
            y: 18.73,
          },
          {
            x: 116,
            y: 18.79,
          },
          {
            x: 117,
            y: 18.85,
          },
          {
            x: 118,
            y: 18.91,
          },
          {
            x: 119,
            y: 18.97,
          },
          {
            x: 120,
            y: 19.03,
          },
          {
            x: 121,
            y: 19.1,
          },
          {
            x: 122,
            y: 19.16,
          },
          {
            x: 123,
            y: 19.23,
          },
          {
            x: 124,
            y: 19.29,
          },
          {
            x: 125,
            y: 19.36,
          },
          {
            x: 126,
            y: 19.43,
          },
          {
            x: 127,
            y: 19.5,
          },
          {
            x: 128,
            y: 19.57,
          },
          {
            x: 129,
            y: 19.64,
          },
          {
            x: 130,
            y: 19.71,
          },
          {
            x: 131,
            y: 19.79,
          },
          {
            x: 132,
            y: 19.86,
          },
          {
            x: 133,
            y: 19.93,
          },
          {
            x: 134,
            y: 20.01,
          },
          {
            x: 135,
            y: 20.09,
          },
          {
            x: 136,
            y: 20.16,
          },
          {
            x: 137,
            y: 20.24,
          },
          {
            x: 138,
            y: 20.32,
          },
          {
            x: 139,
            y: 20.4,
          },
          {
            x: 140,
            y: 20.48,
          },
          {
            x: 141,
            y: 20.56,
          },
          {
            x: 142,
            y: 20.64,
          },
          {
            x: 143,
            y: 20.72,
          },
          {
            x: 144,
            y: 20.81,
          },
          {
            x: 145,
            y: 20.89,
          },
          {
            x: 146,
            y: 20.97,
          },
          {
            x: 147,
            y: 21.05,
          },
          {
            x: 148,
            y: 21.14,
          },
          {
            x: 149,
            y: 21.22,
          },
          {
            x: 150,
            y: 21.3,
          },
          {
            x: 151,
            y: 21.39,
          },
          {
            x: 152,
            y: 21.47,
          },
          {
            x: 153,
            y: 21.55,
          },
          {
            x: 154,
            y: 21.64,
          },
          {
            x: 155,
            y: 21.72,
          },
          {
            x: 156,
            y: 21.8,
          },
          {
            x: 157,
            y: 21.88,
          },
          {
            x: 158,
            y: 21.96,
          },
          {
            x: 159,
            y: 22.04,
          },
          {
            x: 160,
            y: 22.12,
          },
          {
            x: 161,
            y: 22.2,
          },
          {
            x: 162,
            y: 22.28,
          },
          {
            x: 163,
            y: 22.36,
          },
          {
            x: 164,
            y: 22.43,
          },
          {
            x: 165,
            y: 22.51,
          },
          {
            x: 166,
            y: 22.58,
          },
          {
            x: 167,
            y: 22.66,
          },
          {
            x: 168,
            y: 22.73,
          },
          {
            x: 169,
            y: 22.8,
          },
          {
            x: 170,
            y: 22.87,
          },
          {
            x: 171,
            y: 22.94,
          },
          {
            x: 172,
            y: 23.01,
          },
          {
            x: 173,
            y: 23.08,
          },
          {
            x: 174,
            y: 23.14,
          },
          {
            x: 175,
            y: 23.21,
          },
          {
            x: 176,
            y: 23.27,
          },
          {
            x: 177,
            y: 23.34,
          },
          {
            x: 178,
            y: 23.4,
          },
          {
            x: 179,
            y: 23.46,
          },
          {
            x: 180,
            y: 23.51,
          },
          {
            x: 181,
            y: 23.57,
          },
          {
            x: 182,
            y: 23.63,
          },
          {
            x: 183,
            y: 23.68,
          },
          {
            x: 184,
            y: 23.73,
          },
          {
            x: 185,
            y: 23.78,
          },
          {
            x: 186,
            y: 23.83,
          },
          {
            x: 187,
            y: 23.88,
          },
          {
            x: 188,
            y: 23.93,
          },
          {
            x: 189,
            y: 23.97,
          },
          {
            x: 190,
            y: 24.02,
          },
          {
            x: 191,
            y: 24.06,
          },
          {
            x: 192,
            y: 24.1,
          },
          {
            x: 193,
            y: 24.14,
          },
          {
            x: 194,
            y: 24.18,
          },
          {
            x: 195,
            y: 24.22,
          },
          {
            x: 196,
            y: 24.25,
          },
          {
            x: 197,
            y: 24.29,
          },
          {
            x: 198,
            y: 24.32,
          },
          {
            x: 199,
            y: 24.36,
          },
          {
            x: 200,
            y: 24.39,
          },
          {
            x: 201,
            y: 24.42,
          },
          {
            x: 202,
            y: 24.45,
          },
          {
            x: 203,
            y: 24.48,
          },
          {
            x: 204,
            y: 24.5,
          },
          {
            x: 205,
            y: 24.53,
          },
          {
            x: 206,
            y: 24.55,
          },
          {
            x: 207,
            y: 24.58,
          },
          {
            x: 208,
            y: 24.6,
          },
          {
            x: 209,
            y: 24.63,
          },
          {
            x: 210,
            y: 24.65,
          },
          {
            x: 211,
            y: 24.67,
          },
          {
            x: 212,
            y: 24.69,
          },
          {
            x: 213,
            y: 24.71,
          },
          {
            x: 214,
            y: 24.73,
          },
          {
            x: 215,
            y: 24.75,
          },
          {
            x: 216,
            y: 24.77,
          },
          {
            x: 217,
            y: 24.79,
          },
          {
            x: 218,
            y: 24.8,
          },
          {
            x: 219,
            y: 24.82,
          },
          {
            x: 220,
            y: 24.84,
          },
          {
            x: 221,
            y: 24.86,
          },
          {
            x: 222,
            y: 24.87,
          },
          {
            x: 223,
            y: 24.89,
          },
          {
            x: 224,
            y: 24.91,
          },
          {
            x: 225,
            y: 24.92,
          },
          {
            x: 226,
            y: 24.93,
          },
          {
            x: 227,
            y: 24.95,
          },
          {
            x: 228,
            y: 24.96,
          },
        ],
      },
      // 0
      {
        color: "#77EA04",
        name: "0",
        data: [
          {
            x: 0,
            y: 13.3,
          },
          {
            x: 1,
            y: 14.6,
          },
          {
            x: 2,
            y: 15.8,
          },
          {
            x: 3,
            y: 16.4,
          },
          {
            x: 4,
            y: 16.7,
          },
          {
            x: 5,
            y: 16.8,
          },
          {
            x: 6,
            y: 16.9,
          },
          {
            x: 7,
            y: 16.9,
          },
          {
            x: 8,
            y: 16.8,
          },
          {
            x: 9,
            y: 16.7,
          },
          {
            x: 10,
            y: 16.6,
          },
          {
            x: 11,
            y: 16.5,
          },
          {
            x: 12,
            y: 16.4,
          },
          {
            x: 13,
            y: 16.2,
          },
          {
            x: 14,
            y: 16.1,
          },
          {
            x: 15,
            y: 16,
          },
          {
            x: 16,
            y: 15.9,
          },
          {
            x: 17,
            y: 15.8,
          },
          {
            x: 18,
            y: 15.7,
          },
          {
            x: 19,
            y: 15.7,
          },
          {
            x: 20,
            y: 15.6,
          },
          {
            x: 21,
            y: 15.5,
          },
          {
            x: 22,
            y: 15.5,
          },
          {
            x: 23,
            y: 15.4,
          },
          {
            x: 24,
            y: 15.7,
          },
          {
            x: 25,
            y: 15.7,
          },
          {
            x: 26,
            y: 15.6,
          },
          {
            x: 27,
            y: 15.6,
          },
          {
            x: 28,
            y: 15.6,
          },
          {
            x: 29,
            y: 15.6,
          },
          {
            x: 30,
            y: 15.5,
          },
          {
            x: 31,
            y: 15.5,
          },
          {
            x: 32,
            y: 15.5,
          },
          {
            x: 33,
            y: 15.5,
          },
          {
            x: 34,
            y: 15.4,
          },
          {
            x: 35,
            y: 15.4,
          },
          {
            x: 36,
            y: 15.4,
          },
          {
            x: 37,
            y: 15.4,
          },
          {
            x: 38,
            y: 15.4,
          },
          {
            x: 39,
            y: 15.3,
          },
          {
            x: 40,
            y: 15.3,
          },
          {
            x: 41,
            y: 15.3,
          },
          {
            x: 42,
            y: 15.3,
          },
          {
            x: 43,
            y: 15.3,
          },
          {
            x: 44,
            y: 15.3,
          },
          {
            x: 45,
            y: 15.3,
          },
          {
            x: 46,
            y: 15.3,
          },
          {
            x: 47,
            y: 15.3,
          },
          {
            x: 48,
            y: 15.3,
          },
          {
            x: 49,
            y: 15.3,
          },
          {
            x: 50,
            y: 15.3,
          },
          {
            x: 51,
            y: 15.3,
          },
          {
            x: 52,
            y: 15.2,
          },
          {
            x: 53,
            y: 15.3,
          },
          {
            x: 54,
            y: 15.3,
          },
          {
            x: 55,
            y: 15.3,
          },
          {
            x: 56,
            y: 15.3,
          },
          {
            x: 57,
            y: 15.3,
          },
          {
            x: 58,
            y: 15.3,
          },
          {
            x: 59,
            y: 15.3,
          },
          {
            x: 60,
            y: 15.3,
          },
          {
            x: 61,
            y: 15.24,
          },
          {
            x: 62,
            y: 15.24,
          },
          {
            x: 63,
            y: 15.24,
          },
          {
            x: 64,
            y: 15.24,
          },
          {
            x: 65,
            y: 15.24,
          },
          {
            x: 66,
            y: 15.25,
          },
          {
            x: 67,
            y: 15.25,
          },
          {
            x: 68,
            y: 15.25,
          },
          {
            x: 69,
            y: 15.26,
          },
          {
            x: 70,
            y: 15.26,
          },
          {
            x: 71,
            y: 15.26,
          },
          {
            x: 72,
            y: 15.27,
          },
          {
            x: 73,
            y: 15.28,
          },
          {
            x: 74,
            y: 15.28,
          },
          {
            x: 75,
            y: 15.29,
          },
          {
            x: 76,
            y: 15.3,
          },
          {
            x: 77,
            y: 15.31,
          },
          {
            x: 78,
            y: 15.32,
          },
          {
            x: 79,
            y: 15.33,
          },
          {
            x: 80,
            y: 15.34,
          },
          {
            x: 81,
            y: 15.36,
          },
          {
            x: 82,
            y: 15.37,
          },
          {
            x: 83,
            y: 15.39,
          },
          {
            x: 84,
            y: 15.4,
          },
          {
            x: 85,
            y: 15.42,
          },
          {
            x: 86,
            y: 15.44,
          },
          {
            x: 87,
            y: 15.46,
          },
          {
            x: 88,
            y: 15.48,
          },
          {
            x: 89,
            y: 15.5,
          },
          {
            x: 90,
            y: 15.52,
          },
          {
            x: 91,
            y: 15.55,
          },
          {
            x: 92,
            y: 15.57,
          },
          {
            x: 93,
            y: 15.6,
          },
          {
            x: 94,
            y: 15.63,
          },
          {
            x: 95,
            y: 15.65,
          },
          {
            x: 96,
            y: 15.68,
          },
          {
            x: 97,
            y: 15.71,
          },
          {
            x: 98,
            y: 15.74,
          },
          {
            x: 99,
            y: 15.77,
          },
          {
            x: 100,
            y: 15.81,
          },
          {
            x: 101,
            y: 15.84,
          },
          {
            x: 102,
            y: 15.87,
          },
          {
            x: 103,
            y: 15.91,
          },
          {
            x: 104,
            y: 15.95,
          },
          {
            x: 105,
            y: 15.98,
          },
          {
            x: 106,
            y: 16.02,
          },
          {
            x: 107,
            y: 16.06,
          },
          {
            x: 108,
            y: 16.1,
          },
          {
            x: 109,
            y: 16.14,
          },
          {
            x: 110,
            y: 16.18,
          },
          {
            x: 111,
            y: 16.22,
          },
          {
            x: 112,
            y: 16.26,
          },
          {
            x: 113,
            y: 16.3,
          },
          {
            x: 114,
            y: 16.34,
          },
          {
            x: 115,
            y: 16.39,
          },
          {
            x: 116,
            y: 16.43,
          },
          {
            x: 117,
            y: 16.48,
          },
          {
            x: 118,
            y: 16.52,
          },
          {
            x: 119,
            y: 16.57,
          },
          {
            x: 120,
            y: 16.61,
          },
          {
            x: 121,
            y: 16.66,
          },
          {
            x: 122,
            y: 16.71,
          },
          {
            x: 123,
            y: 16.76,
          },
          {
            x: 124,
            y: 16.81,
          },
          {
            x: 125,
            y: 16.86,
          },
          {
            x: 126,
            y: 16.91,
          },
          {
            x: 127,
            y: 16.97,
          },
          {
            x: 128,
            y: 17.02,
          },
          {
            x: 129,
            y: 17.08,
          },
          {
            x: 130,
            y: 17.13,
          },
          {
            x: 131,
            y: 17.19,
          },
          {
            x: 132,
            y: 17.25,
          },
          {
            x: 133,
            y: 17.3,
          },
          {
            x: 134,
            y: 17.36,
          },
          {
            x: 135,
            y: 17.42,
          },
          {
            x: 136,
            y: 17.48,
          },
          {
            x: 137,
            y: 17.55,
          },
          {
            x: 138,
            y: 17.61,
          },
          {
            x: 139,
            y: 17.67,
          },
          {
            x: 140,
            y: 17.74,
          },
          {
            x: 141,
            y: 17.8,
          },
          {
            x: 142,
            y: 17.86,
          },
          {
            x: 143,
            y: 17.93,
          },
          {
            x: 144,
            y: 18,
          },
          {
            x: 145,
            y: 18.06,
          },
          {
            x: 146,
            y: 18.13,
          },
          {
            x: 147,
            y: 18.2,
          },
          {
            x: 148,
            y: 18.26,
          },
          {
            x: 149,
            y: 18.33,
          },
          {
            x: 150,
            y: 18.4,
          },
          {
            x: 151,
            y: 18.47,
          },
          {
            x: 152,
            y: 18.53,
          },
          {
            x: 153,
            y: 18.6,
          },
          {
            x: 154,
            y: 18.67,
          },
          {
            x: 155,
            y: 18.73,
          },
          {
            x: 156,
            y: 18.8,
          },
          {
            x: 157,
            y: 18.87,
          },
          {
            x: 158,
            y: 18.93,
          },
          {
            x: 159,
            y: 19,
          },
          {
            x: 160,
            y: 19.06,
          },
          {
            x: 161,
            y: 19.13,
          },
          {
            x: 162,
            y: 19.19,
          },
          {
            x: 163,
            y: 19.26,
          },
          {
            x: 164,
            y: 19.32,
          },
          {
            x: 165,
            y: 19.38,
          },
          {
            x: 166,
            y: 19.44,
          },
          {
            x: 167,
            y: 19.5,
          },
          {
            x: 168,
            y: 19.57,
          },
          {
            x: 169,
            y: 19.62,
          },
          {
            x: 170,
            y: 19.68,
          },
          {
            x: 171,
            y: 19.74,
          },
          {
            x: 172,
            y: 19.8,
          },
          {
            x: 173,
            y: 19.85,
          },
          {
            x: 174,
            y: 19.91,
          },
          {
            x: 175,
            y: 19.96,
          },
          {
            x: 176,
            y: 20.01,
          },
          {
            x: 177,
            y: 20.07,
          },
          {
            x: 178,
            y: 20.11,
          },
          {
            x: 179,
            y: 20.16,
          },
          {
            x: 180,
            y: 20.21,
          },
          {
            x: 181,
            y: 20.26,
          },
          {
            x: 182,
            y: 20.3,
          },
          {
            x: 183,
            y: 20.35,
          },
          {
            x: 184,
            y: 20.39,
          },
          {
            x: 185,
            y: 20.44,
          },
          {
            x: 186,
            y: 20.48,
          },
          {
            x: 187,
            y: 20.52,
          },
          {
            x: 188,
            y: 20.56,
          },
          {
            x: 189,
            y: 20.59,
          },
          {
            x: 190,
            y: 20.63,
          },
          {
            x: 191,
            y: 20.67,
          },
          {
            x: 192,
            y: 20.7,
          },
          {
            x: 193,
            y: 20.73,
          },
          {
            x: 194,
            y: 20.77,
          },
          {
            x: 195,
            y: 20.8,
          },
          {
            x: 196,
            y: 20.83,
          },
          {
            x: 197,
            y: 20.86,
          },
          {
            x: 198,
            y: 20.89,
          },
          {
            x: 199,
            y: 20.91,
          },
          {
            x: 200,
            y: 20.94,
          },
          {
            x: 201,
            y: 20.97,
          },
          {
            x: 202,
            y: 20.99,
          },
          {
            x: 203,
            y: 21.01,
          },
          {
            x: 204,
            y: 21.04,
          },
          {
            x: 205,
            y: 21.06,
          },
          {
            x: 206,
            y: 21.08,
          },
          {
            x: 207,
            y: 21.1,
          },
          {
            x: 208,
            y: 21.12,
          },
          {
            x: 209,
            y: 21.14,
          },
          {
            x: 210,
            y: 21.16,
          },
          {
            x: 211,
            y: 21.18,
          },
          {
            x: 212,
            y: 21.19,
          },
          {
            x: 213,
            y: 21.21,
          },
          {
            x: 214,
            y: 21.23,
          },
          {
            x: 215,
            y: 21.24,
          },
          {
            x: 216,
            y: 21.26,
          },
          {
            x: 217,
            y: 21.28,
          },
          {
            x: 218,
            y: 21.29,
          },
          {
            x: 219,
            y: 21.31,
          },
          {
            x: 220,
            y: 21.32,
          },
          {
            x: 221,
            y: 21.33,
          },
          {
            x: 222,
            y: 21.35,
          },
          {
            x: 223,
            y: 21.36,
          },
          {
            x: 224,
            y: 21.38,
          },
          {
            x: 225,
            y: 21.39,
          },
          {
            x: 226,
            y: 21.4,
          },
          {
            x: 227,
            y: 21.41,
          },
          {
            x: 228,
            y: 21.43,
          },
        ],
      },
      // -1
      {
        color: "#000000",
        name: "-1",
        data: [
          {
            x: 0,
            y: 12.2,
          },
          {
            x: 1,
            y: 13.2,
          },
          {
            x: 2,
            y: 14.3,
          },
          {
            x: 3,
            y: 14.9,
          },
          {
            x: 4,
            y: 15.2,
          },
          {
            x: 5,
            y: 15.4,
          },
          {
            x: 6,
            y: 15.5,
          },
          {
            x: 7,
            y: 15.5,
          },
          {
            x: 8,
            y: 15.4,
          },
          {
            x: 9,
            y: 15.3,
          },
          {
            x: 10,
            y: 15.2,
          },
          {
            x: 11,
            y: 15.1,
          },
          {
            x: 12,
            y: 15,
          },
          {
            x: 13,
            y: 14.9,
          },
          {
            x: 14,
            y: 14.8,
          },
          {
            x: 15,
            y: 14.7,
          },
          {
            x: 16,
            y: 14.6,
          },
          {
            x: 17,
            y: 14.5,
          },
          {
            x: 18,
            y: 14.4,
          },
          {
            x: 19,
            y: 14.4,
          },
          {
            x: 20,
            y: 14.3,
          },
          {
            x: 21,
            y: 14.3,
          },
          {
            x: 22,
            y: 14.2,
          },
          {
            x: 23,
            y: 14.2,
          },
          {
            x: 24,
            y: 14.4,
          },
          {
            x: 25,
            y: 14.4,
          },
          {
            x: 26,
            y: 14.4,
          },
          {
            x: 27,
            y: 14.4,
          },
          {
            x: 28,
            y: 14.3,
          },
          {
            x: 29,
            y: 14.3,
          },
          {
            x: 30,
            y: 14.3,
          },
          {
            x: 31,
            y: 14.3,
          },
          {
            x: 32,
            y: 14.3,
          },
          {
            x: 33,
            y: 14.2,
          },
          {
            x: 34,
            y: 14.2,
          },
          {
            x: 35,
            y: 14.2,
          },
          {
            x: 36,
            y: 14.2,
          },
          {
            x: 37,
            y: 14.1,
          },
          {
            x: 38,
            y: 14.1,
          },
          {
            x: 39,
            y: 14.1,
          },
          {
            x: 40,
            y: 14.1,
          },
          {
            x: 41,
            y: 14.1,
          },
          {
            x: 42,
            y: 14,
          },
          {
            x: 43,
            y: 14,
          },
          {
            x: 44,
            y: 14,
          },
          {
            x: 45,
            y: 14,
          },
          {
            x: 46,
            y: 14,
          },
          {
            x: 47,
            y: 14,
          },
          {
            x: 48,
            y: 14,
          },
          {
            x: 49,
            y: 13.9,
          },
          {
            x: 50,
            y: 13.9,
          },
          {
            x: 51,
            y: 13.9,
          },
          {
            x: 52,
            y: 13.9,
          },
          {
            x: 53,
            y: 13.9,
          },
          {
            x: 54,
            y: 13.9,
          },
          {
            x: 55,
            y: 13.9,
          },
          {
            x: 56,
            y: 13.9,
          },
          {
            x: 57,
            y: 13.9,
          },
          {
            x: 58,
            y: 13.9,
          },
          {
            x: 59,
            y: 13.9,
          },
          {
            x: 60,
            y: 13.9,
          },
          {
            x: 61,
            y: 13.89,
          },
          {
            x: 62,
            y: 13.88,
          },
          {
            x: 63,
            y: 13.88,
          },
          {
            x: 64,
            y: 13.88,
          },
          {
            x: 65,
            y: 13.87,
          },
          {
            x: 66,
            y: 13.87,
          },
          {
            x: 67,
            y: 13.87,
          },
          {
            x: 68,
            y: 13.86,
          },
          {
            x: 69,
            y: 13.86,
          },
          {
            x: 70,
            y: 13.86,
          },
          {
            x: 71,
            y: 13.86,
          },
          {
            x: 72,
            y: 13.86,
          },
          {
            x: 73,
            y: 13.86,
          },
          {
            x: 74,
            y: 13.87,
          },
          {
            x: 75,
            y: 13.87,
          },
          {
            x: 76,
            y: 13.87,
          },
          {
            x: 77,
            y: 13.87,
          },
          {
            x: 78,
            y: 13.88,
          },
          {
            x: 79,
            y: 13.88,
          },
          {
            x: 80,
            y: 13.89,
          },
          {
            x: 81,
            y: 13.9,
          },
          {
            x: 82,
            y: 13.91,
          },
          {
            x: 83,
            y: 13.92,
          },
          {
            x: 84,
            y: 13.93,
          },
          {
            x: 85,
            y: 13.94,
          },
          {
            x: 86,
            y: 13.95,
          },
          {
            x: 87,
            y: 13.96,
          },
          {
            x: 88,
            y: 13.98,
          },
          {
            x: 89,
            y: 13.99,
          },
          {
            x: 90,
            y: 14.01,
          },
          {
            x: 91,
            y: 14.02,
          },
          {
            x: 92,
            y: 14.04,
          },
          {
            x: 93,
            y: 14.06,
          },
          {
            x: 94,
            y: 14.08,
          },
          {
            x: 95,
            y: 14.1,
          },
          {
            x: 96,
            y: 14.12,
          },
          {
            x: 97,
            y: 14.14,
          },
          {
            x: 98,
            y: 14.16,
          },
          {
            x: 99,
            y: 14.19,
          },
          {
            x: 100,
            y: 14.21,
          },
          {
            x: 101,
            y: 14.24,
          },
          {
            x: 102,
            y: 14.26,
          },
          {
            x: 103,
            y: 14.29,
          },
          {
            x: 104,
            y: 14.32,
          },
          {
            x: 105,
            y: 14.35,
          },
          {
            x: 106,
            y: 14.38,
          },
          {
            x: 107,
            y: 14.4,
          },
          {
            x: 108,
            y: 14.43,
          },
          {
            x: 109,
            y: 14.46,
          },
          {
            x: 110,
            y: 14.5,
          },
          {
            x: 111,
            y: 14.53,
          },
          {
            x: 112,
            y: 14.56,
          },
          {
            x: 113,
            y: 14.59,
          },
          {
            x: 114,
            y: 14.63,
          },
          {
            x: 115,
            y: 14.66,
          },
          {
            x: 116,
            y: 14.69,
          },
          {
            x: 117,
            y: 14.73,
          },
          {
            x: 118,
            y: 14.76,
          },
          {
            x: 119,
            y: 14.8,
          },
          {
            x: 120,
            y: 14.84,
          },
          {
            x: 121,
            y: 14.88,
          },
          {
            x: 122,
            y: 14.91,
          },
          {
            x: 123,
            y: 14.95,
          },
          {
            x: 124,
            y: 14.99,
          },
          {
            x: 125,
            y: 15.04,
          },
          {
            x: 126,
            y: 15.08,
          },
          {
            x: 127,
            y: 15.12,
          },
          {
            x: 128,
            y: 15.16,
          },
          {
            x: 129,
            y: 15.21,
          },
          {
            x: 130,
            y: 15.25,
          },
          {
            x: 131,
            y: 15.3,
          },
          {
            x: 132,
            y: 15.34,
          },
          {
            x: 133,
            y: 15.39,
          },
          {
            x: 134,
            y: 15.44,
          },
          {
            x: 135,
            y: 15.49,
          },
          {
            x: 136,
            y: 15.54,
          },
          {
            x: 137,
            y: 15.59,
          },
          {
            x: 138,
            y: 15.64,
          },
          {
            x: 139,
            y: 15.69,
          },
          {
            x: 140,
            y: 15.74,
          },
          {
            x: 141,
            y: 15.79,
          },
          {
            x: 142,
            y: 15.85,
          },
          {
            x: 143,
            y: 15.9,
          },
          {
            x: 144,
            y: 15.95,
          },
          {
            x: 145,
            y: 16.01,
          },
          {
            x: 146,
            y: 16.06,
          },
          {
            x: 147,
            y: 16.12,
          },
          {
            x: 148,
            y: 16.17,
          },
          {
            x: 149,
            y: 16.23,
          },
          {
            x: 150,
            y: 16.28,
          },
          {
            x: 151,
            y: 16.34,
          },
          {
            x: 152,
            y: 16.39,
          },
          {
            x: 153,
            y: 16.45,
          },
          {
            x: 154,
            y: 16.5,
          },
          {
            x: 155,
            y: 16.56,
          },
          {
            x: 156,
            y: 16.61,
          },
          {
            x: 157,
            y: 16.67,
          },
          {
            x: 158,
            y: 16.72,
          },
          {
            x: 159,
            y: 16.77,
          },
          {
            x: 160,
            y: 16.83,
          },
          {
            x: 161,
            y: 16.88,
          },
          {
            x: 162,
            y: 16.93,
          },
          {
            x: 163,
            y: 16.99,
          },
          {
            x: 164,
            y: 17.04,
          },
          {
            x: 165,
            y: 17.09,
          },
          {
            x: 166,
            y: 17.14,
          },
          {
            x: 167,
            y: 17.19,
          },
          {
            x: 168,
            y: 17.24,
          },
          {
            x: 169,
            y: 17.29,
          },
          {
            x: 170,
            y: 17.33,
          },
          {
            x: 171,
            y: 17.38,
          },
          {
            x: 172,
            y: 17.43,
          },
          {
            x: 173,
            y: 17.47,
          },
          {
            x: 174,
            y: 17.52,
          },
          {
            x: 175,
            y: 17.56,
          },
          {
            x: 176,
            y: 17.6,
          },
          {
            x: 177,
            y: 17.64,
          },
          {
            x: 178,
            y: 17.68,
          },
          {
            x: 179,
            y: 17.73,
          },
          {
            x: 180,
            y: 17.76,
          },
          {
            x: 181,
            y: 17.8,
          },
          {
            x: 182,
            y: 17.84,
          },
          {
            x: 183,
            y: 17.87,
          },
          {
            x: 184,
            y: 17.91,
          },
          {
            x: 185,
            y: 17.94,
          },
          {
            x: 186,
            y: 17.98,
          },
          {
            x: 187,
            y: 18.01,
          },
          {
            x: 188,
            y: 18.04,
          },
          {
            x: 189,
            y: 18.07,
          },
          {
            x: 190,
            y: 18.1,
          },
          {
            x: 191,
            y: 18.13,
          },
          {
            x: 192,
            y: 18.15,
          },
          {
            x: 193,
            y: 18.18,
          },
          {
            x: 194,
            y: 18.2,
          },
          {
            x: 195,
            y: 18.23,
          },
          {
            x: 196,
            y: 18.25,
          },
          {
            x: 197,
            y: 18.27,
          },
          {
            x: 198,
            y: 18.3,
          },
          {
            x: 199,
            y: 18.32,
          },
          {
            x: 200,
            y: 18.34,
          },
          {
            x: 201,
            y: 18.36,
          },
          {
            x: 202,
            y: 18.38,
          },
          {
            x: 203,
            y: 18.39,
          },
          {
            x: 204,
            y: 18.41,
          },
          {
            x: 205,
            y: 18.43,
          },
          {
            x: 206,
            y: 18.44,
          },
          {
            x: 207,
            y: 18.46,
          },
          {
            x: 208,
            y: 18.47,
          },
          {
            x: 209,
            y: 18.49,
          },
          {
            x: 210,
            y: 18.5,
          },
          {
            x: 211,
            y: 18.51,
          },
          {
            x: 212,
            y: 18.52,
          },
          {
            x: 213,
            y: 18.54,
          },
          {
            x: 214,
            y: 18.55,
          },
          {
            x: 215,
            y: 18.56,
          },
          {
            x: 216,
            y: 18.57,
          },
          {
            x: 217,
            y: 18.58,
          },
          {
            x: 218,
            y: 18.59,
          },
          {
            x: 219,
            y: 18.6,
          },
          {
            x: 220,
            y: 18.61,
          },
          {
            x: 221,
            y: 18.62,
          },
          {
            x: 222,
            y: 18.63,
          },
          {
            x: 223,
            y: 18.64,
          },
          {
            x: 224,
            y: 18.65,
          },
          {
            x: 225,
            y: 18.66,
          },
          {
            x: 226,
            y: 18.66,
          },
          {
            x: 227,
            y: 18.67,
          },
          {
            x: 228,
            y: 18.68,
          },
        ],
      },
      // -2
      {
        color: "#F30037",
        name: "-2",
        data: [
          {
            x: 0,
            y: 11.1,
          },
          {
            x: 1,
            y: 12,
          },
          {
            x: 2,
            y: 13,
          },
          {
            x: 3,
            y: 13.6,
          },
          {
            x: 4,
            y: 13.9,
          },
          {
            x: 5,
            y: 14.1,
          },
          {
            x: 6,
            y: 14.1,
          },
          {
            x: 7,
            y: 14.2,
          },
          {
            x: 8,
            y: 14.1,
          },
          {
            x: 9,
            y: 14.1,
          },
          {
            x: 10,
            y: 14,
          },
          {
            x: 11,
            y: 13.9,
          },
          {
            x: 12,
            y: 13.8,
          },
          {
            x: 13,
            y: 13.7,
          },
          {
            x: 14,
            y: 13.6,
          },
          {
            x: 15,
            y: 13.5,
          },
          {
            x: 16,
            y: 13.5,
          },
          {
            x: 17,
            y: 13.4,
          },
          {
            x: 18,
            y: 13.3,
          },
          {
            x: 19,
            y: 13.3,
          },
          {
            x: 20,
            y: 13.2,
          },
          {
            x: 21,
            y: 13.2,
          },
          {
            x: 22,
            y: 13.1,
          },
          {
            x: 23,
            y: 13.1,
          },
          {
            x: 24,
            y: 13.3,
          },
          {
            x: 25,
            y: 13.3,
          },
          {
            x: 26,
            y: 13.3,
          },
          {
            x: 27,
            y: 13.3,
          },
          {
            x: 28,
            y: 13.3,
          },
          {
            x: 29,
            y: 13.2,
          },
          {
            x: 30,
            y: 13.2,
          },
          {
            x: 31,
            y: 13.2,
          },
          {
            x: 32,
            y: 13.2,
          },
          {
            x: 33,
            y: 13.1,
          },
          {
            x: 34,
            y: 13.1,
          },
          {
            x: 35,
            y: 13.1,
          },
          {
            x: 36,
            y: 13.1,
          },
          {
            x: 37,
            y: 13.1,
          },
          {
            x: 38,
            y: 13,
          },
          {
            x: 39,
            y: 13,
          },
          {
            x: 40,
            y: 13,
          },
          {
            x: 41,
            y: 13,
          },
          {
            x: 42,
            y: 12.9,
          },
          {
            x: 43,
            y: 12.9,
          },
          {
            x: 44,
            y: 12.9,
          },
          {
            x: 45,
            y: 12.9,
          },
          {
            x: 46,
            y: 12.9,
          },
          {
            x: 47,
            y: 12.8,
          },
          {
            x: 48,
            y: 12.8,
          },
          {
            x: 49,
            y: 12.8,
          },
          {
            x: 50,
            y: 12.8,
          },
          {
            x: 51,
            y: 12.8,
          },
          {
            x: 52,
            y: 12.8,
          },
          {
            x: 53,
            y: 12.7,
          },
          {
            x: 54,
            y: 12.7,
          },
          {
            x: 55,
            y: 12.7,
          },
          {
            x: 56,
            y: 12.7,
          },
          {
            x: 57,
            y: 12.7,
          },
          {
            x: 58,
            y: 12.7,
          },
          {
            x: 59,
            y: 12.7,
          },
          {
            x: 60,
            y: 12.7,
          },
          {
            x: 61,
            y: 12.75,
          },
          {
            x: 62,
            y: 12.74,
          },
          {
            x: 63,
            y: 12.73,
          },
          {
            x: 64,
            y: 12.73,
          },
          {
            x: 65,
            y: 12.72,
          },
          {
            x: 66,
            y: 12.72,
          },
          {
            x: 67,
            y: 12.71,
          },
          {
            x: 68,
            y: 12.71,
          },
          {
            x: 69,
            y: 12.71,
          },
          {
            x: 70,
            y: 12.7,
          },
          {
            x: 71,
            y: 12.7,
          },
          {
            x: 72,
            y: 12.7,
          },
          {
            x: 73,
            y: 12.7,
          },
          {
            x: 74,
            y: 12.7,
          },
          {
            x: 75,
            y: 12.7,
          },
          {
            x: 76,
            y: 12.7,
          },
          {
            x: 77,
            y: 12.7,
          },
          {
            x: 78,
            y: 12.7,
          },
          {
            x: 79,
            y: 12.71,
          },
          {
            x: 80,
            y: 12.71,
          },
          {
            x: 81,
            y: 12.72,
          },
          {
            x: 82,
            y: 12.72,
          },
          {
            x: 83,
            y: 12.73,
          },
          {
            x: 84,
            y: 12.73,
          },
          {
            x: 85,
            y: 12.74,
          },
          {
            x: 86,
            y: 12.75,
          },
          {
            x: 87,
            y: 12.76,
          },
          {
            x: 88,
            y: 12.77,
          },
          {
            x: 89,
            y: 12.78,
          },
          {
            x: 90,
            y: 12.79,
          },
          {
            x: 91,
            y: 12.81,
          },
          {
            x: 92,
            y: 12.82,
          },
          {
            x: 93,
            y: 12.84,
          },
          {
            x: 94,
            y: 12.85,
          },
          {
            x: 95,
            y: 12.87,
          },
          {
            x: 96,
            y: 12.88,
          },
          {
            x: 97,
            y: 12.9,
          },
          {
            x: 98,
            y: 12.92,
          },
          {
            x: 99,
            y: 12.94,
          },
          {
            x: 100,
            y: 12.96,
          },
          {
            x: 101,
            y: 12.98,
          },
          {
            x: 102,
            y: 13,
          },
          {
            x: 103,
            y: 13.02,
          },
          {
            x: 104,
            y: 13.04,
          },
          {
            x: 105,
            y: 13.07,
          },
          {
            x: 106,
            y: 13.09,
          },
          {
            x: 107,
            y: 13.12,
          },
          {
            x: 108,
            y: 13.14,
          },
          {
            x: 109,
            y: 13.16,
          },
          {
            x: 110,
            y: 13.19,
          },
          {
            x: 111,
            y: 13.22,
          },
          {
            x: 112,
            y: 13.24,
          },
          {
            x: 113,
            y: 13.27,
          },
          {
            x: 114,
            y: 13.3,
          },
          {
            x: 115,
            y: 13.32,
          },
          {
            x: 116,
            y: 13.35,
          },
          {
            x: 117,
            y: 13.38,
          },
          {
            x: 118,
            y: 13.41,
          },
          {
            x: 119,
            y: 13.44,
          },
          {
            x: 120,
            y: 13.47,
          },
          {
            x: 121,
            y: 13.5,
          },
          {
            x: 122,
            y: 13.53,
          },
          {
            x: 123,
            y: 13.56,
          },
          {
            x: 124,
            y: 13.6,
          },
          {
            x: 125,
            y: 13.63,
          },
          {
            x: 126,
            y: 13.67,
          },
          {
            x: 127,
            y: 13.7,
          },
          {
            x: 128,
            y: 13.74,
          },
          {
            x: 129,
            y: 13.77,
          },
          {
            x: 130,
            y: 13.81,
          },
          {
            x: 131,
            y: 13.85,
          },
          {
            x: 132,
            y: 13.88,
          },
          {
            x: 133,
            y: 13.93,
          },
          {
            x: 134,
            y: 13.96,
          },
          {
            x: 135,
            y: 14,
          },
          {
            x: 136,
            y: 14.04,
          },
          {
            x: 137,
            y: 14.09,
          },
          {
            x: 138,
            y: 14.13,
          },
          {
            x: 139,
            y: 14.17,
          },
          {
            x: 140,
            y: 14.21,
          },
          {
            x: 141,
            y: 14.26,
          },
          {
            x: 142,
            y: 14.3,
          },
          {
            x: 143,
            y: 14.35,
          },
          {
            x: 144,
            y: 14.39,
          },
          {
            x: 145,
            y: 14.44,
          },
          {
            x: 146,
            y: 14.48,
          },
          {
            x: 147,
            y: 14.53,
          },
          {
            x: 148,
            y: 14.57,
          },
          {
            x: 149,
            y: 14.62,
          },
          {
            x: 150,
            y: 14.66,
          },
          {
            x: 151,
            y: 14.71,
          },
          {
            x: 152,
            y: 14.76,
          },
          {
            x: 153,
            y: 14.8,
          },
          {
            x: 154,
            y: 14.85,
          },
          {
            x: 155,
            y: 14.89,
          },
          {
            x: 156,
            y: 14.94,
          },
          {
            x: 157,
            y: 14.98,
          },
          {
            x: 158,
            y: 15.03,
          },
          {
            x: 159,
            y: 15.07,
          },
          {
            x: 160,
            y: 15.11,
          },
          {
            x: 161,
            y: 15.16,
          },
          {
            x: 162,
            y: 15.2,
          },
          {
            x: 163,
            y: 15.24,
          },
          {
            x: 164,
            y: 15.29,
          },
          {
            x: 165,
            y: 15.33,
          },
          {
            x: 166,
            y: 15.37,
          },
          {
            x: 167,
            y: 15.41,
          },
          {
            x: 168,
            y: 15.45,
          },
          {
            x: 169,
            y: 15.49,
          },
          {
            x: 170,
            y: 15.53,
          },
          {
            x: 171,
            y: 15.56,
          },
          {
            x: 172,
            y: 15.6,
          },
          {
            x: 173,
            y: 15.64,
          },
          {
            x: 174,
            y: 15.67,
          },
          {
            x: 175,
            y: 15.71,
          },
          {
            x: 176,
            y: 15.74,
          },
          {
            x: 177,
            y: 15.78,
          },
          {
            x: 178,
            y: 15.81,
          },
          {
            x: 179,
            y: 15.84,
          },
          {
            x: 180,
            y: 15.87,
          },
          {
            x: 181,
            y: 15.9,
          },
          {
            x: 182,
            y: 15.93,
          },
          {
            x: 183,
            y: 15.96,
          },
          {
            x: 184,
            y: 15.98,
          },
          {
            x: 185,
            y: 16.01,
          },
          {
            x: 186,
            y: 16.04,
          },
          {
            x: 187,
            y: 16.06,
          },
          {
            x: 188,
            y: 16.09,
          },
          {
            x: 189,
            y: 16.11,
          },
          {
            x: 190,
            y: 16.13,
          },
          {
            x: 191,
            y: 16.15,
          },
          {
            x: 192,
            y: 16.17,
          },
          {
            x: 193,
            y: 16.19,
          },
          {
            x: 194,
            y: 16.21,
          },
          {
            x: 195,
            y: 16.23,
          },
          {
            x: 196,
            y: 16.25,
          },
          {
            x: 197,
            y: 16.26,
          },
          {
            x: 198,
            y: 16.28,
          },
          {
            x: 199,
            y: 16.29,
          },
          {
            x: 200,
            y: 16.3,
          },
          {
            x: 201,
            y: 16.32,
          },
          {
            x: 202,
            y: 16.33,
          },
          {
            x: 203,
            y: 16.34,
          },
          {
            x: 204,
            y: 16.35,
          },
          {
            x: 205,
            y: 16.36,
          },
          {
            x: 206,
            y: 16.38,
          },
          {
            x: 207,
            y: 16.38,
          },
          {
            x: 208,
            y: 16.39,
          },
          {
            x: 209,
            y: 16.4,
          },
          {
            x: 210,
            y: 16.41,
          },
          {
            x: 211,
            y: 16.42,
          },
          {
            x: 212,
            y: 16.42,
          },
          {
            x: 213,
            y: 16.43,
          },
          {
            x: 214,
            y: 16.44,
          },
          {
            x: 215,
            y: 16.44,
          },
          {
            x: 216,
            y: 16.45,
          },
          {
            x: 217,
            y: 16.45,
          },
          {
            x: 218,
            y: 16.46,
          },
          {
            x: 219,
            y: 16.46,
          },
          {
            x: 220,
            y: 16.47,
          },
          {
            x: 221,
            y: 16.47,
          },
          {
            x: 222,
            y: 16.48,
          },
          {
            x: 223,
            y: 16.48,
          },
          {
            x: 224,
            y: 16.48,
          },
          {
            x: 225,
            y: 16.49,
          },
          {
            x: 226,
            y: 16.49,
          },
          {
            x: 227,
            y: 16.49,
          },
          {
            x: 228,
            y: 16.5,
          },
        ],
      },
    ],
    imcForAgeBoy: [
      // 2
      {
        color: "#8260BB",
        name: "2",
        data: [
          {
            x: 0,
            y: 16.3,
          },
          {
            x: 1,
            y: 17.8,
          },
          {
            x: 2,
            y: 19.4,
          },
          {
            x: 3,
            y: 20,
          },
          {
            x: 4,
            y: 20.3,
          },
          {
            x: 5,
            y: 20.5,
          },
          {
            x: 6,
            y: 20.5,
          },
          {
            x: 7,
            y: 20.5,
          },
          {
            x: 8,
            y: 20.4,
          },
          {
            x: 9,
            y: 20.3,
          },
          {
            x: 10,
            y: 20.1,
          },
          {
            x: 11,
            y: 20,
          },
          {
            x: 12,
            y: 19.8,
          },
          {
            x: 13,
            y: 19.7,
          },
          {
            x: 14,
            y: 19.5,
          },
          {
            x: 15,
            y: 19.4,
          },
          {
            x: 16,
            y: 19.3,
          },
          {
            x: 17,
            y: 19.1,
          },
          {
            x: 18,
            y: 19,
          },
          {
            x: 19,
            y: 18.9,
          },
          {
            x: 20,
            y: 18.8,
          },
          {
            x: 21,
            y: 18.7,
          },
          {
            x: 22,
            y: 18.7,
          },
          {
            x: 23,
            y: 18.6,
          },
          {
            x: 24,
            y: 18.9,
          },
          {
            x: 25,
            y: 18.8,
          },
          {
            x: 26,
            y: 18.8,
          },
          {
            x: 27,
            y: 18.7,
          },
          {
            x: 28,
            y: 18.7,
          },
          {
            x: 29,
            y: 18.6,
          },
          {
            x: 30,
            y: 18.6,
          },
          {
            x: 31,
            y: 18.5,
          },
          {
            x: 32,
            y: 18.5,
          },
          {
            x: 33,
            y: 18.5,
          },
          {
            x: 34,
            y: 18.4,
          },
          {
            x: 35,
            y: 18.4,
          },
          {
            x: 36,
            y: 18.4,
          },
          {
            x: 37,
            y: 18.3,
          },
          {
            x: 38,
            y: 18.3,
          },
          {
            x: 39,
            y: 18.3,
          },
          {
            x: 40,
            y: 18.2,
          },
          {
            x: 41,
            y: 18.2,
          },
          {
            x: 42,
            y: 18.2,
          },
          {
            x: 43,
            y: 18.2,
          },
          {
            x: 44,
            y: 18.2,
          },
          {
            x: 45,
            y: 18.2,
          },
          {
            x: 46,
            y: 18.2,
          },
          {
            x: 47,
            y: 18.2,
          },
          {
            x: 48,
            y: 18.2,
          },
          {
            x: 49,
            y: 18.2,
          },
          {
            x: 50,
            y: 18.2,
          },
          {
            x: 51,
            y: 18.2,
          },
          {
            x: 52,
            y: 18.2,
          },
          {
            x: 53,
            y: 18.2,
          },
          {
            x: 54,
            y: 18.2,
          },
          {
            x: 55,
            y: 18.2,
          },
          {
            x: 56,
            y: 18.2,
          },
          {
            x: 57,
            y: 18.2,
          },
          {
            x: 58,
            y: 18.3,
          },
          {
            x: 59,
            y: 18.3,
          },
          {
            x: 60,
            y: 18.3,
          },
          {
            x: 61,
            y: 18.26,
          },
          {
            x: 62,
            y: 18.27,
          },
          {
            x: 63,
            y: 18.29,
          },
          {
            x: 64,
            y: 18.31,
          },
          {
            x: 65,
            y: 18.33,
          },
          {
            x: 66,
            y: 18.35,
          },
          {
            x: 67,
            y: 18.37,
          },
          {
            x: 68,
            y: 18.4,
          },
          {
            x: 69,
            y: 18.43,
          },
          {
            x: 70,
            y: 18.46,
          },
          {
            x: 71,
            y: 18.49,
          },
          {
            x: 72,
            y: 18.52,
          },
          {
            x: 73,
            y: 18.55,
          },
          {
            x: 74,
            y: 18.59,
          },
          {
            x: 75,
            y: 18.63,
          },
          {
            x: 76,
            y: 18.66,
          },
          {
            x: 77,
            y: 18.7,
          },
          {
            x: 78,
            y: 18.75,
          },
          {
            x: 79,
            y: 18.79,
          },
          {
            x: 80,
            y: 18.83,
          },
          {
            x: 81,
            y: 18.88,
          },
          {
            x: 82,
            y: 18.92,
          },
          {
            x: 83,
            y: 18.97,
          },
          {
            x: 84,
            y: 19.02,
          },
          {
            x: 85,
            y: 19.07,
          },
          {
            x: 86,
            y: 19.12,
          },
          {
            x: 87,
            y: 19.17,
          },
          {
            x: 88,
            y: 19.22,
          },
          {
            x: 89,
            y: 19.27,
          },
          {
            x: 90,
            y: 19.33,
          },
          {
            x: 91,
            y: 19.38,
          },
          {
            x: 92,
            y: 19.44,
          },
          {
            x: 93,
            y: 19.5,
          },
          {
            x: 94,
            y: 19.55,
          },
          {
            x: 95,
            y: 19.61,
          },
          {
            x: 96,
            y: 19.68,
          },
          {
            x: 97,
            y: 19.74,
          },
          {
            x: 98,
            y: 19.8,
          },
          {
            x: 99,
            y: 19.86,
          },
          {
            x: 100,
            y: 19.93,
          },
          {
            x: 101,
            y: 19.99,
          },
          {
            x: 102,
            y: 20.06,
          },
          {
            x: 103,
            y: 20.12,
          },
          {
            x: 104,
            y: 20.19,
          },
          {
            x: 105,
            y: 20.26,
          },
          {
            x: 106,
            y: 20.33,
          },
          {
            x: 107,
            y: 20.4,
          },
          {
            x: 108,
            y: 20.47,
          },
          {
            x: 109,
            y: 20.54,
          },
          {
            x: 110,
            y: 20.61,
          },
          {
            x: 111,
            y: 20.69,
          },
          {
            x: 112,
            y: 20.76,
          },
          {
            x: 113,
            y: 20.84,
          },
          {
            x: 114,
            y: 20.92,
          },
          {
            x: 115,
            y: 20.99,
          },
          {
            x: 116,
            y: 21.07,
          },
          {
            x: 117,
            y: 21.15,
          },
          {
            x: 118,
            y: 21.23,
          },
          {
            x: 119,
            y: 21.32,
          },
          {
            x: 120,
            y: 21.4,
          },
          {
            x: 121,
            y: 21.48,
          },
          {
            x: 122,
            y: 21.57,
          },
          {
            x: 123,
            y: 21.65,
          },
          {
            x: 124,
            y: 21.74,
          },
          {
            x: 125,
            y: 21.83,
          },
          {
            x: 126,
            y: 21.91,
          },
          {
            x: 127,
            y: 22,
          },
          {
            x: 128,
            y: 22.09,
          },
          {
            x: 129,
            y: 22.18,
          },
          {
            x: 130,
            y: 22.27,
          },
          {
            x: 131,
            y: 22.36,
          },
          {
            x: 132,
            y: 22.45,
          },
          {
            x: 133,
            y: 22.54,
          },
          {
            x: 134,
            y: 22.64,
          },
          {
            x: 135,
            y: 22.73,
          },
          {
            x: 136,
            y: 22.82,
          },
          {
            x: 137,
            y: 22.91,
          },
          {
            x: 138,
            y: 23.01,
          },
          {
            x: 139,
            y: 23.1,
          },
          {
            x: 140,
            y: 23.2,
          },
          {
            x: 141,
            y: 23.29,
          },
          {
            x: 142,
            y: 23.39,
          },
          {
            x: 143,
            y: 23.48,
          },
          {
            x: 144,
            y: 23.58,
          },
          {
            x: 145,
            y: 23.68,
          },
          {
            x: 146,
            y: 23.77,
          },
          {
            x: 147,
            y: 23.87,
          },
          {
            x: 148,
            y: 23.97,
          },
          {
            x: 149,
            y: 24.07,
          },
          {
            x: 150,
            y: 24.16,
          },
          {
            x: 151,
            y: 24.26,
          },
          {
            x: 152,
            y: 24.36,
          },
          {
            x: 153,
            y: 24.46,
          },
          {
            x: 154,
            y: 24.56,
          },
          {
            x: 155,
            y: 24.66,
          },
          {
            x: 156,
            y: 24.76,
          },
          {
            x: 157,
            y: 24.86,
          },
          {
            x: 158,
            y: 24.95,
          },
          {
            x: 159,
            y: 25.05,
          },
          {
            x: 160,
            y: 25.15,
          },
          {
            x: 161,
            y: 25.25,
          },
          {
            x: 162,
            y: 25.35,
          },
          {
            x: 163,
            y: 25.44,
          },
          {
            x: 164,
            y: 25.54,
          },
          {
            x: 165,
            y: 25.64,
          },
          {
            x: 166,
            y: 25.73,
          },
          {
            x: 167,
            y: 25.82,
          },
          {
            x: 168,
            y: 25.92,
          },
          {
            x: 169,
            y: 26.01,
          },
          {
            x: 170,
            y: 26.1,
          },
          {
            x: 171,
            y: 26.19,
          },
          {
            x: 172,
            y: 26.28,
          },
          {
            x: 173,
            y: 26.37,
          },
          {
            x: 174,
            y: 26.46,
          },
          {
            x: 175,
            y: 26.55,
          },
          {
            x: 176,
            y: 26.64,
          },
          {
            x: 177,
            y: 26.72,
          },
          {
            x: 178,
            y: 26.8,
          },
          {
            x: 179,
            y: 26.89,
          },
          {
            x: 180,
            y: 26.97,
          },
          {
            x: 181,
            y: 27.05,
          },
          {
            x: 182,
            y: 27.13,
          },
          {
            x: 183,
            y: 27.21,
          },
          {
            x: 184,
            y: 27.29,
          },
          {
            x: 185,
            y: 27.36,
          },
          {
            x: 186,
            y: 27.44,
          },
          {
            x: 187,
            y: 27.52,
          },
          {
            x: 188,
            y: 27.59,
          },
          {
            x: 189,
            y: 27.66,
          },
          {
            x: 190,
            y: 27.73,
          },
          {
            x: 191,
            y: 27.8,
          },
          {
            x: 192,
            y: 27.88,
          },
          {
            x: 193,
            y: 27.94,
          },
          {
            x: 194,
            y: 28.01,
          },
          {
            x: 195,
            y: 28.08,
          },
          {
            x: 196,
            y: 28.14,
          },
          {
            x: 197,
            y: 28.21,
          },
          {
            x: 198,
            y: 28.27,
          },
          {
            x: 199,
            y: 28.33,
          },
          {
            x: 200,
            y: 28.39,
          },
          {
            x: 201,
            y: 28.46,
          },
          {
            x: 202,
            y: 28.52,
          },
          {
            x: 203,
            y: 28.57,
          },
          {
            x: 204,
            y: 28.63,
          },
          {
            x: 205,
            y: 28.69,
          },
          {
            x: 206,
            y: 28.74,
          },
          {
            x: 207,
            y: 28.8,
          },
          {
            x: 208,
            y: 28.85,
          },
          {
            x: 209,
            y: 28.9,
          },
          {
            x: 210,
            y: 28.95,
          },
          {
            x: 211,
            y: 29,
          },
          {
            x: 212,
            y: 29.05,
          },
          {
            x: 213,
            y: 29.1,
          },
          {
            x: 214,
            y: 29.15,
          },
          {
            x: 215,
            y: 29.2,
          },
          {
            x: 216,
            y: 29.24,
          },
          {
            x: 217,
            y: 29.29,
          },
          {
            x: 218,
            y: 29.33,
          },
          {
            x: 219,
            y: 29.37,
          },
          {
            x: 220,
            y: 29.41,
          },
          {
            x: 221,
            y: 29.45,
          },
          {
            x: 222,
            y: 29.5,
          },
          {
            x: 223,
            y: 29.53,
          },
          {
            x: 224,
            y: 29.57,
          },
          {
            x: 225,
            y: 29.61,
          },
          {
            x: 226,
            y: 29.65,
          },
          {
            x: 227,
            y: 29.68,
          },
          {
            x: 228,
            y: 29.72,
          },
        ],
      },
      // 1
      {
        color: "#ED9300",
        name: "1",
        data: [
          {
            x: 0,
            y: 14.8,
          },
          {
            x: 1,
            y: 16.3,
          },
          {
            x: 2,
            y: 17.8,
          },
          {
            x: 3,
            y: 18.4,
          },
          {
            x: 4,
            y: 18.7,
          },
          {
            x: 5,
            y: 18.8,
          },
          {
            x: 6,
            y: 18.8,
          },
          {
            x: 7,
            y: 18.8,
          },
          {
            x: 8,
            y: 18.7,
          },
          {
            x: 9,
            y: 18.6,
          },
          {
            x: 10,
            y: 18.5,
          },
          {
            x: 11,
            y: 18.4,
          },
          {
            x: 12,
            y: 18.2,
          },
          {
            x: 13,
            y: 18.1,
          },
          {
            x: 14,
            y: 18,
          },
          {
            x: 15,
            y: 17.8,
          },
          {
            x: 16,
            y: 17.7,
          },
          {
            x: 17,
            y: 17.6,
          },
          {
            x: 18,
            y: 17.5,
          },
          {
            x: 19,
            y: 17.4,
          },
          {
            x: 20,
            y: 17.3,
          },
          {
            x: 21,
            y: 17.2,
          },
          {
            x: 22,
            y: 17.2,
          },
          {
            x: 23,
            y: 17.1,
          },
          {
            x: 24,
            y: 17.3,
          },
          {
            x: 25,
            y: 17.3,
          },
          {
            x: 26,
            y: 17.3,
          },
          {
            x: 27,
            y: 17.2,
          },
          {
            x: 28,
            y: 17.2,
          },
          {
            x: 29,
            y: 17.1,
          },
          {
            x: 30,
            y: 17.1,
          },
          {
            x: 31,
            y: 17.1,
          },
          {
            x: 32,
            y: 17,
          },
          {
            x: 33,
            y: 17,
          },
          {
            x: 34,
            y: 17,
          },
          {
            x: 35,
            y: 16.9,
          },
          {
            x: 36,
            y: 16.9,
          },
          {
            x: 37,
            y: 16.9,
          },
          {
            x: 38,
            y: 16.8,
          },
          {
            x: 39,
            y: 16.8,
          },
          {
            x: 40,
            y: 16.8,
          },
          {
            x: 41,
            y: 16.8,
          },
          {
            x: 42,
            y: 16.8,
          },
          {
            x: 43,
            y: 16.7,
          },
          {
            x: 44,
            y: 16.7,
          },
          {
            x: 45,
            y: 16.7,
          },
          {
            x: 46,
            y: 16.7,
          },
          {
            x: 47,
            y: 16.7,
          },
          {
            x: 48,
            y: 16.7,
          },
          {
            x: 49,
            y: 16.7,
          },
          {
            x: 50,
            y: 16.7,
          },
          {
            x: 51,
            y: 16.6,
          },
          {
            x: 52,
            y: 16.6,
          },
          {
            x: 53,
            y: 16.6,
          },
          {
            x: 54,
            y: 16.6,
          },
          {
            x: 55,
            y: 16.6,
          },
          {
            x: 56,
            y: 16.6,
          },
          {
            x: 57,
            y: 16.6,
          },
          {
            x: 58,
            y: 16.6,
          },
          {
            x: 59,
            y: 16.6,
          },
          {
            x: 60,
            y: 16.6,
          },
          {
            x: 61,
            y: 16.64,
          },
          {
            x: 62,
            y: 16.65,
          },
          {
            x: 63,
            y: 16.65,
          },
          {
            x: 64,
            y: 16.66,
          },
          {
            x: 65,
            y: 16.67,
          },
          {
            x: 66,
            y: 16.68,
          },
          {
            x: 67,
            y: 16.69,
          },
          {
            x: 68,
            y: 16.7,
          },
          {
            x: 69,
            y: 16.71,
          },
          {
            x: 70,
            y: 16.73,
          },
          {
            x: 71,
            y: 16.74,
          },
          {
            x: 72,
            y: 16.76,
          },
          {
            x: 73,
            y: 16.78,
          },
          {
            x: 74,
            y: 16.8,
          },
          {
            x: 75,
            y: 16.82,
          },
          {
            x: 76,
            y: 16.84,
          },
          {
            x: 77,
            y: 16.86,
          },
          {
            x: 78,
            y: 16.89,
          },
          {
            x: 79,
            y: 16.91,
          },
          {
            x: 80,
            y: 16.94,
          },
          {
            x: 81,
            y: 16.96,
          },
          {
            x: 82,
            y: 16.99,
          },
          {
            x: 83,
            y: 17.02,
          },
          {
            x: 84,
            y: 17.05,
          },
          {
            x: 85,
            y: 17.08,
          },
          {
            x: 86,
            y: 17.11,
          },
          {
            x: 87,
            y: 17.14,
          },
          {
            x: 88,
            y: 17.17,
          },
          {
            x: 89,
            y: 17.2,
          },
          {
            x: 90,
            y: 17.23,
          },
          {
            x: 91,
            y: 17.26,
          },
          {
            x: 92,
            y: 17.3,
          },
          {
            x: 93,
            y: 17.33,
          },
          {
            x: 94,
            y: 17.37,
          },
          {
            x: 95,
            y: 17.4,
          },
          {
            x: 96,
            y: 17.44,
          },
          {
            x: 97,
            y: 17.47,
          },
          {
            x: 98,
            y: 17.51,
          },
          {
            x: 99,
            y: 17.55,
          },
          {
            x: 100,
            y: 17.59,
          },
          {
            x: 101,
            y: 17.62,
          },
          {
            x: 102,
            y: 17.66,
          },
          {
            x: 103,
            y: 17.7,
          },
          {
            x: 104,
            y: 17.74,
          },
          {
            x: 105,
            y: 17.78,
          },
          {
            x: 106,
            y: 17.82,
          },
          {
            x: 107,
            y: 17.87,
          },
          {
            x: 108,
            y: 17.91,
          },
          {
            x: 109,
            y: 17.95,
          },
          {
            x: 110,
            y: 18,
          },
          {
            x: 111,
            y: 18.04,
          },
          {
            x: 112,
            y: 18.09,
          },
          {
            x: 113,
            y: 18.13,
          },
          {
            x: 114,
            y: 18.18,
          },
          {
            x: 115,
            y: 18.23,
          },
          {
            x: 116,
            y: 18.28,
          },
          {
            x: 117,
            y: 18.33,
          },
          {
            x: 118,
            y: 18.38,
          },
          {
            x: 119,
            y: 18.43,
          },
          {
            x: 120,
            y: 18.48,
          },
          {
            x: 121,
            y: 18.53,
          },
          {
            x: 122,
            y: 18.59,
          },
          {
            x: 123,
            y: 18.64,
          },
          {
            x: 124,
            y: 18.7,
          },
          {
            x: 125,
            y: 18.75,
          },
          {
            x: 126,
            y: 18.81,
          },
          {
            x: 127,
            y: 18.86,
          },
          {
            x: 128,
            y: 18.92,
          },
          {
            x: 129,
            y: 18.98,
          },
          {
            x: 130,
            y: 19.04,
          },
          {
            x: 131,
            y: 19.1,
          },
          {
            x: 132,
            y: 19.16,
          },
          {
            x: 133,
            y: 19.22,
          },
          {
            x: 134,
            y: 19.29,
          },
          {
            x: 135,
            y: 19.35,
          },
          {
            x: 136,
            y: 19.41,
          },
          {
            x: 137,
            y: 19.48,
          },
          {
            x: 138,
            y: 19.54,
          },
          {
            x: 139,
            y: 19.61,
          },
          {
            x: 140,
            y: 19.67,
          },
          {
            x: 141,
            y: 19.74,
          },
          {
            x: 142,
            y: 19.81,
          },
          {
            x: 143,
            y: 19.88,
          },
          {
            x: 144,
            y: 19.95,
          },
          {
            x: 145,
            y: 20.02,
          },
          {
            x: 146,
            y: 20.09,
          },
          {
            x: 147,
            y: 20.16,
          },
          {
            x: 148,
            y: 20.23,
          },
          {
            x: 149,
            y: 20.3,
          },
          {
            x: 150,
            y: 20.38,
          },
          {
            x: 151,
            y: 20.45,
          },
          {
            x: 152,
            y: 20.52,
          },
          {
            x: 153,
            y: 20.6,
          },
          {
            x: 154,
            y: 20.68,
          },
          {
            x: 155,
            y: 20.75,
          },
          {
            x: 156,
            y: 20.83,
          },
          {
            x: 157,
            y: 20.91,
          },
          {
            x: 158,
            y: 20.98,
          },
          {
            x: 159,
            y: 21.06,
          },
          {
            x: 160,
            y: 21.14,
          },
          {
            x: 161,
            y: 21.22,
          },
          {
            x: 162,
            y: 21.3,
          },
          {
            x: 163,
            y: 21.38,
          },
          {
            x: 164,
            y: 21.45,
          },
          {
            x: 165,
            y: 21.53,
          },
          {
            x: 166,
            y: 21.61,
          },
          {
            x: 167,
            y: 21.69,
          },
          {
            x: 168,
            y: 21.77,
          },
          {
            x: 169,
            y: 21.85,
          },
          {
            x: 170,
            y: 21.93,
          },
          {
            x: 171,
            y: 22,
          },
          {
            x: 172,
            y: 22.08,
          },
          {
            x: 173,
            y: 22.16,
          },
          {
            x: 174,
            y: 22.23,
          },
          {
            x: 175,
            y: 22.31,
          },
          {
            x: 176,
            y: 22.39,
          },
          {
            x: 177,
            y: 22.46,
          },
          {
            x: 178,
            y: 22.54,
          },
          {
            x: 179,
            y: 22.61,
          },
          {
            x: 180,
            y: 22.68,
          },
          {
            x: 181,
            y: 22.76,
          },
          {
            x: 182,
            y: 22.83,
          },
          {
            x: 183,
            y: 22.9,
          },
          {
            x: 184,
            y: 22.98,
          },
          {
            x: 185,
            y: 23.05,
          },
          {
            x: 186,
            y: 23.12,
          },
          {
            x: 187,
            y: 23.19,
          },
          {
            x: 188,
            y: 23.25,
          },
          {
            x: 189,
            y: 23.32,
          },
          {
            x: 190,
            y: 23.39,
          },
          {
            x: 191,
            y: 23.46,
          },
          {
            x: 192,
            y: 23.52,
          },
          {
            x: 193,
            y: 23.59,
          },
          {
            x: 194,
            y: 23.66,
          },
          {
            x: 195,
            y: 23.72,
          },
          {
            x: 196,
            y: 23.79,
          },
          {
            x: 197,
            y: 23.85,
          },
          {
            x: 198,
            y: 23.91,
          },
          {
            x: 199,
            y: 23.97,
          },
          {
            x: 200,
            y: 24.03,
          },
          {
            x: 201,
            y: 24.09,
          },
          {
            x: 202,
            y: 24.15,
          },
          {
            x: 203,
            y: 24.21,
          },
          {
            x: 204,
            y: 24.27,
          },
          {
            x: 205,
            y: 24.33,
          },
          {
            x: 206,
            y: 24.38,
          },
          {
            x: 207,
            y: 24.44,
          },
          {
            x: 208,
            y: 24.49,
          },
          {
            x: 209,
            y: 24.55,
          },
          {
            x: 210,
            y: 24.6,
          },
          {
            x: 211,
            y: 24.66,
          },
          {
            x: 212,
            y: 24.71,
          },
          {
            x: 213,
            y: 24.76,
          },
          {
            x: 214,
            y: 24.81,
          },
          {
            x: 215,
            y: 24.86,
          },
          {
            x: 216,
            y: 24.91,
          },
          {
            x: 217,
            y: 24.96,
          },
          {
            x: 218,
            y: 25.01,
          },
          {
            x: 219,
            y: 25.05,
          },
          {
            x: 220,
            y: 25.1,
          },
          {
            x: 221,
            y: 25.15,
          },
          {
            x: 222,
            y: 25.19,
          },
          {
            x: 223,
            y: 25.24,
          },
          {
            x: 224,
            y: 25.28,
          },
          {
            x: 225,
            y: 25.32,
          },
          {
            x: 226,
            y: 25.37,
          },
          {
            x: 227,
            y: 25.41,
          },
          {
            x: 228,
            y: 25.45,
          },
        ],
      },
      // 0
      {
        color: "#77EA04",
        name: "0",
        data: [
          {
            x: 0,
            y: 13.4,
          },
          {
            x: 1,
            y: 14.9,
          },
          {
            x: 2,
            y: 16.3,
          },
          {
            x: 3,
            y: 16.9,
          },
          {
            x: 4,
            y: 17.2,
          },
          {
            x: 5,
            y: 17.3,
          },
          {
            x: 6,
            y: 17.3,
          },
          {
            x: 7,
            y: 17.3,
          },
          {
            x: 8,
            y: 17.3,
          },
          {
            x: 9,
            y: 17.2,
          },
          {
            x: 10,
            y: 17,
          },
          {
            x: 11,
            y: 16.9,
          },
          {
            x: 12,
            y: 16.8,
          },
          {
            x: 13,
            y: 16.7,
          },
          {
            x: 14,
            y: 16.6,
          },
          {
            x: 15,
            y: 16.4,
          },
          {
            x: 16,
            y: 16.3,
          },
          {
            x: 17,
            y: 16.2,
          },
          {
            x: 18,
            y: 16.1,
          },
          {
            x: 19,
            y: 16.1,
          },
          {
            x: 20,
            y: 16,
          },
          {
            x: 21,
            y: 15.9,
          },
          {
            x: 22,
            y: 15.8,
          },
          {
            x: 23,
            y: 15.8,
          },
          {
            x: 24,
            y: 16,
          },
          {
            x: 25,
            y: 16,
          },
          {
            x: 26,
            y: 15.9,
          },
          {
            x: 27,
            y: 15.9,
          },
          {
            x: 28,
            y: 15.9,
          },
          {
            x: 29,
            y: 15.8,
          },
          {
            x: 30,
            y: 15.8,
          },
          {
            x: 31,
            y: 15.8,
          },
          {
            x: 32,
            y: 15.7,
          },
          {
            x: 33,
            y: 15.7,
          },
          {
            x: 34,
            y: 15.7,
          },
          {
            x: 35,
            y: 15.6,
          },
          {
            x: 36,
            y: 15.6,
          },
          {
            x: 37,
            y: 15.6,
          },
          {
            x: 38,
            y: 15.5,
          },
          {
            x: 39,
            y: 15.5,
          },
          {
            x: 40,
            y: 15.5,
          },
          {
            x: 41,
            y: 15.5,
          },
          {
            x: 42,
            y: 15.4,
          },
          {
            x: 43,
            y: 15.4,
          },
          {
            x: 44,
            y: 15.4,
          },
          {
            x: 45,
            y: 15.4,
          },
          {
            x: 46,
            y: 15.4,
          },
          {
            x: 47,
            y: 15.3,
          },
          {
            x: 48,
            y: 15.3,
          },
          {
            x: 49,
            y: 15.3,
          },
          {
            x: 50,
            y: 15.3,
          },
          {
            x: 51,
            y: 15.3,
          },
          {
            x: 52,
            y: 15.3,
          },
          {
            x: 53,
            y: 15.3,
          },
          {
            x: 54,
            y: 15.3,
          },
          {
            x: 55,
            y: 15.2,
          },
          {
            x: 56,
            y: 15.2,
          },
          {
            x: 57,
            y: 15.2,
          },
          {
            x: 58,
            y: 15.2,
          },
          {
            x: 59,
            y: 15.2,
          },
          {
            x: 60,
            y: 15.2,
          },
          {
            x: 61,
            y: 15.26,
          },
          {
            x: 62,
            y: 15.26,
          },
          {
            x: 63,
            y: 15.26,
          },
          {
            x: 64,
            y: 15.26,
          },
          {
            x: 65,
            y: 15.26,
          },
          {
            x: 66,
            y: 15.26,
          },
          {
            x: 67,
            y: 15.27,
          },
          {
            x: 68,
            y: 15.27,
          },
          {
            x: 69,
            y: 15.28,
          },
          {
            x: 70,
            y: 15.29,
          },
          {
            x: 71,
            y: 15.3,
          },
          {
            x: 72,
            y: 15.31,
          },
          {
            x: 73,
            y: 15.32,
          },
          {
            x: 74,
            y: 15.33,
          },
          {
            x: 75,
            y: 15.34,
          },
          {
            x: 76,
            y: 15.35,
          },
          {
            x: 77,
            y: 15.37,
          },
          {
            x: 78,
            y: 15.38,
          },
          {
            x: 79,
            y: 15.4,
          },
          {
            x: 80,
            y: 15.41,
          },
          {
            x: 81,
            y: 15.43,
          },
          {
            x: 82,
            y: 15.45,
          },
          {
            x: 83,
            y: 15.46,
          },
          {
            x: 84,
            y: 15.48,
          },
          {
            x: 85,
            y: 15.5,
          },
          {
            x: 86,
            y: 15.52,
          },
          {
            x: 87,
            y: 15.54,
          },
          {
            x: 88,
            y: 15.56,
          },
          {
            x: 89,
            y: 15.58,
          },
          {
            x: 90,
            y: 15.6,
          },
          {
            x: 91,
            y: 15.62,
          },
          {
            x: 92,
            y: 15.65,
          },
          {
            x: 93,
            y: 15.67,
          },
          {
            x: 94,
            y: 15.69,
          },
          {
            x: 95,
            y: 15.71,
          },
          {
            x: 96,
            y: 15.74,
          },
          {
            x: 97,
            y: 15.76,
          },
          {
            x: 98,
            y: 15.79,
          },
          {
            x: 99,
            y: 15.81,
          },
          {
            x: 100,
            y: 15.83,
          },
          {
            x: 101,
            y: 15.86,
          },
          {
            x: 102,
            y: 15.89,
          },
          {
            x: 103,
            y: 15.91,
          },
          {
            x: 104,
            y: 15.94,
          },
          {
            x: 105,
            y: 15.96,
          },
          {
            x: 106,
            y: 15.99,
          },
          {
            x: 107,
            y: 16.02,
          },
          {
            x: 108,
            y: 16.05,
          },
          {
            x: 109,
            y: 16.08,
          },
          {
            x: 110,
            y: 16.11,
          },
          {
            x: 111,
            y: 16.14,
          },
          {
            x: 112,
            y: 16.17,
          },
          {
            x: 113,
            y: 16.2,
          },
          {
            x: 114,
            y: 16.23,
          },
          {
            x: 115,
            y: 16.27,
          },
          {
            x: 116,
            y: 16.3,
          },
          {
            x: 117,
            y: 16.34,
          },
          {
            x: 118,
            y: 16.37,
          },
          {
            x: 119,
            y: 16.41,
          },
          {
            x: 120,
            y: 16.44,
          },
          {
            x: 121,
            y: 16.48,
          },
          {
            x: 122,
            y: 16.52,
          },
          {
            x: 123,
            y: 16.56,
          },
          {
            x: 124,
            y: 16.6,
          },
          {
            x: 125,
            y: 16.64,
          },
          {
            x: 126,
            y: 16.68,
          },
          {
            x: 127,
            y: 16.72,
          },
          {
            x: 128,
            y: 16.76,
          },
          {
            x: 129,
            y: 16.81,
          },
          {
            x: 130,
            y: 16.85,
          },
          {
            x: 131,
            y: 16.89,
          },
          {
            x: 132,
            y: 16.94,
          },
          {
            x: 133,
            y: 16.98,
          },
          {
            x: 134,
            y: 17.03,
          },
          {
            x: 135,
            y: 17.08,
          },
          {
            x: 136,
            y: 17.13,
          },
          {
            x: 137,
            y: 17.18,
          },
          {
            x: 138,
            y: 17.22,
          },
          {
            x: 139,
            y: 17.27,
          },
          {
            x: 140,
            y: 17.32,
          },
          {
            x: 141,
            y: 17.38,
          },
          {
            x: 142,
            y: 17.43,
          },
          {
            x: 143,
            y: 17.48,
          },
          {
            x: 144,
            y: 17.53,
          },
          {
            x: 145,
            y: 17.59,
          },
          {
            x: 146,
            y: 17.64,
          },
          {
            x: 147,
            y: 17.7,
          },
          {
            x: 148,
            y: 17.75,
          },
          {
            x: 149,
            y: 17.81,
          },
          {
            x: 150,
            y: 17.87,
          },
          {
            x: 151,
            y: 17.93,
          },
          {
            x: 152,
            y: 17.99,
          },
          {
            x: 153,
            y: 18.05,
          },
          {
            x: 154,
            y: 18.11,
          },
          {
            x: 155,
            y: 18.17,
          },
          {
            x: 156,
            y: 18.23,
          },
          {
            x: 157,
            y: 18.3,
          },
          {
            x: 158,
            y: 18.36,
          },
          {
            x: 159,
            y: 18.42,
          },
          {
            x: 160,
            y: 18.49,
          },
          {
            x: 161,
            y: 18.55,
          },
          {
            x: 162,
            y: 18.61,
          },
          {
            x: 163,
            y: 18.68,
          },
          {
            x: 164,
            y: 18.74,
          },
          {
            x: 165,
            y: 18.81,
          },
          {
            x: 166,
            y: 18.88,
          },
          {
            x: 167,
            y: 18.94,
          },
          {
            x: 168,
            y: 19,
          },
          {
            x: 169,
            y: 19.07,
          },
          {
            x: 170,
            y: 19.14,
          },
          {
            x: 171,
            y: 19.2,
          },
          {
            x: 172,
            y: 19.27,
          },
          {
            x: 173,
            y: 19.33,
          },
          {
            x: 174,
            y: 19.39,
          },
          {
            x: 175,
            y: 19.46,
          },
          {
            x: 176,
            y: 19.52,
          },
          {
            x: 177,
            y: 19.59,
          },
          {
            x: 178,
            y: 19.65,
          },
          {
            x: 179,
            y: 19.71,
          },
          {
            x: 180,
            y: 19.77,
          },
          {
            x: 181,
            y: 19.84,
          },
          {
            x: 182,
            y: 19.9,
          },
          {
            x: 183,
            y: 19.96,
          },
          {
            x: 184,
            y: 20.02,
          },
          {
            x: 185,
            y: 20.08,
          },
          {
            x: 186,
            y: 20.14,
          },
          {
            x: 187,
            y: 20.2,
          },
          {
            x: 188,
            y: 20.26,
          },
          {
            x: 189,
            y: 20.32,
          },
          {
            x: 190,
            y: 20.38,
          },
          {
            x: 191,
            y: 20.44,
          },
          {
            x: 192,
            y: 20.5,
          },
          {
            x: 193,
            y: 20.55,
          },
          {
            x: 194,
            y: 20.61,
          },
          {
            x: 195,
            y: 20.66,
          },
          {
            x: 196,
            y: 20.72,
          },
          {
            x: 197,
            y: 20.77,
          },
          {
            x: 198,
            y: 20.83,
          },
          {
            x: 199,
            y: 20.88,
          },
          {
            x: 200,
            y: 20.94,
          },
          {
            x: 201,
            y: 20.99,
          },
          {
            x: 202,
            y: 21.04,
          },
          {
            x: 203,
            y: 21.09,
          },
          {
            x: 204,
            y: 21.14,
          },
          {
            x: 205,
            y: 21.19,
          },
          {
            x: 206,
            y: 21.24,
          },
          {
            x: 207,
            y: 21.29,
          },
          {
            x: 208,
            y: 21.34,
          },
          {
            x: 209,
            y: 21.39,
          },
          {
            x: 210,
            y: 21.43,
          },
          {
            x: 211,
            y: 21.48,
          },
          {
            x: 212,
            y: 21.53,
          },
          {
            x: 213,
            y: 21.57,
          },
          {
            x: 214,
            y: 21.62,
          },
          {
            x: 215,
            y: 21.66,
          },
          {
            x: 216,
            y: 21.71,
          },
          {
            x: 217,
            y: 21.75,
          },
          {
            x: 218,
            y: 21.79,
          },
          {
            x: 219,
            y: 21.84,
          },
          {
            x: 220,
            y: 21.88,
          },
          {
            x: 221,
            y: 21.92,
          },
          {
            x: 222,
            y: 21.96,
          },
          {
            x: 223,
            y: 22,
          },
          {
            x: 224,
            y: 22.04,
          },
          {
            x: 225,
            y: 22.08,
          },
          {
            x: 226,
            y: 22.11,
          },
          {
            x: 227,
            y: 22.15,
          },
          {
            x: 228,
            y: 22.19,
          },
        ],
      },
      // -1
      {
        color: "#000000",
        name: "-1",
        data: [
          {
            x: 0,
            y: 12.2,
          },
          {
            x: 1,
            y: 13.6,
          },
          {
            x: 2,
            y: 15,
          },
          {
            x: 3,
            y: 15.5,
          },
          {
            x: 4,
            y: 15.8,
          },
          {
            x: 5,
            y: 15.9,
          },
          {
            x: 6,
            y: 16,
          },
          {
            x: 7,
            y: 16,
          },
          {
            x: 8,
            y: 15.9,
          },
          {
            x: 9,
            y: 15.8,
          },
          {
            x: 10,
            y: 15.7,
          },
          {
            x: 11,
            y: 15.6,
          },
          {
            x: 12,
            y: 15.5,
          },
          {
            x: 13,
            y: 15.4,
          },
          {
            x: 14,
            y: 15.3,
          },
          {
            x: 15,
            y: 15.2,
          },
          {
            x: 16,
            y: 15.1,
          },
          {
            x: 17,
            y: 15,
          },
          {
            x: 18,
            y: 14.9,
          },
          {
            x: 19,
            y: 14.9,
          },
          {
            x: 20,
            y: 14.8,
          },
          {
            x: 21,
            y: 14.7,
          },
          {
            x: 22,
            y: 14.7,
          },
          {
            x: 23,
            y: 14.6,
          },
          {
            x: 24,
            y: 14.8,
          },
          {
            x: 25,
            y: 14.8,
          },
          {
            x: 26,
            y: 14.8,
          },
          {
            x: 27,
            y: 14.7,
          },
          {
            x: 28,
            y: 14.7,
          },
          {
            x: 29,
            y: 14.7,
          },
          {
            x: 30,
            y: 14.6,
          },
          {
            x: 31,
            y: 14.6,
          },
          {
            x: 32,
            y: 14.6,
          },
          {
            x: 33,
            y: 14.5,
          },
          {
            x: 34,
            y: 14.5,
          },
          {
            x: 35,
            y: 14.5,
          },
          {
            x: 36,
            y: 14.4,
          },
          {
            x: 37,
            y: 14.4,
          },
          {
            x: 38,
            y: 14.4,
          },
          {
            x: 39,
            y: 14.3,
          },
          {
            x: 40,
            y: 14.3,
          },
          {
            x: 41,
            y: 14.3,
          },
          {
            x: 42,
            y: 14.3,
          },
          {
            x: 43,
            y: 14.2,
          },
          {
            x: 44,
            y: 14.2,
          },
          {
            x: 45,
            y: 14.2,
          },
          {
            x: 46,
            y: 14.2,
          },
          {
            x: 47,
            y: 14.2,
          },
          {
            x: 48,
            y: 14.1,
          },
          {
            x: 49,
            y: 14.1,
          },
          {
            x: 50,
            y: 14.1,
          },
          {
            x: 51,
            y: 14.1,
          },
          {
            x: 52,
            y: 14.1,
          },
          {
            x: 53,
            y: 14.1,
          },
          {
            x: 54,
            y: 14,
          },
          {
            x: 55,
            y: 14,
          },
          {
            x: 56,
            y: 14,
          },
          {
            x: 57,
            y: 14,
          },
          {
            x: 58,
            y: 14,
          },
          {
            x: 59,
            y: 14,
          },
          {
            x: 60,
            y: 14,
          },
          {
            x: 61,
            y: 14.07,
          },
          {
            x: 62,
            y: 14.07,
          },
          {
            x: 63,
            y: 14.06,
          },
          {
            x: 64,
            y: 14.06,
          },
          {
            x: 65,
            y: 14.06,
          },
          {
            x: 66,
            y: 14.06,
          },
          {
            x: 67,
            y: 14.06,
          },
          {
            x: 68,
            y: 14.06,
          },
          {
            x: 69,
            y: 14.07,
          },
          {
            x: 70,
            y: 14.07,
          },
          {
            x: 71,
            y: 14.08,
          },
          {
            x: 72,
            y: 14.08,
          },
          {
            x: 73,
            y: 14.09,
          },
          {
            x: 74,
            y: 14.1,
          },
          {
            x: 75,
            y: 14.11,
          },
          {
            x: 76,
            y: 14.12,
          },
          {
            x: 77,
            y: 14.13,
          },
          {
            x: 78,
            y: 14.14,
          },
          {
            x: 79,
            y: 14.15,
          },
          {
            x: 80,
            y: 14.16,
          },
          {
            x: 81,
            y: 14.17,
          },
          {
            x: 82,
            y: 14.18,
          },
          {
            x: 83,
            y: 14.2,
          },
          {
            x: 84,
            y: 14.21,
          },
          {
            x: 85,
            y: 14.22,
          },
          {
            x: 86,
            y: 14.24,
          },
          {
            x: 87,
            y: 14.25,
          },
          {
            x: 88,
            y: 14.27,
          },
          {
            x: 89,
            y: 14.28,
          },
          {
            x: 90,
            y: 14.29,
          },
          {
            x: 91,
            y: 14.31,
          },
          {
            x: 92,
            y: 14.33,
          },
          {
            x: 93,
            y: 14.34,
          },
          {
            x: 94,
            y: 14.36,
          },
          {
            x: 95,
            y: 14.38,
          },
          {
            x: 96,
            y: 14.39,
          },
          {
            x: 97,
            y: 14.41,
          },
          {
            x: 98,
            y: 14.43,
          },
          {
            x: 99,
            y: 14.45,
          },
          {
            x: 100,
            y: 14.47,
          },
          {
            x: 101,
            y: 14.48,
          },
          {
            x: 102,
            y: 14.5,
          },
          {
            x: 103,
            y: 14.52,
          },
          {
            x: 104,
            y: 14.54,
          },
          {
            x: 105,
            y: 14.56,
          },
          {
            x: 106,
            y: 14.58,
          },
          {
            x: 107,
            y: 14.6,
          },
          {
            x: 108,
            y: 14.62,
          },
          {
            x: 109,
            y: 14.65,
          },
          {
            x: 110,
            y: 14.67,
          },
          {
            x: 111,
            y: 14.69,
          },
          {
            x: 112,
            y: 14.71,
          },
          {
            x: 113,
            y: 14.74,
          },
          {
            x: 114,
            y: 14.76,
          },
          {
            x: 115,
            y: 14.79,
          },
          {
            x: 116,
            y: 14.81,
          },
          {
            x: 117,
            y: 14.84,
          },
          {
            x: 118,
            y: 14.87,
          },
          {
            x: 119,
            y: 14.89,
          },
          {
            x: 120,
            y: 14.92,
          },
          {
            x: 121,
            y: 14.95,
          },
          {
            x: 122,
            y: 14.98,
          },
          {
            x: 123,
            y: 15.01,
          },
          {
            x: 124,
            y: 15.04,
          },
          {
            x: 125,
            y: 15.07,
          },
          {
            x: 126,
            y: 15.11,
          },
          {
            x: 127,
            y: 15.14,
          },
          {
            x: 128,
            y: 15.17,
          },
          {
            x: 129,
            y: 15.21,
          },
          {
            x: 130,
            y: 15.24,
          },
          {
            x: 131,
            y: 15.28,
          },
          {
            x: 132,
            y: 15.31,
          },
          {
            x: 133,
            y: 15.35,
          },
          {
            x: 134,
            y: 15.38,
          },
          {
            x: 135,
            y: 15.42,
          },
          {
            x: 136,
            y: 15.46,
          },
          {
            x: 137,
            y: 15.5,
          },
          {
            x: 138,
            y: 15.54,
          },
          {
            x: 139,
            y: 15.58,
          },
          {
            x: 140,
            y: 15.62,
          },
          {
            x: 141,
            y: 15.66,
          },
          {
            x: 142,
            y: 15.7,
          },
          {
            x: 143,
            y: 15.74,
          },
          {
            x: 144,
            y: 15.79,
          },
          {
            x: 145,
            y: 15.83,
          },
          {
            x: 146,
            y: 15.88,
          },
          {
            x: 147,
            y: 15.92,
          },
          {
            x: 148,
            y: 15.97,
          },
          {
            x: 149,
            y: 16.02,
          },
          {
            x: 150,
            y: 16.06,
          },
          {
            x: 151,
            y: 16.11,
          },
          {
            x: 152,
            y: 16.16,
          },
          {
            x: 153,
            y: 16.21,
          },
          {
            x: 154,
            y: 16.26,
          },
          {
            x: 155,
            y: 16.31,
          },
          {
            x: 156,
            y: 16.36,
          },
          {
            x: 157,
            y: 16.41,
          },
          {
            x: 158,
            y: 16.47,
          },
          {
            x: 159,
            y: 16.52,
          },
          {
            x: 160,
            y: 16.57,
          },
          {
            x: 161,
            y: 16.63,
          },
          {
            x: 162,
            y: 16.68,
          },
          {
            x: 163,
            y: 16.73,
          },
          {
            x: 164,
            y: 16.79,
          },
          {
            x: 165,
            y: 16.84,
          },
          {
            x: 166,
            y: 16.89,
          },
          {
            x: 167,
            y: 16.95,
          },
          {
            x: 168,
            y: 17,
          },
          {
            x: 169,
            y: 17.06,
          },
          {
            x: 170,
            y: 17.11,
          },
          {
            x: 171,
            y: 17.17,
          },
          {
            x: 172,
            y: 17.22,
          },
          {
            x: 173,
            y: 17.27,
          },
          {
            x: 174,
            y: 17.33,
          },
          {
            x: 175,
            y: 17.38,
          },
          {
            x: 176,
            y: 17.43,
          },
          {
            x: 177,
            y: 17.49,
          },
          {
            x: 178,
            y: 17.54,
          },
          {
            x: 179,
            y: 17.59,
          },
          {
            x: 180,
            y: 17.65,
          },
          {
            x: 181,
            y: 17.7,
          },
          {
            x: 182,
            y: 17.75,
          },
          {
            x: 183,
            y: 17.8,
          },
          {
            x: 184,
            y: 17.85,
          },
          {
            x: 185,
            y: 17.9,
          },
          {
            x: 186,
            y: 17.95,
          },
          {
            x: 187,
            y: 18,
          },
          {
            x: 188,
            y: 18.05,
          },
          {
            x: 189,
            y: 18.1,
          },
          {
            x: 190,
            y: 18.15,
          },
          {
            x: 191,
            y: 18.2,
          },
          {
            x: 192,
            y: 18.25,
          },
          {
            x: 193,
            y: 18.3,
          },
          {
            x: 194,
            y: 18.34,
          },
          {
            x: 195,
            y: 18.39,
          },
          {
            x: 196,
            y: 18.43,
          },
          {
            x: 197,
            y: 18.48,
          },
          {
            x: 198,
            y: 18.52,
          },
          {
            x: 199,
            y: 18.57,
          },
          {
            x: 200,
            y: 18.61,
          },
          {
            x: 201,
            y: 18.66,
          },
          {
            x: 202,
            y: 18.7,
          },
          {
            x: 203,
            y: 18.74,
          },
          {
            x: 204,
            y: 18.78,
          },
          {
            x: 205,
            y: 18.82,
          },
          {
            x: 206,
            y: 18.86,
          },
          {
            x: 207,
            y: 18.9,
          },
          {
            x: 208,
            y: 18.94,
          },
          {
            x: 209,
            y: 18.98,
          },
          {
            x: 210,
            y: 19.02,
          },
          {
            x: 211,
            y: 19.06,
          },
          {
            x: 212,
            y: 19.1,
          },
          {
            x: 213,
            y: 19.13,
          },
          {
            x: 214,
            y: 19.17,
          },
          {
            x: 215,
            y: 19.21,
          },
          {
            x: 216,
            y: 19.24,
          },
          {
            x: 217,
            y: 19.28,
          },
          {
            x: 218,
            y: 19.31,
          },
          {
            x: 219,
            y: 19.34,
          },
          {
            x: 220,
            y: 19.38,
          },
          {
            x: 221,
            y: 19.41,
          },
          {
            x: 222,
            y: 19.44,
          },
          {
            x: 223,
            y: 19.47,
          },
          {
            x: 224,
            y: 19.5,
          },
          {
            x: 225,
            y: 19.54,
          },
          {
            x: 226,
            y: 19.56,
          },
          {
            x: 227,
            y: 19.59,
          },
          {
            x: 228,
            y: 19.62,
          },
        ],
      },
      // -2
      {
        color: "#F30037",
        name: "-2",
        data: [
          {
            x: 0,
            y: 11.1,
          },
          {
            x: 1,
            y: 12.4,
          },
          {
            x: 2,
            y: 13.7,
          },
          {
            x: 3,
            y: 14.3,
          },
          {
            x: 4,
            y: 14.5,
          },
          {
            x: 5,
            y: 14.7,
          },
          {
            x: 6,
            y: 14.7,
          },
          {
            x: 7,
            y: 14.8,
          },
          {
            x: 8,
            y: 14.7,
          },
          {
            x: 9,
            y: 14.7,
          },
          {
            x: 10,
            y: 14.6,
          },
          {
            x: 11,
            y: 14.5,
          },
          {
            x: 12,
            y: 14.4,
          },
          {
            x: 13,
            y: 14.3,
          },
          {
            x: 14,
            y: 14.2,
          },
          {
            x: 15,
            y: 14.1,
          },
          {
            x: 16,
            y: 14,
          },
          {
            x: 17,
            y: 13.9,
          },
          {
            x: 18,
            y: 13.9,
          },
          {
            x: 19,
            y: 13.8,
          },
          {
            x: 20,
            y: 13.7,
          },
          {
            x: 21,
            y: 13.7,
          },
          {
            x: 22,
            y: 13.6,
          },
          {
            x: 23,
            y: 13.6,
          },
          {
            x: 24,
            y: 13.8,
          },
          {
            x: 25,
            y: 13.8,
          },
          {
            x: 26,
            y: 13.7,
          },
          {
            x: 27,
            y: 13.7,
          },
          {
            x: 28,
            y: 13.6,
          },
          {
            x: 29,
            y: 13.6,
          },
          {
            x: 30,
            y: 13.6,
          },
          {
            x: 31,
            y: 13.5,
          },
          {
            x: 32,
            y: 13.5,
          },
          {
            x: 33,
            y: 13.5,
          },
          {
            x: 34,
            y: 13.4,
          },
          {
            x: 35,
            y: 13.4,
          },
          {
            x: 36,
            y: 13.4,
          },
          {
            x: 37,
            y: 13.3,
          },
          {
            x: 38,
            y: 13.3,
          },
          {
            x: 39,
            y: 13.3,
          },
          {
            x: 40,
            y: 13.2,
          },
          {
            x: 41,
            y: 13.2,
          },
          {
            x: 42,
            y: 13.2,
          },
          {
            x: 43,
            y: 13.2,
          },
          {
            x: 44,
            y: 13.1,
          },
          {
            x: 45,
            y: 13.1,
          },
          {
            x: 46,
            y: 13.1,
          },
          {
            x: 47,
            y: 13.1,
          },
          {
            x: 48,
            y: 13.1,
          },
          {
            x: 49,
            y: 13,
          },
          {
            x: 50,
            y: 13,
          },
          {
            x: 51,
            y: 13,
          },
          {
            x: 52,
            y: 13,
          },
          {
            x: 53,
            y: 13,
          },
          {
            x: 54,
            y: 13,
          },
          {
            x: 55,
            y: 13,
          },
          {
            x: 56,
            y: 12.9,
          },
          {
            x: 57,
            y: 12.9,
          },
          {
            x: 58,
            y: 12.9,
          },
          {
            x: 59,
            y: 12.9,
          },
          {
            x: 60,
            y: 12.9,
          },
          {
            x: 61,
            y: 13.03,
          },
          {
            x: 62,
            y: 13.03,
          },
          {
            x: 63,
            y: 13.02,
          },
          {
            x: 64,
            y: 13.02,
          },
          {
            x: 65,
            y: 13.02,
          },
          {
            x: 66,
            y: 13.02,
          },
          {
            x: 67,
            y: 13.02,
          },
          {
            x: 68,
            y: 13.02,
          },
          {
            x: 69,
            y: 13.03,
          },
          {
            x: 70,
            y: 13.03,
          },
          {
            x: 71,
            y: 13.04,
          },
          {
            x: 72,
            y: 13.04,
          },
          {
            x: 73,
            y: 13.05,
          },
          {
            x: 74,
            y: 13.05,
          },
          {
            x: 75,
            y: 13.06,
          },
          {
            x: 76,
            y: 13.07,
          },
          {
            x: 77,
            y: 13.08,
          },
          {
            x: 78,
            y: 13.09,
          },
          {
            x: 79,
            y: 13.1,
          },
          {
            x: 80,
            y: 13.11,
          },
          {
            x: 81,
            y: 13.12,
          },
          {
            x: 82,
            y: 13.13,
          },
          {
            x: 83,
            y: 13.14,
          },
          {
            x: 84,
            y: 13.15,
          },
          {
            x: 85,
            y: 13.16,
          },
          {
            x: 86,
            y: 13.17,
          },
          {
            x: 87,
            y: 13.18,
          },
          {
            x: 88,
            y: 13.2,
          },
          {
            x: 89,
            y: 13.21,
          },
          {
            x: 90,
            y: 13.22,
          },
          {
            x: 91,
            y: 13.23,
          },
          {
            x: 92,
            y: 13.25,
          },
          {
            x: 93,
            y: 13.26,
          },
          {
            x: 94,
            y: 13.27,
          },
          {
            x: 95,
            y: 13.29,
          },
          {
            x: 96,
            y: 13.3,
          },
          {
            x: 97,
            y: 13.32,
          },
          {
            x: 98,
            y: 13.33,
          },
          {
            x: 99,
            y: 13.35,
          },
          {
            x: 100,
            y: 13.36,
          },
          {
            x: 101,
            y: 13.38,
          },
          {
            x: 102,
            y: 13.39,
          },
          {
            x: 103,
            y: 13.41,
          },
          {
            x: 104,
            y: 13.42,
          },
          {
            x: 105,
            y: 13.44,
          },
          {
            x: 106,
            y: 13.46,
          },
          {
            x: 107,
            y: 13.47,
          },
          {
            x: 108,
            y: 13.49,
          },
          {
            x: 109,
            y: 13.51,
          },
          {
            x: 110,
            y: 13.53,
          },
          {
            x: 111,
            y: 13.54,
          },
          {
            x: 112,
            y: 13.56,
          },
          {
            x: 113,
            y: 13.58,
          },
          {
            x: 114,
            y: 13.6,
          },
          {
            x: 115,
            y: 13.62,
          },
          {
            x: 116,
            y: 13.64,
          },
          {
            x: 117,
            y: 13.67,
          },
          {
            x: 118,
            y: 13.69,
          },
          {
            x: 119,
            y: 13.71,
          },
          {
            x: 120,
            y: 13.73,
          },
          {
            x: 121,
            y: 13.76,
          },
          {
            x: 122,
            y: 13.78,
          },
          {
            x: 123,
            y: 13.81,
          },
          {
            x: 124,
            y: 13.83,
          },
          {
            x: 125,
            y: 13.86,
          },
          {
            x: 126,
            y: 13.89,
          },
          {
            x: 127,
            y: 13.91,
          },
          {
            x: 128,
            y: 13.94,
          },
          {
            x: 129,
            y: 13.97,
          },
          {
            x: 130,
            y: 14,
          },
          {
            x: 131,
            y: 14.03,
          },
          {
            x: 132,
            y: 14.06,
          },
          {
            x: 133,
            y: 14.09,
          },
          {
            x: 134,
            y: 14.12,
          },
          {
            x: 135,
            y: 14.15,
          },
          {
            x: 136,
            y: 14.18,
          },
          {
            x: 137,
            y: 14.21,
          },
          {
            x: 138,
            y: 14.24,
          },
          {
            x: 139,
            y: 14.28,
          },
          {
            x: 140,
            y: 14.31,
          },
          {
            x: 141,
            y: 14.35,
          },
          {
            x: 142,
            y: 14.38,
          },
          {
            x: 143,
            y: 14.42,
          },
          {
            x: 144,
            y: 14.45,
          },
          {
            x: 145,
            y: 14.49,
          },
          {
            x: 146,
            y: 14.53,
          },
          {
            x: 147,
            y: 14.57,
          },
          {
            x: 148,
            y: 14.61,
          },
          {
            x: 149,
            y: 14.64,
          },
          {
            x: 150,
            y: 14.68,
          },
          {
            x: 151,
            y: 14.72,
          },
          {
            x: 152,
            y: 14.77,
          },
          {
            x: 153,
            y: 14.81,
          },
          {
            x: 154,
            y: 14.85,
          },
          {
            x: 155,
            y: 14.89,
          },
          {
            x: 156,
            y: 14.94,
          },
          {
            x: 157,
            y: 14.98,
          },
          {
            x: 158,
            y: 15.02,
          },
          {
            x: 159,
            y: 15.07,
          },
          {
            x: 160,
            y: 15.11,
          },
          {
            x: 161,
            y: 15.16,
          },
          {
            x: 162,
            y: 15.2,
          },
          {
            x: 163,
            y: 15.25,
          },
          {
            x: 164,
            y: 15.29,
          },
          {
            x: 165,
            y: 15.34,
          },
          {
            x: 166,
            y: 15.38,
          },
          {
            x: 167,
            y: 15.43,
          },
          {
            x: 168,
            y: 15.47,
          },
          {
            x: 169,
            y: 15.52,
          },
          {
            x: 170,
            y: 15.57,
          },
          {
            x: 171,
            y: 15.61,
          },
          {
            x: 172,
            y: 15.66,
          },
          {
            x: 173,
            y: 15.7,
          },
          {
            x: 174,
            y: 15.75,
          },
          {
            x: 175,
            y: 15.79,
          },
          {
            x: 176,
            y: 15.84,
          },
          {
            x: 177,
            y: 15.88,
          },
          {
            x: 178,
            y: 15.92,
          },
          {
            x: 179,
            y: 15.97,
          },
          {
            x: 180,
            y: 16.01,
          },
          {
            x: 181,
            y: 16.05,
          },
          {
            x: 182,
            y: 16.1,
          },
          {
            x: 183,
            y: 16.14,
          },
          {
            x: 184,
            y: 16.18,
          },
          {
            x: 185,
            y: 16.22,
          },
          {
            x: 186,
            y: 16.27,
          },
          {
            x: 187,
            y: 16.31,
          },
          {
            x: 188,
            y: 16.35,
          },
          {
            x: 189,
            y: 16.39,
          },
          {
            x: 190,
            y: 16.43,
          },
          {
            x: 191,
            y: 16.47,
          },
          {
            x: 192,
            y: 16.5,
          },
          {
            x: 193,
            y: 16.54,
          },
          {
            x: 194,
            y: 16.58,
          },
          {
            x: 195,
            y: 16.62,
          },
          {
            x: 196,
            y: 16.66,
          },
          {
            x: 197,
            y: 16.69,
          },
          {
            x: 198,
            y: 16.73,
          },
          {
            x: 199,
            y: 16.76,
          },
          {
            x: 200,
            y: 16.8,
          },
          {
            x: 201,
            y: 16.83,
          },
          {
            x: 202,
            y: 16.87,
          },
          {
            x: 203,
            y: 16.9,
          },
          {
            x: 204,
            y: 16.93,
          },
          {
            x: 205,
            y: 16.96,
          },
          {
            x: 206,
            y: 17,
          },
          {
            x: 207,
            y: 17.03,
          },
          {
            x: 208,
            y: 17.06,
          },
          {
            x: 209,
            y: 17.09,
          },
          {
            x: 210,
            y: 17.12,
          },
          {
            x: 211,
            y: 17.15,
          },
          {
            x: 212,
            y: 17.18,
          },
          {
            x: 213,
            y: 17.2,
          },
          {
            x: 214,
            y: 17.23,
          },
          {
            x: 215,
            y: 17.26,
          },
          {
            x: 216,
            y: 17.28,
          },
          {
            x: 217,
            y: 17.31,
          },
          {
            x: 218,
            y: 17.34,
          },
          {
            x: 219,
            y: 17.36,
          },
          {
            x: 220,
            y: 17.38,
          },
          {
            x: 221,
            y: 17.41,
          },
          {
            x: 222,
            y: 17.43,
          },
          {
            x: 223,
            y: 17.45,
          },
          {
            x: 224,
            y: 17.47,
          },
          {
            x: 225,
            y: 17.5,
          },
          {
            x: 226,
            y: 17.52,
          },
          {
            x: 227,
            y: 17.54,
          },
          {
            x: 228,
            y: 17.55,
          },
        ],
      },
    ],
    /** IMC para la edad - Nuevo */

    /** Perímetro Cefálico para la edad - Nuevo */
    headCircunferenceForAgeGirl: [
      // 2
      {
        color: "#8260BB",
        name: "2",
        data: [
          {
            x: 0,
            y: 36.2,
          },
          {
            x: 1,
            y: 38.9,
          },
          {
            x: 2,
            y: 40.7,
          },
          {
            x: 3,
            y: 42,
          },
          {
            x: 4,
            y: 43.1,
          },
          {
            x: 5,
            y: 44,
          },
          {
            x: 6,
            y: 44.8,
          },
          {
            x: 7,
            y: 45.5,
          },
          {
            x: 8,
            y: 46,
          },
          {
            x: 9,
            y: 46.5,
          },
          {
            x: 10,
            y: 46.9,
          },
          {
            x: 11,
            y: 47.3,
          },
          {
            x: 12,
            y: 47.6,
          },
          {
            x: 13,
            y: 47.9,
          },
          {
            x: 14,
            y: 48.2,
          },
          {
            x: 15,
            y: 48.4,
          },
          {
            x: 16,
            y: 48.6,
          },
          {
            x: 17,
            y: 48.8,
          },
          {
            x: 18,
            y: 49,
          },
          {
            x: 19,
            y: 49.2,
          },
          {
            x: 20,
            y: 49.4,
          },
          {
            x: 21,
            y: 49.5,
          },
          {
            x: 22,
            y: 49.7,
          },
          {
            x: 23,
            y: 49.8,
          },
          {
            x: 24,
            y: 50,
          },
          {
            x: 25,
            y: 50.1,
          },
          {
            x: 26,
            y: 50.3,
          },
          {
            x: 27,
            y: 50.4,
          },
          {
            x: 28,
            y: 50.5,
          },
          {
            x: 29,
            y: 50.6,
          },
          {
            x: 30,
            y: 50.7,
          },
          {
            x: 31,
            y: 50.9,
          },
          {
            x: 32,
            y: 51,
          },
          {
            x: 33,
            y: 51.1,
          },
          {
            x: 34,
            y: 51.2,
          },
          {
            x: 35,
            y: 51.2,
          },
          {
            x: 36,
            y: 51.3,
          },
          {
            x: 37,
            y: 51.4,
          },
          {
            x: 38,
            y: 51.5,
          },
          {
            x: 39,
            y: 51.6,
          },
          {
            x: 40,
            y: 51.7,
          },
          {
            x: 41,
            y: 51.7,
          },
          {
            x: 42,
            y: 51.8,
          },
          {
            x: 43,
            y: 51.9,
          },
          {
            x: 44,
            y: 51.9,
          },
          {
            x: 45,
            y: 52,
          },
          {
            x: 46,
            y: 52.1,
          },
          {
            x: 47,
            y: 52.1,
          },
          {
            x: 48,
            y: 52.2,
          },
          {
            x: 49,
            y: 52.2,
          },
          {
            x: 50,
            y: 52.3,
          },
          {
            x: 51,
            y: 52.3,
          },
          {
            x: 52,
            y: 52.4,
          },
          {
            x: 53,
            y: 52.4,
          },
          {
            x: 54,
            y: 52.5,
          },
          {
            x: 55,
            y: 52.5,
          },
          {
            x: 56,
            y: 52.6,
          },
          {
            x: 57,
            y: 52.6,
          },
          {
            x: 58,
            y: 52.7,
          },
          {
            x: 59,
            y: 52.7,
          },
          {
            x: 60,
            y: 52.8,
          },
        ],
      },
      // 1
      {
        color: "#ED9300",
        name: "1",
        data: [
          {
            x: 0,
            y: 35.1,
          },
          {
            x: 1,
            y: 37.7,
          },
          {
            x: 2,
            y: 39.5,
          },
          {
            x: 3,
            y: 40.8,
          },
          {
            x: 4,
            y: 41.8,
          },
          {
            x: 5,
            y: 42.7,
          },
          {
            x: 6,
            y: 43.5,
          },
          {
            x: 7,
            y: 44.1,
          },
          {
            x: 8,
            y: 44.7,
          },
          {
            x: 9,
            y: 45.2,
          },
          {
            x: 10,
            y: 45.6,
          },
          {
            x: 11,
            y: 45.9,
          },
          {
            x: 12,
            y: 46.3,
          },
          {
            x: 13,
            y: 46.5,
          },
          {
            x: 14,
            y: 46.8,
          },
          {
            x: 15,
            y: 47,
          },
          {
            x: 16,
            y: 47.2,
          },
          {
            x: 17,
            y: 47.4,
          },
          {
            x: 18,
            y: 47.6,
          },
          {
            x: 19,
            y: 47.8,
          },
          {
            x: 20,
            y: 48,
          },
          {
            x: 21,
            y: 48.1,
          },
          {
            x: 22,
            y: 48.3,
          },
          {
            x: 23,
            y: 48.4,
          },
          {
            x: 24,
            y: 48.6,
          },
          {
            x: 25,
            y: 48.7,
          },
          {
            x: 26,
            y: 48.9,
          },
          {
            x: 27,
            y: 49,
          },
          {
            x: 28,
            y: 49.1,
          },
          {
            x: 29,
            y: 49.2,
          },
          {
            x: 30,
            y: 49.3,
          },
          {
            x: 31,
            y: 49.4,
          },
          {
            x: 32,
            y: 49.6,
          },
          {
            x: 33,
            y: 49.7,
          },
          {
            x: 34,
            y: 49.7,
          },
          {
            x: 35,
            y: 49.8,
          },
          {
            x: 36,
            y: 49.9,
          },
          {
            x: 37,
            y: 50,
          },
          {
            x: 38,
            y: 50.1,
          },
          {
            x: 39,
            y: 50.2,
          },
          {
            x: 40,
            y: 50.2,
          },
          {
            x: 41,
            y: 50.3,
          },
          {
            x: 42,
            y: 50.4,
          },
          {
            x: 43,
            y: 50.4,
          },
          {
            x: 44,
            y: 50.5,
          },
          {
            x: 45,
            y: 50.6,
          },
          {
            x: 46,
            y: 50.6,
          },
          {
            x: 47,
            y: 50.7,
          },
          {
            x: 48,
            y: 50.8,
          },
          {
            x: 49,
            y: 50.8,
          },
          {
            x: 50,
            y: 50.9,
          },
          {
            x: 51,
            y: 50.9,
          },
          {
            x: 52,
            y: 51,
          },
          {
            x: 53,
            y: 51,
          },
          {
            x: 54,
            y: 51.1,
          },
          {
            x: 55,
            y: 51.1,
          },
          {
            x: 56,
            y: 51.2,
          },
          {
            x: 57,
            y: 51.2,
          },
          {
            x: 58,
            y: 51.3,
          },
          {
            x: 59,
            y: 51.3,
          },
          {
            x: 60,
            y: 51.3,
          },
        ],
      },
      // 0
      {
        color: "#77EA04",
        name: "0",
        data: [
          {
            x: 0,
            y: 33.9,
          },
          {
            x: 1,
            y: 36.5,
          },
          {
            x: 2,
            y: 38.3,
          },
          {
            x: 3,
            y: 39.5,
          },
          {
            x: 4,
            y: 40.6,
          },
          {
            x: 5,
            y: 41.5,
          },
          {
            x: 6,
            y: 42.2,
          },
          {
            x: 7,
            y: 42.8,
          },
          {
            x: 8,
            y: 43.4,
          },
          {
            x: 9,
            y: 43.8,
          },
          {
            x: 10,
            y: 44.2,
          },
          {
            x: 11,
            y: 44.6,
          },
          {
            x: 12,
            y: 44.9,
          },
          {
            x: 13,
            y: 45.2,
          },
          {
            x: 14,
            y: 45.4,
          },
          {
            x: 15,
            y: 45.7,
          },
          {
            x: 16,
            y: 45.9,
          },
          {
            x: 17,
            y: 46.1,
          },
          {
            x: 18,
            y: 46.2,
          },
          {
            x: 19,
            y: 46.4,
          },
          {
            x: 20,
            y: 46.6,
          },
          {
            x: 21,
            y: 46.7,
          },
          {
            x: 22,
            y: 46.9,
          },
          {
            x: 23,
            y: 47,
          },
          {
            x: 24,
            y: 47.2,
          },
          {
            x: 25,
            y: 47.3,
          },
          {
            x: 26,
            y: 47.5,
          },
          {
            x: 27,
            y: 47.6,
          },
          {
            x: 28,
            y: 47.7,
          },
          {
            x: 29,
            y: 47.8,
          },
          {
            x: 30,
            y: 47.9,
          },
          {
            x: 31,
            y: 48,
          },
          {
            x: 32,
            y: 48.1,
          },
          {
            x: 33,
            y: 48.2,
          },
          {
            x: 34,
            y: 48.3,
          },
          {
            x: 35,
            y: 48.4,
          },
          {
            x: 36,
            y: 48.5,
          },
          {
            x: 37,
            y: 48.6,
          },
          {
            x: 38,
            y: 48.7,
          },
          {
            x: 39,
            y: 48.7,
          },
          {
            x: 40,
            y: 48.8,
          },
          {
            x: 41,
            y: 48.9,
          },
          {
            x: 42,
            y: 49,
          },
          {
            x: 43,
            y: 49,
          },
          {
            x: 44,
            y: 49.1,
          },
          {
            x: 45,
            y: 49.2,
          },
          {
            x: 46,
            y: 49.2,
          },
          {
            x: 47,
            y: 49.3,
          },
          {
            x: 48,
            y: 49.3,
          },
          {
            x: 49,
            y: 49.4,
          },
          {
            x: 50,
            y: 49.4,
          },
          {
            x: 51,
            y: 49.5,
          },
          {
            x: 52,
            y: 49.5,
          },
          {
            x: 53,
            y: 49.6,
          },
          {
            x: 54,
            y: 49.6,
          },
          {
            x: 55,
            y: 49.7,
          },
          {
            x: 56,
            y: 49.7,
          },
          {
            x: 57,
            y: 49.8,
          },
          {
            x: 58,
            y: 49.8,
          },
          {
            x: 59,
            y: 49.9,
          },
          {
            x: 60,
            y: 49.9,
          },
        ],
      },
      // -1
      {
        color: "#000000",
        name: "-1",
        data: [
          {
            x: 0,
            y: 32.7,
          },
          {
            x: 1,
            y: 35.4,
          },
          {
            x: 2,
            y: 37,
          },
          {
            x: 3,
            y: 38.3,
          },
          {
            x: 4,
            y: 39.3,
          },
          {
            x: 5,
            y: 40.2,
          },
          {
            x: 6,
            y: 40.9,
          },
          {
            x: 7,
            y: 41.5,
          },
          {
            x: 8,
            y: 42,
          },
          {
            x: 9,
            y: 42.5,
          },
          {
            x: 10,
            y: 42.9,
          },
          {
            x: 11,
            y: 43.2,
          },
          {
            x: 12,
            y: 43.5,
          },
          {
            x: 13,
            y: 43.8,
          },
          {
            x: 14,
            y: 44.1,
          },
          {
            x: 15,
            y: 44.3,
          },
          {
            x: 16,
            y: 44.5,
          },
          {
            x: 17,
            y: 44.7,
          },
          {
            x: 18,
            y: 44.9,
          },
          {
            x: 19,
            y: 45,
          },
          {
            x: 20,
            y: 45.2,
          },
          {
            x: 21,
            y: 45.3,
          },
          {
            x: 22,
            y: 45.5,
          },
          {
            x: 23,
            y: 45.6,
          },
          {
            x: 24,
            y: 45.8,
          },
          {
            x: 25,
            y: 45.9,
          },
          {
            x: 26,
            y: 46.1,
          },
          {
            x: 27,
            y: 46.2,
          },
          {
            x: 28,
            y: 46.3,
          },
          {
            x: 29,
            y: 46.4,
          },
          {
            x: 30,
            y: 46.5,
          },
          {
            x: 31,
            y: 46.6,
          },
          {
            x: 32,
            y: 46.7,
          },
          {
            x: 33,
            y: 46.8,
          },
          {
            x: 34,
            y: 46.9,
          },
          {
            x: 35,
            y: 47,
          },
          {
            x: 36,
            y: 47.1,
          },
          {
            x: 37,
            y: 47.2,
          },
          {
            x: 38,
            y: 47.3,
          },
          {
            x: 39,
            y: 47.3,
          },
          {
            x: 40,
            y: 47.4,
          },
          {
            x: 41,
            y: 47.5,
          },
          {
            x: 42,
            y: 47.5,
          },
          {
            x: 43,
            y: 47.6,
          },
          {
            x: 44,
            y: 47.7,
          },
          {
            x: 45,
            y: 47.7,
          },
          {
            x: 46,
            y: 47.8,
          },
          {
            x: 47,
            y: 47.9,
          },
          {
            x: 48,
            y: 47.9,
          },
          {
            x: 49,
            y: 48,
          },
          {
            x: 50,
            y: 48,
          },
          {
            x: 51,
            y: 48.1,
          },
          {
            x: 52,
            y: 48.1,
          },
          {
            x: 53,
            y: 48.2,
          },
          {
            x: 54,
            y: 48.2,
          },
          {
            x: 55,
            y: 48.3,
          },
          {
            x: 56,
            y: 48.3,
          },
          {
            x: 57,
            y: 48.4,
          },
          {
            x: 58,
            y: 48.4,
          },
          {
            x: 59,
            y: 48.5,
          },
          {
            x: 60,
            y: 48.5,
          },
        ],
      },
      // -2
      {
        color: "#F30037",
        name: "-2",
        data: [
          {
            x: 0,
            y: 31.5,
          },
          {
            x: 1,
            y: 34.2,
          },
          {
            x: 2,
            y: 35.8,
          },
          {
            x: 3,
            y: 37.1,
          },
          {
            x: 4,
            y: 38.1,
          },
          {
            x: 5,
            y: 38.9,
          },
          {
            x: 6,
            y: 39.6,
          },
          {
            x: 7,
            y: 40.2,
          },
          {
            x: 8,
            y: 40.7,
          },
          {
            x: 9,
            y: 41.2,
          },
          {
            x: 10,
            y: 41.5,
          },
          {
            x: 11,
            y: 41.9,
          },
          {
            x: 12,
            y: 42.2,
          },
          {
            x: 13,
            y: 42.4,
          },
          {
            x: 14,
            y: 42.7,
          },
          {
            x: 15,
            y: 42.9,
          },
          {
            x: 16,
            y: 43.1,
          },
          {
            x: 17,
            y: 43.3,
          },
          {
            x: 18,
            y: 43.5,
          },
          {
            x: 19,
            y: 43.6,
          },
          {
            x: 20,
            y: 43.8,
          },
          {
            x: 21,
            y: 44,
          },
          {
            x: 22,
            y: 44.1,
          },
          {
            x: 23,
            y: 44.3,
          },
          {
            x: 24,
            y: 44.4,
          },
          {
            x: 25,
            y: 44.5,
          },
          {
            x: 26,
            y: 44.7,
          },
          {
            x: 27,
            y: 44.8,
          },
          {
            x: 28,
            y: 44.9,
          },
          {
            x: 29,
            y: 45,
          },
          {
            x: 30,
            y: 45.1,
          },
          {
            x: 31,
            y: 45.2,
          },
          {
            x: 32,
            y: 45.3,
          },
          {
            x: 33,
            y: 45.4,
          },
          {
            x: 34,
            y: 45.5,
          },
          {
            x: 35,
            y: 45.6,
          },
          {
            x: 36,
            y: 45.7,
          },
          {
            x: 37,
            y: 45.8,
          },
          {
            x: 38,
            y: 45.8,
          },
          {
            x: 39,
            y: 45.9,
          },
          {
            x: 40,
            y: 46,
          },
          {
            x: 41,
            y: 46.1,
          },
          {
            x: 42,
            y: 46.1,
          },
          {
            x: 43,
            y: 46.2,
          },
          {
            x: 44,
            y: 46.3,
          },
          {
            x: 45,
            y: 46.3,
          },
          {
            x: 46,
            y: 46.4,
          },
          {
            x: 47,
            y: 46.4,
          },
          {
            x: 48,
            y: 46.5,
          },
          {
            x: 49,
            y: 46.5,
          },
          {
            x: 50,
            y: 46.6,
          },
          {
            x: 51,
            y: 46.7,
          },
          {
            x: 52,
            y: 46.7,
          },
          {
            x: 53,
            y: 46.8,
          },
          {
            x: 54,
            y: 46.8,
          },
          {
            x: 55,
            y: 46.9,
          },
          {
            x: 56,
            y: 46.9,
          },
          {
            x: 57,
            y: 46.9,
          },
          {
            x: 58,
            y: 47,
          },
          {
            x: 59,
            y: 47,
          },
          {
            x: 60,
            y: 47.1,
          },
        ],
      },
    ],
    headCircunferenceForAgeBoy: [
      // 2
      {
        color: "#8260BB",
        name: "2",
        data: [
          {
            x: 0,
            y: 37,
          },
          {
            x: 1,
            y: 39.6,
          },
          {
            x: 2,
            y: 41.5,
          },
          {
            x: 3,
            y: 42.9,
          },
          {
            x: 4,
            y: 44,
          },
          {
            x: 5,
            y: 45,
          },
          {
            x: 6,
            y: 45.8,
          },
          {
            x: 7,
            y: 46.4,
          },
          {
            x: 8,
            y: 47,
          },
          {
            x: 9,
            y: 47.5,
          },
          {
            x: 10,
            y: 47.9,
          },
          {
            x: 11,
            y: 48.3,
          },
          {
            x: 12,
            y: 48.6,
          },
          {
            x: 13,
            y: 48.9,
          },
          {
            x: 14,
            y: 49.2,
          },
          {
            x: 15,
            y: 49.4,
          },
          {
            x: 16,
            y: 49.6,
          },
          {
            x: 17,
            y: 49.8,
          },
          {
            x: 18,
            y: 50,
          },
          {
            x: 19,
            y: 50.2,
          },
          {
            x: 20,
            y: 50.4,
          },
          {
            x: 21,
            y: 50.5,
          },
          {
            x: 22,
            y: 50.7,
          },
          {
            x: 23,
            y: 50.8,
          },
          {
            x: 24,
            y: 51,
          },
          {
            x: 25,
            y: 51.1,
          },
          {
            x: 26,
            y: 51.2,
          },
          {
            x: 27,
            y: 51.4,
          },
          {
            x: 28,
            y: 51.5,
          },
          {
            x: 29,
            y: 51.6,
          },
          {
            x: 30,
            y: 51.7,
          },
          {
            x: 31,
            y: 51.8,
          },
          {
            x: 32,
            y: 51.9,
          },
          {
            x: 33,
            y: 52,
          },
          {
            x: 34,
            y: 52.1,
          },
          {
            x: 35,
            y: 52.2,
          },
          {
            x: 36,
            y: 52.3,
          },
          {
            x: 37,
            y: 52.4,
          },
          {
            x: 38,
            y: 52.5,
          },
          {
            x: 39,
            y: 52.5,
          },
          {
            x: 40,
            y: 52.6,
          },
          {
            x: 41,
            y: 52.7,
          },
          {
            x: 42,
            y: 52.8,
          },
          {
            x: 43,
            y: 52.8,
          },
          {
            x: 44,
            y: 52.9,
          },
          {
            x: 45,
            y: 53,
          },
          {
            x: 46,
            y: 53,
          },
          {
            x: 47,
            y: 53.1,
          },
          {
            x: 48,
            y: 53.1,
          },
          {
            x: 49,
            y: 53.2,
          },
          {
            x: 50,
            y: 53.2,
          },
          {
            x: 51,
            y: 53.3,
          },
          {
            x: 52,
            y: 53.4,
          },
          {
            x: 53,
            y: 53.4,
          },
          {
            x: 54,
            y: 53.5,
          },
          {
            x: 55,
            y: 53.5,
          },
          {
            x: 56,
            y: 53.5,
          },
          {
            x: 57,
            y: 53.6,
          },
          {
            x: 58,
            y: 53.6,
          },
          {
            x: 59,
            y: 53.7,
          },
          {
            x: 60,
            y: 53.7,
          },
        ],
      },
      // 1
      {
        color: "#ED9300",
        name: "1",
        data: [
          {
            x: 0,
            y: 35.7,
          },
          {
            x: 1,
            y: 38.4,
          },
          {
            x: 2,
            y: 40.3,
          },
          {
            x: 3,
            y: 41.7,
          },
          {
            x: 4,
            y: 42.8,
          },
          {
            x: 5,
            y: 43.8,
          },
          {
            x: 6,
            y: 44.6,
          },
          {
            x: 7,
            y: 45.2,
          },
          {
            x: 8,
            y: 45.8,
          },
          {
            x: 9,
            y: 46.3,
          },
          {
            x: 10,
            y: 46.7,
          },
          {
            x: 11,
            y: 47,
          },
          {
            x: 12,
            y: 47.4,
          },
          {
            x: 13,
            y: 47.6,
          },
          {
            x: 14,
            y: 47.9,
          },
          {
            x: 15,
            y: 48.1,
          },
          {
            x: 16,
            y: 48.3,
          },
          {
            x: 17,
            y: 48.5,
          },
          {
            x: 18,
            y: 48.7,
          },
          {
            x: 19,
            y: 48.9,
          },
          {
            x: 20,
            y: 49,
          },
          {
            x: 21,
            y: 49.2,
          },
          {
            x: 22,
            y: 49.3,
          },
          {
            x: 23,
            y: 49.5,
          },
          {
            x: 24,
            y: 49.6,
          },
          {
            x: 25,
            y: 49.7,
          },
          {
            x: 26,
            y: 49.9,
          },
          {
            x: 27,
            y: 50,
          },
          {
            x: 28,
            y: 50.1,
          },
          {
            x: 29,
            y: 50.2,
          },
          {
            x: 30,
            y: 50.3,
          },
          {
            x: 31,
            y: 50.4,
          },
          {
            x: 32,
            y: 50.5,
          },
          {
            x: 33,
            y: 50.6,
          },
          {
            x: 34,
            y: 50.7,
          },
          {
            x: 35,
            y: 50.8,
          },
          {
            x: 36,
            y: 50.9,
          },
          {
            x: 37,
            y: 51,
          },
          {
            x: 38,
            y: 51,
          },
          {
            x: 39,
            y: 51.1,
          },
          {
            x: 40,
            y: 51.2,
          },
          {
            x: 41,
            y: 51.3,
          },
          {
            x: 42,
            y: 51.3,
          },
          {
            x: 43,
            y: 51.4,
          },
          {
            x: 44,
            y: 51.4,
          },
          {
            x: 45,
            y: 51.5,
          },
          {
            x: 46,
            y: 51.6,
          },
          {
            x: 47,
            y: 51.6,
          },
          {
            x: 48,
            y: 51.7,
          },
          {
            x: 49,
            y: 51.7,
          },
          {
            x: 50,
            y: 51.8,
          },
          {
            x: 51,
            y: 51.8,
          },
          {
            x: 52,
            y: 51.9,
          },
          {
            x: 53,
            y: 51.9,
          },
          {
            x: 54,
            y: 52,
          },
          {
            x: 55,
            y: 52,
          },
          {
            x: 56,
            y: 52.1,
          },
          {
            x: 57,
            y: 52.1,
          },
          {
            x: 58,
            y: 52.1,
          },
          {
            x: 59,
            y: 52.2,
          },
          {
            x: 60,
            y: 52.2,
          },
        ],
      },
      // 0
      {
        color: "#77EA04",
        name: "0",
        data: [
          {
            x: 0,
            y: 34.5,
          },
          {
            x: 1,
            y: 37.3,
          },
          {
            x: 2,
            y: 39.1,
          },
          {
            x: 3,
            y: 40.5,
          },
          {
            x: 4,
            y: 41.6,
          },
          {
            x: 5,
            y: 42.6,
          },
          {
            x: 6,
            y: 43.3,
          },
          {
            x: 7,
            y: 44,
          },
          {
            x: 8,
            y: 44.5,
          },
          {
            x: 9,
            y: 45,
          },
          {
            x: 10,
            y: 45.4,
          },
          {
            x: 11,
            y: 45.8,
          },
          {
            x: 12,
            y: 46.1,
          },
          {
            x: 13,
            y: 46.3,
          },
          {
            x: 14,
            y: 46.6,
          },
          {
            x: 15,
            y: 46.8,
          },
          {
            x: 16,
            y: 47,
          },
          {
            x: 17,
            y: 47.2,
          },
          {
            x: 18,
            y: 47.4,
          },
          {
            x: 19,
            y: 47.5,
          },
          {
            x: 20,
            y: 47.7,
          },
          {
            x: 21,
            y: 47.8,
          },
          {
            x: 22,
            y: 48,
          },
          {
            x: 23,
            y: 48.1,
          },
          {
            x: 24,
            y: 48.3,
          },
          {
            x: 25,
            y: 48.4,
          },
          {
            x: 26,
            y: 48.5,
          },
          {
            x: 27,
            y: 48.6,
          },
          {
            x: 28,
            y: 48.7,
          },
          {
            x: 29,
            y: 48.8,
          },
          {
            x: 30,
            y: 48.9,
          },
          {
            x: 31,
            y: 49,
          },
          {
            x: 32,
            y: 49.1,
          },
          {
            x: 33,
            y: 49.2,
          },
          {
            x: 34,
            y: 49.3,
          },
          {
            x: 35,
            y: 49.4,
          },
          {
            x: 36,
            y: 49.5,
          },
          {
            x: 37,
            y: 49.5,
          },
          {
            x: 38,
            y: 49.6,
          },
          {
            x: 39,
            y: 49.7,
          },
          {
            x: 40,
            y: 49.7,
          },
          {
            x: 41,
            y: 49.8,
          },
          {
            x: 42,
            y: 49.9,
          },
          {
            x: 43,
            y: 49.9,
          },
          {
            x: 44,
            y: 50,
          },
          {
            x: 45,
            y: 50.1,
          },
          {
            x: 46,
            y: 50.1,
          },
          {
            x: 47,
            y: 50.2,
          },
          {
            x: 48,
            y: 50.2,
          },
          {
            x: 49,
            y: 50.3,
          },
          {
            x: 50,
            y: 50.3,
          },
          {
            x: 51,
            y: 50.4,
          },
          {
            x: 52,
            y: 50.4,
          },
          {
            x: 53,
            y: 50.4,
          },
          {
            x: 54,
            y: 50.5,
          },
          {
            x: 55,
            y: 50.5,
          },
          {
            x: 56,
            y: 50.6,
          },
          {
            x: 57,
            y: 50.6,
          },
          {
            x: 58,
            y: 50.7,
          },
          {
            x: 59,
            y: 50.7,
          },
          {
            x: 60,
            y: 50.7,
          },
        ],
      },
      // -1
      {
        color: "#000000",
        name: "-1",
        data: [
          {
            x: 0,
            y: 33.2,
          },
          {
            x: 1,
            y: 36.1,
          },
          {
            x: 2,
            y: 38,
          },
          {
            x: 3,
            y: 39.3,
          },
          {
            x: 4,
            y: 40.4,
          },
          {
            x: 5,
            y: 41.4,
          },
          {
            x: 6,
            y: 42.1,
          },
          {
            x: 7,
            y: 42.7,
          },
          {
            x: 8,
            y: 43.3,
          },
          {
            x: 9,
            y: 43.7,
          },
          {
            x: 10,
            y: 44.1,
          },
          {
            x: 11,
            y: 44.5,
          },
          {
            x: 12,
            y: 44.8,
          },
          {
            x: 13,
            y: 45,
          },
          {
            x: 14,
            y: 45.3,
          },
          {
            x: 15,
            y: 45.5,
          },
          {
            x: 16,
            y: 45.7,
          },
          {
            x: 17,
            y: 45.9,
          },
          {
            x: 18,
            y: 46,
          },
          {
            x: 19,
            y: 46.2,
          },
          {
            x: 20,
            y: 46.4,
          },
          {
            x: 21,
            y: 46.5,
          },
          {
            x: 22,
            y: 46.6,
          },
          {
            x: 23,
            y: 46.8,
          },
          {
            x: 24,
            y: 46.9,
          },
          {
            x: 25,
            y: 47,
          },
          {
            x: 26,
            y: 47.1,
          },
          {
            x: 27,
            y: 47.2,
          },
          {
            x: 28,
            y: 47.3,
          },
          {
            x: 29,
            y: 47.4,
          },
          {
            x: 30,
            y: 47.5,
          },
          {
            x: 31,
            y: 47.6,
          },
          {
            x: 32,
            y: 47.7,
          },
          {
            x: 33,
            y: 47.8,
          },
          {
            x: 34,
            y: 47.9,
          },
          {
            x: 35,
            y: 48,
          },
          {
            x: 36,
            y: 48,
          },
          {
            x: 37,
            y: 48.1,
          },
          {
            x: 38,
            y: 48.2,
          },
          {
            x: 39,
            y: 48.2,
          },
          {
            x: 40,
            y: 48.3,
          },
          {
            x: 41,
            y: 48.4,
          },
          {
            x: 42,
            y: 48.4,
          },
          {
            x: 43,
            y: 48.5,
          },
          {
            x: 44,
            y: 48.5,
          },
          {
            x: 45,
            y: 48.6,
          },
          {
            x: 46,
            y: 48.7,
          },
          {
            x: 47,
            y: 48.7,
          },
          {
            x: 48,
            y: 48.7,
          },
          {
            x: 49,
            y: 48.8,
          },
          {
            x: 50,
            y: 48.8,
          },
          {
            x: 51,
            y: 48.9,
          },
          {
            x: 52,
            y: 48.9,
          },
          {
            x: 53,
            y: 49,
          },
          {
            x: 54,
            y: 49,
          },
          {
            x: 55,
            y: 49.1,
          },
          {
            x: 56,
            y: 49.1,
          },
          {
            x: 57,
            y: 49.1,
          },
          {
            x: 58,
            y: 49.2,
          },
          {
            x: 59,
            y: 49.2,
          },
          {
            x: 60,
            y: 49.2,
          },
        ],
      },
      // -2
      {
        color: "#F30037",
        name: "-2",
        data: [
          {
            x: 0,
            y: 31.9,
          },
          {
            x: 1,
            y: 34.9,
          },
          {
            x: 2,
            y: 36.8,
          },
          {
            x: 3,
            y: 38.1,
          },
          {
            x: 4,
            y: 39.2,
          },
          {
            x: 5,
            y: 40.1,
          },
          {
            x: 6,
            y: 40.9,
          },
          {
            x: 7,
            y: 41.5,
          },
          {
            x: 8,
            y: 42,
          },
          {
            x: 9,
            y: 42.5,
          },
          {
            x: 10,
            y: 42.9,
          },
          {
            x: 11,
            y: 43.2,
          },
          {
            x: 12,
            y: 43.5,
          },
          {
            x: 13,
            y: 43.8,
          },
          {
            x: 14,
            y: 44,
          },
          {
            x: 15,
            y: 44.2,
          },
          {
            x: 16,
            y: 44.4,
          },
          {
            x: 17,
            y: 44.6,
          },
          {
            x: 18,
            y: 44.7,
          },
          {
            x: 19,
            y: 44.9,
          },
          {
            x: 20,
            y: 45,
          },
          {
            x: 21,
            y: 45.2,
          },
          {
            x: 22,
            y: 45.3,
          },
          {
            x: 23,
            y: 45.4,
          },
          {
            x: 24,
            y: 45.5,
          },
          {
            x: 25,
            y: 45.6,
          },
          {
            x: 26,
            y: 45.8,
          },
          {
            x: 27,
            y: 45.9,
          },
          {
            x: 28,
            y: 46,
          },
          {
            x: 29,
            y: 46.1,
          },
          {
            x: 30,
            y: 46.1,
          },
          {
            x: 31,
            y: 46.2,
          },
          {
            x: 32,
            y: 46.3,
          },
          {
            x: 33,
            y: 46.4,
          },
          {
            x: 34,
            y: 46.5,
          },
          {
            x: 35,
            y: 46.6,
          },
          {
            x: 36,
            y: 46.6,
          },
          {
            x: 37,
            y: 46.7,
          },
          {
            x: 38,
            y: 46.8,
          },
          {
            x: 39,
            y: 46.8,
          },
          {
            x: 40,
            y: 46.9,
          },
          {
            x: 41,
            y: 46.9,
          },
          {
            x: 42,
            y: 47,
          },
          {
            x: 43,
            y: 47,
          },
          {
            x: 44,
            y: 47.1,
          },
          {
            x: 45,
            y: 47.1,
          },
          {
            x: 46,
            y: 47.2,
          },
          {
            x: 47,
            y: 47.2,
          },
          {
            x: 48,
            y: 47.3,
          },
          {
            x: 49,
            y: 47.3,
          },
          {
            x: 50,
            y: 47.4,
          },
          {
            x: 51,
            y: 47.4,
          },
          {
            x: 52,
            y: 47.5,
          },
          {
            x: 53,
            y: 47.5,
          },
          {
            x: 54,
            y: 47.5,
          },
          {
            x: 55,
            y: 47.6,
          },
          {
            x: 56,
            y: 47.6,
          },
          {
            x: 57,
            y: 47.6,
          },
          {
            x: 58,
            y: 47.7,
          },
          {
            x: 59,
            y: 47.7,
          },
          {
            x: 60,
            y: 47.7,
          },
        ],
      },
    ],
    /** Perímetro Cefálico para la edad - Nuevo */

    jsonDataGraphic: [],
    specialtySelected: null,
    queryTypeSelected: null,
    documentPatient: null,
    telexpertisesListWP: [],
  };
}

export default state;
