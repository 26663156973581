import { executeApi } from "@/apis";
import { MedicationAdministratorState } from "@/interfaces/global/interfaces";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import errorException from "@/functions/ErrorException";

const URL_PATH = "/api/telemedicine/medicine-administration";

const actions: ActionTree<MedicationAdministratorState, StateInterface> = {
  async getListMedicationAdministrator({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().get(URL_PATH, { params });
      const { success, message } = data;

      commit("setMedicationAdministratorList", data.data);

      return { success, message, data: data.data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async createMedicationAdministrator({ commit }, params): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}`, params);
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async createRegisterByAdministredMedicine(
    { commit },
    { params, id }
  ): Promise<object> {
    try {
      const { data } = await executeApi().post(
        `${URL_PATH}/medicine/${id}/registers`,
        params
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async closeRegisterByAdministredMedicine(
    { commit },
    { id, reason }
  ): Promise<object> {
    try {
      const { data } = await executeApi().post(`${URL_PATH}/${id}/close`, {
        reason,
      });
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async getRegistersByAdministredMedicine(
    { commit },
    { id, page }
  ): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH}/medicine/${id}/registers?page=${page}`
      );
      const { success, message } = data;

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async deleteRegistersByAdministredMedicine({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().delete(
        `${URL_PATH}/medicine/registers/${id}`
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async getByIdMedicationAdministrator({ commit }, id): Promise<object> {
    try {
      const { data } = await executeApi().get(`${URL_PATH}/${id}`);
      const { success, message } = data;

      commit("setMedicalAdministrator", data.data);

      return { success, message };
    } catch (error: any) {
      return errorException(error);
    }
  },
  async getMedicinesListByIdMedicationAdministrator(
    { commit },
    { id, page }
  ): Promise<object> {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH}/${id}/medicines?rows=${page}`
      );
      const { success, message } = data;

      return { success, message, data: data.data };
    } catch (error: any) {
      return errorException(error);
    }
  },
};

export default actions;
