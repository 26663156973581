import { ActionTree } from "vuex";
// apis
import { surveysBasicApi } from "@/apis/surveys/surveysApi";

// composable
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";

// interfaces
import { ISurveyAbsenceCustom } from "@/interfaces/customs/ISurveysCustom";
import { StateInterface } from "../index";
import { IError } from "@/interfaces/customs/interface";
import { ISurveyAbsence } from "@/interfaces/global/ISurveys";
import { useRedirectURL } from "@/composables/useRedirectURL";
import { useAlertV2 } from "@/composables/useAlertV2";

// destructure composables
const { showAlert } = useAlertV2();

const actions: ActionTree<ISurveyAbsenceCustom, StateInterface> = {
  async LoadSuveysOptions({ commit }, surveyId: string) {
    const { openMainLoader } = useMainLoader();
    openMainLoader(true);
    try {
      const surveyList = await surveysBasicApi.get(`/${surveyId}`, {
        headers: {
          Authorization: `Bearer ${surveyId}`,
        },
      });

      const surveyDataRef: ISurveyAbsence = surveyList.data;

      const surveyInfo = {
        key: surveyDataRef.data.key,
        patient: surveyDataRef.data.patient,
        user: surveyDataRef.data.user ?? "Sin definir",
        specialty: surveyDataRef.data.specialty.toUpperCase(),
        scheduledAtformatted:
          surveyDataRef.data.scheduled_at_formatted ?? "Sin definir",
        status: !surveyDataRef.data.completed_at_formatted
          ? "survey/success"
          : "survey/error",
        type: !surveyDataRef.data.completed_at_formatted
          ? surveyDataRef.data.type
          : "survey-is-fill",
      };

      const surveyOptions = surveyDataRef.data.questions.map((survey) => ({
        id: survey.id,
        reference: `answer${survey.id}`,
        survey: survey.name,
        options: survey.options.map((option) => ({
          label: option.name,
          value: option.id,
        })),
      }));

      const surveyMessage = surveyDataRef.message;

      openMainLoader(false);

      commit("setSurveyInfo", surveyInfo);
      commit("setSurveyOptions", surveyOptions);

      //alert
      showAlert(surveyMessage, "success");
    } catch (e) {
      const { showCatchError } = showErrorInTS();
      const error = e as IError;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      const surveyInfo = {
        key: "",
        patient: "",
        user: "",
        specialty: "",
        scheduledAtformatted: "Sin definir",
        status: "survey/error",
        type: "survey-error",
      };

      commit("setSurveyInfo", surveyInfo);
      openMainLoader(false);

      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async ResponseSurvey(_, { answerIds, surveyId, token, surveyRecaptcha }) {
    const { goToURLMutation } = useRedirectURL();
    const { openMainLoader } = useMainLoader();
    openMainLoader(true);
    try {
      await surveysBasicApi.post(
        `/${surveyId}`,
        {
          answers: answerIds,
          "g-recaptcha-response": surveyRecaptcha,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      goToURLMutation("SurveyStatus");
    } catch (e) {
      const { showCatchError } = showErrorInTS();
      const error = e as IError;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      openMainLoader(false);

      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },
};

export default actions;
