import {
  IData,
  IMedicine,
  IRequestResponse,
} from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";
import { Datum, MedicinesState } from "./state";

const mutation: MutationTree<MedicinesState> = {
  getMedicineById(state: MedicinesState, medicine: Datum): void {
    if (medicine) {
      const { code, description, pbs }: Datum = medicine;
      state.medicineCode = code;
      state.medicineDescription = description;
      state.pbs =
        pbs === 0 ? { label: "No", pbsValue: 0 } : { label: "Si", pbsValue: 1 };
    }
  },
  clearMedicinesData(state: MedicinesState): void {
    state.medicineCode = "";
    state.medicineDescription = "";
  },

  // Medicines V.3
  setMedicines(state: MedicinesState, data?: IData): void {
    if (data) {
      state.data.medicines = data.data as IMedicine[];

      state.pages.medicines.currentPage.value = data.current_page;
      state.pages.medicines.lastPage.value = data.last_page;
    } else {
      state.data.medicines = [];
    }
  },
  setResponse(state: MedicinesState, response?: IRequestResponse) {
    if (response) {
      state.response = response;
    } else {
      state.response = { success: false, message: "" };
    }
  },
  setListMedicinesSelector(state: MedicinesState, data: object[] = []) {
    state.medicinesListSelector = data;
  },
};

export default mutation;
