export interface NursingNotesState {
  nursingNotesList: unknown;
  nursingNote: unknown;
  nursingNotesCurrentPage: number;
  nursingNotesLastPage: number;
}

function state(): NursingNotesState {
  return {
    nursingNotesList: [],
    nursingNote: null,
    nursingNotesCurrentPage: 1,
    nursingNotesLastPage: 1,
  };
}

export default state;
