import {  computed, ref } from "vue";


const redirectURL = ref<string | undefined>();
const getRedirectURL = computed(() => redirectURL.value)

export const useRedirectURL = () => {



  function goToURLMutation(goToURL: string) {
    redirectURL.value = goToURL;
  }

  function redirectURLAction() {
    if (!redirectURL.value) return;
  }

  return {
    redirectURL,
    getRedirectURL,
    goToURLMutation,
    
  };
};
