import { ActionTree } from "vuex";
import { DiagnosticsState, Response } from "./state";
import { StateInterface } from "../index";
import { diagnosticsApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const actions: ActionTree<DiagnosticsState, StateInterface> = {
  async changeDiagnosticStatusById(
    { commit },
    { token, id }
  ): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await diagnosticsApi().post(
        `/changeStatus/${id}`,
        {},
        config
      );
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async createDiagnostic(
    { commit },
    { token, bodyRequest }
  ): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await diagnosticsApi().post("/", bodyRequest, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getDiagnosticById({ commit }, { token, id }): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await diagnosticsApi().get(`/${id}`, config);
      const { success, message, data: diagnosticData } = data;

      commit("getDiagnosticById", diagnosticData);
      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async updateDiagnostic(
    { commit },
    { token, bodyRequest }
  ): Promise<Response> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await diagnosticsApi().post("/", bodyRequest, config);
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },


  // Diagnostics V.2
  async loadDiagnostics({ commit }, params) {
    commit("setDiagnostics");
    commit("setResponse");

    try {
      const { data: response } = await diagnosticsApi().get("/", { params });
      const { success, message, data } = response;

      commit("setDiagnostics", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;
