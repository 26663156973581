import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/gestion-de-pacientes/certificados",
    beforeEnter: [isAuthenticatedGuard],
    name: "CertificatesListView",
    component: () =>
      import(
        /* webpackChunkName: "avalible View" */
        "@/views/certificates-view/certificate-list-view/CertificatesListView.vue"
      )
  },
];
