import { IPatientSearchInfoCustom } from "@/interfaces/customs/Patient";
import {
  IAvailableOptions,
  IAvailablesByDay,
} from "../../interfaces/global/Availables";
import { IMeetResponseCustom } from "@/interfaces/customs/MeetResponseCustom";
export interface GeneralStoreUI {
  serviceTypeOptions: IAvailableOptions[];
  serviceTypeOptionsLoader: boolean;
  //current Telexpertice Selected
  currentTelexperticeSelected: {
    responseStatus: string;
    title: string;
    status: string;
    baseURL: string;
    baseMeetURL: string;
    meetURL: string;
    telexperticeStatus: string;
    initialHour: string;
    finalHour: string;
    dateYear: string;
    patient: string;
    completeResponseURL: string;
    telexperticeId: number | undefined;
  };

  //telexpertice badge
  jobListBadge:
    | {
        id: number;
        name: string;
        date: string;
        availableHour: string;
        finalHour: string;
        careCenter: string;
        patient: string;
        document: string;
        priority: string;
        type: string;
        status: string;
        meetURL: string;
      }
    | undefined;

  jobListBadgeTotal: number;

  //redirect
  currentRenderURL: string;
  goToRenderURL: string;

  //current component
  sectionComponent: string;

  //basic loader
  loader: boolean;

  // loading page
  loadAvailablePage: string;

  //update section
  updateSection: string;

  // alert general ini
  showAlert: boolean;
  alertMessage: string;
  alertTitle: string;
  alertType: string;

  // popup
  popupOpen: boolean;
  popupLoading: boolean;
  popupLoadingMessage: string;
  popupBasicList: string[] | undefined;
  popupBasicListSelected: string;
  popupTitle: string;
  popupMessage: string;
  popupBtnAcceptLabel: string;
  popupBtnCancelLabel: string;
  showContinue: boolean;
  showClose: boolean;
  //BreadCrumbs
  currentBreadcrumb: {
    title: string;
    path: {
      label: string;
      path: string;
    }[];
    btn: string;
    navigate: string;
  };

  // Selectors care centers
  centerRefSelectedLoader: boolean;
  centerRefDisable: boolean;
  firstCenterRefOption: { label: string; value: number } | undefined;
  centerRefSelected: { label: string; value: number } | undefined;
  centerRefSelectorOptions: {
    label: string;
    value: number;
  }[];

  // Selectors care centers
  careCenterSelectedLoader: true;
  careCenterSelected: string | undefined;
  firstCareCenterOption: { label: string; id: number } | undefined;
  careCenterSelectorOptions: {
    id: number;
    label: string;
    centersTypesId: number;
    status: string;
  }[];

  //eps selector
  epsSelected: string | undefined;
  epsSelectorOptions: {
    value: number;
    label: string;
  }[];
  epsSelectorLoader: boolean;

  //regime selector
  regimenSelected: string | undefined;
  regimenSelectorOptions: {
    value: number;
    label: string;
  }[];
  regimenSelectorLoader: boolean;

  // Selectors specialities
  specialitiesByCenterRefLoader: boolean;
  careCenterDisable: boolean;
  specialitiesByCenterRefSelected: { label: string; id: number } | undefined;
  specialitiesByCenterRefOptions: {
    id: number;
    created_at: string | undefined;
    updated_at: string | undefined;
    description: string;
    status: string;
  }[];

  // Selectors doctors by specialities
  doctorBySpecialityLoader: boolean;
  doctorBySpecialitySelected: { label: string; id: number } | undefined;
  doctorBySpecialityOptions: {
    id: number;
    email: string;
    status: string;
    last_login: string | undefined;
    logout: string | undefined;
    expiry_date: string | undefined;
    updated_at: string | undefined;
    user_types_id: number;
    user_info_id: number;
    centers_id: number;
    contract_id: string | undefined;
    get_user_details: {
      id: number;
      created_at: string | undefined;
      updated_at: string | undefined;
      names: string;
      surnames: string;
      document: string;
      phone: string;
      signature: string | undefined;
      photo: string | undefined;
      professional_reg: string | undefined;
      professional_type: string | undefined;
      gender: string;
    };
  }[];

  // telexpertices availables
  telexperticesAvailablesLoader: boolean;
  telexperticesAvailablesSelected: undefined;
  telexperticesAvailablesOptions: {
    id: number;
    user_id: number | undefined;
    patient_id: number;
    type_teleexpertise_id: number;
    is_notified: number;
    status: string;
    application_date: string;
    date: string | undefined;
    priority: string;
    reason_for_consultation: string;
    current_illness: string;
    vital_signs: {
      height: string;
      weight: string;
      heart_rate: string;
      temperature: string;
      blood_pressure: string;
      bmi_for_age_BMIE: string;
      oxygen_saturation: string;
      weight_for_age_PE: string;
      weight_for_age_TE: string;
      breathing_frequency: string;
      weight_for_PT_table: string;
      bmi_for_gestational_age_BMIGA: string;
      head_circumference_for_age_PCE: string;
    };
    patient_signature_url: string | undefined;
    query_type: string;
    specialty_id: number;
    created_by: number;
    center_reference_id: number;
    url_meeting: string | undefined;
    created_at: string;
    updated_at: string;
    center: {
      id: number;
      created_at: string | undefined;
      updated_at: string | undefined;
      name: string;
      nit: string;
      address: string;
      phone: string;
      email: string;
      logo: string | undefined;
      contact_name: string;
      contact_phone: string;
      status: string;
      centers_types_id: number;
      laravel_through_key: number;
    };
    patient: {
      id: number;
      name: string;
      last_name: string;
      care_center_id: number;
      birthday: string;
      document_type_id: number;
      document: string;
      eps_id: number;
      email: string;
      gender: string;
      marital_status: string;
      phone: string;
      department_code: string;
      city_code: string;
      address: string;
      regime_id: number;
      membership_id: number;
      scholarship: string;
      status: string;
      companion_phone: string;
      companion_name: string;
      occupation: string;
      rh_group: string;
      pacientescol: string | undefined;
      background: string | undefined;
      allergies: string | undefined;
      reason: string | undefined;
      url_informed_consent: string;
      type_of_population: string;
      relationship_companion: string | undefined;
      is_companion: number;
      created_at: string | undefined;
      updated_at: string | undefined;
    };
    creator: {
      id: number;
      email: string;
      email_verified_at: string;
      status: string;
      last_login: string;
      last_change_password: string | undefined;
      logout: string | undefined;
      expiry_date: string | undefined;
      created_at: string | undefined;
      updated_at: string;
      user_types_id: number;
      user_info_id: number;
      centers_id: number;
      contract_id: number | undefined;
      name: string;
      details: {
        id: number;
        created_at: string;
        updated_at: string;
        names: string;
        surnames: string;
        document: string;
        phone: string;
        signature: string | undefined;
        photo: string | undefined;
        professional_reg: string | undefined;
        professional_type: string | undefined;
        gender: string;
      };
    };
    specialty: {
      id: number;
      created_at: string | undefined;
      updated_at: string | undefined;
      description: string;
      status: string;
    };
  }[];

  // patient card info
  patientCardInfo:
    | {
        label: string;
        telexpertiseId: number;

        description: string;
        priority: string;
        patientId: number;
        patientName: string;
        patientDocument: string;
        careCenter: string;
        careCenterId: string;

        specialityName: string;
        specialityId: number;
        creatorName: string;
        creatorId: number;
      }
    | undefined;
  patientCardLoader: boolean;

  // telexpertices availables by month calendar
  availableByCalendar:
    | {
        day: number;
        date: string;
        quota: number;
        availability: boolean;
        telexpertice?: boolean;
      }
    | undefined;
  //current schedule component
  currentScheduleComponent: string;
  scheduleCalendarLoader: boolean;

  // info selected by day
  selectedAvailableByDay:
    | { day: number; date: string; quota: number; availability: boolean }
    | undefined;

  // availables hour by day
  availablesHoursByDay: IAvailablesByDay[];
  //schedule reducer type
  scheduleReducer: string;
  //schedule info reducer type
  scheduleCreatePayload:
    | {
        telexpertise_id: number;
        user_id: number;
        date: string;
      }
    | undefined;

  reloadScheduleStatus: string;
  patientInfo: IPatientSearchInfoCustom | undefined
  searchByPatient: boolean
  meetResponse: IMeetResponseCustom | object;

  meetStatus: "counter/active" | "counter/standby" | "counter/error";
}

function state(): GeneralStoreUI {
  return {
    serviceTypeOptionsLoader: false,
    serviceTypeOptions: [],
    //current Telexpertice Selected
    currentTelexperticeSelected: {
      responseStatus: "standby",
      title: "",
      status: "",
      baseURL: "",
      baseMeetURL: "",
      meetURL: "",
      telexperticeStatus: "",
      initialHour: "",
      finalHour: "",
      dateYear: "",
      patient: "",
      completeResponseURL: "",
      telexperticeId: undefined,
    },

    jobListBadge: undefined,
    jobListBadgeTotal: 0,

    //redirect
    currentRenderURL: "",
    goToRenderURL: "",

    //current component
    sectionComponent: "default",

    //loader
    loader: true,

    // loading page
    loadAvailablePage: "standby",

    //update section
    updateSection: "standby",

    //alert
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
    alertType: "success",

    // popup
    popupOpen: false,
    popupLoading: false,
    popupLoadingMessage: "Cargando...",
    popupTitle: "Accediendo al Modulo",
    popupMessage: "en proceso...",
    popupBasicList: [],
    popupBasicListSelected: "",
    popupBtnAcceptLabel: "Aceptar",
    popupBtnCancelLabel: "Cancelar",
    showContinue: false,
    showClose: false,

    //header
    currentBreadcrumb: {
      title: "Sin Configurar",
      path: [
        {
          label: "Sin configurar",
          path: "",
        },
      ],
      btn: "",
      navigate: "",
    },

    // Selectors ref centers
    centerRefSelectedLoader: true,
    centerRefDisable: false,
    centerRefSelected: undefined,
    firstCenterRefOption: undefined,
    centerRefSelectorOptions: [],

    // Selectors care centers
    careCenterSelectedLoader: true,
    careCenterDisable: false,
    careCenterSelected: undefined,
    firstCareCenterOption: undefined,
    careCenterSelectorOptions: [],

    //eps selector
    epsSelected: undefined,
    epsSelectorOptions: [],
    epsSelectorLoader: false,

    //regime selector
    regimenSelected: undefined,
    regimenSelectorOptions: [],
    regimenSelectorLoader: false,

    // Selectors specialities
    specialitiesByCenterRefLoader: false,
    specialitiesByCenterRefSelected: undefined,
    specialitiesByCenterRefOptions: [],

    // Selectors doctors by specialities
    doctorBySpecialityLoader: false,
    doctorBySpecialitySelected: undefined,
    doctorBySpecialityOptions: [],

    // telexpertices availables
    telexperticesAvailablesLoader: false,
    telexperticesAvailablesSelected: undefined,
    telexperticesAvailablesOptions: [],

    // patient card info
    patientCardInfo: undefined,
    patientCardLoader: false,

    // telexpertices availables by month calendar
    availableByCalendar: undefined,

    //current schedule component
    currentScheduleComponent: "CalendarBasicSchedule",
    scheduleCalendarLoader: false,

    // info selected by day
    selectedAvailableByDay: undefined,
    // availables hour by day
    availablesHoursByDay: [],

    //schedule reducer type
    scheduleReducer: "schedule/standby",

    //schedule info reducer
    scheduleCreatePayload: undefined,

    //reload schedule
    reloadScheduleStatus: "schedule/standby",

    patientInfo: undefined,
    searchByPatient: false,

     
        meetResponse: {},
    
        meetStatus: "counter/standby",

  };
}

export default state;
