import { ISupportCustom } from "@/interfaces/customs/ISupportCustom";


function state(): ISupportCustom {
    return {
        ticketViewStatus: 'support/reload',
        chatMessages: [],
        ticketsStatus: 'support/initial-state',
        ticketInfo: [],
        ticketList: [],
        supportDateRange: {
            dateRangeIni: '',
            dateRangeEnd: ''
        },
        ticketListStatus: false,
        firstTicketPage: 0,
        lastTicketPage: 0,
    }
}

export default state;