import { ActionTree } from "vuex";
import { NursingNotesState } from "./state";
import { StateInterface } from "../index";
import { nursingNotesApi } from "@/apis";
import moment from "moment";

const actions: ActionTree<NursingNotesState, StateInterface> = {
  async getNursingNotes(
    { commit },
    { token, referenceCenterId, careCenterId, page }
  ): Promise<object> {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await nursingNotesApi.get(
        `?page=${page}&reference_center=${referenceCenterId}&care_center=${careCenterId}`,
        config
      );
      const { success, message, data: dataRequest } = data;

      dataRequest.data.forEach((element: any, i: number) => {
        element[
          "patient_name"
        ] = `${element.patient.name} ${element.patient?.last_name}`;
        element["patient_document"] = element.patient.document;
        element["created_at"] = element.created_at
          ? moment(element.created_at).format("DD-MM-YYYY h:mm")
          : null;
      });

      commit("setNursingNotesList", data);
      return { success, message, dataRequest };
    } catch (error) {
      return errorException(error);
    }
  },
  async createNursingNotes({ commit }, { token, bodyRequest, documents }) {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      : {};

    try {
      const { data } = await nursingNotesApi.post(
        "/",
        {
          patient: bodyRequest.patient,
          event_date: bodyRequest.event_date,
          notes: bodyRequest.notes,
          vital_signs: JSON.stringify(bodyRequest.vital_signs),
          documents,
        },
        config
      );
      const { success, message } = data;

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
  async getNursingNoteByID({ commit }, { token, id }) {
    const config: object = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {};

    try {
      const { data } = await nursingNotesApi.get(`/${id}`, config);
      const { success, message, data: dataRequest } = data;

      commit("setNursingNote", data);
      return { success, message, dataRequest };
    } catch (error) {
      return errorException(error);
    }
  },
};

function errorException(error: any) {
  //No hay conexión
  if (error.message == "Network Error") {
    const success = false;
    const { message } = error;
    return { success, message };
  }
  const { data } = error.response;
  const { success, message } = data;
  return { success, message };
}

export default actions;
