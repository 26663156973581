//vuex
import { ActionTree } from "vuex";

// apis
import { getWorkListV3 } from "@/apis/healthcare-management/jobListApi";

// composables
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";

// interfaces
import { StateInterface } from "../../index";
import { IErrors } from "@/interfaces/customs/IShowErrors";
import { JobListUI } from "./state";
import {
  ICardsOptions,
  IWorkListBadge,
  IWorkListData,
} from "@/interfaces/global/WorkList";
import { useAlertV2 } from "@/composables/useAlertV2";

const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();
const { showAlert } = useAlertV2();

const actions: ActionTree<JobListUI, StateInterface> = {
  async DispatchJobList({ commit }, goToURL) {
    openMainLoader(true);
    // loader
    try {
      const dashboardCards = await getWorkListV3().get(`getWorkListDash`);

      const jobList = await getWorkListV3().get(`getWorkList?${goToURL}`);

      const dashboardCardsOptions: ICardsOptions = dashboardCards.data.data;
      const listOptions: IWorkListData[] = jobList.data.data.data;
      const counterCards = Object.entries(dashboardCardsOptions);

      const result = counterCards.map(([title, count]) => ({
        title,
        count,
      }));

      const listOptionsMessage = jobList.data.message;
      const currentPage = jobList.data.data.current_page;
      const lastPage = jobList.data.data.last_page;
      const listRow = [...listOptions].map((option) => {
        return {
          availableHour: option.date,
          careCenter: option.careCenter,
          date: option.date,
          document: option.document,
          finalHour: option.final_hour,
          id: option.id,
          meetURL: option.url_meeting,
          name: option.doctor,
          patient: option.patient,
          priority: option.priority,
          status: option.status,
          telexperticeType: option.type,
          type: option.type,
          serviceTypeId: option.type_id,
        };
      });
      // selectors - center
      commit("setJobCounterList", result);
      commit("setJobList", listRow);
      commit("setJobListCurrentPage", currentPage);
      commit("setJobListLastPage", lastPage);

      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${listOptionsMessage}!`, "success");
      return "success";
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setJobCounterList", []);
      commit("setJobList", []);
      // loader
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async DispatchJobListBadge({ commit }) {
    openMainLoader(true);

    try {
      const jobListBadge = await getWorkListV3().get(
        `getWorkList?notifications=true&rows=5`
      );
      const listOptions = jobListBadge.data.data.data;
      const listOptionsMessage = jobListBadge.data.message;
      const listJobBadgeRow = [...listOptions].map((option: IWorkListBadge) => {
        return {
          availableHour: option.date,
          careCenter: "sin definir",
          date: option.date,
          document: option.document,
          finalHour: option.final_hour,
          id: option.id,
          meetURL: option.url_meeting,
          name: `${option.patient}`,
          patient: option.patient,
          priority: option.priority,
          status: option.status,
          telexperticeType: option.type,
          type: option.type,
        };
      });

      const counterCards = listJobBadgeRow.length;
      // selectors - center
      commit("general/setJobListBadge", listJobBadgeRow, { root: true });
      commit("general/setJobListBadgeTotal", counterCards, { root: true });

      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${listOptionsMessage}!`, "success");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      openMainLoader(false);
      // alert
      commit("general/setJobListBadge", [], { root: true });
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async DispatchCancel(
    { commit },
    {
      telexperticeId,
      reasonSelected,
      typeId,
    }: { telexperticeId: string; reasonSelected: string; typeId: number }
  ) {
    commit("general/setPopupBasicList", null, { root: true });
    commit(
      "general/setPopupLoadingMessage",
      `Cancelando por ${reasonSelected}...`,
      {
        root: true,
      }
    );

    commit("general/setPopupLoading", true, { root: true });
    // loader
    try {
      const updateCancel = await getWorkListV3().post(`/cancel`, {
        session_id: telexperticeId,
        reason: reasonSelected,
        type: typeId,
      });

      const telexperticeCancelMessage = updateCancel.data.message;

      // popup
      commit("general/setPopupBasicListSelected", "", { root: true });
      commit("general/setPopupOpen", false, { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupLoadingMessage", "", { root: true });
      // alert
      showAlert(`¡${telexperticeCancelMessage}!`, "success");

      //updateSection
      commit("general/setUpdateSection", "success", { root: true });
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // popup
      commit("general/setPopupOpen", false, { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupBasicListSelected", "");

      // alert
      showAlert(String(showCatchError(errorParse)), "error");
      return "schedule/cancel/error";
    }
  },
};

export default actions;
