export const useTypes = () => {
  const serviceType = (serviceType: number) => {
    switch (serviceType) {
      case -1:
        return "Todas las telexperticias";
      case 1:
        return "Telexperticia interactiva";
      case 2:
        return "Telexperticia no Interactivas";
      case 3:
        return "Teleapoyo Interactiva";
      case 9:
        return "Teleapoyo no interactiva";
      case 8:
        return "Telemedicina";
      case 10:
        return "Telemedicina no interactiva";
      default:
        return "Sin definir...";
    }
  };

  const responseMeetStatus = (statusMessage: string) => {
    switch (statusMessage) {
      case "Esta sesion esta cancelada":
        return {
          type: "counter/cancel",
          message: statusMessage,
        };
      case "Esta sesion esta enviada":
        return {
          type: "counter/reschedule",
          message: statusMessage,
        };

      case "Esta sesion esta realizada":
        return {
          type: "counter/success",
          message: statusMessage,
        };

      default:
        return {
          type: "counter/error",
          message: statusMessage,
        };
    }
  };

  const fullFileTypes = [
    'image/jpeg',
    'image/png',
    'image/pjpeg',
    'image/bmp',
    'image/x-windows-bmp',
    'video/avi',
    'video/msvideo',
    'video/x-msvideo',
    'audio/mpeg',
    'audio/x-mpeg',
    'audio/mp3',
    'audio/x-mp3',
    'audio/mpeg3',
    'audio/x-mpeg3',
    'audio/mpg',
    'audio/x-mpg',
    'audio/x-mpegaudio',
    'application/x-troff-msvideo',
    'application/msword',
    'application/pdf',
    'application/zsa',
    'application/dicom',
    'application/scp',
    'application/octet-stream',
    'text/plain',
    'text/xml',
    'application/x-zip-compressed'
  ]


  const fileTypes = (fileType: string) => {
    switch (fileType) {
      case "image/jpeg":
        return 'JPG'
      case "image/png":
        return 'png'
      case "application/x-zip-compressed":
        return 'ZIP'
      case "application/pdf":
        return 'PDF'
      case 'text/plain':
        return 'TXT'
      default:
        return '?'
    }
  }

  




  return {
    serviceType,
    responseMeetStatus,
    fileTypes,
    fullFileTypes
  };
};
