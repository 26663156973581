import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { IProcedure, IRequestResponse } from "@/interfaces/global/interfaces";
import { IPages } from "@/interfaces/customs/interface";
import {
  ProceduresData,
  ProceduresList,
  ProceduresPages,
} from "@/store/procedures/state";

export const useProceduresStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    // Procedures V.2
    procedures: computed<IProcedure[]>(
      () => store.state.procedures.data.procedures
    ),
    proceduresPages: computed<IPages>(
      () => store.state.procedures.pages.procedures
    ),
    data: computed<ProceduresData>(() => store.state.procedures.data),
    pages: computed<ProceduresPages>(() => store.state.procedures.pages),
    response: computed<IRequestResponse>(() => store.state.procedures.response),

    // Getters
    procedureCode: computed<string>(
      () => store.getters["procedures/getProcedureCode"]
    ),
    procedureDescription: computed<string>(
      () => store.getters["procedures/getProcedureDescription"]
    ),

    // Procedures V.2
    getProceduresList: computed<ProceduresList[]>(
      () => store.getters["procedures/getProceduresList"]
    ),

    // Actions
    changeProcedureStatusById: (token: string, id: number) =>
      store.dispatch("procedures/changeProcedureStatusById", { token, id }),
    createProcedure: (token: string, bodyRequest: object) =>
      store.dispatch("procedures/createProcedure", { token, bodyRequest }),
    getProcedureById: (token: string, id: string | string[]) =>
      store.dispatch("procedures/getProcedureById", { token, id }),
    updateProcedure: (token: string, bodyRequest: object) =>
      store.dispatch("procedures/updateProcedure", {
        token,
        bodyRequest,
      }),
    _loadProceduresWithoutPaginate: (
      filters: object | null = null,
      page: number = 1,
      urlRequest: string
    ) =>
      store.dispatch("procedures/loadProceduresWithoutPaginate", {
        filters,
        page,
        urlRequest,
      }),
    _loadProceduresWithoutPaginateV2: (params: object = {}) =>
      store.dispatch("procedures/loadProceduresWithoutPaginateV2", params),

    // Procedures V.2
    loadProcedures: (params: {
      status: string;
      page: number;
      full_reference?: string;
      care_center_id?: number;
    }) => store.dispatch("procedures/loadProcedures", params),

    // Mutations
    // Procedures V.2
    setProcedures: () => store.commit("procedures/setProcedures"),
  };
};
