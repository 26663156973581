import { IPages } from "@/interfaces/customs/interface";
import { IMedicine, IRequestResponse } from "@/interfaces/global/interfaces";

export interface Datum {
  id: number;
  created_at: null | string;
  updated_at: null | string;
  code: string;
  description: string;
  drug_concentration: null | string;
  dosage_form: null | string;
  status: string;
  pbs: number | undefined;
}

export interface Response {
  success: boolean;
  message: string;
}

// Medicines V.3
export type MedicinesPages = Record<"medicines", IPages>;

export interface MedicinesList {
  id: IMedicine["id"];
  code: IMedicine["code"];
  description: IMedicine["description"];
  pbs: string;
  status: IMedicine["status"];
}

export interface MedicinesData {
  medicines: IMedicine[];
}

export interface MedicinesState {
  medicineCode: string;
  medicineDescription: string;
  pbs:
    | {
        label: string;
        pbsValue: number;
      }
    | undefined;

  // Medicines V.3
  data: MedicinesData;
  pages: MedicinesPages;
  response: IRequestResponse;
  medicinesListSelector: object[];
}

function state(): MedicinesState {
  return {
    medicineCode: "",
    medicineDescription: "",
    pbs: undefined,

    // Medicines V.3
    data: {
      medicines: [],
    },
    pages: {
      medicines: {
        currentPage: { value: 1 },
        lastPage: { value: 1 },
      },
    },
    response: {
      success: false,
      message: "",
    },
    medicinesListSelector: [],
  };
}

export default state;
