import { StateInterface } from "@/store/index";
import { NotificationsList } from "@/store/notifications/state";
import { computed } from "vue";
import { useStore } from "vuex";

export const useNotificationsStore = () => {
  const store = useStore<StateInterface>();

  return {
    // State
    notifications: computed(() => store.state.notifications.data.notifications),
    notificationsPages: computed(
      () => store.state.notifications.pages.notifications
    ),
    typeNotifications: computed(
      () => store.state.notifications.data.typeNotifications
    ),
    data: computed(() => store.state.notifications.data),
    pages: computed(() => store.state.notifications.pages),
    response: computed(() => store.state.notifications.response),

    // Getters
    getNotificationsList: computed<NotificationsList[]>(
      () => store.getters["notifications/getNotificationsList"]
    ),

    // Actions
    loadNotifications: (params: {
      notification: boolean;
      type?: number;
      search?: string;
      from_date?: string;
      to_date?: string;
      status?: string;
      page?: number;
    }) => store.dispatch("notifications/loadNotifications", params),
    loadTypeNotifications: () =>
      store.dispatch("notifications/loadTypeNotifications"),
    readNotification: (id: number) =>
      store.dispatch("notifications/readNotification", id),

    //Mutations
    setNotifications: () => store.commit("notifications/setNotifications"),
    setTypeNotifications: () =>
      store.commit("notifications/setTypeNotifications"),
  };
};
