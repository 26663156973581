import { GetterTree } from "vuex";
import { ProceduresState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<ProceduresState, StateInterface> = {
  getProcedureCode(state) {
    return state.procedureCode;
  },
  getProcedureDescription(state) {
    return state.procedureDescription;
  },

  // Procedures V.2
  getProceduresList(state) {
    return state.data.procedures.length >= 1
      ? state.data.procedures.map(({ id, code, description, status }) => ({
          id,
          code,
          description,
          status,
        }))
      : [];
  },
};

export default getters;
