import { MutationTree } from "vuex";
import { NursingNotesState } from "./state";

const mutation: MutationTree<NursingNotesState> = {
  setNursingNotesList(state: NursingNotesState, list: any): void {
    state.nursingNotesList = list?.data?.data;

    state.nursingNotesCurrentPage = list?.data?.current_page || 1;
    state.nursingNotesLastPage = list?.data?.last_page || 1;
  },

  setNursingNote(state: NursingNotesState, nursingNote: any): void {
    state.nursingNote = nursingNote;
  },
};

export default mutation;
