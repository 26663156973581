import { GetterTree } from "vuex";
import { GeneralStoreUI } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<GeneralStoreUI, StateInterface> = {
  getServiceTypeOptions(state) {
    return state.serviceTypeOptions;
  },

  getServiceTypeOptionsLoader(state) {
    return state.serviceTypeOptionsLoader;
  },

  //current Telexpertice Selected
  getCurrentTelexperticeSelected(state) {
    return state.currentTelexperticeSelected;
  },

  //telexpertice badge
  getJobListBadge(state) {
    return state.jobListBadge;
  },

  getJobListBadgeTotal(state) {
    return state.jobListBadgeTotal;
  },

  //redirect
  getCurrentRenderURL(state) {
    return state.currentRenderURL;
  },
  getGoToRenderURL(state) {
    return state.goToRenderURL;
  },

  // get current component
  getSectionComponent(state) {
    return state.sectionComponent;
  },

  // get loader
  getLoader(state) {
    return state.loader;
  },

  //update section
  getUpdateSection(state) {
    return state.updateSection;
  },

  // loading page
  getLoadAvailablePage(state) {
    return state.loadAvailablePage;
  },

  // get alert
  getShowAlert(state) {
    return state.showAlert;
  },
  getAlertTitle(state) {
    return state.alertTitle;
  },

  getAlertMessage(state) {
    return state.alertMessage;
  },

  getAlertType(state) {
    const type = state.alertType;
    switch (type) {
      case "success":
        return {
          color: "bg-positive",
          text: "text-white",
          icon: "mdi-check-circle",
        };

      case "warning":
        return {
          color: "bg-warning",
          text: "text-white",
          icon: "mdi-message-alert",
        };
      case "error":
        return {
          color: "bg-negative",
          text: "text-white",
          icon: "mdi-alert-circle",
        };

      case "info":
        return {
          color: "bg-info",
          text: "text-blue-10",
          icon: "mdi-information",
        };

      default:
        return {
          color: "bg-primary",
          text: "text-white",
          icon: "mdi-hand-pointing-right",
        };
    }
  },

  // get popup
  getPopupOpen(state) {
    return state.popupOpen;
  },
  getPopupLoading(state) {
    return state.popupLoading;
  },
  getPopupLoadingMessage(state) {
    return state.popupLoadingMessage;
  },
  getPopupTitle(state) {
    return state.popupTitle;
  },
  getPopupMessage(state) {
    return state.popupMessage;
  },

  getPopupBasicList(state) {
    return state.popupBasicList;
  },

  getPopupBasicListSelected(state) {
    return state.popupBasicListSelected;
  },

  getPopupBtnAcceptLabel(state) {
    return state.popupBtnAcceptLabel;
  },

  getPopupBtnCancelLabel(state) {
    return state.popupBtnCancelLabel;
  },

  getShowContinue(state) {
    return state.showContinue;
  },
  getShowClose(state) {
    return state.showClose;
  },

  // Breadcrumb routes
  getCurrentBreadcrumb(state) {
    return state.currentBreadcrumb;
  },

  // Selectors centers ref
  getCenterRefSelectedLoader(state) {
    return state.centerRefSelectedLoader;
  },

  getCenterRefSelected(state) {
    return state.centerRefSelected;
  },
  getCenterRefDisable(state) {
    return state.centerRefDisable;
  },

  getFirstCenterRefOption(state) {
    return state.firstCenterRefOption;
  },

  // Selectors care centers
  getCareCenterSelectedLoader(state) {
    return state.careCenterSelectedLoader;
  },

  getCareCenterSelected(state) {
    return state.careCenterSelected;
  },

  getCareCenterDisable(state) {
    return state.careCenterDisable;
  },
  getFirstCareCenterOption(state) {
    return state.firstCareCenterOption;
  },

  //eps selector
  getEpsSelected(state) {
    return state.epsSelected;
  },

  getEpsSelectorOptions(state) {
    return state.epsSelectorOptions;
  },
  getEpsSelectorLoader(state) {
    return state.epsSelectorLoader;
  },

  //regime selector
  getRegimenSelected(state) {
    return state.regimenSelected;
  },

  getRegimenSelectorOptions(state) {
    return state.regimenSelectorOptions;
  },
  getRegimenSelectorLoader(state) {
    return state.regimenSelectorLoader;
  },

  // Selectors specialities
  getSpecialitiesByCenterRefLoader(state) {
    return state.specialitiesByCenterRefLoader;
  },
  getSpecialitiesByCenterRefSelected(state) {
    return state.specialitiesByCenterRefSelected;
  },
  getSpecialitiesByCenterRefOptions(state) {
    const reference = [...state.specialitiesByCenterRefOptions];
    const options = reference.map((option) => ({
      label: option.description,
      id: option.id,
    }));
    return options.length > 0 ? options : [];
  },

  // Selectors doctors by specialities
  getDoctorBySpecialityLoader(state) {
    return state.doctorBySpecialityLoader;
  },
  getDoctorBySpecialitySelected(state) {
    return state.doctorBySpecialitySelected;
  },
  getDoctorBySpecialityOptions(state) {
    const reference = [...state.doctorBySpecialityOptions];
    const options = reference.map((option) => ({
      label:
        option.get_user_details.names + " " + option.get_user_details.surnames,
      id: option.id,
    }));
    return options.length > 0 ? options : [];
  },

  // telexpertices availables
  getTelexperticesAvailablesLoader(state) {
    return state.telexperticesAvailablesLoader;
  },
  getTelexperticesAvailablesOptions(state) {
    const reference = [...state.telexperticesAvailablesOptions];
    const options = reference.map((option) => ({
      label: option.current_illness,
      telexpertiseId: option.id,

      description: option.reason_for_consultation,
      priority: option.priority,
      patientId: option.patient_id,
      patientName: `${option.patient?.name} ${option.patient?.last_name}`,
      patientDocument: option.patient?.document,
      careCenter: option.center.name,
      careCenterId: option.center.id,

      specialityName: option.specialty.description,
      specialityId: option.specialty.id,
      creatorName: option.creator.name,
      creatorId: option.creator.id,
    }));

    return options.length > 0 ? options : [];
  },

  // patient card info
  getPatientCardInfo(state) {
    return state.patientCardInfo;
  },

  getPatientCardLoader(state) {
    return state.patientCardLoader;
  },

  // telexpertices availables by month calendar
  getAvailableByCalendar(state) {
    return state.availableByCalendar;
  },

  //current schedule component
  getCurrentScheduleComponent(state) {
    return state.currentScheduleComponent;
  },

  getScheduleCalendarLoader(state) {
    return state.scheduleCalendarLoader;
  },

  // info selected by day
  getSelectedAvailableByDay(state) {
    return state.selectedAvailableByDay;
  },

  // availables hour by day
  getAvailablesHoursByDay(state) {
    const availablesHours = [...state.availablesHoursByDay];
    const setupInfo = availablesHours.map((available) => {
      const setupData = {
        availbleFullYear: available.date.slice(0, 10),
        availableHour: available.date.slice(11, 16),
        availableDay: new Date(available.date.slice(0, 10)).getUTCDate(),
      };

      const availableDate = {
        selectedFullYear: new Date(setupData.availbleFullYear).getFullYear(),
        selectedMonth: new Date(setupData.availbleFullYear).getMonth() + 1,
        selectedDay: new Date(setupData.availbleFullYear).getUTCDate(),
      };
      return { setupData, availableDate, available };
    });
    return setupInfo ? setupInfo : [];
  },
  //schedule reducer type
  getScheduleReducer(state) {
    return state.scheduleReducer;
  },

  //schedule info reducer type
  getScheduleCreatePayload(state) {
    return state.scheduleCreatePayload;
  },
  //reload schedule
  getReloadScheduleStatus(state) {
    return state.scheduleCreatePayload;
  },

  getPatientInfo(state) {
    return state.patientInfo;
  },

  getSearchByPatient(state) {
    return state.searchByPatient;
  },


  getMeetResponse(state) {
   return state.meetResponse ;
  },

  getMeetStatus(state) {
   return state.meetStatus ;
  },
};
export default getters;
