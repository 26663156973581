import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import store from "@/store";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAppInstance } from "@/main";

const { openMainLoader } = useMainLoader();

const apiBase = axios.create({
  baseURL: `${process.env.VUE_APP_URL}`,
});

apiBase.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    const token = sessionStorage.getItem("token");

    //Intercept the request and do something before it is sent
    if (token) {
      config.headers.Authorization = `Bearer ${sessionStorage.getItem(
        "token"
      )}`;
    }
    return config;
  },
  (error) => {}
);

apiBase.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    openMainLoader(false);
    if (error.response.status === 401) {
      store.dispatch("auth/logOutUser", sessionStorage.getItem("token"));
      store.commit("selectors/setCenters", { type: 1, data: undefined });
      store.commit("selectors/setCenters", { type: 2, data: undefined });
      sessionStorage.clear();
      window.location.replace("/auth");

      setTimeout(() => {
        // Verificar y reinicializar el servicio Ably
        const app = getAppInstance();
        if (app && app.config.globalProperties.$reinitializeAbly) {
          (app.config.globalProperties.$reinitializeAbly as () => void)();
        }
      }, 1000);
    }

    return Promise.reject(error);
  }
);

export default apiBase;
